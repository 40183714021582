import {AfterViewInit, Component, OnInit} from '@angular/core';
import { IndependentFuncs as IndiFuncs } from 'src/functions/IndependentFuncs';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css'],
  // encapsulation: ViewEncapsulation.Emulated
})

export class ModalComponent implements OnInit, AfterViewInit{

  constructor() { }

  ngOnInit() {
  }

  ngAfterViewInit() {

  }

  closeLink() {
    const basic = document.getElementById('myLink');
    basic.style.display = 'none';
  }

  closeHelp() {
    const helpTab = document.getElementById('helpModal');
    helpTab.style.display = 'none';
    const helpContent = document.getElementById('helpContent');
    helpContent.hidden = true;
    const helpBackButton = document.getElementById('helpBackButton');
    helpBackButton.hidden = true;

    const helpButtonWrapper = document.getElementById('helpButtonWrapper');
    helpButtonWrapper.hidden = false;
  }

  backHelp() {
    const helpContent = document.getElementById('helpContent');
    helpContent.hidden = true;
    const helpBackButton = document.getElementById('helpBackButton');
    helpBackButton.hidden = true;

    const helpButtonWrapper = document.getElementById('helpButtonWrapper');
    helpButtonWrapper.hidden = false;
  }

  animationHelp() {
    this.showOneHelp('animationHelp');
  }

  tableHelp() {
    this.showOneHelp('tableHelp');
  }

  gridHelp() {
    this.showOneHelp('gridHelp');
  }

  toolbarHelp() {
    this.showOneHelp('toolbarHelp');
  }


  showOneHelp(id: string) {
    const helpButtonWrapper = document.getElementById('helpButtonWrapper');
    helpButtonWrapper.hidden = true;
    const helpContent = document.getElementById('helpContent');
    helpContent.hidden = false;
    const helpBackButton = document.getElementById('helpBackButton');
    helpBackButton.hidden = false;

    this.hideAllSections();
    const show = document.getElementById(id);
    this.showSection(show);
  }

  hideAllSections() {
    const animationHelp = document.getElementById('animationHelp');
    const gridHelp = document.getElementById('gridHelp');
    const tableHelp = document.getElementById('tableHelp');
    const toolbarHelp = document.getElementById('toolbarHelp');

    this.hideSection(animationHelp);
    this.hideSection(gridHelp);
    this.hideSection(tableHelp);
    this.hideSection(toolbarHelp);
  }

  hideSection (section: HTMLElement) {
    section.style.display = 'none';
  }

  showSection(section: HTMLElement) {
    section.style.display = 'flex';
  }

  go4Bar() {
    // const content = 'j=A,0,0,R,t,t,0%0AB,0,2,R,f,f,0%0AC,4,2,R,f,f,0%0AD,4,0,R,t,f,0%0A&l=1,A%7CB,b,0,0,0,0,0,2,0,2%0A2,C%7CB,b,0,2,4,2,4,2,0,2%0A3,D%7CC,b,4,0,4,0,4,2,4,2%0A&f=';
    const content = 'j=A,-1.24,-0.89,AB,R,t,0,0.1,t,%0AB,-0.1,2.01,AB%7CCB,R,f,0,0.1,f,%0AC,4.26,2.27,CB%7CDC,R,f,' +
      '0,0.1,f,%0AD,3.36,-1.01,DC,R,t,0,0.1,f,%0A&l=AB,1,1,-0.67,0.5599999999999998,A%7CB,,l,-0.1,-0.89,-1.24,-0.89,-1.24,2.01,-0.1,' +
      '2.01%0ACB,1,1,2.08,2.1399999999999997,B%7CC,,l,-0.1,2.27,4.26,2.27,4.26,2.01,-0.1,2.01%0ADC,1,1,3.81,0.63,C%7CD,,l,4.26,-1.01,' +
      '3.36,-1.01,3.36,2.27,4.26,2.27%0A&f=&pp=&tp=&s=10,false,false,cm';
    const url = IndiFuncs.getURL();
    // const dataURLString = `${content}`;
    const dataURLString = `${url}?${content}`;
    IndiFuncs.openURL(dataURLString);
  }

  goWattI() {
    const content = 'j=A,-3.74,-2.41,AB,R,t,0,0.1,t,%0AB,-2.72,0.91,AB%7CCBD,R,f,0,0.1,f,%0AC,1.58,0.43,' +
      'CBD%7CCFG,R,f,0,0.1,f,%0AD,-0.24,4.01,DE%7CCBD,R,f,0,0.1,f,%0AE,5.08,5.31,DE%7CFE,R,f,0,0.1,f,%0AF,8.14,3.35,FE%7CCFG,R,f,' +
      '0,0.1,f,%0AG,7.32,-3.51,CFG,R,t,0,0.1,f,%0A&l=AB,1,1,-3.2300000000000004,-0.75,A%7CB,,l,-2.72,-2.41,-3.74,-2.41,-3.74,0.91,' +
      '-2.72,0.91%0ADE,1,1,2.42,4.659999999999999,D%7CE,,l,5.08,4.01,-0.24,4.01,-0.24,5.31,5.08,5.31%0ACBD,1,1,-0.3510600176910136,' +
      '2.6313373415180026,B%7CC%7CD,,et,1.902,0.193,-3.086,0.75,-2.604,5.07,2.384,4.513%0AFE,1,1,6.609999999999999,4.33,E%7CF,,l,5.08,' +
      '3.35,8.14,3.35,8.14,5.31,5.08,5.31%0ACFG,1,1,6.165062615799421,-1.0419214930288367,C%7CF%7CG,,et,1.182,0.472,8.375,3.674,' +
      '11.148,-2.556,3.955,-5.757%0A&f=&pp=&tp=&s=10,false,false,cm';
    const url = IndiFuncs.getURL();
    // const dataURLString = `${content}`;
    const dataURLString = `${url}?${content}`;
    IndiFuncs.openURL(dataURLString);
  }

  goWattII() {
    const content = 'j=A,-3.74,-1.71,AB,R,t,0,0.1,f,%0AB,-3.14,1.33,AB%7CCB,R,f,0,0.1,f,%0AC,0.18,1.15,CB%7CDCG,R,' +
      'f,0,0.1,f,%0AD,3.68,1.35,ED%7CDCG,R,f,0,0.1,f,%0AE,8.64,0.75,ED%7CFE,R,f,0,0.1,f,%0AF,8,-2.25,FE,R,t,0,0.1,f,%0AG,2.12,-1.83,DCG,' +
      'R,t,0,0.1,t,%0A&l=AB,1,1,-3.4400000000000004,-0.18999999999999995,A%7CB,,l,-3.14,-1.71,-3.74,-1.71,-3.74,1.33,-3.14,1.33%0ACB,1,' +
      '1,-1.48,1.24,B%7CC,,l,-3.14,1.15,0.18,1.15,0.18,1.33,-3.14,1.33%0AED,1,1,6.16,1.05,D%7CE,,l,3.68,0.75,8.64,0.75,8.64,1.35,' +
      '3.68,1.35%0AFE,1,1,8.32,-0.75,E%7CF,,l,8.64,-2.25,8,-2.25,8,0.75,8.64,0.75%0ADCG,1,1,2.077692480567706,-0.43461840993483425,' +
      'C%7CD%7CG,,et,-0.2,1.47,4.288,1.548,4.355,-2.339,-0.133,-2.417%0A&f=&pp=&tp=&s=10,false,false,cm';
    const url = IndiFuncs.getURL();
    // const dataURLString = `${content}`;
    const dataURLString = `${url}?${content}`;
    IndiFuncs.openURL(dataURLString);
  }

  goStephensonI() {
    const content = 'http://localhost:4200/?j=A,-1.59,-1.12,ABE,R,t,0,0.1,t,%0AB,0.86,0.19,CB%7CABE,R,f,0,0.1,f,%0AC,' +
      '4.62,0.77,CB%7CGCD,R,f,0,0.1,f,%0AE,-1.9,1.89,EF%7CABE,R,f,0,0.1,f,%0AF,1.72,3.81,EF%7CGF,R,f,0,0.1,f,%0AG,7.12,' +
      '1.17,GF%7CGCD,R,f,0,0.1,f,%0AD,6.35,-1.08,GCD,R,t,0,0.1,f,%0A&l=CB,1,1,2.74,0.48,B%7CC,,l,4.62,0.19,0.86,0.19,0.86,' +
      '0.77,4.62,0.77%0AEF,1,1,-0.09000000000000002,2.85,E%7CF,,l,1.72,1.89,-1.9,1.89,-1.9,3.81,1.72,3.81%0AABE,1,1,-1.202,' +
      '0.7669999999999999,A%7CB%7CE,,et,1.468,0.306,-2.04,-1.81,-3.872,1.228,-0.364,3.344%0AGF,1,1,4.42,2.49,F%7CG,,l,7.12,' +
      '3.81,1.72,3.81,1.72,1.17,7.12,1.17%0AGCD,1,1,6.127203773868867,-0.2462040051820522,G%7CC%7CD,,et,4.121,0.93,7.578,1.571,' +
      '8.133,-1.423,4.676,-2.064%0A&f=&pp=&tp=&s=10,false,false,cm';
    const url = IndiFuncs.getURL();
    // const dataURLString = `${content}`;
    const dataURLString = `${url}?${content}`;
    IndiFuncs.openURL(dataURLString);
  }

  goStephensonIII() {
    const content = 'j=A,-1.44,-0.93,AB,R,t,0,0.1,t,%0AB,-1.12,0.97,AB%7CBCE,R,f,0,0.1,f,%0AC,1.6,1.19,DC%7CBCE,' +
      'R,f,0,0.1,f,%0AD,1.52,-0.91,DC,R,t,0,0.1,f,%0AE,0,3.15,EF%7CBCE,R,f,0,0.1,f,%0AF,4.02,4.87,EF%7CGF,R,f,0,0.1,f,%0AG,4.98,2.61,' +
      'GF,R,t,0,0.1,f,%0A&l=AB,1,1,-1.28,0.019999999999999962,A%7CB,,l,-1.12,-0.93,-1.44,-0.93,-1.44,0.97,-1.12,0.97%0ADC,1,1,1.56,' +
      '0.13999999999999996,C%7CD,,l,1.52,1.19,1.6,1.19,1.6,-0.91,1.52,-0.91%0AEF,1,1,2.01,4.01,E%7CF,,l,4.02,3.15,0,3.15,0,4.87,' +
      '4.02,4.87%0ABCE,1,1,0.13675000000000004,2.3575,B%7CC%7CE,,et,1.961,1.019,-1.449,0.743,-1.688,3.696,1.723,3.972%0AGF,1,1,' +
      '4.5,3.74,F%7CG,,l,4.02,2.61,4.98,2.61,4.98,4.87,4.02,4.87%0A&f=&pp=&tp=&s=10,false,false,cm';
    const url = IndiFuncs.getURL();
    // const dataURLString = `${content}`;
    const dataURLString = `${url}?${content}`;
    IndiFuncs.openURL(dataURLString);
  }

  goSliding() {
    // const content = 'j=A,0,0,R,t,t,0%0AB,0,2,R,f,f,0%0AC,3,2,R,f,f,0%0AD,3,4,P,t,f,0%0A&l=1,A%7CB,b,0,0,0,0,0,2,0,2%0A2,C%7CB,b,0,2,3,2,3,2,0,2%0A3,D%7CC,b,3,4,3,4,3,2,3,2%0A&f=';
    const content = 'j=A,-1.76,-1.99,AB,R,t,0,0.1,t,%0AB,0.52,1.95,AB%7CCB,R,f,0,0.1,f,%0AC,2.54,0.15,CB,P,t,0,' +
      '0.1,f,%0A&l=AB,1,1,-0.4,1.13,A%7CB,,l,0.52,-1.99,-1.76,-1.99,-1.76,1.95,0.52,1.95%0ACB,1,1,0.97,1.6400000000000001,B%7CC,,l,' +
      '0.52,0.15,2.54,0.15,2.54,1.95,0.52,1.95%0A&f=&pp=&tp=&s=10,false,false,cm';
    const url = IndiFuncs.getURL();
    // const dataURLString = `${content}`;
    const dataURLString = `${url}?${content}`;
    IndiFuncs.openURL(dataURLString);
  }

  goForces() {
    // const content = 'j=A,0,0,R,t,t,0%0AB,3,3,R,f,f,0%0A&l=1,A%7CB,b,3,0,0,0,0,3,3,3%0A&f=F1,1,1,1,1,-1,t%0AF2,1,2,2,2,0,f%0A';
    const content = 'j=A,-1.24,-0.89,AB,R,t,0,0.1,t,%0AB,-0.1,2.01,AB%7CCB,R,f,0,0.1,f,%0AC,4.26,2.27,CB%7CDC,' +
      'R,f,0,0.1,f,%0AD,3.36,-1.01,DC,R,t,0,0.1,f,%0A&l=AB,1,1,-0.67,0.5599999999999998,A%7CB,,l,-0.1,-0.89,-1.24,-0.89,-1.24,2.01,-0.1,' +
      '2.01%0ACB,1,1,2.08,2.1399999999999997,B%7CC,F1,l,-0.1,2.27,4.26,2.27,4.26,2.01,-0.1,2.01%0ADC,1,1,3.81,0.63,C%7CD,,l,4.26,-1.01,' +
      '3.36,-1.01,3.36,2.27,4.26,2.27%0A&f=F1,CB,1.8,2.123,4.38,4.13,t,true,1,1%0A';
    const url = IndiFuncs.getURL();
    // const dataURLString = `${content}`;
    const dataURLString = `${url}?${content}`;
    IndiFuncs.openURL(dataURLString);
  }

}
