import {Vertex} from './Vertex';
import { Force } from './Force';
import { SVGFuncs } from 'src/functions/SVGFuncs';
export enum ForceEndpointType {
    start,
    end
}

export class ForceEndpoint extends Vertex {
    svg: SVGElement;
    force: Force;
    type: ForceEndpointType;

    // Main constructor
    constructor(id: string, x: number, y: number, force: Force, type: ForceEndpointType) {
        super(id, x, y);
        this.force = force;
        this.type = type;
        this.svg = SVGFuncs.createForceEndpoint(id, x, y);
    }

    relocate(x: number, y: number) {
        super.relocate(x, y);
        this.svg.setAttributeNS(undefined, 'x', x.toString());
        this.svg.setAttributeNS(undefined, 'y', y.toString());
        this.force.updateArrowSVG();
    }
}
