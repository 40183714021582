import { Joint } from './Joint';
import { SVGFuncs, Bounds, editorID, SVGSettings, Shape } from '../functions/SVGFuncs';
import { Coord } from './Coord';
import { AppConstants } from './AppConstants';
import deepcopy from 'deepcopy';
import { Force } from './Force';
import {StaticFuncs} from '../functions/StaticFuncs';


export class Link {

  protected _id: string;
  // protected joints: Joint[];
  private _joints: Joint[];
  private _forces: Force[] = [];
  protected _linkSVG: SVGElement;
  protected _boundsSVG: SVGElement;
  protected _editorSVGs: { b1: SVGElement, b2: SVGElement, b3: SVGElement, b4: SVGElement, arrow: SVGElement};
  protected _uiBounds: Bounds;   // temporary bounds displayed on ui, changed when dragging
  protected tempBounds: Bounds; // temporary bounds cached while editing
  protected relativeCoords: { b1: Coord, b2: Coord, b3: Coord, b4: Coord, arrow: Coord }; // the
  protected _comTag: SVGElement;
  protected _idTag: SVGElement;
  // relative coordinate of each bound vs. the first joint, this is regardless of rotation
  protected _uiShape: Shape;
  protected _editing = false;
  private _mass: number;
  private _massMomentOfInertia: number;
  private _centerOfMass_x: number;
  private _centerOfMass_y: number;

  constructor(id: string, jointArray: Joint[], shape?: Shape, bounds?: Bounds, color?: string) {
    this._joints = jointArray;
    this._id = id;
    this._mass = shape !== undefined ? Link.determineMass(shape) : 1;
    this._massMomentOfInertia = shape !== undefined ? Link.determineMassMoI(shape) : 1;
    this._uiShape = shape !== undefined ? shape : Shape.line;
    //   this._massMomentOfInertia = Link.determineMassMoI(shape);
    //   this._uiShape = shape;
    // } else { // shape is a line
    //   this._mass = 1;
    //   this._massMomentOfInertia = 1;
    //   this._uiShape = Shape.line;
    // }
    // if (shape !== undefined) {
    //   this._mass = Link.determineMass(shape);
    //   this._massMomentOfInertia = Link.determineMassMoI(shape);
    //   this._uiShape = shape;
    // } else { // shape is a line
    //   this._mass = 1;
    //   this._massMomentOfInertia = 1;
    //   this._uiShape = Shape.line;
    // }
    // if (shape !== undefined) {
    //   this._mass = this.determineMass(shape);
    //   this._massMomentOfInertia = this.determineMassMoI(shape);
    //   this._uiShape = shape;
    // } else if (jointArray.length === 2) {
    //   this._uiShape = Shape.line;
    //   this._mass = 1;
    //   this._massMomentOfInertia = 1;
    // } else {
    //   console.error('ERROR: don\'t create a line link with >2 joints!');
    //   return;
    // }
    bounds !== undefined ? this._uiBounds = bounds : this._uiBounds = this.getDefaultBounds();
    // if (bounds) {
    //   this._uiBounds = bounds;
    // } else {
    //   this._uiBounds = this.getDefaultBounds();
    // }
    this.drawSVG();
    if (color !== undefined) {
      this.linkSVG.setAttribute('fill', color);
      // color && this.linkSVG.setAttribute('fill', color);
    }
    this.saveBounds();

    jointArray.forEach(j => j.addLink(this));
    // for (let i = 0; i < jointArray.length; i++) {
    //   jointArray[i].addLink(this);
    // }
    // const uiBoundsArray = [this._uiBounds.b1, this._uiBounds.b2, this._uiBounds.b3, this._uiBounds.b4];
    // uiBoundsArray.forEach(b => {
    //   this._centerOfMass_x += b.x / 4;
    //   this._centerOfMass_y += b.y / 4;
    // });
    this._centerOfMass_x = this.determineCenterOfMassX();
    this._centerOfMass_y = this.determineCenterOfMassY();
    this._idTag = SVGFuncs.createJointLinkTag(this.id, this._centerOfMass_x, this._centerOfMass_y);
    this._comTag = SVGFuncs.createCoMTag(this.id, this._centerOfMass_x, this._centerOfMass_y);
    this._idTag.style.textAnchor = 'middle';
    this._comTag.style.textAnchor = 'middle';
    // this._centerOfMass_x = StaticFuncs.determineCenterOfMassX(this.uiBounds, this.uiShape);
    // this._centerOfMass_y = StaticFuncs.determineCenterOfMassY(this.uiBounds, this.uiShape);
  }

  get idTag(): SVGElement {
    return this._idTag;
  }

  get forces() {
    return this._forces;
  }

  set joints(value: Joint[]) {
    this._joints = value;
  }

  set forces(value: Force[]) {
    this._forces = value;
  }

  get id(): string {
    return this._id;
  }

  set id(id: string) {
    this._id = id;
  }

  get joints() {
    return this._joints;
  }

  get linkSVG() {
    return this._linkSVG;
  }

  get boundsSVG() {
    return this._boundsSVG;
  }

  get uiBounds() {
    return this._uiBounds;
  }

  get editorSVGs() {
    return this._editorSVGs;
  }

  get uiShape() {
    return this._uiShape;
  }

  get editing() {
    return this._editing;
  }

  get mass() {
    return this._mass;
  }

  set mass(value: number) {
    this._mass = value;
  }

  get massMomentOfInertia() {
    return this._massMomentOfInertia;
  }

  set massMomentOfInertia(value: number) {
    this._massMomentOfInertia = value;
  }

  get centerOfMassX() {
    return this._centerOfMass_x;
  }

  set centerOfMassX(value: number) {
    this._centerOfMass_x = value;
  }

  get centerOfMassY() {
    return this._centerOfMass_y;
  }

  set centerOfMassY(value: number) {
    this._centerOfMass_y = value;
  }

  private static determineMass(shape: Shape) {
    let area: number;
    switch (shape) {
      case Shape.bar:
        area = 1;
        break;
      case Shape.circle:
        area = 1;
        break;
      case Shape.cShape:
        area = 1;
        break;
      case Shape.eTriangle:
        area = 1;
        break;
      case Shape.line:
        area = 1;
        break;
      case Shape.lShape:
        area = 1;
        break;
      case Shape.rectangle:
        area = 1;
        break;
      case Shape.rTriangle:
        area = 1;
        break;
      case Shape.square:
        area = 1;
        break;
      case Shape.tShape:
        area = 1;
        break;
    }
    const height = 1;
    const density = 1;
    return area * height * density;
  }

  private static determineMassMoI(shape: Shape) {
    switch (shape) {
      case Shape.bar:
        return 1;
      case Shape.circle:
        return 1;
      case Shape.cShape:
        return 1;
      case Shape.eTriangle:
        return 1;
      case Shape.line:
        return 1;
      case Shape.lShape:
        return 1;
      case Shape.rectangle:
        return 1;
      case Shape.rTriangle:
        return 1;
      case Shape.square:
        return 1;
      case Shape.tShape:
        return 1;
    }
  }

  determineCenterOfMassX() {
    const uiBoundsArray = [this._uiBounds.b1, this._uiBounds.b2, this._uiBounds.b3, this._uiBounds.b4];
    // determineCenterOfMassX(uiBounds: Bounds, shape: Shape.line | Shape.bar | Shape.eTriangle | Shape.rTriangle | Shape.rectangle |
    //   Shape.square | Shape.circle | Shape.cShape | Shape.tShape | Shape.lShape) {
    let link_x = 0;
    switch (this._uiShape) {
      case Shape.bar:
        uiBoundsArray.forEach(ui => {
          link_x += ui.x / 4;
        });
        break;
      case Shape.circle:
        uiBoundsArray.forEach(ui => {
          link_x += ui.x / 4;
        });
        break;
      case Shape.cShape:
        uiBoundsArray.forEach(ui => {
          link_x += ui.x / 4;
        });
        break;
      case Shape.eTriangle:
        uiBoundsArray.forEach(ui => {
          link_x += ui.x / 4;
        });
        break;
      case Shape.line:
        uiBoundsArray.forEach(ui => {
          link_x += ui.x / 4;
        });
        break;
      case Shape.lShape:
        uiBoundsArray.forEach(ui => {
          link_x += ui.x / 4;
        });
        break;
      case Shape.rectangle:
        uiBoundsArray.forEach(ui => {
          link_x += ui.x / 4;
        });
        break;
      case Shape.rTriangle:
        uiBoundsArray.forEach(ui => {
          link_x += ui.x / 4;
        });
        break;
      case Shape.square:
        uiBoundsArray.forEach(ui => {
          link_x += ui.x / 4;
        });
        break;
      case Shape.tShape:
        uiBoundsArray.forEach(ui => {
          link_x += ui.x / 4;
        });
        break;
    }
    return link_x;
  }

  determineCenterOfMassY() {
    const uiBoundsArray = [this._uiBounds.b1, this._uiBounds.b2, this._uiBounds.b3, this._uiBounds.b4];
    let link_y = 0;
    switch (this._uiShape) {
      case Shape.bar:
        uiBoundsArray.forEach(ui => {
          link_y += ui.y / 4;
        });
        break;
      case Shape.circle:
        uiBoundsArray.forEach(ui => {
          link_y += ui.y / 4;
        });
        break;
      case Shape.cShape:
        uiBoundsArray.forEach(ui => {
          link_y += ui.y / 4;
        });
        break;
      case Shape.eTriangle:
        uiBoundsArray.forEach(ui => {
          link_y += ui.y / 4;
        });
        break;
      case Shape.line:
        uiBoundsArray.forEach(ui => {
          link_y += ui.y / 4;
        });
        break;
      case Shape.lShape:
        uiBoundsArray.forEach(ui => {
          link_y += ui.y / 4;
        });
        break;
      case Shape.rectangle:
        uiBoundsArray.forEach(ui => {
          link_y += ui.y / 4;
        });
        break;
      case Shape.rTriangle:
        uiBoundsArray.forEach(ui => {
          link_y += ui.y / 4;
        });
        break;
      case Shape.square:
        uiBoundsArray.forEach(ui => {
          link_y += ui.y / 4;
        });
        break;
      case Shape.tShape:
        uiBoundsArray.forEach(ui => {
          link_y += ui.y / 4;
        });
        break;
    }
    return link_y;
  }

  getColor() {
    return this.linkSVG.getAttribute('fill');
  }

  startEdit() {
    this._editing = true;
    if (this.boundsSVG) {
      this.boundsSVG.style.display = 'block';
    }
    if (this.editorSVGs) {
      Object.values(this.editorSVGs).forEach(editorSVG => {
        editorSVG.style.display = 'block';
      });
    }
    this.joints.forEach(joint => {
      SVGFuncs.highlightCircle(joint.svg);
    });
  }

  endEdit() {
    this._editing = false;
    if (this.boundsSVG) {
      this.boundsSVG.style.display = 'none';
    }
    if (this.editorSVGs) {
      Object.values(this.editorSVGs).forEach(editorSVG => {
        editorSVG.style.display = 'none';
      });
    }
    this.joints.forEach(joint => {
      SVGFuncs.unhighlightCircle(joint.svg);
    });
  }

  drag(delta: Coord) {
    const newBounds = {} as Bounds;
    Object.keys(this._uiBounds).forEach(eid => {
      newBounds[eid] = {
        x: this.tempBounds[eid].x + delta.x,
        y: this.tempBounds[eid].y + delta.y
      };
    });
    this.tryNewBounds(newBounds);
  }

  tryNewBound(newBound: Coord, eid: editorID) {
    this._uiBounds = SVGFuncs.getNewBounds(newBound, eid, this.tempBounds);
    this.updateShapeSVG();
    this.updateBoundsSVG();
  }

  tryNewBounds(newBounds: Bounds) {
    this._uiBounds = newBounds;
    this.updateShapeSVG();
    this.updateBoundsSVG();
  }

  cacheBounds() {
    this.tempBounds = deepcopy(this.uiBounds);
  }

  saveBounds() {
    this.tempBounds = deepcopy(this.uiBounds);
    this.saveRelativeCoords();
  }

  saveRelativeCoords() {
    const refJoint1 = this.joints[0];
    const refJoint2 = this.joints[1];
    const refCoord1 = new Coord(refJoint1.x, refJoint1.y);
    const refCoord2 = new Coord(refJoint2.x, refJoint2.y);
    this.relativeCoords = SVGFuncs.getBoundsRelativeCoords(refCoord1, refCoord2, this.uiBounds);
  }

  changeShape(shape: Shape) {
    this._uiShape = shape;
    // re-create svg at default location
    this._uiBounds = this.getDefaultBounds();
    this.drawSVG();
    this.tempBounds = deepcopy(this.uiBounds);
  }

  // re-calculate bounds when joints move
  updateBounds() {
    const refJoint1 = this.joints[0];
    const refJoint2 = this.joints[1];
    const refCoord1 = new Coord(refJoint1.x, refJoint1.y);
    const refCoord2 = new Coord(refJoint2.x, refJoint2.y);
    this._uiBounds = SVGFuncs.getBoundsByRelativeCoords(refCoord1, refCoord2, this.relativeCoords);
  }

  // update bound and editor svgs using uiBounds
  updateBoundsSVG() {
    const boundPointsString = SVGFuncs.arrayToPointsString([
      this.uiBounds.b1,
      this.uiBounds.b2,
      this.uiBounds.b3,
      this.uiBounds.b4,
      // this.uiBounds.arrow
    ]);
    this.boundsSVG.setAttributeNS(undefined, 'points', boundPointsString);
    if (this._editorSVGs !== undefined) {
      Object.keys(this.editorSVGs).forEach(eid => {
        if (eid === 'arrow') {
          const x = (this.uiBounds['b1'].x + this.uiBounds['b2'].x + this.uiBounds['b3'].x + this.uiBounds['b4'].x) / 4;
          const y = (this.uiBounds['b1'].y + this.uiBounds['b2'].y + this.uiBounds['b3'].y + this.uiBounds['b4'].y) / 4;
          this.editorSVGs[eid].setAttribute('x', x.toString());
          this.editorSVGs[eid].setAttribute('y', y.toString());
          return;
        }
        this.editorSVGs[eid].setAttribute('x', this.uiBounds[eid].x.toString());
        this.editorSVGs[eid].setAttribute('y', this.uiBounds[eid].y.toString());
      });
      // this._editorSVGs && Object.keys(this.editorSVGs).forEach(eid => {
      //   this.editorSVGs[eid].setAttribute('x', this.uiBounds[eid].x.toString());
      //   this.editorSVGs[eid].setAttribute('y', this.uiBounds[eid].y.toString());
      // });
    }
    // maybe also put into here for center of mass and link and joint ID?
  }

  // update link svg using uiBounds
  updateShapeSVG() {
    let r = SVGSettings.cornerRadius * 2 * AppConstants.scaleFactor;
    const points = SVGFuncs.getPointsFromBounds(this.uiBounds, this.uiShape);
    if (this.uiShape === Shape.circle) {
      const xdiff = points[1].x - points[0].x;
      const ydiff = points[1].y - points[0].y;
      r = Math.sqrt(xdiff * xdiff + ydiff * ydiff) / 2;
    }
    const pathString = SVGFuncs.arrayToPathString(points, r);
    this.linkSVG.setAttribute('d', pathString);
  }

  // create bounds according to the first two joints and uiShape
  // then make svgs
  getDefaultBounds() {
    const joint1 = this.joints[0];
    const joint2 = this.joints[1];
    const refCoord1 = new Coord(joint1.x, joint1.y);
    const refCoord2 = new Coord(joint2.x, joint2.y);
    return SVGFuncs.getBounds(refCoord1, refCoord2, this.uiShape);
  }

  // make svg and save to linkSVG according to uishape and uibounds
  // make boundsSVG and editorSVGs
  drawSVG() {
    // keep the color
    const color = (this._linkSVG && this._linkSVG.getAttribute('fill')) || undefined;
    this._linkSVG = SVGFuncs.createShape(this.id, this.uiBounds, this.uiShape, color);
    this.drawBoundSVGs();
  }


  drawBoundSVGs() {
    const show = this.editing ? 'block' : 'none';
    this._boundsSVG = SVGFuncs.createBounds(this.id + '_bounds', this.uiBounds);
    this._boundsSVG.style.display = show;
    if (this.uiShape !== Shape.line) {
      const editor1 = SVGFuncs.createEditor(this.id + '_editor1', this.uiBounds.b1.x, this.uiBounds.b1.y);
      const editor2 = SVGFuncs.createEditor(this.id + '_editor2', this.uiBounds.b2.x, this.uiBounds.b2.y);
      const editor3 = SVGFuncs.createEditor(this.id + '_editor3', this.uiBounds.b3.x, this.uiBounds.b3.y);
      const editor4 = SVGFuncs.createEditor(this.id + '_editor4', this.uiBounds.b4.x, this.uiBounds.b4.y);
      const mid_x = (this.uiBounds.b1.x + this.uiBounds.b2.x + this.uiBounds.b3.x + this.uiBounds.b4.x) / 4;
      const mid_y = (this.uiBounds.b1.y + this.uiBounds.b2.y + this.uiBounds.b3.y + this.uiBounds.b4.y) / 4;
      // const arrow = SVGFuncs.createEditorArrow(this.id + 'arrow')
      const arrow = SVGFuncs.createEditorArrow(this.id + 'arrow', mid_x, mid_y);

      editor1.style.display = show;
      editor2.style.display = show;
      editor3.style.display = show;
      editor4.style.display = show;
      arrow.style.display = show;
      this._editorSVGs = { b1: editor1, b2: editor2, b3: editor3, b4: editor4, arrow: arrow };
    } else {
      this._editorSVGs = undefined;
    }
  }

  // check whether all child joints are inside this link
  checkJointsInLink() {
    for (let i = 0; i < this.joints.length; i++) {
      const joint = this.joints[i];
      if (!this.checkJointInLink(joint)) { return false; }
    }
    return true;
  }

  checkJointInLink(joint: Joint) {
    return this.checkCoordInLink(new Coord(joint.x, joint.y));
  }

  checkForceEndpointInLink() {
    for (let i = 0; i < this.forces.length; i++) {
      const force = this.forces[i];
      if (!this.checkCoordInLink(new Coord(force.start.x, force.start.y))) {
        console.log(force.start);
        return false;
      }
    }
    return true;
  }

  checkCoordInLink(coord: Coord) {
    const geo = this.linkSVG as SVGGeometryElement;
    return SVGFuncs.pointInsideSVGPath(coord, geo);
  }

  checkValidLink() {
    if (!this.checkForceEndpointInLink()) {
      return { valid: false, reason: 'Link should include all highlighted forces!' };
    }
    if (this.uiShape === Shape.line) {
      if (this.joints.length === 2) {
        return { valid: true };
      } else {
        return { valid: false, reason: 'A line link cannot have 3 joints on it!' };
      }
    } else {
      if (this.checkJointsInLink()) {
        return { valid: true };
      } else {
        return { valid: false, reason: 'Link should include all highlighted joints!' };
      }
    }
  }

  // Used when deleting from the mainpage
  delete() {
    for (let i = 0; i < this.joints.length; i++) {
      const index = this.joints[i].getLinkIndex(this);
      // if (index > 0) {
      this.joints[i].deleteLink(index);
      // }
    }
  }

  // Used when deleting from the grid component
  deleteAndDeleteSVG(canvas: SVGElement) {
    for (let i = 0; i < this.joints.length; i++) {
      const index = this.joints[i].getLinkIndex(this);
      if (index > 0) {
        this.joints[i].deleteLink(index);
      }
    }
    canvas.removeChild(this.linkSVG);
  }

  removeJoint(joint: Joint) {
    let i;
    for (i = 0; i < this.joints.length; i++) {
      if (this.joints[i] === joint) {
        this.joints.splice(i, 1);
      }
    }
  }

  removeJointAndSVG(joint: Joint, canvas: SVGElement) {
    this.removeJoint(joint);
    if (this.joints.length < 2) {
      this.deleteAndDeleteSVG(canvas);
    }
  }

  addJoint(joint: Joint) {
    this.joints.push(joint);
  }

  compareWithLink(compareLink: Link) {
    return this.joints === compareLink.joints;
  }

  createIDTag() {
    this._idTag = SVGFuncs.createJointLinkTag(this.id, this._centerOfMass_x, this._centerOfMass_y);
    this._idTag.style.textAnchor = 'middle';
  }

  createCoMTag() {
    this._comTag = SVGFuncs.createCoMTag(this.id, this._centerOfMass_x, this._centerOfMass_y);
    this._comTag.style.textAnchor = 'middle';
  }
}
