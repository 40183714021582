import {Component, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {Joint} from '../../classes/Joint';
import {Link} from '../../classes/Link';
import {Force} from 'src/classes/Force';
import {Simulator} from '../../simulator/Simulator';
import {TimeSortedList} from '../../simulator/TimeSortedList';
import {Bounds, Shape} from '../../functions/SVGFuncs';
import {StaticFuncs} from '../../functions/StaticFuncs';
import {IndependentFuncs as IndiFuncs} from '../../functions/IndependentFuncs';
import {GridComponent} from '../grid/grid.component';
import {ToolbarComponent} from '../toolbar/toolbar.component';
import {LinkagetableComponent} from '../linkagetable/linkagetable.component';
import {AnimationbarComponent} from '../animationbar/animationbar.component';
import {ContextmenuComponent} from '../contextmenu/contextmenu.component';
import {Tag} from '../../classes/Tag';
import {ThreePosition} from '../../classes/ThreePosition';
import {PathPoint} from '../../classes/PathPoint';
import {ForceSolver} from '../../simulator/Analysis/ForceSolver';
import {KinematicsSolver} from '../../simulator/Analysis/KinematicsSolver';
import {ICSolver} from '../../simulator/Analysis/ICSolver';

@Component({
  selector: 'app-mainpage',
  templateUrl: './mainpage.component.html',
  styleUrls: ['./mainpage.component.css']
})
export class MainpageComponent implements OnInit, OnChanges {
  // IDs for child components (Used in the HTML Template)
  @ViewChild(GridComponent) grid: GridComponent;
  @ViewChild(ToolbarComponent) toolbar: ToolbarComponent;
  @ViewChild(LinkagetableComponent) linkageTable: LinkagetableComponent;
  @ViewChild(AnimationbarComponent) animationBar: AnimationbarComponent;
  @ViewChild(ContextmenuComponent) contextMenu: ContextmenuComponent;

  jointArray: Joint[] = []; // The array containing all joints
  linkArray: Link[] = []; // The array containing all joints
  forceArray: Force[] = [];
  simulatorArray: Simulator[] = [];
  // jointLinkTagArray: Tag[] = [];
  comTagArray: Tag[] = [];
  pathPointArray: PathPoint[] = [];
  threePositionArray: ThreePosition[] = [];
  inputJointAssigned: Boolean = false;
  posTimeSortedList: TimeSortedList = new TimeSortedList();
  numJointsAndLinks: Array<number>;
  analysis: Array<Array<string>>;
  titleRow: Array<string>;
  analysisLength: number;
  initialAngularVelocity = 10;
  // clockwise = false;
  gravity = false;
  clockwise = false;
  // synthesis = 'none';
  // degreeOfFreedom: number;
  mechanismValid = false;
  mechanismValidForceAnalysis = false;
  mechanismValidKinematicAnalysis = false;
  // CSVFile: object;
  unit = 'cm';
  // comTagPressed: boolean;

  constructor() {
    const simData = StaticFuncs.convertToSimulatorData(this.jointArray, this.linkArray, this.forceArray);
    this.simulatorArray = [];
    const sim = new Simulator(simData.simJoints, simData.simLinks, simData.simForces, this.unit, this.gravity);
    this.simulatorArray.push(sim);
    alert('You are now using PMKS+ version: 1.2, updated on Feb 3rd, 2022');
  }

  ngOnInit() {
    const height = window.screen.availHeight;
    const width = window.screen.availWidth;
    console.log('height: ' + height);
    console.log('width: ' + width);
    this.importURL();
    const simData = StaticFuncs.convertToSimulatorData(this.jointArray, this.linkArray, this.forceArray);
    this.simulatorArray = [];
    const sim = new Simulator(simData.simJoints, simData.simLinks, simData.simForces, this.unit, this.gravity);
    this.simulatorArray.push(sim);
    const directionCounterClockwise = <HTMLButtonElement>document.getElementById('counterclockwise');
    const directionClockwise = <HTMLButtonElement>document.getElementById('clockwise');
    const clockwise_string = this.clockwise ? 'clockwise' : 'counterclockwise';
    switch (clockwise_string) {
      case 'clockwise':
        if (directionClockwise === null) {
          directionCounterClockwise.id = 'clockwise';
        }
        break;
      case 'counterclockwise':
        if (directionCounterClockwise === null) {
          directionClockwise.id = 'counterclockwise';
        }
        break;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    console.log(changes);
    if (changes['kinematicAnalysis']) {
      console.log('change on ngOnChanges Analysis mainpage');
    }
    if (changes['kinematicTitleRow']) {
      console.log('changes on ngOnChanges Title mainpage');
    }
  }

  determineTagPressed() {
    return <HTMLButtonElement> document.getElementById('showIDOn') !== null;
    // return dd !== null;
  }

  determineCoMPressed() {
    return <HTMLButtonElement> document.getElementById('showCoMOn') !== null;
    // const dd = <HTMLButtonElement> document.getElementById('showCoMOn');
    // return dd !== null;
  }

  receiveJointAdd($event) {
    this.jointArray.push($event);
    this.refreshJointsAndLinksArray();
  }

  receivePathPointAdd($event) {
    this.pathPointArray.push($event);
    this.refreshPathPointArray();
  }

  receiveThreePositionAdd($event) {
    this.threePositionArray.push($event);
    this.refreshThreePositionArray();
  }

  editJoint($event) {
    this.refreshJointsAndLinksArray();
  }

  editLink($event) {
    this.refreshJointsAndLinksArray();
  }

  editForce($event) {
    this.refreshForceArray();
  }
  receiveForceAdd($event) {
    this.forceArray.push($event);
    this.refreshForceArray();
  }

  receiveNewTwoJointLink($event) {
    const joint1X = $event.joint1X;
    const joint1Y = $event.joint1Y;
    const joint1ID = $event.joint1ID;
    const joint2X = $event.joint2X;
    const joint2Y = $event.joint2Y;
    const joint2ID = $event.joint2ID;
    this.create2JointLink(joint1X, joint1Y, joint1ID, joint2X, joint2Y, joint2ID);
  }

  receiveNewJointAndLinkToExistingJoint($event) {
    const joint1X = $event.joint1X;
    const joint1Y = $event.joint1Y;
    const joint1ID = $event.joint1ID;
    const joint2 = $event.joint2;
    this.createJointAndLinkToExistingJoint(joint1X, joint1Y, joint1ID, joint2);
  }

  // receiveNewJointLinkTagAdd($event) {
  //   const id = $event.id;
  //   const x = $event.x;
  //   const y = $event.y;
  //   const tag = new Tag(id, x, y, 'jointLink');
  //   this.jointLinkTagArray.push(tag);
  // }

  receiveNewCoMTagAdd($event) {
    const id = $event.id;
    const x = $event.x;
    const y = $event.y;
    const tag = new Tag(id, x, y, 'CoM');
    this.comTagArray.push(tag);
  }

  receiveLinkAdd($event) {
    const jointArray = $event.jointArray;
    const linkProps = $event.linkProps;

    // Check if there already exists a link between the joints.
    // If a link exists, delete it.
    // This is done to insure there aren't any links left over when creating a multi-joint link.
    for (let j = 0; j < jointArray.length; j++) {
      for (let k = j + 1; k < jointArray.length; k++) {
        const possLink = StaticFuncs.findAndGetLink(jointArray[j], jointArray[k]);
        if (possLink !== undefined) {
          // this.deleteJointLinkTag(possLink.id);
          this.deleteCoMTag(possLink.id);
          this.deleteLink(possLink);
        }
      }
    }
    let link_id = '';
    jointArray.forEach(j => {
      link_id += j.id;
    });
    let link_x = 0;
    let link_y = 0;
    // if (this.determineTagPressed()) {
      // const bounds = [linkProps.bounds.b1, linkProps.bounds.b1, linkProps.bounds.b1, linkProps.bounds.b1];
      // linkProps.bounds.forEach(b => {
      //   link_x += b.x / 4;
      //   link_y += b.y / 4;
      // });
      // link_x = StaticFuncs.determineCenterOfMassX(linkProps.bounds, linkProps.shape);
      // link_y = StaticFuncs.determineCenterOfMassY(linkProps.bounds, linkProps.shape);
      // this.jointLinkTagArray.push(new Tag(link_id, link_x, link_y, 'jointLink'));
    // }
    if (this.determineCoMPressed()) {
      // link_x = StaticFuncs.determineCenterOfMassX(linkProps.bounds, linkProps.shape);
      // link_y = StaticFuncs.determineCenterOfMassY(linkProps.bounds, linkProps.shape);
      // const bounds = [linkProps.bounds.b1, linkProps.bounds.b1, linkProps.bounds.b1, linkProps.bounds.b1];
      // bounds.forEach(b => {
      //   link_x += b.x / 4;
      //   link_y += b.y / 4;
      // });
      linkProps.bounds.forEach(b => {
        link_x += b.x / 4;
        link_y += b.y / 4;
      });
      this.comTagArray.push(new Tag(link_id, link_x, link_y, 'CoM'));
    }
    this.createLink(jointArray, linkProps);
    this.refreshJointsAndLinksArray();
  }

  receiveJointDel($event) {
    this.deleteJoint($event);
    this.refreshJointsAndLinksArray();
  }

  receivePathPointDel($event) {
    this.deletePathPoint($event);
    this.refreshPathPointArray();
  }

  receiveThreePositionDel($event) {
    this.deleteThreePosition($event);
    this.refreshThreePositionArray();
  }

  receiveLinkDel($event) {
    this.deleteLink($event);
    this.refreshJointsAndLinksArray();
  }

  receiveForceDel($event) {
    this.deleteForce($event);
    this.refreshForceArray();
  }

  // receiveJointLinkTagsDel() {
    // this.deleteJointLinkTags();
    // this.refreshJointLinkTagArray();
  // }

  receiveCoMTagsDel() {
    this.deleteCoMTags();
    this.refreshCoMTagArray();
  }

  receiveForceLocalChange($event) {
    const force = $event;
    force.isGlobal = force.isGlobal !== true;
    this.refreshForceArray();
  }

  receiveForceDirectionChange($event) {
    // const force = $event;
    this.refreshForceArray();
  }

  receiveLinkageDel() {
    // basically refresh a fresh page
    const urlString = IndiFuncs.getURL();
    window.location.href = encodeURI(urlString);
  }

  refreshAllArrays() {
    this.jointArray = [].concat(this.jointArray);
    this.linkArray = [].concat(this.linkArray);
  }

  refreshJointArray() {
    this.jointArray = [].concat(this.jointArray);
  }

  refreshLinkArray() {
    this.linkArray = [].concat(this.linkArray);
  }

  refreshForceArray() {
    this.forceArray = [].concat(this.forceArray);
  }

  // refreshJointLinkTagArray() {
    // this.jointLinkTagArray = [].concat(this.jointLinkTagArray);
  // }

  refreshCoMTagArray() {
    this.comTagArray = [].concat(this.comTagArray);
  }

  refreshPathPointArray() {
    this.pathPointArray = [].concat(this.pathPointArray);
  }

  refreshThreePositionArray() {
    this.threePositionArray = [].concat(this.threePositionArray);
  }

  receiveLinkageReset($event: {
    jointArray?: Joint[], linkArray?: Link[], forceArray?: Force[], pathPointArray?: PathPoint[],
    threePositionArray?: ThreePosition[], settings_?: {
      input_speed_mag: number;
      clockwise: boolean;
      gravity: boolean;
      unit: string;
    }
  }) {
    if ($event.jointArray) {
      this.jointArray = $event.jointArray;
    }
    if ($event.linkArray) {
      this.linkArray = $event.linkArray;
    }
    if ($event.forceArray) {
      this.forceArray = $event.forceArray;
    }
    if ($event.pathPointArray) {
      this.pathPointArray = $event.pathPointArray;
    }
    if ($event.threePositionArray) {
      this.threePositionArray = $event.threePositionArray;
    }
    if ($event.settings_) {
      const settings = $event.settings_;
      this.initialAngularVelocity = settings.input_speed_mag;
      this.clockwise = settings.clockwise;
      // if (settings.clockwise) {
      //   const direction = <HTMLButtonElement> document.getElementById('counterclockwise');
      //   direction.id = 'clockwise';
      // }
      this.gravity = settings.gravity;
      this.unit = settings.unit;
    }
  }

  refreshJointsAndLinksArray() {
    this.refreshJointArray();
    this.refreshLinkArray();
  }

  receiveInputJointSet($event) {
    this.inputJointAssigned = $event;
    this.refreshJointsAndLinksArray();
  }

  // Deletes all links who don't contain at least 2 joints
  deleteUnusedLinks() {
    // Function that runs through linkarray and deletes the first link it finds with < 2 joints attached
    // NOTE: It stops after first invalid link because the linkarray indices are changed.
    // TODO: Make it so we don't have to do this ^
    function findAndDeleteInvalidLinksFromLinkArray() {
      let i;
      for (i = 0; i < outerContext.linkArray.length; i++) {
        if (outerContext.linkArray[i].joints.length < 2) {
          // delete unused tags
          // this.deleteTag(outerContext.linkArray[i].getID());
          // outerContext.linkArray[i].getID();
          outerContext.deleteLink(outerContext.linkArray[i]);
          return true;
        }
      }
      return false;
    }

    const outerContext = this; // Preserve reference to this (the mainpage component) in a separate variable
    let ret = true;
    while (ret) {
      ret = findAndDeleteInvalidLinksFromLinkArray();
    }
  }

  // Deletes the entire linkage.
  deleteLinkage() {
    this.jointArray = [];
    this.linkArray = [];
    this.forceArray = [];
    this.inputJointAssigned = false;
    this.posTimeSortedList = new TimeSortedList();
  }

  deletePathPoint(pathPoint: PathPoint) {
    switch (this.pathPointArray.length) {
      case 1:
        break;
      case 2:
        pathPoint.neighbor_one.neighbor_one = undefined;
        break;
      case 3:
        pathPoint.neighbor_one.neighbor_two = pathPoint.neighbor_two;
        pathPoint.neighbor_two.neighbor_one = pathPoint.neighbor_one;
        pathPoint.neighbor_one.neighbor_two = undefined;
        pathPoint.neighbor_two.neighbor_two = undefined;
        break;
      default:
        pathPoint.neighbor_one.neighbor_two = pathPoint.neighbor_two;
        pathPoint.neighbor_two.neighbor_one = pathPoint.neighbor_one;
        break;
    }
    const pathPointIndex = this.pathPointArray.indexOf(pathPoint);
    this.pathPointArray.splice(pathPointIndex, 1);
  }

  deleteThreePosition(threePosition: ThreePosition) {
    const threePositionIndex = this.threePositionArray.indexOf(threePosition);
    this.threePositionArray.splice(threePositionIndex, 1);
  }

  deleteJoint(joint: Joint) {
    const jIndex = this.jointArray.indexOf(joint);
    console.log('THIS IS THE RECEIVE JOINT INDEX:' + jIndex);
    const jointID = joint.id;
    // this.jointArray[jIndex].links.forEach(l => {
    //   let l_id = l.getID();
    //   l_id = l_id.replace(jointID, '');
    //   l.setID(l_id);
    // });
    this.jointArray[jIndex].removeLinks();
    this.jointArray.splice(jIndex, 1);
    // if (this.determineTagPressed()) {
    //   this.deleteJointLinkTag(joint.id);
    // }
    this.deleteUnusedLinks();
    joint.links.forEach(l => {
      if (l === undefined) {
        return;
      }
      let l_id = l.id;
      // if (this.determineTagPressed()) {
      //   this.deleteJointLinkTag(l_id);
      // }
      if (this.determineCoMPressed()) {
        // this.changeCoMTagID(l_id);
        this.deleteCoMTag(l_id);
      }
      // if (this.comTagPressed) {
      //   const link_x = StaticFuncs.determineCenterOfMassX(l.uiBounds, l.uiShape);
      //   const link_y = StaticFuncs.determineCenterOfMassY(l.uiBounds, l.uiShape);
      //   const new_tag = new Tag(l_id, link_x, link_y, 'CoM');
      //   this.comTagArray.push(new_tag);
      // }
      l_id = l_id.replace(jointID, '');
      l.id = l_id;
      // if (this.determineTagPressed() && this.jointLinkTagArray.findIndex(curr_tag => curr_tag.id === l_id) === -1) {
      //   const link_x = l.determineCenterOfMassX();
      //   const link_y = l.determineCenterOfMassY();
      //   // const link_x = StaticFuncs.determineCenterOfMassX(l.uiBounds, l.uiShape);
      //   // const link_y = StaticFuncs.determineCenterOfMassY(l.uiBounds, l.uiShape);
      //   const new_tag = new Tag(l_id, link_x, link_y, 'jointLink');
      //   this.jointLinkTagArray.push(new_tag);
      // }
      if (this.determineCoMPressed() && l_id.length > 1) {
        // const link_x = StaticFuncs.determineCenterOfMassX(l.uiBounds, l.uiShape);
        // const link_y = StaticFuncs.determineCenterOfMassY(l.uiBounds, l.uiShape);
        const link_x = l.determineCenterOfMassX();
        const link_y = l.determineCenterOfMassY();
        const new_tag = new Tag(l_id, link_x, link_y, 'CoM');
        this.comTagArray.push(new_tag);
      }
      // if (this.comTagPressed) {
      //   const link_x = StaticFuncs.determineCenterOfMassX(l.uiBounds, l.uiShape);
      //   const link_y = StaticFuncs.determineCenterOfMassY(l.uiBounds, l.uiShape);
      //   const new_tag = new Tag(l_id, link_x, link_y, 'CoM');
      //   this.comTagArray.push(new_tag);
      // }
    });
  }

  deleteLink(link: Link) {
    const joints = link.joints; // Get the joints in the link
    for (let i = 0; i < joints.length; i++) {
      const connlinks = joints[i].links;
      // Find the link in each joints link array
      const indexOfLink = connlinks.indexOf(link);
      joints[i].links.splice(indexOfLink, 1);
    }
    const lIndex = this.linkArray.indexOf(link);
    // Delete the link from the global link array
    this.linkArray.splice(lIndex, 1);
    // Delete tag associated with the link
    // this.deleteJointLinkTag(link.id);
    this.deleteCoMTag(link.id);
  }

  deleteForce(force: Force) {
    this.linkArray.forEach(link => {
      const flIndex = link.forces.indexOf(force);
      link.forces.splice(flIndex, 1);
    });
    const fIndex = this.forceArray.indexOf(force);
    // Delete the link from the global link array
    this.forceArray.splice(fIndex, 1);
  }

  deleteCoMTag(tagID: string) {
    for (let i = 0; i < this.comTagArray.length; i++) {
      if (this.comTagArray[i].id === tagID) {
        this.comTagArray.splice(i, 1);
      }
    }
  }

  changeCoMTagID(tagID: string) {
    for (let i = 0; i < this.comTagArray.length; i++) {
      if (this.comTagArray[i].id === tagID) {
        this.comTagArray[i].id = tagID;
        // this.comTagArray.splice(i, 1);
      }
    }
  }

  // deleteJointLinkTag(tagID: string) {
  //   for (let i = 0; i < this.jointLinkTagArray.length; i++) {
  //     if (this.jointLinkTagArray[i].id === tagID) {
  //       this.jointLinkTagArray.splice(i, 1);
  //     }
  //   }
  // }

  // deleteJointLinkTags() {
  //   this.jointLinkTagArray = [];
  // }

  deleteCoMTags() {
    this.comTagArray = [];
  }

  simulate() {
    this.posTimeSortedList = new TimeSortedList();
    const rpmToRad = (2 * Math.PI) / 60;
    // const rpmToRad = (2 * Math.PI) / 360;
    const direction = <HTMLButtonElement> document.getElementById('clockwise');
    const sign = direction !== null ? -1 : 1; // true = clockwise
    this.posTimeSortedList = this.simulatorArray[0].findFullMovementPos(sign * this.initialAngularVelocity * rpmToRad);
  }

  updateSimulator() {
    const simData = StaticFuncs.convertToSimulatorData(this.jointArray, this.linkArray, this.forceArray);
    this.simulatorArray = [];
    const sim = new Simulator(simData.simJoints, simData.simLinks, simData.simForces, this.unit, this.gravity);
    this.simulatorArray.push(sim);
    const DOFDisplay = document.getElementById('btnDOF');
    this.inputJointAssigned = false;
    this.jointArray.forEach(j => {
      if (j.input) {
        this.inputJointAssigned = true;
      }
    });
    if (this.inputJointAssigned && this.simulatorArray[0].dof === 1) {
      DOFDisplay.innerHTML = 'DOF ' + this.simulatorArray[0].dof.toString();
      this.determineReadyToSimulate();
    } else {
      DOFDisplay.innerHTML = 'DOF  ';
    }
  }

  determineReadyToSimulate() {
    this.inputJointAssigned = false;
    this.jointArray.forEach(j => {
      if (j.input) {
        this.inputJointAssigned = true;
      }
    });
    if (this.simulatorArray[0].dof === 1 && this.inputJointAssigned) {
      this.simulate();
      this.mechanismValidForceAnalysis = !(!this.gravity && this.forceArray.length === 0);
      this.mechanismValidKinematicAnalysis = true;
      this.mechanismValid = true;
    } else {
      this.posTimeSortedList.TSL = [];
      this.mechanismValidForceAnalysis = false;
      this.mechanismValidKinematicAnalysis = false;
      this.mechanismValid = false;
    }
  }

  createJoint(jointX: number, jointY: number, inputID: string) {
    // Create a joint object to store in the joints array.
    const joint = new Joint(inputID, jointX, jointY, 'R');
    // Add the new joint to the jointArray
    this.jointArray.push(joint);
    this.jointArray = this.jointArray.slice();
    return joint;
  }

  // Creates a joint and links to existing joint
  createJointAndLinkToExistingJoint(joint1X: number, joint1Y: number, joint1ID: string, joint2: Joint) {
    const joint1 = this.createJoint(joint1X, joint1Y, joint1ID);
    const link = this.createLink([joint1, joint2]);
    // if (this.determineTagPressed()) {
    //   const joint_tag = new Tag(joint1ID, joint1X, joint1Y, 'jointLink');
    //   const link_tag = new Tag(joint1ID + joint2.id, (joint1X + joint2.x) / 2, (joint1Y + joint2.y) / 2, 'jointLink' );
    //   this.jointLinkTagArray.push(joint_tag);
    //   this.jointLinkTagArray.push(link_tag);
    // }
    if (this.determineCoMPressed()) {
      const com_tag = new Tag(joint1ID + joint2.id, (joint1X + joint2.x) / 2, (joint1Y + joint2.y) / 2, 'CoM');
      this.comTagArray.push(com_tag);
    }
    return {joint1: joint1, joint2: joint2, link: link};
  }

  // Creates two joints and a link between them
  create2JointLink(joint1X: number, joint1Y: number, joint1ID: string, joint2X: number, joint2Y: number, joint2ID: string) {
    const joint1 = this.createJoint(joint1X, joint1Y, joint1ID);
    const joint2 = this.createJoint(joint2X, joint2Y, joint2ID);
    const link = this.createLink([joint1, joint2]);
    let link_x: number;
    let link_y: number;
    // if (this.determineTagPressed()) {
      // this.jointLinkTagArray.push(new Tag(joint1.id, joint1.x, joint1.y, 'jointLink'));
      // this.jointLinkTagArray.push(new Tag(joint2.id, joint2.x, joint2.y, 'jointLink'));
      // link_x = link.determineCenterOfMassX();
      // link_y = link.determineCenterOfMassY();
      // link_x = StaticFuncs.determineCenterOfMassX(link.uiBounds, link.uiShape);
      // link_y = StaticFuncs.determineCenterOfMassY(link.uiBounds, link.uiShape);
      // this.jointLinkTagArray.push(new Tag(link.id, link_x, link_y, 'jointLink'));
    // }
    if (this.determineCoMPressed()) {
      link_x = link.determineCenterOfMassX();
      link_y = link.determineCenterOfMassY();
      // link_x = StaticFuncs.determineCenterOfMassX(link.uiBounds, link.uiShape);
      // link_y = StaticFuncs.determineCenterOfMassY(link.uiBounds, link.uiShape);
      this.comTagArray.push(new Tag(link.id, link_x, link_y, 'CoM'));
    }
    return {joint1: joint1, joint2: joint2, link: link};
  }

  createLink(JointArray: Joint[], linkProps?: { shape?: Shape, bounds?: Bounds, color?: string, forces: Force[] }) {
    // Create the link
    const newID = StaticFuncs.getNewLinkID(JointArray);
    const link = linkProps ?
      new Link(newID, JointArray, linkProps.shape, linkProps.bounds, linkProps.color)
      :
      new Link(newID, JointArray);
    if (linkProps !== undefined) {
      linkProps.forces.forEach(f => {
        f.link = link;
      });
    }
    // Add the new link to the linkArray
    this.linkArray.push(link);
    this.linkArray = this.linkArray.slice();
    return link;
  }

  importURL() {
    try {
      const decodedURL = decodeURI(window.location.href);
      const res = StaticFuncs.parseURL(decodedURL);
      this.receiveLinkageReset(res);
      // this.simulate();
    } catch (e) {
      IndiFuncs.showErrorNotification('error parsing url, url incorrect');
    }
  }

  receiveCSV($event) {
    try {
      const res = StaticFuncs.parseImportFile($event);
      this.receiveLinkageReset(res);
      // this.simulate();
    } catch (e) {
      IndiFuncs.showErrorNotification('error parsing csv, file corrupted');
    }
  }

  receiveDownloadLinkage() {
    const direction = <HTMLButtonElement> document.getElementById('clockwise');
    const clockwise = direction !== null;
    const content = StaticFuncs.generateExportFile(this.jointArray, this.linkArray, this.forceArray, this.pathPointArray,
      this.threePositionArray, this.initialAngularVelocity, clockwise, this.gravity, this.unit);

    const blob = new Blob([content], {type: 'text/csv;charset=utf-8;'});
    const fileName = `PMKS+_${new Date().toISOString()}.csv`;
    if (navigator.msSaveBlob) { // IE 10+
      navigator.msSaveBlob(blob, fileName);
    } else {
      const link = document.createElement('a');
      if (link.download !== undefined) { // feature detection
        // Browsers that support HTML5 download attribute
        // fake an <a> to click on
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', fileName);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }

  receiveCopyURL() {
    const direction = <HTMLButtonElement> document.getElementById('clockwise');
    const clockwise = direction !== null;
    const content = StaticFuncs.generateExportURL(this.jointArray, this.linkArray, this.forceArray, this.pathPointArray,
      this.threePositionArray, this.initialAngularVelocity, clockwise, this.gravity, this.unit);
    const url = IndiFuncs.getURL();
    const dataURLString = `${url}?${content}`;
    const dataURL = encodeURI(dataURLString);
    console.log(dataURL.length);
    if (dataURL.length > 2000) {
      IndiFuncs.showErrorNotification('linkage too large, please use export file');
      return;
    } else {
      IndiFuncs.showNotification('URL copied!');
    }

    // fake a text area to exec copy
    const toolman = document.createElement('textarea');
    document.body.appendChild(toolman);
    toolman.value = dataURL;
    toolman.textContent = dataURL;
    toolman.select();
    document.execCommand('copy');
    document.body.removeChild(toolman);
  }

  setGravity(gravityToggle: boolean): void {
    this.gravity = gravityToggle;
    this.simulatorArray[0].setGravity = this.gravity;
    this.mechanismValidForceAnalysis = !(!this.gravity && this.forceArray.length === 0 && this.simulatorArray[0].dof === 1 &&
      this.inputJointAssigned);
  }

  // setSynthesis(synthesis: string): void {
  //   this.synthesis = synthesis;
  // }

  receiveInitialVel(initialInputVel: number): void {
    this.initialAngularVelocity = initialInputVel;
    this.updateSimulator();
    // this.determineReadyToSimulate();
  }

  receiveUnit(unit: string): void {
    this.unit = unit;
    this.simulatorArray[0].unit = unit;
  }

  determineAnalysis(type: string): void {
    this.titleRow = new Array<string>();
    this.analysis = new Array<Array<string>>();
    switch (type) {
      case 'loops':
        this.titleRow = this.simulatorArray[0].loopTitleRow();
        this.analysis = this.simulatorArray[0].loopAnalysis();
        break;
      case 'statics':
        ForceSolver.resetVariables();
        this.titleRow = this.simulatorArray[0].forceTitleRow('static');
        this.analysis = this.simulatorArray[0].forceAnalysis('static');
        break;
      case 'stress':
        break;
      case 'dynamics':
        ForceSolver.resetVariables();
        this.titleRow = this.simulatorArray[0].forceTitleRow('dynamic');
        this.analysis = this.simulatorArray[0].forceAnalysis('dynamic');
        break;
      case 'kinematic_loops':
        KinematicsSolver.resetVariables();
        this.titleRow = this.simulatorArray[0].kinematicLoopTitleRow();
        this.analysis = this.simulatorArray[0].kinematicLoopAnalysis();
        break;
      case 'kinematic_ics':
        ICSolver.resetVariables();
        this.titleRow = this.simulatorArray[0].kinematicICTitleRow();
        this.analysis = this.simulatorArray[0].kinematicICAnalysis();
        break;
    }
    this.analysisLength = this.analysis.length - 1;
    this.numJointsAndLinks = this.simulatorArray[0].getNumJointsAndLinks();
  }
}
