import {Joint, RealJoint} from '../Joints_Links_Forces/Joint';
import {Link} from '../Joints_Links_Forces/Link';

export class LoopSolver {
  static determineLoops(simJoints: Joint[], simLinks: Link[]): [string[], string[]] {
    let allLoops = [];
    let requiredLoops = [];
    const groundJoints = [];
    // insert input joint
    // const inputJoint = simJoints.find(j => j.input);
    // if (inputJoint === undefined) {
    //   return;
    // }
    // if (inputJoint.ground) { // revolute joint
    //   groundJoints.push(inputJoint);
    // } else if (inputJoint.jointType === 'P') { // prismatic joint
      // groundJoints.push(inputJoint.connectedJoints.find(j => j.ground));
    // }
    // groundJoints.push(simJoints.find(j => {
    //   return j.ground && j.input;
    // }));
    // find ground Joints
    // groundJoints.push(simJoints.find(j => {
    //   return j.ground && !j.input;
    // }));
    simJoints.forEach(j => {
      if (j.ground && j.input) {
        groundJoints.unshift(j);
      } else if (j.ground && j.connectedJoints.findIndex(jt => jt.input) !== -1) {
        groundJoints.unshift(j);
      } else if (j.ground) {
        groundJoints.push(j);
      }
    });
    // find loops from one ground joint to another ground joint
    while (groundJoints.length >= 2) {
      const desiredGround = groundJoints.shift();
      desiredGround.connectedJoints.forEach(cj => {
        const [validLoops, requiredSubLoops] = this.findGround(cj, groundJoints, cj.id,
          desiredGround.id + cj.id, [], [], desiredGround.input);
        allLoops = allLoops.concat(validLoops);
        requiredLoops = requiredLoops.concat(requiredSubLoops);
      });
    }
    // check whether requiredLoops contains all determinable links!
    // Now, determine number of links determined already within allLoops and how many need to be determined
    const links_num = simLinks.length;
    let links_known_count = 0;
    const links_determined_map = new Map<number, number>();
    const joints_to_link_determined = new Map<string, number>();
    const deleteLoop = [];
    requiredLoops.forEach(loop => {
      let prevLink: number;
      let currLink: number;
      for (let i = 0; i < loop.length - 2; i++) {
        if (joints_to_link_determined.has(loop[i] + loop[i + 1])) {
          if (i === 0) {
            currLink = joints_to_link_determined.get(loop[i] + loop[i + 1]);
          } else {
            prevLink = currLink;
            currLink = joints_to_link_determined.get(loop[i] + loop[i + 1]);
          }
          if (prevLink === currLink) {
            deleteLoop.push(loop);
          }
          continue;
        }
        const link_index = simLinks.findIndex(cur_link => cur_link.id.includes(loop[i]) && cur_link.id.includes(loop[i + 1]));
        joints_to_link_determined.set(loop[i] + loop[i + 1], link_index);
        if (i === 0) {
          currLink = link_index;
        } else {
          prevLink = currLink;
          currLink = link_index;
        }
        if (prevLink === currLink) {
          deleteLoop.push(loop);
        }
        if (links_determined_map.has(link_index)) {
          continue;
        }
        links_determined_map.set(link_index, 1); // doesn't matter what the second number is
        links_known_count++;
      }
    });
    deleteLoop.forEach(loop => {
      requiredLoops = requiredLoops.filter(e => e !== loop);
    });
    if (links_known_count === links_num) {
      return [allLoops, requiredLoops];
    }
      // Add necessary loops within allLoops
      // First, get rid of all of the joints where the first joint is not the input joint
    const temp_all_loops = [];
    allLoops.forEach(loop => {
      if (loop[0] === requiredLoops[0][0]) {
        temp_all_loops.push(loop);
      }
    });
    // Utilize array to organize array of array (QuickSort Algorithm may be better??)
    // Utilize sort from typescript https://stackoverflow.com/questions/16096872/how-to-sort-2-dimensional-array-by-column-value
    // change array to 2d array
    const two_d_array = [];
    for (let i = 0; i < temp_all_loops.length; i++) {
      two_d_array.push([temp_all_loops[i], temp_all_loops[i].length]);
    }
    // organize 2d array and return first value of each array as an array
    const temp_all_loops_sorted = two_d_array.sort(sortFunction);
    function sortFunction(a, b) {
      if (a[1] === b[1]) {
        return 0;
      } else {
        return (a[1] < b[1]) ? -1 : 1;
      }
    }
    // now, from the sorted array, go through and check to see if there is a new link
    // if so, add it to known links and increment count
    for (let i = 0; i < temp_all_loops_sorted.length; i++) {
      temp_all_loops_sorted[i].forEach(loop => {
        let noFoundLink = true;
        for (let index = 0; index < loop.length - 2; index++) {
          if (joints_to_link_determined.has(loop[index] + loop[index + 1])) {
            continue;
          }
          const link_index = simLinks.findIndex(cur_link => cur_link.id.includes(loop[index]) && cur_link.id.includes(loop[index + 1]));
          joints_to_link_determined.set(loop[index] + loop[index + 1], 1); // doesn't matter what the second number is
          if (links_determined_map.has(link_index)) {
            continue;
          }
          links_determined_map.set(link_index, 1); // doesn't matter what the second number is
          links_known_count++;
          noFoundLink = false;
        }
        if (!noFoundLink) {
          requiredLoops.push(loop);
        }
      });
      if (links_known_count === links_num) {
        return [allLoops, requiredLoops];
      }
    }
    // should not be here... (if we expect the code to actually utilize this
    const error = 'ruh-roh';
    return [allLoops, requiredLoops];
  }

// Searches through neighboring joints until ground joint is found
  private static findGround(joint: Joint, groundJoints: Joint[], linkPath: string, path: string, allFoundLoops: string[],
                    requiredLoops: string[], storeJointPath: boolean): [string[], string[]] {
    joint.connectedJoints.forEach(j => {
      if (linkPath.includes(j.id)) {
        return;
      }
      if (j.ground) {
        if (groundJoints.indexOf(j) === -1) {
          return;
        }
        if (storeJointPath) {
          const currentPathLoop = requiredLoops.find(loop => loop[loop.length - 2] === j.id);
          if (currentPathLoop === undefined) {
            requiredLoops.push(path + j.id + path[0]);
          } else if (currentPathLoop.length > (path.length + 2)) {
            requiredLoops.splice(requiredLoops.indexOf(currentPathLoop), 1);
            requiredLoops.push(path + j.id + path[0]);
          }
        }
        allFoundLoops.push(path + j.id + path[0]);
      } else {
        [allFoundLoops, requiredLoops] = this.findGround(j, groundJoints, linkPath + j.id, path + j.id,
          allFoundLoops, requiredLoops, storeJointPath);
      }
    });
    return [allFoundLoops, requiredLoops];
  }
}


// import {Joint} from '../Joints_Links_Forces/Joint';
// import {Link} from '../Joints_Links_Forces/Link';
//
// export class Loops {
//   // original meta data passed into the constructor and only used as a reference in the functions
//   // Input links and joints.
//   private readonly origJoints: Array<Joint>;
//   private readonly origLinks: Array<Link>;
//   // Count of only physical (user created) links. Should not include ground or imaginary links in this count.
//   private readonly numberOfPhysicalLinks;
//
//   /** All the fields below hold index values to whatever they are referencing, not the actual values to save on memory usage **/
//   // holds all possible vector loop in the linkage system based on joints, starting and ending each loop at a ground joint.
//   private _loopsWithJointIndices: Array<Array<number>>;
//   // holds only the unique vector loops from the above field. AKA: direction of the loop is ignored so reversed loops are removed.
//   private _uniqueLoopsWithJointIndices: Array<Array<number>>;
//   // holds the links associated with each unique loop from the field above. Duplicate links are removed and so are the ground links.
//   private _loopsWithLinkIndices: Array<Array<number>>;
//   // holds the relationship of all the duplicate loops with the first loop in each list being considered the unique and rest being duplicate
//   private _dupLinkLoopsMapToRecordedUniqueLinkLoop: Array<Array<number>>;
//   // holds all possible loop combinations.
//   private _linkLoopCombinations: Array<Array<number>>;
//   // holds only unique loop combinations, meaning multiple loop combinations with the same links in different orders are removed.
//   private _uniqueLinkLoopCombinations: Array<Array<number>>;
//
//   constructor(funcInputJoints: Array<Joint>, funcInputLinks: Array<Link>) {
//     this.origJoints = funcInputJoints;
//     this.origLinks = funcInputLinks;
//
//     // keep this in this constructor
//     // gets the count of physical links only
//     let numberOfPhysicalLinks = 0;
//     this.origLinks.forEach(l => {
//       if (!l.isGround && !l.isImaginary) {
//         numberOfPhysicalLinks++;
//       }
//     });
//     this.numberOfPhysicalLinks = numberOfPhysicalLinks;
//   } // End of constructor function
//
// // Getters -----------------------------------------------------------------------------------------------------------------------------
//   get loopsWithJointIndices(): Array<Array<number>> {
//     return this._loopsWithJointIndices;
//   }
//
//   get uniqueLoopsWithJointIndices(): Array<Array<number>> {
//     return this._uniqueLoopsWithJointIndices;
//   }
//
//   get loopsWithLinkIndices(): Array<Array<number>> {
//     return this._loopsWithLinkIndices;
//   }
//
//   get linkLoopCombinations(): Array<Array<number>> {
//     return this._linkLoopCombinations;
//   }
//
//   get uniqueLinkLoopCombinations(): Array<Array<number>> {
//     return this._uniqueLinkLoopCombinations;
//   }
//
//   get dupLinkLoopsMapToRecordedUniqueLinkLoop(): Array<Array<number>> {
//     return this._dupLinkLoopsMapToRecordedUniqueLinkLoop;
//   }
//
// // ----------------------------------------------------------------------------------------------------------------------
//
//   private vectorLoops(instruction: string): void {
//     this._loopsWithJointIndices = new Array<Array<number>>();
//     const loopsFound: Array<Array<Joint>> = new Array<Array<Joint>>(); // Temporary array to all the loops found for the given ground joint
//     // Go through all the joints in the joints array and recursively find all the possible vector loop for each ground joint.
//     let jointsProcessed = 0;
//     this.origJoints.forEach(j => {
//       // depending on the instruction, either only process a single ground joint or all possible ground joints
//       let processThisJoint = false;
//       if ((instruction === 'single') && (jointsProcessed === 0)) {
//         processThisJoint = true;
//       } else if (instruction === 'all') {
//         processThisJoint = true;
//       }
//
//       if (processThisJoint && j.isGrounded) {
//         // Initialize global arrays to assist in finding possible loop during recursive depth first search
//         const processedJoints: Array<Joint> = new Array<Joint>();
//         const visitedJoints: Array<Joint> = new Array<Joint>();
//         const groundLinkUsed: Array<Boolean> = new Array<Boolean>();
//         visitedJoints.push(j);
//         processedJoints.push(j);
//         // call recursive depth first search on the current joint to find all the possible vector loops for this joint. (Direction ignored)
//         this.recursivelyFindVectorLoops(j, j, visitedJoints, loopsFound, groundLinkUsed, processedJoints, instruction);
//         jointsProcessed++;
//       }
//     });
//     // Convert the list(s) of joints of given loop(s) to the list(s) of joint indices corresponding those joints in the original joints list
//     this.convertJointListToJointIndicesList(loopsFound);
//
//     // further process the produced results
//     this.removeDuplicateLoops();
//     this.linksInLoops();
//   } // end of vectorLoops function
//
// // --------------------------------------------------------------
// // helper function to vectorLoops()
//
//   private recursivelyFindVectorLoops(curJoint: Joint, prevJoint: Joint, visitedJoints: Array<Joint>, loopsFound: Array<Array<Joint>>,
//                                      groundLinkUsed: Array<Boolean>, processedJoints: Array<Joint>, instruction: string): void {
//     // First: Continue to find loops within each non ground link connected to the current joint if it exist's
//     if (curJoint.connectedLinks.length > 0) {
//       curJoint.connectedLinks.forEach(l => {
//         this.findAllLoopsForGivenLink(curJoint, prevJoint, l, visitedJoints, loopsFound, groundLinkUsed, processedJoints, instruction);
//       });
//     }
//
//     // dont need to find reverse loop if instruction is not set to find all loops when processing the first joint in a loop
//     if ((instruction === 'all') || (visitedJoints.length > 1)) {
//       // Next: Continue to find loops with all the ground links, one at a time, if they exist
//       if (curJoint.groundLinksForThisJoint.length > 0) {
//         curJoint.groundLinksForThisJoint.forEach(l => {
//           this.findAllLoopsForGivenLink(curJoint, prevJoint, l, visitedJoints, loopsFound, groundLinkUsed, processedJoints, instruction);
//         });
//       }
//     }
//     return;
//   } // end of recursivelyFindVectorLoops function
//
// // --------------------------------------------------------------
// // helper function to vectorLoops()
//
//   private findAllLoopsForGivenLink(curJoint: Joint, prevJoint: Joint, link: Link, visitedJoints: Array<Joint>,
//                                    loopsFound: Array<Array<Joint>>, groundLinkUsed: Array<Boolean>, processedJoints: Array<Joint>,
//                                    instruction: string): void {
//     // Check if the given link is ground link
//     const thisIsGroundLink = link.isGround;
//
//     // Check if ground link has already been used previously to produce or continue to produce a loop.
//     // If so, and this links is also ground link, then pass. Do not pass through ground link twice as all individual ground links
//     // are technically considered a single link. A loop can use the same link twice.
//     // Pass only if this link is ground and ground has already been used, else execute code. AKA: (true true) = pass = !(true true)
//     if (!(thisIsGroundLink && groundLinkUsed.includes(true))) {
//       // Push the state of this link into the queue that tracks ground links
//       groundLinkUsed.push(thisIsGroundLink);
//       // For the given link, process each joint which is part of this link.
//       link.joints.forEach(j => {
//         // Check if the current joint being looked at has already been used as part of the building the loop previously
//         const jointVisitedAlready = visitedJoints.includes(j);
//         const jointAlreadyProcessed = processedJoints.includes(j);
//         if ((j !== curJoint) && (j !== prevJoint)) {
//           visitedJoints.push(j);
//           processedJoints.push(j);
//           // Check if the current joint being processed is the same as the joint we started with. If so, we found a loop.
//           if ((j === visitedJoints[0]) && (visitedJoints.length > 1)) {
//             const visitedJointListCopy: Array<Joint> = new Array<Joint>();
//             // Copy found loop list to a new list before adding to result array to remove the referencing effect
//             this.copyArray(visitedJoints, visitedJointListCopy);
//             // only add this loop as result if it is a correctly formatted loop
//             if (this.correctNewFoundLoop(visitedJointListCopy)) {
//               loopsFound.push(visitedJointListCopy);
//             }
//             processedJoints.splice(-1, 1);
//           } else {
//             // If loop is not processed yet, then recursively continue building the loop
//             if (!jointVisitedAlready) {
//               if ((instruction === 'single') && !jointAlreadyProcessed && !thisIsGroundLink) {
//                 this.recursivelyFindVectorLoops(j, curJoint, visitedJoints, loopsFound, groundLinkUsed, processedJoints, instruction);
//               } else if (instruction === 'all') {
//                 this.recursivelyFindVectorLoops(j, curJoint, visitedJoints, loopsFound, groundLinkUsed, processedJoints, instruction);
//               } else {
//                 processedJoints.splice(-1, 1);
//               }
//             }
//           }
//           // Remove the added joint to reduce effects in finding other possible loops before returning to an earlier unfinished recursion
//           visitedJoints.splice(-1, 1);
//         }
//       });
//       // Remove the added link status to reduce effects in finding other possible loops before returning to an earlier unfinished recursion
//       groundLinkUsed.splice(-1, 1);
//     }
//   } // end of findAllLoopsForGivenLink function
//
// // --------------------------------------------------------------
// // helper function to vectorLoops()
//
//   private correctNewFoundLoop(visitedJoints: Array<Joint>): boolean {
//     let firstLink: Link = null;
//     let correct = false;
//     // Get the first link used in the loop from the ground joint
//     visitedJoints[0].connectedLinks.forEach((l) => {
//       if (visitedJoints[1].connectedLinks.includes(l)) {
//         firstLink = l;
//       }
//     });
//
//     // check if the any other links are used aside from the first link in the loop to prevent single link closed loop result
//     if (firstLink !== null) {
//       visitedJoints.forEach((j, i) => {
//         if ((i !== 0) && !(j.connectedLinks.includes(firstLink))) {
//           correct = true;
//         }
//       });
//     } else {
//       // if no first link used exist, then a ground link was used and must produce the right loop by nature of the algorithm
//       correct = true;
//     }
//     return correct;
//   } // end of correctNewFoundLoop function
//
// // --------------------------------------------------------------
// // helper function to vectorLoops()
//
//   private convertJointListToJointIndicesList(loopsFound: Array<Array<Joint>>): void {
//     // For each loop, convert each physical joint in the loop to the index value of that joint in the joints array
//     loopsFound.forEach(l => {
//       const loopWithJointIndices: Array<number> = new Array<number>();
//       l.forEach(j => {
//         loopWithJointIndices.push(this.origJoints.indexOf(j));
//       });
//       this._loopsWithJointIndices.push(loopWithJointIndices);
//     });
//   } // end of convertJointListToJointIndicesList function
//
// // ----------------------------------------------------------------------------------------------------------------------
//
//   private removeDuplicateLoops(): void {
//     // first step: create a new array of loops where each is sorted and duplicate joint indices removed
//     const sortedLoops: Array<Array<number>> = new Array<Array<number>>();
//     // For each loop
//     this._loopsWithJointIndices.forEach(ni => {
//       const sortedList: Array<number> = new Array<number>();
//       // For each joint in loop
//       ni.forEach(ni1 => {
//         let dup = false;
//         // Add the first value if the sortedList is empty, else add the current index to the end of the list if it is not already present
//         if (sortedList.length === 0) {
//           sortedList.push(ni1);
//         } else {
//           sortedList.forEach(ni2 => {
//             if (ni1 === ni2) {
//               dup = true;
//             }
//           });
//           if (!dup) {
//             sortedList.push(ni1);
//           }
//         }
//       });
//       // Sort the new list and added to the filtered loop array
//       sortedList.sort();
//       sortedLoops.push(sortedList);
//     });
//
//     // next step: find unique loops from among all the sorted loops and create a new array with records of the unique loop and array index
//     const uniqueLoops: Array<Array<number>> = new Array<Array<number>>();
//     const uniqueLoopIndices: Array<number> = new Array<number>();
//     // For each loop
//     sortedLoops.forEach((l , i) => {
//       let dup = false;
//       if (uniqueLoops.length === 0) {
//         uniqueLoops.push(l);
//         uniqueLoopIndices.push(i);
//       } else {
//         // Check if the same loop exist in the uniqueLoops array.
//         uniqueLoops.forEach(l2 => {
//           const sameLoop = (l.length === l2.length) && l.every(function(element, index) {
//             return element === l2[index];
//           });
//           // if duplicate loop exist, set dup flag to true
//           if (sameLoop) {
//             dup = true;
//           }
//         });
//         // If this loop is a duplicate loop, move onto the next loop, else add it to the end of the uniqueLoops list.
//         if (!dup) {
//           uniqueLoops.push(l);
//           uniqueLoopIndices.push(i);
//         }
//       }
//     });
//
//     // Last step: add in all the joint indices for each unique loop. From: loopsWithJointIndices => To: vectorLoopsUnique
//     this._uniqueLoopsWithJointIndices = new Array<Array<number>>();
//     uniqueLoopIndices.forEach(i => {
//       this._uniqueLoopsWithJointIndices.push(this._loopsWithJointIndices[i]);
//     });
//   } // end of removeDuplicateLoops function
//
// // ----------------------------------------------------------------------------------------------------------------------
//
//   private linksInLoops(): void {
//     this._loopsWithLinkIndices = new Array<Array<number>>();
//
//     /** determine the links that are part of these loops
//      * we can eliminate the ground link from this list
//      * that's because for velocity and acceleration loops, we don't need ground **/
//
//     // For each loop
//     this._uniqueLoopsWithJointIndices.forEach(n => {
//       const linksIndexInLoop: Array<number> = new Array<number>();
//
//       // For each pair of joints in the loop, find the associated link and add it to the result list
//       for (let i = 1; i < n.length; i++) {
//         const joint1 = this.origJoints[n[i - 1]];
//         const joint2 = this.origJoints[n[i]];
//         this.origLinks.forEach((l, index) => {
//           const jointsInLink: Array<Joint> = l.joints;
//           // check if joint 1 and 2 exist in the given link and also if it is not ground link. Also remove duplicate links from loop.
//           if (jointsInLink.includes(joint1) && jointsInLink.includes(joint2) && !l.isGround && !linksIndexInLoop.includes(index)) {
//             linksIndexInLoop.push(index);
//           }
//         });
//       }
//       this._loopsWithLinkIndices.push(linksIndexInLoop);
//     });
//   } // end of linksInLoops() function
//
// // ----------------------------------------------------------------------------------------------------------------------
//
//   private loopCombines(instruction: string): void {
//     // depending on the links
//     // we will determine the no of loops
//     // numberOfLinksUnknown = number of physical links - number of input links - number of ground links
//
//     // Initialize global fields
//     this._linkLoopCombinations = new Array<Array<number>>();
//     this._uniqueLinkLoopCombinations = new Array<Array<number>>();
//     this._dupLinkLoopsMapToRecordedUniqueLinkLoop = new Array<Array<number>>();
//
//     // Create a new unique sorted array composed of unique loops from LinksPartOfLoops
//     // and another array of loop indices corresponding each unique loop in LinksPartOfLoops
//     // and another Map that tracks which duplicate loops correspond to which recorded unique loop of that same type (by loop index)
//     const uniqueSortedLinksPartOfLoops: Array<Array<number>> = new Array<Array<number>>();
//     const loopArrayIndex: Array<number> = new Array<number>();
//     const dupMapToUniqueLoop: Map<number, Array<number>> = new Map<number, Array<number>>();
//
//     // Find unique loops using links and Map duplicate loops to their corresponding unique loops
//     this.convertLinkLoopsToUniqueLinkLoops(uniqueSortedLinksPartOfLoops, loopArrayIndex, dupMapToUniqueLoop);
//
//     // Initialize recursion variables and pass them on to the recursion
//     const numLoopsInComb = 0;
//     const numberOfLinksUnknown = (this.numberOfPhysicalLinks - 1);
//     const numberOfLoopsRequired = (numberOfLinksUnknown / 2);
//     const linksAlreadyInComb: Array<number> = new Array<number>();
//     const result: Array<number> = new Array<number>();
//     const numLoopCombosFound = 0;
//
//     this.recursivelyFindUniqueLoopCombs(this.numberOfPhysicalLinks, numberOfLoopsRequired, numLoopsInComb, linksAlreadyInComb,
//       uniqueSortedLinksPartOfLoops, loopArrayIndex, result, instruction, numLoopCombosFound);
//
//     if (instruction === 'all') {
//       // find all possible combinations, but now using joint based loops (non-duplicate) by combining uniqueLinkLoopsCombos results
//       this.findAllPossibleLoopCombo(dupMapToUniqueLoop);
//     }
//   } // end of loopCombines function
//
// // --------------------------------------------------------------
// // helper function to loopCombines()
//
//   private recursivelyFindUniqueLoopCombs(numPhysicalLinks: number, numberOfLoopsRequired: number, numLoopsInComb: number,
//                                          origLinksAlreadyInComb: Array<number>, origUniqueSortedLinksPartOfLoops: Array<Array<number>>,
//                                          origLoopArrayIndex: Array<number>, result: Array<number>, instruction: string,
//                                          numLoopCombosFound: number): void {
//
//     // This is recursive depth first search. Recursion only happens on remaining loops not part of this combo already. Result: unique combos
//     const remainingUniqueSortedLinksPartOfLoops: Array<Array<number>> = new Array<Array<number>>();
//     const remainingLoopArrayIndex: Array<number> = new Array<number>();
//     numLoopsInComb++;
//
//     // Deep copy input arrays to remove referencing effect when modified/prepared for passing onto the next recursion.
//     // We need the original input arrays (unmodified) when we move onto the next loop in that array for analysis and search.
//     this.copyArray(origUniqueSortedLinksPartOfLoops, remainingUniqueSortedLinksPartOfLoops);
//     this.copyArray(origLoopArrayIndex, remainingLoopArrayIndex);
//
//     // Check each element in the input loop array for results and for further recursion.
//     origUniqueSortedLinksPartOfLoops.forEach((loop) => {
//       // depending on the instruction, either only process a single ground joint or all possible ground joints
//       let processThisLoop = false;
//       if ((instruction === 'single') && (numLoopCombosFound === 0)) {
//         processThisLoop = true;
//       } else if (instruction === 'all') {
//         processThisLoop = true;
//       }
//
//       if (processThisLoop) {
//         // Add the current loop as a result
//         result.push(remainingLoopArrayIndex[0]);
//
//         const curLinksAlreadyInComb: Array<number> = new Array<number>();
//         this.copyArray(origLinksAlreadyInComb, curLinksAlreadyInComb);
//
//         // Add any new links to the combo list. No duplicates allowed so each link in this loop must be checked against all used links.
//         loop.forEach(link => {
//           if (!origLinksAlreadyInComb.includes(link)) {
//             // Add the unique link to the links list
//             curLinksAlreadyInComb.push(link);
//           }
//         });
//
//         // First remove the loop observed before passing on to the remaining loops or the next recursion
//         remainingUniqueSortedLinksPartOfLoops.splice(0, 1);
//         remainingLoopArrayIndex.splice(0, 1);
//
//         // Check if we met the number of loops required and all of the physical links are accounted for.  If so, add as combo result.
//         if ((numLoopsInComb === numberOfLoopsRequired) && (curLinksAlreadyInComb.length === numPhysicalLinks)) {
//
//           // remove referencing effect so copy first then push
//           const addResult: Array<number> = new Array<number>();
//           this.copyArray(result, addResult);
//           this._uniqueLinkLoopCombinations.push(addResult);
//           numLoopCombosFound++;
//
//         } else if (numLoopsInComb < numberOfLoopsRequired) {
//
//           // Recursively check other loops for possible results if the number of required loops has not been met.
//           this.recursivelyFindUniqueLoopCombs(numPhysicalLinks, numberOfLoopsRequired, numLoopsInComb, curLinksAlreadyInComb,
//             remainingUniqueSortedLinksPartOfLoops, remainingLoopArrayIndex, result, instruction, numLoopCombosFound);
//         }
//         // Remove effects of this current recursion on result and loop counter before moving onto the previous recursion
//         result.splice(-1, 1);
//       }
//     });
//     numLoopsInComb--;
//     return;
//   } // end of recursivelyFindUniqueLoopCombs function
//
// // --------------------------------------------------------------
// // helper function to loopCombines()
//
//   private findAllPossibleLoopCombo(dupMapToUniqueLoop: Map<number, Array<number>>): void {
//     // Go through each unique loop combination and substitute each loop in the combo with the same duplicate loops as stored in the MAP
//     this._uniqueLinkLoopCombinations.forEach(uLoopComb => {
//       // Deep copy results to remove referencing and add the unique loop first
//       const unqLoopComb: Array<number> = new Array<number>();
//       this.copyArray(uLoopComb, unqLoopComb);
//       this._linkLoopCombinations.push(unqLoopComb);
//
//       // now go though each loop in the given combo and substitute with same duplicate loop to get different combinations
//       uLoopComb.forEach((uLoop, i) => {
//         const dupLoopComb: Array<number> = new Array<number>();
//         const dupLoops: Array<number> = new Array<number>();
//         this.copyArray(uLoopComb, dupLoopComb);
//         this.copyArray(dupMapToUniqueLoop.get(uLoop), dupLoops);
//
//         // substitute the loop and push the new loop combo as a result
//         dupLoops.forEach(dl => {
//           dupLoopComb[i] = dl;
//           this._linkLoopCombinations.push(dupLoopComb);
//         });
//       });
//     });
//   } // end of findAllPossibleLoopCombo function
//
// // --------------------------------------------------------------
// // helper function to loopCombines()
//
//   private convertLinkLoopsToUniqueLinkLoops(uniqueSortedLinksPartOfLoops: Array<Array<number>>, loopArrayIndex: Array<number>,
//                                             dupMapToUniqueLoop: Map<number, Array<number>>): void {
//     // Create a new unique sorted array composed of unique loops from LinksPartOfLoops
//     // and another array of loop indices corresponding each unique loop in LinksPartOfLoops
//     // and another matrix that tracks which duplicate loops correspond to which recorded unique loop of that same type (by loop index)
//
//     // For each loop in LinksPartOfLoops filter out the unique loops based on links, not joints.
//     // Ex: Triangle will be one unique loop when considering link based loops but 2 unique loops when considering joint based loops.
//     this._loopsWithLinkIndices.forEach((loop, i)  => {
//       // Duplicate flag
//       let dup = false;
//       const sortedLoop: Array<number> = new Array<number>();
//
//       // Deep copy the loop to remove altering original array by referencing it
//       this.copyArray(loop, sortedLoop);
//
//       // Sort the new loop and then check if it matches any loop already added to the result
//       sortedLoop.sort();
//       uniqueSortedLinksPartOfLoops.forEach((loop2, i2) => {
//         const same = ((loop2.length === sortedLoop.length) && loop2.every(function(element, index) {
//           return (element === sortedLoop[index]);
//         }));
//         if (same) {
//           dup = true;
//
//           // Add this duplicate loop index to the array that contains the same unique version of the loop
//           // Aka, index of the first occurrence of this same loop would be the index of the unique loop of this kind
//           if (!this._dupLinkLoopsMapToRecordedUniqueLinkLoop[i2].includes(i)) {
//             this._dupLinkLoopsMapToRecordedUniqueLinkLoop[i2].push(i);
//           }
//         }
//       });
//
//       // Add to the unique list if this is not a duplicate loop
//       if (!dup) {
//         uniqueSortedLinksPartOfLoops.push(sortedLoop);
//         loopArrayIndex.push(i);
//         this._dupLinkLoopsMapToRecordedUniqueLinkLoop.push([i]);
//       }
//     });
//
//     // convert matrix of duplicate loop mapped to its unique loop to a Map for easier processing at a later time.
//     this._dupLinkLoopsMapToRecordedUniqueLinkLoop.forEach(l => {
//       // Take each unique loop as key and array of duplicate loop as value and put them into MAP (similar to HashMap)
//       // First copy array to remove referencings effect
//       const lcpy: Array<number> = new Array<number>();
//       this.copyArray(l, lcpy);
//       const uLI = lcpy[0];
//       lcpy.splice(0, 1);
//       dupMapToUniqueLoop.set(uLI, lcpy);
//     });
//   } // end of convertLinkLoopsToUniqueLinkLoops function
//
// // ----------------------------------------------------------------------------------------------------------------------
//
//   /** this function is vital for the operation of application and should be called once before angValAndAcc solver is instantiated.
//    * it is fairly efficient and may be stable for higher then 12 bar linkage systems. **/
//   getDataOnLoopComboToUse(): Array<Array<Array<number>>> {
//     // Run the loop finder code for only a single ground joint because it is sufficient and resource efficient
//     this.vectorLoops('single');
//     this.loopCombines('single');
//
//     const resultArr: Array<Array<Array<number>>> = new Array<Array<Array<number>>>();
//
//     const vectorLoopsJointBased: Array<Array<number>> = new Array<Array<number>>();
//     const vectorLoopsLinkBased: Array<Array<number>> = new Array<Array<number>>();
//     if (this.uniqueLinkLoopCombinations.length > 0) {
//       this._uniqueLinkLoopCombinations[0].forEach(loop => {
//         vectorLoopsJointBased.push(this._uniqueLoopsWithJointIndices[loop]);
//         vectorLoopsLinkBased.push(this._loopsWithLinkIndices[loop]);
//       });
//     }
//
//     resultArr.push(vectorLoopsJointBased);
//     resultArr.push(vectorLoopsLinkBased);
//     return resultArr;
//   } // end of getDataOnLoopComboToUse function
//
// // ----------------------------------------------------------------------------------------------------------------------
//
//   /** this function is for additional information purposes only to see/learn all possible loops. It is not vital for the operation of app.
//    * It is very resource intensive for higher then 12 bar linkage system and could possible crash the application if run in this case.
//    * Recommendation: Do not use this function for higher then 12 bar linkage systems if possible for stability purposes. **/
//   processAllPossibleLoops(): void {
//     // Run the loop finder code for only a single ground joint because it is sufficient and resource efficient
//     this.vectorLoops('all');
//     this.loopCombines('all');
//   } // end of getDataOnLoopComboToUse function
//
// // ----------------------------------------------------------------------------------------------------------------------------------------
// // Supporting Functions for copying arrays
//
//   private copyArray(origArray: Array<any>, copiedArray: Array<any>): void {
//     // Copy the given array into the new list to partially remove referencing effect
//     origArray.forEach(elm => {
//       copiedArray.push(elm);
//     });
//   } // end of copyArray function
//
// } // End of Loops class
