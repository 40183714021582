import { ForceEndpoint, ForceEndpointType } from './ForceEndpoint';
import { Coord } from './Coord';
import {Shape, SVGFuncs} from 'src/functions/SVGFuncs';
import { Link } from './Link';
import { IndependentFuncs as IndiFuncs } from 'src/functions/IndependentFuncs';

export class Force {
  _id: string; // The unique ID assigned to the Force
  private _link: Link;
  private _relativeCoords: { start: Coord, end: Coord };
  private _isGlobal: boolean; // If the force wil rotate with the link.
  private _start: ForceEndpoint;
  private _end: ForceEndpoint;
  private _svg: SVGElement;
  private _xMag: number;
  private _yMag: number;
  private _directionOutward: boolean;

  // Main constructor
  constructor(id: string, link: Link, start: Coord, end: Coord, isGlobal?: boolean) {
    this._id = id;
    this._link = link;
    this._isGlobal = isGlobal === undefined ? true : isGlobal;
    this._start = new ForceEndpoint('force_' + id + '_start', start.x, start.y, this, ForceEndpointType.start);
    this._end = new ForceEndpoint('force_' + id + '_end', end.x, end.y, this, ForceEndpointType.end);
    this._svg = SVGFuncs.createArrow('force_' + id, start.x, start.y, end.x, end.y);
    this.updateGlobalSVG();
    this.saveRelativeCoords();
    link.forces.push(this);
    this._xMag = 1;
    this._yMag = 1;
    this._directionOutward = true;
  }


  get relativeCoords(): { start: Coord; end: Coord } {
    return this._relativeCoords;
  }

  set relativeCoords(value: { start: Coord; end: Coord }) {
    this._relativeCoords = value;
  }

  get id() {
    return this._id;
  }

  get angle () {
    return IndiFuncs.roundNumber(Math.atan2((this._end.y - this._start.y), (this._end.x - this._start.x)) * (180 / Math.PI), 3);
  }

  get isGlobal(): boolean {
    return this._isGlobal;
  }

  set isGlobal(value: boolean) {
    this._isGlobal = value;
  }

  get link(): Link {
    return this._link;
  }

  set link(value: Link) {
    this._link = value;
  }

  updateArrowSVG() {
    if (this._directionOutward) {
      SVGFuncs.updateArrow(this._svg, this._start.x, this._start.y, this._end.x, this._end.y);
    } else {
      SVGFuncs.updateArrow(this._svg, this._end.x, this._end.y, this._start.x, this._start.y);
    }
    // SVGFuncs.updateArrow(this.svg, this.start.x, this.start.y, this.end.x, this.end.y);
    this.updateGlobalSVG();
  }

  updateGlobalSVG() {
    if (this._isGlobal) {
      SVGFuncs.changeArrowToGlobal(this._svg);
    } else {
      SVGFuncs.changeArrowToLocal(this._svg);
    }
  }


  // update force svg (and end points) using updated joint 1 and joint 2 positions
  // and relative coordinates
  updateSVG() {
    const j1 = this._link.joints[0];
    const j2 = this._link.joints[1];
    const rotation = SVGFuncs.getLinkRotation(j1, j2);
    const newStart = SVGFuncs.getPointByRelativeCoord(j1, rotation, this._relativeCoords.start);
    let newEnd: Coord;
    if (this._isGlobal) {
      newEnd = SVGFuncs.getPointByRelativeCoord(j1, rotation, this._relativeCoords.end);
    } else {
      const xcomp = this._end.x - this._start.x;
      const ycomp = this._end.y - this._start.y;
      newEnd = new Coord(newStart.x + xcomp, newStart.y + ycomp);
    }
    this._start.relocate(IndiFuncs.roundNumber(newStart.x, 3), IndiFuncs.roundNumber(newStart.y, 3));
    this._end.relocate(IndiFuncs.roundNumber(newEnd.x, 3), IndiFuncs.roundNumber(newEnd.y, 3));
  }


  saveRelativeCoords() {
    const j1 = this._link.joints[0];
    const j2 = this._link.joints[1];
    const rotation = SVGFuncs.getLinkRotation(j1, j2);
    this._relativeCoords = {
      start: SVGFuncs.getRelativeCoord(j1, rotation, this._start),
      end: SVGFuncs.getRelativeCoord(j1, rotation, this._end)
    };
  }


  get start(): ForceEndpoint {
    return this._start;
  }

  set start(value: ForceEndpoint) {
    this._start = value;
  }

  get end(): ForceEndpoint {
    return this._end;
  }

  set end(value: ForceEndpoint) {
    this._end = value;
  }

  get startCoord(): Coord {
    return new Coord(this._start.x, this._start.y);
  }

  get endCoord(): Coord {
    return new Coord(this._end.x, this._end.y);
  }

  get componentCoord(): Coord {
    return new Coord(0, 0);
  }

  get xMag(): number {
    return this._xMag;
  }

  set xMag(value: number) {
    this._xMag = value;
  }

  get yMag(): number {
    return this._yMag;
  }

  set yMag(value: number) {
    this._yMag = value;
  }

// get angle(): number {
  //   return this._angle;
  // }

  relocate(x_start: number, y_start: number, x_end: number, y_end: number) {
    // super.relocate(x, y);
    this._start = new ForceEndpoint('force_' + this.id + '_start', x_start, y_start, this, ForceEndpointType.start);
    this._end = new ForceEndpoint('force_' + this.id + '_end', x_end, y_end, this, ForceEndpointType.end);
    // this.end = new ForceEndpoint('force_' + this.id + '_end', x, y, this, ForceEndpointType.end);
    this._svg.setAttributeNS(undefined, 'x1', x_start.toString());
    this._svg.setAttributeNS(undefined, 'y1', y_start.toString());
    this._svg.setAttributeNS(undefined, 'x2', x_end.toString());
    this._svg.setAttributeNS(undefined, 'y2', y_end.toString());
    // this.svg.setAttributeNS(undefined, 'x', x.toString());
    // this.svg.setAttributeNS(undefined, 'y', y.toString());
    this.updateArrowSVG();
    // this.updateSVG();
    // this.links.forEach(link => {
    //   if (link.getUiShape() === Shape.line) {
    //     const firstJoint = link.getJoints()[0];
    //     const secondJoint = link.getJoints()[1];
    //     const newBounds = SVGFuncs.getLineBounds(
    //       { x: firstJoint.getX(), y: firstJoint.getY() },
    //       { x: secondJoint.getX(), y: secondJoint.getY() });
    //     link.tryNewBounds(newBounds);
    //
    //     link.forces.forEach(force => {
    //       force.updateSVG();
    //     });
    //   }
    // });
  }

  get directionOutward() {
    return this._directionOutward;
  }

  set directionOutward(value: boolean) {
    this._directionOutward = value;
  }

  get svg(): SVGElement {
    return this._svg;
  }

  set svg(value: SVGElement) {
    this._svg = value;
  }
}
