import {Vertex} from './Vertex';
import {SVGFuncs} from '../functions/SVGFuncs';

export class ThreePosition extends Vertex {
  private readonly _svg: SVGElement;
  // private _neighbor_one: ThreePosition;
  // private _neighbor_two: ThreePosition;

  constructor(id: string, x: number, y: number)  {
    super(id, x, y);
    this._svg = SVGFuncs.createThreePosition(id, x, y);
  }

  get svg() {
    return this._svg;
  }

  // get neighbor_one(): ThreePosition {
  //   return this._neighbor_one;
  // }
  //
  // set neighbor_one(value: ThreePosition) {
  //   this._neighbor_one = value;
  // }
  //
  // get neighbor_two(): ThreePosition {
  //   return this._neighbor_two;
  // }
  //
  // set neighbor_two(value: ThreePosition) {
  //   this._neighbor_two = value;
  // }

  relocate(x: number, y: number) {
    this.x = x;
    this.y = y;
    this._svg.setAttributeNS(undefined, 'x', x.toString());
    this._svg.setAttributeNS(undefined, 'y', y.toString());
  }
}
