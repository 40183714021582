import {
  Component, EventEmitter, Input, NgModule, OnChanges, OnInit,
  AfterViewInit, Output, SimpleChange, SimpleChanges
} from '@angular/core';
import {Joint} from '../../classes/Joint';
import {Vertex} from '../../classes/Vertex';
import {Link} from '../../classes/Link';
import {JointCreationError} from './JointCreationError';
import {NgScrollbarModule} from 'ngx-scrollbar';
import {Force} from 'src/classes/Force';
import {StaticFuncs} from '../../functions/StaticFuncs';
import {PathPoint} from '../../classes/PathPoint';
import {ThreePosition} from '../../classes/ThreePosition';

// @NgModule({
//   imports: [
//     NgScrollbarModule
//   ]
// })

@Component({
  selector: 'app-linkagetable',
  templateUrl: './linkagetable.component.html',
  styleUrls: ['./linkagetable.component.css']
})
export class LinkagetableComponent implements OnInit, AfterViewInit, OnChanges {

  @Input() jointArray: Joint[];
  @Input() linkArray: Link[];
  @Input() forceArray: Force[];
  @Input() threePositionArray: ThreePosition[];
  @Input() pathPointArray: PathPoint[];
  // @Input() selectedJoint: Joint;
  @Input() synthesis: string;
  @Input() synthesisDisplay: string;
  @Input() unit: string;
  @Output() newJointEmit = new EventEmitter<Joint>();
  @Output() editJointEmit = new EventEmitter<Joint>();
  @Output() editLinkEmit = new EventEmitter<Link>();
  @Output() editForceEmit = new EventEmitter<Force>();
  @Output() refreshJointEmit = new EventEmitter();
  @Output() renameJointLinkLabels = new EventEmitter<{oldJointID: string, newJointID: string}>();
  @Output() refreshThreePositionEmit = new EventEmitter();
  @Output() refreshPathPointEmit = new EventEmitter();
  @Output() checkPathPointsConnectedEmit = new EventEmitter();
  @Output() refreshForceEmit = new EventEmitter();
  @Output() newLinkEmit = new EventEmitter<Link>();
  @Output() newForceEmit = new EventEmitter<Force>();
  @Output() newInputEmit = new EventEmitter<Boolean>();
  @Output() delJointEmit = new EventEmitter<Joint>();
  @Output() delLinkEmit = new EventEmitter<Link>();
  @Output() delForceEmit = new EventEmitter<Force>();
  @Output() delThreePositionEmit = new EventEmitter<ThreePosition>();
  @Output() delPathPointEmit = new EventEmitter<PathPoint>();
  // @Output() newArrayRefreshEmit = new EventEmitter<boolean>();
  @Output() newJointArrayRefreshEmit = new EventEmitter();
  @Output() newLinkArrayRefreshEmit = new EventEmitter();
  @Output() newForceArrayRefreshEmit = new EventEmitter();
  @Output() editLinkShape = new EventEmitter<Link>();

  jointIncrement: number;
  table: HTMLElement;
  tableWrapper: HTMLElement;
  jointTableTitle: Array<Array<string>>;
  linkTableTitle: Array<Array<string>>;
  forceTableTitle: Array<Array<string>>;
  threePositionTableTitle: Array<Array<string>>;
  pathPointTableTitle: Array<Array<string>>;
  gearSynTableTitle: Array<Array<string>>;

  jointTable: Array<Array<string>>;
  linkTable: Array<Array<string>>;
  forceTable: Array<Array<string>>;
  threePositionTable: Array<Array<string>>;
  pathPointTable: Array<Array<string>>;
  gearSynTable: Array<Array<string>>;
  distanceJointTableOfTables: Array<Array<Array<string>>>;
  connJointsTableOfTables: Array<Array<Array<string>>>;
  inputTable: Array<boolean>;
  forceDirectionTable: Array<boolean>;
  forceLocalTable: Array<boolean>;
  tab: number;

  options = [
    { name: 'R', value: 'R'},
    { name: 'RP', value: 'RP'}
  ];

  neigh_ones = [
    { name: '', value: ''}
  ];

  constructor() { }

  ngOnInit() {
    this.tab = 0;
    this.jointIncrement = 2;
    this.displayJointsTitle();
    this.displayJoints();
    this.displayThreePositionTitle();
    this.displayPathPointTitle();
    // this.displayGearSynTitle();
    const three_position_table = <HTMLElement>document.getElementById('threePositionTable');
    const path_point_table = <HTMLElement>document.getElementById('pathPointTable');
    const gear_syn_table = <HTMLElement>document.getElementById('gearSynTable');
    three_position_table.style.visibility = 'hidden';
    path_point_table.style.visibility = 'hidden';
    gear_syn_table.style.visibility = 'hidden';
    // this.displayThreePointTitle();
    // this.displayThreePoint();
    // this.checkJointInputField();
  }
  ngAfterViewInit() {
    this.table = <HTMLTableElement>document.getElementById('joint_tbl');
    this.tableWrapper = <HTMLElement>document.getElementById('tableGroup');
    this.setTableMaxHeight(this.tableWrapper);
    window.addEventListener('resize', () => {
      this.setTableMaxHeight(this.tableWrapper);
    });
  }
  ngOnChanges(changes: SimpleChanges) {
    // StaticFuncs.showErrorNotification(' ' + this.tab);
    if (changes.selectedJoint !== undefined) {
      if (changes.selectedJoint && !changes.selectedJoint.isFirstChange()) {
        const joint = changes.selectedJoint.currentValue;
        if (joint !== undefined) {
          this.highlightSelected(joint);
        }
      }
    }
    if (this.tab === 0) {
      if (changes.jointArray || changes.linkArray) {
        if (changes.jointArray !== undefined && changes.linkArray !== undefined) {
          if (changes.jointArray.isFirstChange() || changes.linkArray.isFirstChange()) {
          } else {
            this.displayJointsTitle();
            this.displayJoints();
            // this.checkJointInputField();
          }
        }
      }
    }
    if (changes.threePositionArray) {
      this.displayThreePositionTitle();
      this.displayThreePosition();
    }
    if (changes.pathPointArray) {
      this.displayPathPointTitle();
      this.displayPathPoint();
    }
    if (changes.unit) {
      this.displayJointsTitle();
      this.displayJoints();
      // this.checkJointInputField();
    }
    if (this.tab === 1) {
      if (changes.jointArray || changes.linkArray) {
        if (changes.jointArray !== undefined && changes.linkArray !== undefined) {
          if (changes.jointArray.isFirstChange() || changes.linkArray.isFirstChange()) {
          } else {
            this.displayLinksTitle();
            this.displayLinks();
          }
        }
      }
      if (changes.unit) {
        this.displayLinks();
        this.displayLinksTitle();
      }
    }
    if (this.tab === 2) {
      if (changes.jointArray || changes.linkArray || changes.forceArray) {
        if (changes.jointArray !== undefined && changes.linkArray !== undefined || changes.forceArray !== undefined) {
          // if (changes.jointArray.isFirstChange() || changes.linkArray.isFirstChange() || changes.forceArray.isFirstChange()) {
          // } else {
          this.displayForcesTitle();
          this.displayForces();
          // }
        }
      }
      if (changes.unit) {
        this.displayForcesTitle();
        this.displayForces();
      }
    }
  }

  setTableMaxHeight(tableWrapper: HTMLElement) {
    if (tableWrapper) {
      tableWrapper.style.maxHeight = `${Math.max(window.innerHeight * 0.65, 410)}px`;
    }
  }

  // showThreePointTable(elementName) {
  //   const linkage_table = <HTMLElement>document.getElementById('linkageTable');
  //   const show_linkage_table_button = document.getElementById('showLinkageTable');
  //   const three_point_table = <HTMLElement>document.getElementById('threePointTable');
  //   const show_three_point_table_button = document.getElementById('showThreePointTable');
  //   const path_point_table = <HTMLElement>document.getElementById('pathPointTable');
  //   const show_path_point_table_button = document.getElementById('showLinkageTable');
  //   const gear_syn_table = <HTMLElement>document.getElementById('gearSynTable');
  //   const show_gear_syn_table_button = document.getElementById('showLinkageTable');
  //   return;
  //   switch (this.synthesis) {
  //     case 'none':
  //       linkage_table.style.visibility = 'visible';
  //       show_linkage_table_button.style.visibility = 'visible';
  //       three_point_table.style.visibility = 'hidden';
  //       show_three_point_table_button.style.visibility = 'hidden';
  //       path_point_table.style.visibility = 'hidden';
  //       show_path_point_table_button.style.visibility = 'hidden';
  //       gear_syn_table.style.visibility = 'hidden';
  //       show_gear_syn_table_button.style.visibility = 'hidden';
  //       return;
  //     case 'three_point':
  //       linkage_table.style.visibility = 'hidden';
  //       show_linkage_table_button.style.visibility = 'hidden';
  //       three_point_table.style.visibility = 'visible';
  //       show_three_point_table_button.style.visibility = 'visible';
  //       path_point_table.style.visibility = 'hidden';
  //       show_path_point_table_button.style.visibility = 'hidden';
  //       gear_syn_table.style.visibility = 'hidden';
  //       show_gear_syn_table_button.style.visibility = 'hidden';
  //       break;
  //     case 'path_point':
  //       linkage_table.style.visibility = 'hidden';
  //       show_linkage_table_button.style.visibility = 'hidden';
  //       three_point_table.style.visibility = 'hidden';
  //       show_three_point_table_button.style.visibility = 'hidden';
  //       path_point_table.style.visibility = 'visible';
  //       show_path_point_table_button.style.visibility = 'visible';
  //       gear_syn_table.style.visibility = 'hidden';
  //       show_gear_syn_table_button.style.visibility = 'hidden';
  //       return;
  //     case 'gear_syn':
  //       linkage_table.style.visibility = 'hidden';
  //       show_linkage_table_button.style.visibility = 'hidden';
  //       three_point_table.style.visibility = 'hidden';
  //       show_three_point_table_button.style.visibility = 'hidden';
  //       path_point_table.style.visibility = 'hidden';
  //       show_path_point_table_button.style.visibility = 'hidden';
  //       gear_syn_table.style.visibility = 'visible';
  //       show_gear_syn_table_button.style.visibility = 'visible';
  //       return;
  //   }
  //   const x = <HTMLElement>document.getElementById(elementName);
  //   if (x.style.visibility === 'hidden') {
  //     x.style.visibility = 'visible';
  //   } else {
  //     x.style.visibility = 'hidden';
  //   }
  //   const elem = document.getElementById('showThreePointTable');
  //   if (elem.innerText === 'Hide table') {
  //     elem.innerText = 'Show Table';
  //     elem.style.backgroundColor = 'white';
  //   } else {
  //     elem.innerText = 'Hide table';
  //     elem.style.backgroundColor = '#AAAAAA';
  //   }
  // }
  //
  // showPathPointTable(elementName) {
  //   const linkage_table = <HTMLElement>document.getElementById('linkageTable');
  //   const show_linkage_table_button = document.getElementById('showLinkageTable');
  //   const three_point_table = <HTMLElement>document.getElementById('threePointTable');
  //   const show_three_point_table_button = document.getElementById('showThreePointTable');
  //   const path_point_table = <HTMLElement>document.getElementById('pathPointTable');
  //   const show_path_point_table_button = document.getElementById('showLinkageTable');
  //   const gear_syn_table = <HTMLElement>document.getElementById('gearSynTable');
  //   const show_gear_syn_table_button = document.getElementById('showLinkageTable');
  //   switch (this.synthesis) {
  //     case 'none':
  //       linkage_table.style.visibility = 'visible';
  //       show_linkage_table_button.style.visibility = 'visible';
  //       three_point_table.style.visibility = 'hidden';
  //       show_three_point_table_button.style.visibility = 'hidden';
  //       path_point_table.style.visibility = 'hidden';
  //       show_path_point_table_button.style.visibility = 'hidden';
  //       gear_syn_table.style.visibility = 'hidden';
  //       show_gear_syn_table_button.style.visibility = 'hidden';
  //       return;
  //     case 'three_point':
  //       linkage_table.style.visibility = 'hidden';
  //       show_linkage_table_button.style.visibility = 'hidden';
  //       three_point_table.style.visibility = 'visible';
  //       show_three_point_table_button.style.visibility = 'visible';
  //       path_point_table.style.visibility = 'hidden';
  //       show_path_point_table_button.style.visibility = 'hidden';
  //       gear_syn_table.style.visibility = 'hidden';
  //       show_gear_syn_table_button.style.visibility = 'hidden';
  //       return;
  //     case 'path_point':
  //       linkage_table.style.visibility = 'hidden';
  //       show_linkage_table_button.style.visibility = 'hidden';
  //       three_point_table.style.visibility = 'hidden';
  //       show_three_point_table_button.style.visibility = 'hidden';
  //       path_point_table.style.visibility = 'visible';
  //       show_path_point_table_button.style.visibility = 'visible';
  //       gear_syn_table.style.visibility = 'hidden';
  //       show_gear_syn_table_button.style.visibility = 'hidden';
  //       break;
  //     case 'gear_syn':
  //       linkage_table.style.visibility = 'hidden';
  //       show_linkage_table_button.style.visibility = 'hidden';
  //       three_point_table.style.visibility = 'hidden';
  //       show_three_point_table_button.style.visibility = 'hidden';
  //       path_point_table.style.visibility = 'hidden';
  //       show_path_point_table_button.style.visibility = 'hidden';
  //       gear_syn_table.style.visibility = 'visible';
  //       show_gear_syn_table_button.style.visibility = 'visible';
  //       return;
  //   }
  //   const x = <HTMLElement>document.getElementById(elementName);
  //   if (x.style.visibility === 'hidden') {
  //     x.style.visibility = 'visible';
  //   } else {
  //     x.style.visibility = 'hidden';
  //   }
  //   const elem = document.getElementById('showPathPointTable');
  //   if (elem.innerText === 'Hide table') {
  //     elem.innerText = 'Show Table';
  //     elem.style.backgroundColor = 'white';
  //   } else {
  //     elem.innerText = 'Hide table';
  //     elem.style.backgroundColor = '#AAAAAA';
  //   }
  // }
  //
  // showGearSynTable(elementName) {
  //   const linkage_table = <HTMLElement>document.getElementById('linkageTable');
  //   const show_linkage_table_button = document.getElementById('showLinkageTable');
  //   const three_point_table = <HTMLElement>document.getElementById('threePointTable');
  //   const show_three_point_table_button = document.getElementById('showThreePointTable');
  //   const path_point_table = <HTMLElement>document.getElementById('pathPointTable');
  //   const show_path_point_table_button = document.getElementById('showLinkageTable');
  //   const gear_syn_table = <HTMLElement>document.getElementById('gearSynTable');
  //   const show_gear_syn_table_button = document.getElementById('showLinkageTable');
  //   switch (this.synthesis) {
  //     case 'none':
  //       linkage_table.style.visibility = 'visible';
  //       show_linkage_table_button.style.visibility = 'visible';
  //       three_point_table.style.visibility = 'hidden';
  //       show_three_point_table_button.style.visibility = 'hidden';
  //       path_point_table.style.visibility = 'hidden';
  //       show_path_point_table_button.style.visibility = 'hidden';
  //       gear_syn_table.style.visibility = 'hidden';
  //       show_gear_syn_table_button.style.visibility = 'hidden';
  //       return;
  //     case 'three_point':
  //       linkage_table.style.visibility = 'hidden';
  //       show_linkage_table_button.style.visibility = 'hidden';
  //       three_point_table.style.visibility = 'visible';
  //       show_three_point_table_button.style.visibility = 'visible';
  //       path_point_table.style.visibility = 'hidden';
  //       show_path_point_table_button.style.visibility = 'hidden';
  //       gear_syn_table.style.visibility = 'hidden';
  //       show_gear_syn_table_button.style.visibility = 'hidden';
  //       return;
  //     case 'path_point':
  //       linkage_table.style.visibility = 'hidden';
  //       show_linkage_table_button.style.visibility = 'hidden';
  //       three_point_table.style.visibility = 'hidden';
  //       show_three_point_table_button.style.visibility = 'hidden';
  //       path_point_table.style.visibility = 'visible';
  //       show_path_point_table_button.style.visibility = 'visible';
  //       gear_syn_table.style.visibility = 'hidden';
  //       show_gear_syn_table_button.style.visibility = 'hidden';
  //       return;
  //     case 'gear_syn':
  //       linkage_table.style.visibility = 'hidden';
  //       show_linkage_table_button.style.visibility = 'hidden';
  //       three_point_table.style.visibility = 'hidden';
  //       show_three_point_table_button.style.visibility = 'hidden';
  //       path_point_table.style.visibility = 'hidden';
  //       show_path_point_table_button.style.visibility = 'hidden';
  //       gear_syn_table.style.visibility = 'visible';
  //       show_gear_syn_table_button.style.visibility = 'visible';
  //       break;
  //   }
  //   const x = <HTMLElement>document.getElementById(elementName);
  //   if (x.style.visibility === 'hidden') {
  //     x.style.visibility = 'visible';
  //   } else {
  //     x.style.visibility = 'hidden';
  //   }
  //   const elem = document.getElementById('showGearSynTable');
  //   if (elem.innerText === 'Hide table') {
  //     elem.innerText = 'Show Table';
  //     elem.style.backgroundColor = 'white';
  //   } else {
  //     elem.innerText = 'Hide table';
  //     elem.style.backgroundColor = '#AAAAAA';
  //   }
  // }

  showLinkageTable() {
    const linkage_table = <HTMLElement>document.getElementById('linkageTable');
    const three_position_table = <HTMLElement>document.getElementById('threePositionTable');
    const path_point_table = <HTMLElement>document.getElementById('pathPointTable');
    const gear_syn_table = <HTMLElement>document.getElementById('gearSynTable');
    const show_table_button = document.getElementById('showTable');
    switch (this.synthesis) {
      case 'none':
        if (linkage_table.style.visibility === 'hidden') {
          linkage_table.style.visibility = 'visible';
        } else {
          linkage_table.style.visibility = 'hidden';
        }
        three_position_table.style.visibility = 'hidden';
        path_point_table.style.visibility = 'hidden';
        gear_syn_table.style.visibility = 'hidden';
        break;
      case 'three_pos':
        if (three_position_table.style.visibility === 'visible') {
          three_position_table.style.visibility = 'hidden';
        } else {
          three_position_table.style.visibility = 'visible';
        }
        linkage_table.style.visibility = 'hidden';
        path_point_table.style.visibility = 'hidden';
        gear_syn_table.style.visibility = 'hidden';
        break;
      case 'path_point':
        if (path_point_table.style.visibility === 'visible') {
          path_point_table.style.visibility = 'hidden';
        } else {
          path_point_table.style.visibility = 'visible';
        }
        linkage_table.style.visibility = 'hidden';
        three_position_table.style.visibility = 'hidden';
        gear_syn_table.style.visibility = 'hidden';
        break;
      case 'gear_syn':
        if (gear_syn_table.style.visibility === 'visible') {
          gear_syn_table.style.visibility = 'hidden';
        } else {
          gear_syn_table.style.visibility = 'visible';
        }
        linkage_table.style.visibility = 'hidden';
        three_position_table.style.visibility = 'hidden';
        path_point_table.style.visibility = 'hidden';
        break;
    }
    if (show_table_button.innerText === 'Show table') {
      show_table_button.innerText = 'Hide table';
      show_table_button.style.backgroundColor = '#AAAAAA';
    } else {
      show_table_button.innerText = 'Show table';
      show_table_button.style.backgroundColor = 'white';
    }
  }

  changeInput(index: number) {
    for (let i = 0; i < this.jointArray.length; i++) {
      if (index === i) {
        this.jointArray[i].input = true;
        this.newInputEmit.emit(this.jointArray[i].input);
      } else {
        this.jointArray[i].input = false;
      }
    }
    this.refreshJointEmit.emit();
    this.displayJointsTitle();
    this.displayJoints();
    // this.checkJointInputField();
  }
  changeJointType(index: number, type: string) {
    // const newType = document.getElementById('jointType').textContent;
    const joint = this.jointArray[index];
    if (type === 'RP') {
      joint.type = 'P';
    } else { // it's 'R'
      joint.type = type;
    }
    this.refreshJointEmit.emit();
    this.displayJointsTitle();
    this.displayJoints();
    // this.checkJointInputField();
  }

  changeNeighborOne(desired_pathPoint_id, indexOfElement: number) {
    const pathPoint = this.pathPointArray[indexOfElement];
    // need to get the event letter and find the desired path point
    const desired_pathPoint = this.pathPointArray.find(pp => pp.id === desired_pathPoint_id);
    // afterward, just switch the letters
    pathPoint.neighbor_one = desired_pathPoint;
    this.refreshPathPointEmit.emit();
    this.displayPathPointTitle();
    this.displayPathPoint();
    this.checkPathPointsConnectedEmit.emit();
  }

  changeNeighborTwo(desired_pathPoint_id, indexOfElement: number) {
    const pathPoint = this.pathPointArray[indexOfElement];
    // need to get the event letter and find the desired path point
    const desired_pathPoint = this.pathPointArray.find(pp => pp.id === desired_pathPoint_id);
    // afterward, just switch the letters
    pathPoint.neighbor_two = desired_pathPoint;
    this.refreshPathPointEmit.emit();
    this.displayPathPointTitle();
    this.displayPathPoint();
    this.checkPathPointsConnectedEmit.emit();
  }

  changeTab($event) {
    console.log($event.index);
    switch ($event.index) {
      case 0:
        this.tab = 0;
        switch (this.synthesis) {
          case 'none':
            this.displayJointsTitle();
            this.displayJoints();
            break;
          case 'three_pos':
            this.displayThreePositionTitle();
            this.displayThreePosition();
            break;
          case 'path_point':
            this.displayPathPointTitle();
            this.displayPathPoint();
        }
        // this.checkJointInputField();
        break;
      case 1:
        this.tab = 1;
        this.displayLinksTitle();
        this.displayLinks();
        break;
      case 2:
        this.tab = 2;
        this.displayForcesTitle();
        this.displayForces();
        break;
    }
  }
  displayJointsTitle() {
    this.jointTableTitle = Array<Array<string>>();
    const row = Array<string>();
    row.push('Input');
    row.push('ID');
    row.push('Type');
    row.push('Linked To');
    switch (this.unit) {
      case 'cm':
        row.push('Dist From Joint (cm)');
        row.push('x (cm)');
        row.push('y (cm)');
        break;
      case 'm':
        row.push('Dist From Joint (m)');
        row.push('x (m)');
        row.push('y (m)');
        break;
      // case 'km':
      //   row.push('Distance From Joint (km)');
      //   row.push('x (km)');
      //   row.push('y (km)');
      //   break;
      // case 'in':
      //   row.push('Distance From Joint (in)');
      //   row.push('x (in)');
      //   row.push('y (in)');
      //   break;
      // case 'ft':
      //   row.push('Distance From Joint (ft)');
      //   row.push('x (ft)');
      //   row.push('y (ft)');
      //   break;
      // case 'Metric':
      //   row.push('Distance From Joint (cm)');
      //   row.push('x (cm)');
      //   row.push('y (cm)');
      //   break;
      // case 'English':
      //   row.push('Distance From Joint (in)');
      //   row.push('x (in)');
      //   row.push('y (in)');
      //   break;
    }
    row.push('Angle');
    row.push('Delete');
    this.jointTableTitle.push(row);
  }
  displayLinksTitle() {
    this.linkTableTitle = Array<Array<string>>();
    const row = Array<string>();
    row.push('ID');
    switch (this.unit) {
      case 'cm':
        row.push('Mass (g)');
        row.push('MmoI (kg*cm^2)');
        row.push('X CoM (cm)');
        row.push('Y CoM (cm)');
        break;
      case 'm':
        row.push('Mass (kg)');
        row.push('MmoI (kg*m^2)');
        row.push('X CoM (m)');
        row.push('Y CoM (m)');
        break;
      // case 'km':
      //   row.push('Mass (kg)');
      //   row.push('MmoI (kg*km^2)');
      //   row.push('X CoM (km)');
      //   row.push('Y CoM (km)');
      //   break;
      // case 'in':
      //   row.push('Mass (lb)');
      //   row.push('MmoI (lb*in^2)');
      //   row.push('X CoM (in)');
      //   row.push('Y CoM (in)');
      //   break;
      // case 'ft':
      //   row.push('Mass (lb)');
      //   row.push('MmoI (lb*ft^2)');
      //   row.push('X CoM (ft)');
      //   row.push('Y CoM (ft)');
      //   break;
    }
    // switch (this.unit) {
    //   case 'Metric':
    //     row.push('Mass (g)');
    //     // row.push('Mass (kg)');
    //     // row.push('MmoI (kg*m^2)');
    //     row.push('MmoI (kg*cm^2)');
    //     row.push('X CoM (cm)');
    //     row.push('Y CoM (cm)');
    //     break;
    //   case 'English':
    //     row.push('Mass (lbm)');
    //     row.push('MmoI (lbf*ft*s^2)');
    //     row.push('X CoM (in)');
    //     row.push('Y CoM (in)');
    //     break;
    // }
    row.push('Edit Shape');
    row.push('Delete');
    this.linkTableTitle.push(row);
  }
  displayForcesTitle() {
    this.forceTableTitle = Array<Array<string>>();
    const row = Array<string>();
    row.push('On Link ID');
    row.push('Angle (deg)');
    switch (this.unit) {
      case 'cm':
        row.push('X Mag (N)');
        row.push('Y Mag (N)');
        row.push('X Pos (cm)');
        row.push('Y Pos (cm)');
        break;
      case 'm':
        row.push('X Mag (N)');
        row.push('Y Mag (N)');
        row.push('X Pos (m)');
        row.push('Y Pos (m)');
        break;
      // case 'km':
      //   row.push('X Mag (N)');
      //   row.push('Y Mag (N)');
      //   row.push('X Pos (km)');
      //   row.push('Y Pos (km)');
      //   break;
      // case 'in':
      //   row.push('X Mag (lbf)');
      //   row.push('Y Mag (lbf)');
      //   row.push('X Pos (in)');
      //   row.push('Y Pos (in)');
      //   break;
      // case 'ft':
      //   row.push('X Mag (lbf)');
      //   row.push('Y Mag (lbf)');
      //   row.push('X Pos (ft)');
      //   row.push('Y Pos (ft)');
      //   break;
    }
    // switch (this.unit) {
    //   case 'Metric':
    //     row.push('X Mag (N)');
    //     row.push('Y Mag (N)');
    //     row.push('X Pos (cm)');
    //     row.push('Y Pos (cm)');
    //     break;
    //   case 'English':
    //     row.push('X Mag (lbf)');
    //     row.push('Y Mag (lbf)');
    //     row.push('X Pos (in)');
    //     row.push('Y Pos (in)');
    //     break;
    // }
    row.push('Outward');
    row.push('Global');
    row.push('Delete');
    this.forceTableTitle.push(row);
  }

  displayThreePositionTitle() {
    this.threePositionTableTitle = Array<Array<string>>();
    const row = Array<string>();
    switch (this.unit) {
      case 'cm':
        row.push('ID');
        row.push('X Pos (cm)');
        row.push('Y Pos (cm)');
        row.push('Delete');
        // row.push('Neighbor 1');
        // row.push('Neighbor 2');
        break;
      case 'm':
        row.push('ID');
        row.push('X Pos (m)');
        row.push('Y Pos (m)');
        row.push('Delete');
        // row.push('Neighbor 1');
        // row.push('Neighbor 2');
        break;
    }
    this.threePositionTableTitle.push(row);
  }

  displayPathPointTitle() {
    this.pathPointTableTitle = Array<Array<string>>();
    const row = Array<string>();
    switch (this.unit) {
      case 'cm':
        row.push('ID');
        row.push('X Pos (cm)');
        row.push('Y Pos (cm)');
        row.push('Neighbor 1');
        row.push('Neighbor 2');
        row.push('Delete');
        break;
      case 'm':
        row.push('ID');
        row.push('X Pos (m)');
        row.push('Y Pos (m)');
        row.push('Neighbor 1');
        row.push('Neighbor 2');
        row.push('Delete');
        break;
    }
    this.pathPointTableTitle.push(row);
  }

  displayJoints() {
    this.jointTable = Array<Array<string>>();
    this.distanceJointTableOfTables = Array<Array<Array<string>>>();
    this.connJointsTableOfTables = Array<Array<Array<string>>>();
    this.inputTable = Array<boolean>();
    this.jointArray.forEach(j => {
      const row = Array<string>();
      const distanceJointTable = Array<Array<string>>();
      const connJointsTable = Array<Array<string>>();
      const links = j.links;
      links.forEach(l => {
        const connectedJoints = l.joints;
        connectedJoints.forEach(cj => {
          const linksIDsRow = Array<string>();
          const distanceRow = Array<string>();
          if (cj.id !== j.id) {
            const conID = cj.id;
            linksIDsRow.push(conID);
            const x2 = Math.pow(j.x - cj.x, 2);
            const y2 = Math.pow(j.y - cj.y, 2);
            const dist = Math.sqrt(x2 + y2);
            switch (this.unit) {
              case 'cm':
                distanceRow.push(Math.abs(dist).toFixed(2) + 'cm');
                break;
              case 'm':
                distanceRow.push(Math.abs(dist).toFixed(2) + 'm');
                break;
              // case 'km':
              //   distanceRow.push(Math.abs(dist).toFixed(2) + 'km');
              //   break;
              // case 'in':
              //   distanceRow.push(Math.abs(dist).toFixed(2) + 'in');
              //   break;
              // case 'ft':
              //   distanceRow.push(Math.abs(dist).toFixed(2) + 'ft');
              //   break;
            }
            // switch (this.unit) {
            //   case 'Metric':
            //     distanceRow.push(Math.abs(dist).toFixed(2) + 'cm');
            //     break;
            //   case 'English':
            //     distanceRow.push(Math.abs(dist).toFixed(2) + 'in');
            //     break;
            // }
            distanceJointTable.push(distanceRow);
            connJointsTable.push(linksIDsRow);
          }
        });
      });
      j.input === true ? this.inputTable.push(true) : this.inputTable.push(false);
      row.push(j.id.toString());
      j.type === 'R' ? row.push('R') : row.push('RP');
      // row.push(j.getType().toString());
      this.distanceJointTableOfTables.push(distanceJointTable); // toString not used because of putting , instead of ' '
      this.connJointsTableOfTables.push(connJointsTable);
      row.push(j.x.toString());
      row.push(j.y.toString());
      if (j.type === 'R') {
        row.push(' ');
      } else { // slider
        row.push(j.angle.toString());
        // row.push(j.getCoeffFriction().toString());
      }
      this.jointTable.push(row);
    });
  }
  displayLinks() {
    this.linkTable = Array<Array<string>>();
    this.linkArray.forEach(l => {
      const row = Array<string>();
      row.push(l.id);
      row.push(l.mass.toString());
      row.push(l.massMomentOfInertia.toString());
      row.push(l.centerOfMassX.toString());
      row.push(l.centerOfMassY.toString());
      this.linkTable.push(row);
    });
  }
  displayForces() {
    this.forceTable = Array<Array<string>>();
    this.forceDirectionTable = Array<boolean>();
    this.forceLocalTable = Array<boolean>();
    this.forceArray.forEach(f => {
      const row = Array<string>();
      row.push(f.id);
      row.push(f.angle.toFixed(2));
      row.push(f.xMag.toString());
      row.push(f.yMag.toString());
      row.push(f.start.x.toFixed(2));
      row.push(f.start.y.toFixed(2));
      f.directionOutward === true ? this.forceDirectionTable.push(true) : this.forceDirectionTable.push(false);
      f.isGlobal === true ? this.forceLocalTable.push(true) : this.forceLocalTable.push(false);
      this.forceTable.push(row);
    });
  }

  displayThreePosition() {
    this.threePositionTable = Array<Array<string>>();
    this.threePositionArray.forEach(threePosition => {
      const row = Array<string>();
      row.push(threePosition.id);
      row.push(threePosition.x.toString());
      row.push(threePosition.y.toString());
      // if (threePosition.neighbor_one !== undefined) {
      //   row.push(threePosition.neighbor_one.id.toString());
      // } else {
      //   row.push(' ');
      // }
      // if (threePosition.neighbor_two !== undefined) {
      //   row.push(threePosition.neighbor_two.id.toString());
      // } else {
      //   row.push(' ');
      // }
      this.threePositionTable.push(row);
    });
  }

  displayPathPoint() {
    this.pathPointTable = Array<Array<string>>();
    this.pathPointArray.forEach(pathPoint => {
      const row = Array<string>();
      row.push(pathPoint.id);
      row.push(pathPoint.x.toString());
      row.push(pathPoint.y.toString());
      if (pathPoint.neighbor_one !== undefined) {
        row.push(pathPoint.neighbor_one.id.toString());
      } else {
        row.push(' ');
      }
      if (pathPoint.neighbor_two !== undefined) {
        row.push(pathPoint.neighbor_two.id.toString());
      } else {
        row.push(' ');
      }
      this.pathPointTable.push(row);
    });
  }

  // have the newJointEmit be used on a button for creating a new joint within the linkage table

  changeThreePositionXPos(event, indexOfElement: number) {
    const threePosition = this.threePositionArray[indexOfElement];
    threePosition.x = Number(event.target.value);
    this.refreshThreePositionEmit.emit();
    this.displayThreePositionTitle();
    this.displayThreePosition();
  }

  changeThreePositionYPos(event, indexOfElement: number) {
    const threePosition = this.threePositionArray[indexOfElement];
    threePosition.y = Number(event.target.value);
    this.refreshThreePositionEmit.emit();
    this.displayThreePositionTitle();
    this.displayThreePosition();
  }

  changePathPointXPos(event, indexOfElement: number) {
    const pathPoint = this.pathPointArray[indexOfElement];
    pathPoint.x = Number(event.target.value);
    this.refreshPathPointEmit.emit();
    this.displayPathPointTitle();
    this.displayPathPoint();
  }

  changePathPointYPos(event, indexOfElement: number) {
    const pathPoint = this.pathPointArray[indexOfElement];
    pathPoint.y = Number(event.target.value);
    this.refreshPathPointEmit.emit();
    this.displayPathPointTitle();
    this.displayPathPoint();
  }

  changeJointId(event, indexOfElement: number) {
    const joint = this.jointArray[indexOfElement];
    const oldJointID = joint.id;
    const newJointID = event.target.value;
    joint.id = newJointID;
    // joint.id = event.target.value;
    joint.links.forEach(l => {
      const indexChange = l.id.indexOf(oldJointID);
      l.id = l.id.substring(0, indexChange) + joint.id + l.id.substring(indexChange + oldJointID.length);
    });
    // joint.id = String(event.target.value);
    this.renameJointLinkLabels.emit({oldJointID, newJointID});
    // this.displayJointsTitle();
    // this.displayJoints();
  }

  changeJointXPos(event, indexOfElement: number) {
    const joint = this.jointArray[indexOfElement];
    joint.x = Number(event.target.value);
    // this.editJointEmit.emit(joint);
    this.refreshJointEmit.emit();
    this.displayJointsTitle();
    this.displayJoints();
    // this.checkJointInputField();
  }
  changeJointYPos(event, indexOfElement: number) {
    const joint = this.jointArray[indexOfElement];
    joint.y = Number(event.target.value);
    // this.editJointEmit.emit(joint);
    this.refreshJointEmit.emit();
    this.displayJointsTitle();
    this.displayJoints();
    // this.checkJointInputField();
  }
  changeSlideAngle(event, indexOfElement: number) {
    const joint = this.jointArray[indexOfElement];
    joint.angle = Number(event.target.value);
    this.editJointEmit.emit(joint);
    this.displayJointsTitle();
    this.displayJoints();
    // this.checkJointInputField();
  }

  changeLinkMass(event, indexOfElement: number) {
    const link = this.linkArray[indexOfElement];
    link.mass = Number(event.target.value);
    this.editLinkEmit.emit(link);
    this.displayLinks();
  }
  changeLinkMassMomentOfInertia(event, indexOfElement: number) {
    const link = this.linkArray[indexOfElement];
    link.massMomentOfInertia = Number(event.target.value);
    this.editLinkEmit.emit(link);
    this.displayLinks();
  }
  changeLinkCenterOfMassX(event, indexOfElement: number) {
    const link = this.linkArray[indexOfElement];
    link.centerOfMassX = Number(event.target.value);
    this.editLinkEmit.emit(link);
    this.displayLinks();
  }
  changeLinkCenterOfMassY(event, indexOfElement: number) {
    const link = this.linkArray[indexOfElement];
    link.centerOfMassY = Number(event.target.value);
    this.editLinkEmit.emit(link);
    this.displayLinks();
  }
  changeLinkShape(event, indexOfElement: number) {
    this.editLinkShape.emit(this.linkArray[indexOfElement]);
  }

  changeForceAngle(event, indexOfElement: number) {
    const force = this.forceArray[indexOfElement];
    const degToRad = Math.PI / 180;
    const x_diff = force.end.x - force.start.x;
    const y_diff = force.end.y - force.start.y;
    const force_length = Math.sqrt(Math.pow(x_diff, 2) + Math.pow(y_diff, 2));
    const x_dist = force_length * Math.cos(event.target.value * degToRad);
    const y_dist = force_length * Math.sin(event.target.value * degToRad);
    force.end.x = force.start.x + x_dist;
    force.end.y = force.start.y + y_dist;
    force.saveRelativeCoords();
    // force.relativeCoords.end.x = force.end.x;
    // force.relativeCoords.end.y = force.end.y;
    const link = force.link;
    const force_index = link.forces.findIndex(f => f.id === force.id);
    link.forces[force_index] = force;
    this.editLinkEmit.emit(link);
    this.editForceEmit.emit(force);
    this.refreshForceEmit.emit();
    this.displayForces();
  }

  changeXMag(event, indexOfElement: number) {
    const force = this.forceArray[indexOfElement];
    force.xMag = Number(event.target.value);
    const link = force.link;
    const force_index = link.forces.findIndex(f => f.id === force.id);
    link.forces[force_index] = force;
    this.editLinkEmit.emit(link);
    this.editForceEmit.emit(force);
    this.refreshForceEmit.emit();
    this.displayForces();
  }
  changeYMag(event, indexOfElement: number) {
    const force = this.forceArray[indexOfElement];
    force.yMag = Number(event.target.value);
    const link = force.link;
    const force_index = link.forces.findIndex(f => f.id === force.id);
    link.forces[force_index] = force;
    this.editLinkEmit.emit(link);
    this.editForceEmit.emit(force);
    this.refreshForceEmit.emit();
    this.displayForces();
  }
  changeForceXPos(event, indexOfElement: number) {
    const newX = Number(event.target.value);
    const force = this.forceArray[indexOfElement];
    const link = force.link;
    if (link.uiShape === 'line') {
      if (((newX >= link.joints[0].x) && (newX <= link.joints[1].x )) ||
        ((newX >= link.joints[1].x) && (newX <= link.joints[0].x ))) {
        // Interpolation formula
        const m = (link.joints[1].y - link.joints[0].y) / (link.joints[1].x - link.joints[0].x);
        force.start.x = newX;
        force.start.y = m * (newX - link.joints[0].x) + link.joints[0].y;
        force.saveRelativeCoords();
      }
    } else {
      force.start.x = newX;
      force.saveRelativeCoords();
    }
    const force_index = link.forces.findIndex(f => f.id === force.id);
    link.forces[force_index] = force;
    this.editLinkEmit.emit(link);
    this.editForceEmit.emit(force);
    this.refreshForceEmit.emit();
    this.displayForces();
  }
  changeForceYPos(event, indexOfElement: number) {
    const newY = Number(event.target.value);
    const force = this.forceArray[indexOfElement];
    const link = force.link;
    if (link.uiShape === 'line') {
      if (((newY >= link.joints[0].y) && (newY <= link.joints[1].y)) ||
        ((newY >= link.joints[1].y) && (newY <= link.joints[0].y))) {
        // Interpolation formula
        const m = (link.joints[1].x - link.joints[0].x) / (link.joints[1].y - link.joints[0].y);
        force.start.y = newY;
        force.start.x = m * (newY - link.joints[0].y) + link.joints[0].x;
        force.saveRelativeCoords();
      }
    } else {
      force.start.y = newY;
      force.saveRelativeCoords();
    }

    const force_index = link.forces.findIndex(f => f.id === force.id);
    link.forces[force_index] = force;
    this.editLinkEmit.emit(link);
    this.editForceEmit.emit(force);
    this.refreshForceEmit.emit();
    this.displayForces();
  }

  changeForceDirection(event, indexOfElement: number) {
    const force = this.forceArray[indexOfElement];
    force.directionOutward = force.directionOutward !== true;
    const link = force.link;
    const force_index = link.forces.findIndex(f => f.id === force.id);
    link.forces[force_index] = force;
    this.editLinkEmit.emit(link);
    this.editForceEmit.emit(force);
    this.refreshForceEmit.emit();
    this.displayForces();
  }

  changeForceLocal(event, indexOfElement: number) {
    const force = this.forceArray[indexOfElement];
    force.isGlobal = force.isGlobal !== true;
    const link = force.link;
    const force_index = link.forces.findIndex(f => f.id === force.id);
    link.forces[force_index] = force;
    this.editLinkEmit.emit(link);
    this.editForceEmit.emit(force);
    this.refreshForceEmit.emit();
    this.displayForces();
  }

  /* Highlight the table based on the currently selected joint */
  highlightSelected(joint) {
    const joint_id = joint.getID();
    let selectRow;
    const tableRef = <HTMLTableElement>document.getElementById('joint_tbl');
    for (let i = 0; i < tableRef.rows.length; i++) {
      const currentRow = <HTMLTableRowElement>tableRef.rows.item(i);
      console.log('CELL TEXT: ' + currentRow.cells.item(1).innerText);
      console.log('JOINT SELECTED ID: ' + joint_id);
      if (currentRow.cells.item(1).innerText.trim() === joint_id) {
        selectRow = <HTMLTableRowElement>tableRef.rows.item(i);
        selectRow.bgColor = 'FFFFFF';
        console.log('selected row chosen');
      } else {
        selectRow = <HTMLTableRowElement>tableRef.rows.item(i);
        selectRow.bgColor = '#FFFFFF';
      }
    }
    if (this.tab === 0) {
      this.displayJointsTitle();
      this.displayJoints();
      // this.checkJointInputField();
    }
    if (this.tab === 1) {
      this.displayForces();
    }
  }

  /* Delete the joint in the table associated with delete button in table */
  deleteJoint(index: number) {
    const joint = this.jointArray[index];
    this.delJointEmit.emit(joint);
    this.displayJointsTitle();
    this.displayJoints();
    // this.checkJointInputField();
  }
  deleteAllJoints() {
  }
  deleteLink(index: number) {
    const link = this.linkArray[index];
    this.delLinkEmit.emit(link);
    this.displayLinks();
  }
  deleteAllLinks() {
  }
  deleteForce(index: number) {
    const force = this.forceArray[index];
    this.delForceEmit.emit(force);
    this.displayForces();
  }
  deleteThreePosition(index: number) {
    const threePosition = this.threePositionArray[index];
    this.delThreePositionEmit.emit(threePosition);
    this.displayThreePosition();
  }
  deletePathPoint(index: number) {
    const pathPoint = this.pathPointArray[index];
    switch (this.pathPointArray.length) {
      case 1:
        break;
      case 2:
        pathPoint.neighbor_one.neighbor_one = undefined;
        break;
      case 3:
        pathPoint.neighbor_one.neighbor_two = pathPoint.neighbor_two;
        pathPoint.neighbor_two.neighbor_one = pathPoint.neighbor_one;
        pathPoint.neighbor_one.neighbor_two = undefined;
        pathPoint.neighbor_two.neighbor_two = undefined;
        break;
      default:
        pathPoint.neighbor_one.neighbor_two = pathPoint.neighbor_two;
        pathPoint.neighbor_two.neighbor_one = pathPoint.neighbor_one;
        break;
    }
    this.delPathPointEmit.emit(pathPoint);
    this.displayPathPoint();


    // const pathPoint = this.pathPointArray[index];
    // pathPoint.neighbor_one.neighbor_two = pathPoint.neighbor_two;
    // pathPoint.neighbor_two.neighbor_one = pathPoint.neighbor_one;
    // switch (this.pathPointArray.length) {
    //   case 2:
    //     pathPoint.neighbor_one.neighbor_one = undefined;
    //     pathPoint.neighbor_one.neighbor_two = undefined;
    //     break;
    //   case 3:
    //     pathPoint.neighbor_one.neighbor_two = undefined;
    //     pathPoint.neighbor_two.neighbor_two = undefined;
    //     break;
    //   default:
    //     break;
    // }
    // this.delPathPointEmit.emit(pathPoint);
    // this.displayPathPoint();
  }
  deleteAllForce() {}
}
