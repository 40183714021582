import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {Joint} from '../../classes/Joint';
import {Link} from '../../classes/Link';
import {Force} from '../../classes/Force';
import {StaticFuncs} from '../../functions/StaticFuncs';
import {Coord} from '../../classes/Coord';
import {GridComponent} from '../grid/grid.component';
import { Shape} from '../../functions/SVGFuncs';

export enum contextSelector {
  joint,
  link,
  force,
  pathPoint,
  threePosition,
  canvasNoSyn,
  canvasThreePositionSyn,
  canvasPathPointSyn
}

@Component({
  selector: 'app-contextmenu',
  templateUrl: './contextmenu.component.html',
  styleUrls: ['./contextmenu.component.css']
})
export class ContextmenuComponent implements OnInit, OnChanges {
  // @Input() newFocus: {newCoords: Coord, section: contextSelector, showSelectionOptions: boolean,
  //   contextElement: Joint|Link|Force|GridComponent};
  // @Input() setVisible: boolean;
  // @Input() newContextElement: Joint|Boolean;
  @Input() jointArray: Joint[];
  @Input() linkArray: Link[];
  @Input() forceArray: Force[];
  @Input() pathPoint: any;
  @Input() threePosition: any;
  @Input() synthesis: string;
  @Output() menuClicked = new EventEmitter<{type: String, event: MouseEvent}>();
  // @Output() selectedElementEmit = new EventEmitter<Joint|Link|Force|GridComponent>();
  @Output() contextMenuCoords = new EventEmitter<Coord>();
  coords: Coord; // The location the context menu is hovering over
  visible: boolean; // Indicates whether the context menu is visible or not
  selectedElement: Joint|Link|Force|GridComponent; // The element the context menu is currently interacting with
  Menu: HTMLElement; // Reference to the entire context menu
  JointOptions: HTMLElement; // Reference to the joint options of the context menu
  LinkOptions: HTMLElement; // Reference to the link options of the context menu
  ForceOptions: HTMLElement;
  CanvasOptionsNoSyn: HTMLElement; // Reference to the canvas options of the context menu
  PathPointOptions: HTMLElement;
  ThreePositionOptions: HTMLElement;
  CanvasOptionsThreePositionSyn: HTMLElement;
  CanvasOptionsPathPointSyn: HTMLElement;
  FloatingJointOptions: HTMLElement;
  GroundJointOptions: HTMLElement;
  JointWithOneOrZeroLinkAttachedOptions: HTMLElement;
  // JointOptionals: HTMLElement; // Reference to the options that only appear
  // when elements are selected  JointOptionals: HTMLElement; // Reference to the options that only appear when elements are selected
  LinkOptionals: HTMLElement; // Reference to the options that only appear when certain links are selected
  // ForceOptionals: HTMLElement;

  constructor() {
    this.coords = new Coord(0, 0);
    this.visible = false;
  }

  handleNewFocus($event) {
    const newFocus = $event;
    this.showmenu(newFocus.newCoords, newFocus.section, newFocus.object);
    // this.showmenu(newFocus.newCoords, newFocus.section, newFocus.showSelectionOptions);
  }

  handleHideMenu($event) {
    this.hideMenu();
  }

  ngOnInit() {
    this.Menu = document.querySelector('.menu');

    this.JointOptions = document.getElementById('jointOptions');
    this.FloatingJointOptions = document.getElementById('floatingJointOptions');
    this.GroundJointOptions = document.getElementById('groundJointOptions');
    this.JointWithOneOrZeroLinkAttachedOptions = document.getElementById('jointWithOneOrZeroLinkAttachedOptions');

    this.LinkOptions = document.getElementById('linkOptions');
    this.LinkOptionals = document.getElementById('linkOptionals');

    this.ForceOptions = document.getElementById('forceOptions');

    this.CanvasOptionsNoSyn = document.getElementById('canvasOptionsNoSyn');
    this.CanvasOptionsPathPointSyn = document.getElementById('canvasOptionsPathPointSyn');
    this.CanvasOptionsThreePositionSyn = document.getElementById('canvasOptionsThreePositionSyn');

    this.ThreePositionOptions = document.getElementById('threePositionOptions');
    this.PathPointOptions = document.getElementById('pathPointOptions');

    this.Menu.style.display = 'none';

    this.JointOptions.style.display = 'none';
    this.FloatingJointOptions.style.display = 'none';
    this.GroundJointOptions.style.display = 'none';
    this.JointWithOneOrZeroLinkAttachedOptions.style.display = 'none';

    this.LinkOptions.style.display = 'none';
    this.LinkOptionals.style.display = 'none';

    this.ForceOptions.style.display = 'none';

    this.CanvasOptionsNoSyn.style.display = 'none';
    this.CanvasOptionsThreePositionSyn.style.display = 'none';
    this.CanvasOptionsPathPointSyn.style.display = 'none';

    this.ThreePositionOptions.style.display = 'none';
    this.PathPointOptions.style.display = 'none';

    this.hideMenu();
    this.contextMenuCoords.emit(this.coords);
    // add the listeners for the menu items
    this.addMenuListeners();
  }

  ngOnChanges(changes: SimpleChanges) {
    // If the parent component communicates to change the location, object focus, or to show selection options
    if (changes.newFocus) {
      const newFocus = changes.newFocus.currentValue;
      this.showmenu(newFocus.newCoords, newFocus.section, newFocus.showSelectionOptions);
      this.selectedElement = newFocus.contextElement;
    }
    // If the parent component communicates to hide the menu
    if (changes.setVisible) {
      const setVisible = changes.setVisible.currentValue;
      if (!setVisible) {
        this.hideMenu();
      }
    }
    // If the parent component communicates to change which element is selected
    if (changes.newContextElement) {
      this.selectedElement = changes.newContextElement.currentValue;
    }
  }

  // Sets the coords of the context menu and emits the new coordinates to other components
  setCoords(newCoords: Coord) {
    this.coords = newCoords;
    this.contextMenuCoords.emit(this.coords);
  }

  // Contains all the event listeners for elements in the context menu
  addMenuListeners() {
    document.getElementById('groundSet').addEventListener('click', e => this.handleClick('groundSet', e));
    document.getElementById('inputSet').addEventListener('click', e => this.handleClick('inputSet', e));
    document.getElementById('linkSet').addEventListener('click', e => this.handleClick('linkSet', e));
    document.getElementById('sliderSet').addEventListener('click', e => this.handleClick('sliderSet', e));
    document.getElementById('createTracerJoint').addEventListener('click', e => this.handleClick('createTracerJoint', e));
    document.getElementById('deleteJoint').addEventListener('click', e => this.handleClick('deleteJoint', e));
    // document.getElementById('positionPath').addEventListener('click', e => this.handleClick('positionPath', e));
    // document.getElementById('jointKinematic').addEventListener('click', e => this.handleClick('jointKinematic', e));
    document.getElementById('createThreePosition').addEventListener('click', e => this.handleClick('createThreePosition', e));
    document.getElementById('createPathPoint').addEventListener('click', e => this.handleClick('createPathPoint', e));
    document.getElementById('deleteThreePosition').addEventListener('click', e => this.handleClick('deleteThreePosition', e));
    document.getElementById('deletePathPoint').addEventListener('click', e => this.handleClick('deletePathPoint', e));
    document.getElementById('createLink').addEventListener('click', e => this.handleClick('createLink', e));
    document.getElementById('deleteLink').addEventListener('click', e => this.handleClick('deleteLink', e));
    document.getElementById('editLink').addEventListener('click', e => this.handleClick('editLink', e));
    document.getElementById('createLinkOnLink').addEventListener('click', e => this.handleClick('createLinkOnLink', e));
    document.getElementById('addForce').addEventListener('click', e => this.handleClick('addForce', e));
    document.getElementById('deleteForce').addEventListener('click', e => this.handleClick('deleteForce', e));
    document.getElementById('toggleGlobal').addEventListener('click', e => this.handleClick('toggleGlobal', e));
    document.getElementById('toggleForceDirection').addEventListener('click', e => this.handleClick('toggleForceDirection', e));
  }

  // Function run after clicking on the context menu. Hides the menu and emits the element clicked to the parent component for handling
  handleClick(type: string, event: MouseEvent) {
    this.hideMenu();
    this.menuClicked.emit({type: type, event: event});
    this.selectedElement = undefined;
  }

  // Move the menu to the coords and set a section of it visible
  // showmenu(coords: Coord, selector: contextSelector, showSelectionOptions: boolean) {
  showmenu(coords: Coord, selector: contextSelector, object: any) {
    this.setCoords(coords);
    this.Menu.style.top = `${this.coords.y}px`;
    this.Menu.style.left = `${this.coords.x}px`;
    this.Menu.style.display = 'block';
    let elements = [];
    switch (selector) {
      case contextSelector.joint:
        this.LinkOptions.style.display = 'none';
        this.LinkOptionals.style.display = 'none';

        this.ForceOptions.style.display = 'none';

        this.CanvasOptionsNoSyn.style.display = 'none';
        this.CanvasOptionsPathPointSyn.style.display = 'none';
        this.CanvasOptionsThreePositionSyn.style.display = 'none';

        this.ThreePositionOptions.style.display = 'none';
        this.PathPointOptions.style.display = 'none';

        // this.ForceOptionals.style.display = 'none';
        elements = [];
        // elements = [this.JointOptions.children];
        const joint = object;
        if (joint.links.length <= 1) {
          if (joint.grounded) {
                this.GroundJointOptions.style.display = 'block';
                this.JointWithOneOrZeroLinkAttachedOptions.style.display = 'none';
                elements.push(this.GroundJointOptions.children);
          } else {
                this.GroundJointOptions.style.display = 'none';
                this.JointWithOneOrZeroLinkAttachedOptions.style.display = 'block';
                elements.push(this.JointWithOneOrZeroLinkAttachedOptions.children);
          }
          this.FloatingJointOptions.style.display = 'block';
          elements.push(this.FloatingJointOptions.children);
        } else {
          this.GroundJointOptions.style.display = 'none';
          this.JointWithOneOrZeroLinkAttachedOptions.style.display = 'none';
          this.FloatingJointOptions.style.display = 'none';
        }
        // switch (joint.links.length) {
        //   case 0:
        //     this.FloatingJointOptions.style.display = 'block';
        //     elements.push(this.FloatingJointOptions.children);
        //     break;
        //   case 1:
        //     this.FloatingJointOptions.style.display = 'block';
        //     elements.push(this.FloatingJointOptions.children);
        //     break;
        //   case 2:
        //     this.FloatingJointOptions.style.display = 'none';
        //     break;
        // }
        // switch (joint.grounded) {
        //   case true:
        //     this.GroundJointOptions.style.display = 'block';
        //     elements.push(this.GroundJointOptions.children);
        //     break;
        //   case false:
        //     this.GroundJointOptions.style.display = 'none';
        //     break;
        // }
        // if (joint.links.length === 1) {
        //   // this.JointOptionals.style.display = 'block';
        //   // elements.push(this.JointOptionals.children);
        // } else {
        //   // this.JointOptionals.style.display = 'none';
        // }
        this.JointOptions.style.display = 'block';
        elements.push(this.JointOptions.children);
        this.setContextMenuHeight(elements);
        break;
      case contextSelector.link:
        this.JointOptions.style.display = 'none';
        this.FloatingJointOptions.style.display = 'none';
        this.GroundJointOptions.style.display = 'none';
        this.JointWithOneOrZeroLinkAttachedOptions.style.display = 'none';

        this.ForceOptions.style.display = 'none';

        this.CanvasOptionsNoSyn.style.display = 'none';
        this.CanvasOptionsPathPointSyn.style.display = 'none';
        this.CanvasOptionsThreePositionSyn.style.display = 'none';

        this.ThreePositionOptions.style.display = 'none';
        this.PathPointOptions.style.display = 'none';

        const link = object;
        elements = [];

        this.LinkOptions.style.display = 'block';
        elements.push(this.LinkOptions.children);

        if (link.uiShape !== Shape.line) {
          this.LinkOptionals.style.display = 'block';
          elements.push(this.LinkOptionals.children);
        } else {
          this.LinkOptionals.style.display = 'none';
        }
        this.setContextMenuHeight(elements);
        break;
      case contextSelector.force:
        this.JointOptions.style.display = 'none';
        this.FloatingJointOptions.style.display = 'none';
        this.GroundJointOptions.style.display = 'none';
        this.JointWithOneOrZeroLinkAttachedOptions.style.display = 'none';

        this.LinkOptions.style.display = 'none';
        this.LinkOptionals.style.display = 'none';

        this.CanvasOptionsNoSyn.style.display = 'none';
        this.CanvasOptionsPathPointSyn.style.display = 'none';
        this.CanvasOptionsThreePositionSyn.style.display = 'none';

        this.ThreePositionOptions.style.display = 'none';
        this.PathPointOptions.style.display = 'none';

        this.ForceOptions.style.display = 'block';
        elements = [this.ForceOptions.children];
        this.setContextMenuHeight(elements);
        break;
      case contextSelector.pathPoint:
        this.JointOptions.style.display = 'none';
        this.FloatingJointOptions.style.display = 'none';
        this.GroundJointOptions.style.display = 'none';
        this.JointWithOneOrZeroLinkAttachedOptions.style.display = 'none';

        this.LinkOptions.style.display = 'none';
        this.LinkOptionals.style.display = 'none';

        this.ForceOptions.style.display = 'none';

        this.CanvasOptionsNoSyn.style.display = 'none';
        this.CanvasOptionsPathPointSyn.style.display = 'none';
        this.CanvasOptionsThreePositionSyn.style.display = 'none';

        this.ThreePositionOptions.style.display = 'none';
        this.PathPointOptions.style.display = 'block';
        elements = [this.PathPointOptions.children];
        this.setContextMenuHeight(elements);
        break;
      case contextSelector.threePosition:
        this.JointOptions.style.display = 'none';
        this.FloatingJointOptions.style.display = 'none';
        this.GroundJointOptions.style.display = 'none';
        this.JointWithOneOrZeroLinkAttachedOptions.style.display = 'none';

        this.LinkOptions.style.display = 'none';
        this.LinkOptionals.style.display = 'none';

        this.ForceOptions.style.display = 'none';

        this.CanvasOptionsNoSyn.style.display = 'none';
        this.CanvasOptionsPathPointSyn.style.display = 'none';
        this.CanvasOptionsThreePositionSyn.style.display = 'none';

        this.ThreePositionOptions.style.display = 'block';
        this.PathPointOptions.style.display = 'none';
        elements = [this.PathPointOptions.children];
        this.setContextMenuHeight(elements);
        break;
      case contextSelector.canvasNoSyn:
        this.JointOptions.style.display = 'none';
        this.FloatingJointOptions.style.display = 'none';
        this.GroundJointOptions.style.display = 'none';
        this.JointWithOneOrZeroLinkAttachedOptions.style.display = 'none';

        this.LinkOptions.style.display = 'none';
        this.LinkOptionals.style.display = 'none';

        this.ForceOptions.style.display = 'none';

        this.CanvasOptionsNoSyn.style.display = 'block';
        this.CanvasOptionsPathPointSyn.style.display = 'none';
        this.CanvasOptionsThreePositionSyn.style.display = 'none';

        this.ThreePositionOptions.style.display = 'none';
        this.PathPointOptions.style.display = 'none';

        // this.JointOptionals.style.display = 'none';
        // this.ForceOptionals.style.display = 'none';
        elements = [this.CanvasOptionsNoSyn.children];
        this.setContextMenuHeight(elements);
        break;
      case contextSelector.canvasPathPointSyn:
        this.JointOptions.style.display = 'none';
        this.FloatingJointOptions.style.display = 'none';
        this.GroundJointOptions.style.display = 'none';
        this.JointWithOneOrZeroLinkAttachedOptions.style.display = 'none';

        this.LinkOptions.style.display = 'none';
        this.LinkOptionals.style.display = 'none';

        this.ForceOptions.style.display = 'none';

        this.CanvasOptionsNoSyn.style.display = 'none';
        this.CanvasOptionsPathPointSyn.style.display = 'block';
        this.CanvasOptionsThreePositionSyn.style.display = 'none';

        this.ThreePositionOptions.style.display = 'none';
        this.PathPointOptions.style.display = 'none';

        elements = [this.CanvasOptionsPathPointSyn.children];
        this.setContextMenuHeight(elements);
        break;
      case contextSelector.canvasThreePositionSyn:
        this.JointOptions.style.display = 'none';
        this.FloatingJointOptions.style.display = 'none';
        this.GroundJointOptions.style.display = 'none';
        this.JointWithOneOrZeroLinkAttachedOptions.style.display = 'none';

        this.LinkOptions.style.display = 'none';
        this.LinkOptionals.style.display = 'none';

        this.ForceOptions.style.display = 'none';

        this.CanvasOptionsNoSyn.style.display = 'none';
        this.CanvasOptionsPathPointSyn.style.display = 'none';
        this.CanvasOptionsThreePositionSyn.style.display = 'block';

        this.ThreePositionOptions.style.display = 'none';
        this.PathPointOptions.style.display = 'none';

        elements = [this.CanvasOptionsThreePositionSyn.children];
        this.setContextMenuHeight(elements);
        break;
      default:
    }
    this.visible = true;
  }

  // Make the context menu invisible
  hideMenu() {
    // this.JointOptionals.style.display = 'none';
    // this.FloatingJointOptions.style.display = 'none';
    // this.GroundJointOptions.style.display = 'none';
    // this.LinkOptionals.style.display = 'none';
    this.Menu.style.display = 'none';

    this.JointOptions.style.display = 'none';
    this.FloatingJointOptions.style.display = 'none';
    this.GroundJointOptions.style.display = 'none';
    this.JointWithOneOrZeroLinkAttachedOptions.style.display = 'none';

    this.LinkOptions.style.display = 'none';
    this.LinkOptionals.style.display = 'none';

    this.ForceOptions.style.display = 'none';

    this.CanvasOptionsPathPointSyn.style.display = 'none';
    this.CanvasOptionsNoSyn.style.display = 'none';

    this.setCoords(new Coord(0, 0));
    // this.Menu.style.display = 'none';
    this.visible = false;
  }
  // Sets the context menu based off of all the elments currently visible
  setContextMenuHeight(elements: HTMLCollection[]) {
    let height = 0;
    for (let j = 0; j < elements.length; j++) {
      const numElements = elements[j].length;
      for (let i = 0; i < numElements; i++) {
        height = height + Number(elements[j][i].clientHeight);
      }
    }
    const newHeight = height + 'px';
    this.Menu.style.height = String(newHeight);
  }
}
