    import {SVGFuncs} from '../functions/SVGFuncs';
import {Vertex} from './Vertex';


export class Tag extends Vertex {
  private _svg: SVGElement;

  constructor(id: string, x: number, y: number, type: string) {
    super(id, x, y);
    switch (type) {
      case 'jointLink':
        this._svg = SVGFuncs.createJointLinkTag(id, x, y);
        break;
      case 'CoM':
        this._svg = SVGFuncs.createCoMTag(id, x, y);
        break;
    }
  }

  get svg() {
    return this._svg;
  }
}
