import { SVGFuncs } from '../functions/SVGFuncs';

// A vertex is a parent class for single point entities on the grid. (Joints, Force endpoints)

export abstract class Vertex {
  private _id: string;
  private _x: number;
  private _y: number;

  // Main constructor
  protected constructor(ID: string, x: number, y: number) {
    this._id = ID;
    this._x = x;
    this._y = y;
  }

  // Getters
  get x() {
    return this._x;
  }


  set x(value: number) {
    this._x = value;
  }

  set y(value: number) {
    this._y = value;
  }

  get y() {
    return this._y;
  }

  get id() {
    return this._id;
  }

  set id(value: string) {
    this._id = value;
  }

  relocate(x: number, y: number) {
    this._x = x;
    this._y = y;
  }

}
