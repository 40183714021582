import {ImagJoint, Joint} from '../Joints_Links_Forces/Joint';
import {ImagLink, Link} from '../Joints_Links_Forces/Link';

export class PositionSolver {
  static desiredIndexWithinPosAnalysisMap = new Map<string, number>();
  static jointMapPositions = new Map<string, Array<number>>();
  static desiredJointGroundIndexMap = new Map<string, number>();
  static unknownJointsIndicesMap = new Map<string, number[]>();
  static desiredLinkIndexMap = new Map<string, number>();
  static inputJointIndex: number;
  static numJointsDetermine: number;
  // static radToDeg = 180 / Math.PI;
  private static internalTriangleValuesMap = new Map<string, number[]>();
  private static jointNumOrderSolverMap = new Map<number, string>();
  private static desiredConnectedJointIndicesMap = new Map<string, number[]>();
  private static desiredAnalysisJointMap = new Map<string, string>();

  static resetStaticVariables() {
    this.desiredIndexWithinPosAnalysisMap = new Map<string, number>();
    this.jointMapPositions = new Map<string, Array<number>>();
    this.desiredJointGroundIndexMap = new Map<string, number>();
    this.unknownJointsIndicesMap = new Map<string, number[]>();
    this.desiredLinkIndexMap = new Map<string, number>();
    this.inputJointIndex = undefined;
    this.numJointsDetermine = undefined;
    this.internalTriangleValuesMap = new Map<string, number[]>();
    this.jointNumOrderSolverMap = new Map<number, string>();
    this.desiredConnectedJointIndicesMap = new Map<string, number[]>();
    this.desiredAnalysisJointMap = new Map<string, string>();
  }

  // static determineJointOrderSolver(simJoints: Joint[], simLinks: Link[]) {
  //   let knownJoints_ids =  [];
  //   // 1st: store all ground joints as known joints
  //   simJoints.forEach(jt => {
  //     if (jt.ground) {
  //       knownJoints_ids.push(jt.id);
  //     }
  //   });
  //   // 2nd: determine joints that neighbor the input joint
  //   const inputJointIndex = simJoints.findIndex(j => j.input);
  //   const inputJoint = simJoints[inputJointIndex];
  //   let solveOrderNumber = 1;
  //   inputJoint.connectedJoints.forEach(current_joint => {
  //     if (current_joint instanceof ImagJoint || current_joint.ground) {
  //       return;
  //     }
  //     // store the solve number
  //     this.jointNumOrderSolverMap.set(solveOrderNumber++, current_joint.id);
  //     // store desired joints as input joint and current_joint
  //     // const currentJointIndex = simJoints.findIndex(jt => jt.id === current_joint.id);
  //     this.desiredConnectedJointIndicesMap.set(current_joint.id, [inputJointIndex]);
  //     // store the solve type from the input solver
  //     switch (inputJoint.jointType) {
  //       case 'R': {
  //         this.desiredAnalysisJointMap.set(current_joint.id, 'incrementRevInput');
  //         break;
  //       }
  //       case 'P': {
  //         this.desiredAnalysisJointMap.set(current_joint.id, 'incrementPrisInput');
  //         break;
  //       }
  //     }
  //     knownJoints_ids.push(current_joint.id);
  //   });
  //   // 3rd: from the determined joints, determine neighboring joints that have known neighboring joints (already determined or ground)
  //   // this.desiredMethod()
  //   inputJoint.connectedJoints.forEach(cur_joint => {
  //     if (cur_joint instanceof ImagJoint || cur_joint.ground) {
  //       return;
  //     }
  //     cur_joint.connectedJoints.forEach(unknown_joint => {
  //       const unknown_joint_bool = knownJoints_ids.findIndex(known_joint_id => known_joint_id === unknown_joint.id) === -1;
  //       if (!unknown_joint_bool) {
  //         return;
  //       }
  //       // if (unknown_joint_bool) {
  //       let unknown_joint_not_determined = true;
  //       unknown_joint.connectedJoints.forEach(known_neighboring_joint => {
  //         if (!unknown_joint_not_determined) {
  //           return;
  //         }
  //         const known_neighboring_joint_bool = knownJoints_ids.findIndex(known_joint_id =>
  //             known_joint_id === known_neighboring_joint.id) !== -1 &&
  //           known_neighboring_joint.id !== cur_joint.id;
  //         if (!known_neighboring_joint_bool) {
  //           return;
  //         }
  //         // if (known_neighboring_joint_bool) {
  //         const [arr_1, arr_2] = this.findNeighboringJoints(simJoints, simLinks, cur_joint, unknown_joint,
  //           known_neighboring_joint, solveOrderNumber, knownJoints_ids);
  //         solveOrderNumber = arr_1;
  //         knownJoints_ids = arr_2;
  //         unknown_joint_not_determined = false;
  //         // }
  //       });
  //       // }
  //     });
  //   });
  // }

  static determineJointOrder(simJoints: Joint[], simLinks: Link[]) {
    const knownJointsIds =  [];
    let orderNum = 1;

    // 1st: store all ground joints as known joints
    simJoints.forEach(jt => {
      if (jt.ground) {
        knownJointsIds.push(jt.id);
      }
    });
    // 2nd: determine joints that neighbor the input joint
    const inputJointIndex = simJoints.findIndex(j => j.input);
    const inputJoint = simJoints[inputJointIndex];
    const tracer_joints = [];
    inputJoint.connectedJoints.forEach(current_joint => {
      if (current_joint instanceof ImagJoint || current_joint.ground) {
        return;
      }
      // store the solve number
      this.jointNumOrderSolverMap.set(orderNum++, current_joint.id);
      // store desired joints as input joint and current_joint
      // const currentJointIndex = simJoints.findIndex(jt => jt.id === current_joint.id);
      this.desiredConnectedJointIndicesMap.set(current_joint.id, [inputJointIndex]);
      // store the solve type from the input solver
      switch (inputJoint.jointType) {
        case 'R': {
          this.desiredAnalysisJointMap.set(current_joint.id, 'incrementRevInput');
          break;
        }
        case 'P': {
          this.desiredAnalysisJointMap.set(current_joint.id, 'incrementPrisInput');
          break;
        }
      }
      knownJointsIds.push(current_joint.id);
      tracer_joints.push(current_joint);
    });
    tracer_joints.forEach(j => {
      orderNum = this.detJointOrder(simJoints, simLinks, j, orderNum, knownJointsIds);
    });
  }

  static detJointOrder(simJoints: Joint[], simLinks: Link[], prev_joint: Joint, orderNum: number, knownJointArray: string[]) {
    prev_joint.connectedJoints.forEach(cur_joint => {
      // cur_joint is already known
      if (knownJointArray.findIndex(j_id => j_id === cur_joint.id) !== -1) {
        return;
      }
      const prev_joint_index = simJoints.findIndex(j => j.id === prev_joint.id);
      // let desired_joint_type = 'R';
      // if (prev_joint.jointType === 'P' || cur_joint.jointType === 'P') {
      //   desired_joint_type = 'P';
      // }
      switch (cur_joint.jointType) {
        case 'R':
          const known_joint = this.findKnownJoint(cur_joint, prev_joint, knownJointArray);
          if (known_joint === undefined) {
            return;
          }
          knownJointArray.push(cur_joint.id);
          const known_joint_index = simJoints.findIndex(j => j.id === known_joint.id);
          this.desiredConnectedJointIndicesMap.set(cur_joint.id, [prev_joint_index, known_joint_index]);
          this.desiredAnalysisJointMap.set(cur_joint.id, 'twoCircleIntersectionPoints');
          this.jointNumOrderSolverMap.set(orderNum++, cur_joint.id);
          const desiredTracerJoints = [];
          cur_joint.connectedJoints.forEach(tracer_joint => {
            const cur_joint_index = simJoints.findIndex(j => j.id === cur_joint.id);
            if (tracer_joint.jointType === 'P') {
              this.desiredConnectedJointIndicesMap.set(tracer_joint.id, [cur_joint_index]);
              this.desiredAnalysisJointMap.set(tracer_joint.id, 'circleLineIntersectionPoints');
              this.jointNumOrderSolverMap.set(orderNum++, tracer_joint.id);
              return;
            }
            const desired_link = simLinks.find(l => {
              return l.joints.findIndex(l_joint => l_joint.id === prev_joint.id) !== -1 &&
              l.joints.findIndex(l_joint => l_joint.id === cur_joint.id) !== -1;
            });
            if (knownJointArray.findIndex(j_id => j_id === tracer_joint.id) !== -1) {
              return;
            }
            // tracer joint is not connected on the same link as prev joint and curr joint
            if (tracer_joint.connectedLinks.findIndex(ll => ll.id === desired_link.id) !== -1) {
              return;
            }
            // const tracer_joint_index = 0;
            this.desiredConnectedJointIndicesMap.set(tracer_joint.id, [cur_joint_index, known_joint_index]);
            this.desiredAnalysisJointMap.set(tracer_joint.id, 'determineTracerJoint');
            this.jointNumOrderSolverMap.set(orderNum++, tracer_joint.id);
            knownJointArray.push(tracer_joint.id);
            desiredTracerJoints.push(tracer_joint);
          });
          desiredTracerJoints.forEach(jt => {
            orderNum = this.detJointOrder(simJoints, simLinks, jt, orderNum, knownJointArray);
          });
          break;
        case 'P':
          this.desiredConnectedJointIndicesMap.set(cur_joint.id, [prev_joint_index]);
          this.desiredAnalysisJointMap.set(cur_joint.id, 'circleLineIntersectionPoints');
          this.jointNumOrderSolverMap.set(orderNum++, cur_joint.id);
          break;
      }
    });
    return orderNum;
  }

  static findKnownJoint(joint, prev_joint, knownJointArray: string[]) {
    return joint.connectedJoints.find(jt => {
      const knownJointIndex = knownJointArray.findIndex(j_id => j_id === jt.id);
      return (knownJointIndex !== -1 && jt.id !== prev_joint.id);
      // return knownJointArray.findIndex(j_id => j_id === jt.id) !== -1 && jt.id !== prev_joint.id;
    });
  }

  static determinePositionAnalysis(simJoints: Joint[], simLinks: Link[], angVelDir: boolean): [Map<string, Array<number>>, boolean] {
    let counter = 1;
    let max_couter = 0;
    simJoints.forEach(j => {
      if (!j.ground && !(j instanceof ImagJoint)) {
        max_couter++;
      }
    });
    while (counter <= max_couter) {
      const joint_id = this.jointNumOrderSolverMap.get(counter);
      const joint = simJoints.find(j => j.id === joint_id);
      const connected_joint_indices = this.desiredConnectedJointIndicesMap.get(joint_id);
      const desired_analysis = this.desiredAnalysisJointMap.get(joint_id);
      let possible: boolean;
      switch (desired_analysis) {
        case 'incrementRevInput':
          this.incrementRevInput(simJoints[connected_joint_indices[0]], joint, angVelDir);
          possible = true;
          break;
        case 'incrementPrisInput':
          this.incrementPrisInput(simJoints[connected_joint_indices[0]], joint, angVelDir);
          possible = true;
          break;
        case 'twoCircleIntersectionPoints':
          possible = this.twoCircleIntersectionPoints(simJoints[connected_joint_indices[0]], simJoints[connected_joint_indices[1]], joint);
          break;
        case 'circleLineIntersectionPoints':
          possible = this.circleLineIntersectionPoints(simJoints[connected_joint_indices[0]], simJoints[connected_joint_indices[1]], joint);
          break;
        case 'determineTracerJoint':
          this.determineTracerJoint(simJoints[connected_joint_indices[0]], simJoints[connected_joint_indices[1]], joint);
          possible = true;
          break;
      }
      if (!possible) {
        return [this.jointMapPositions, false];
      }
      counter++;
    }
    return [this.jointMapPositions, true];
  }

// static determinePositionAnalysis(simJoints: Joint[], simLinks: Link[], angVelDir: boolean): [Map<string, Array<number>>, boolean] {
// let jointOrder = '';
// if (this.numJointsDetermine === undefined) {
//   this.numJointsDetermine = 0;
//   simJoints.forEach(j => {
//     if (!j.ground && !j.input) {
//       this.numJointsDetermine++;
//     }
//   });
// }
// // this.inputJointIndex = simJoints.findIndex(j => j.input);
// jointOrder += simJoints[this.inputJointIndex].id;
// if (!this.desiredJointGroundIndexMap.has(simJoints[0].id)) {
//   simJoints.forEach(j => this.desiredJointGroundIndexMap.set(j.id, simJoints.indexOf(j.connectedJoints.find(cj => cj.ground))));
// }
// simJoints[this.inputJointIndex].connectedJoints.forEach(j => {
//   if (j instanceof ImagJoint) {
//     return;
//   }
//   switch (simJoints[this.inputJointIndex].jointType) {
//     case 'R': {
//       this.incrementRevInput(simJoints[this.inputJointIndex], j, angVelDir);
//       break;
//     }
//     case 'P': {
//       this.incrementPrisInput(simJoints[this.inputJointIndex], j, angVelDir);
//       break;
//     }
//   }
//   jointOrder += j.id;
//   jointOrder = this.findNeighboringJoints(simJoints, simLinks, jointOrder, j);
// });
// return this.numJointsDetermine !== jointOrder.length - 1 ? [this.jointMapPositions, false] : [this.jointMapPositions, true];
// }

  // private static findNeighboringJoints(simJoints: Joint [], simLinks: Link[], prev_solved_joint: Joint, unknown_joint: Joint,
  //                                      known_neighboring_joint: Joint, solveOrderNumber: number, knownJoint_ids: string[]):
  //   [number, string[]] {
  //   // store the solve number
  //   this.jointNumOrderSolverMap.set(solveOrderNumber++, unknown_joint.id);
  //   // store desired joints as input joint and j
  //   const prev_solved_joint_index = simJoints.findIndex(j => j.id === prev_solved_joint.id);
  //   const unknown_joint_index = simJoints.findIndex(j => j.id === unknown_joint.id);
  //   const known_neighboring_joint_index = simJoints.findIndex(j => j.id === known_neighboring_joint.id);
  //   this.desiredConnectedJointIndicesMap.set(unknown_joint.id, [prev_solved_joint_index, known_neighboring_joint_index]);
  //   // store the solve type from the input solver
  //   switch (unknown_joint.jointType) {
  //     case 'R' : {
  //       this.desiredAnalysisJointMap.set(unknown_joint.id, 'twoCircleIntersectionPoints');
  //       break;
  //     }
  //     case 'P' : {
  //       this.desiredAnalysisJointMap.set(unknown_joint.id, 'circleLineIntersectionPoints');
  //       break;
  //     }
  //   }
  //   knownJoint_ids.push(unknown_joint.id);
  //   // Determine all of the tracer points within link
  //   const curr_unknown_tracer_joints = [];
  //   unknown_joint.connectedJoints.forEach(curr_unknown_joint => {
  //     if (curr_unknown_joint instanceof ImagJoint) {
  //       return;
  //     }
  //     const tracer_joint_bool = knownJoint_ids.findIndex(joint_id => joint_id === curr_unknown_joint.id) === -1;
  //     if (tracer_joint_bool) {
  //       curr_unknown_tracer_joints.push(curr_unknown_joint);
  //     }
  //   });
  //   // blah blah blah
  //   curr_unknown_tracer_joints.forEach(curr_unknown_joint => {
  //     // store the solve number
  //     this.jointNumOrderSolverMap.set(solveOrderNumber++, curr_unknown_joint.id);
  //     // store desired joints' index values (need to determine if known_neighboring_joint or prev_solved_joint is also connected)
  //     // if (known_neighboring_joint.connectedJoints.find(jt => jt === curr_unknown_joint) !== undefined) {
  //     if (known_neighboring_joint.connectedJoints.findIndex(jt => jt === curr_unknown_joint) !== -1) {
  //       this.desiredConnectedJointIndicesMap.set(curr_unknown_joint.id, [unknown_joint_index, known_neighboring_joint_index]);
  //       // this.desiredConnectedJointIndicesMap.set(curr_unknown_joint.id, [known_neighboring_joint_index, unknown_joint_index]);
  //     } else {
  //       this.desiredConnectedJointIndicesMap.set(curr_unknown_joint.id, [prev_solved_joint_index, unknown_joint_index]);
  //     }
  //     // this.desiredConnectedJointIndicesMap.set(curr_unknown_joint.id, [unknown_joint_index, known_neighboring_joint_index]);
  //     // this.desiredConnectedJointIndicesMap.set(curr_unknown_joint.id, [unknown_joint_index, known_neighboring_joint_index]);
  //     // this.desiredConnectedJointIndicesMap.set(curr_unknown_joint.id, [prev_solved_joint_index, unknown_joint_index]);
  //     // store the solve type from the input solver
  //     this.desiredAnalysisJointMap.set(curr_unknown_joint.id, 'determineTracerJoint');
  //     // add tracer within knownJoints
  //     knownJoint_ids.push(curr_unknown_joint.id);
  //   });
  //   // blah blah blah
  //   curr_unknown_tracer_joints.forEach(known_joint => {
  //     known_joint.connectedJoints.forEach(curr_unknown_joint => {
  //       const unknown_joint_bool = knownJoint_ids.findIndex(joint_id => joint_id === curr_unknown_joint.id) === -1;
  //       if (!unknown_joint_bool) {
  //         return;
  //       }
  //       // if (unknown_joint_bool) {
  //       curr_unknown_joint.connectedJoints.forEach(curr_known_neighboring_joint => {
  //         const curr_known_neighboring_joint_bool = knownJoint_ids.findIndex(joint_id =>
  //             joint_id === curr_known_neighboring_joint.id) !== -1 &&
  //           known_joint.id !== curr_known_neighboring_joint.id;
  //         if (!curr_known_neighboring_joint_bool) {
  //           return;
  //         }
  //         // if (curr_known_neighboring_joint_bool) {
  //         const [arr_1, arr_2] = this.findNeighboringJoints(simJoints, simLinks, known_joint, curr_unknown_joint,
  //           curr_known_neighboring_joint, solveOrderNumber, knownJoint_ids);
  //         solveOrderNumber = arr_1;
  //         knownJoint_ids = arr_2;
  //         // }
  //       });
  //       // }
  //     });
  //   });
  //   return [solveOrderNumber, knownJoint_ids];
  // }


// maybe make a map with key of joint and value of groundJoint
// private static findNeighboringJoints(simJoints: Joint [], simLinks: Link[], jointOrder: string, lastJoint: Joint): string {
//   // initialization stuff should happen within initialization stage
//   if (!this.unknownJointsIndicesMap.has(lastJoint.id)) {
//     // find all unknown joints that have not been discovered
//     const joints = lastJoint.connectedJoints.filter(j =>
//       // joint isn't a ground AND joint does NOT have a neighboring ground joint
//         j.ground !== true && this.desiredJointGroundIndexMap.get(j.id) === -1 &&
//       // joint hasn't already been determined
//       jointOrder.indexOf(j.id) === -1);
//     const neighboring_ground_joint = lastJoint.connectedJoints.find(j =>
//       // joint isn't a ground AND joint does have a neighboring ground joint
//       j.ground !== true && this.desiredJointGroundIndexMap.get(j.id) !== -1 &&
//       jointOrder.indexOf(j.id) === -1);
//     if (neighboring_ground_joint !== undefined) {
//       joints.push(neighboring_ground_joint);
//     }
//     // this joint has to be last for determining positions properly
//     const jointIndices = [];
//     // need this to avoid out of bound exception from empty joint array
//     if (joints.length > 0) {
//       joints.forEach(j => jointIndices.push(simJoints.findIndex(jt => jt.id === j.id)));
//     }
//     this.unknownJointsIndicesMap.set(lastJoint.id, jointIndices);
//   }
//   const unknownJointIndices = this.unknownJointsIndicesMap.get(lastJoint.id);
//   let cloned_unknownJointIndices  = Object.assign([], unknownJointIndices);
//   // no more unknown joints
//   if (cloned_unknownJointIndices.length === 0) {
//   return jointOrder;
//   }
//
//   let joint_with_neighboring_ground;
//   let desired_ground_joint;
//   while (cloned_unknownJointIndices.length !== 0) {
//     // desired unknown joint
//     const unknown_joint_index = cloned_unknownJointIndices.pop();
//     const unknown_joint = simJoints[unknown_joint_index];
//     // get the unknown joint with a neighboring ground joint
//     if (joint_with_neighboring_ground === undefined) {
//       const connectedGroundIndex = this.desiredJointGroundIndexMap.get(unknown_joint.id);
//       // joint_with_neighboring_ground = simJoints[connectedGroundIndex];
//       desired_ground_joint = simJoints[connectedGroundIndex];
//       joint_with_neighboring_ground = unknown_joint;
//       // determine unknown joint utilizing two joints that are known
//       if (!this.determineUnknownJoint(lastJoint, desired_ground_joint, joint_with_neighboring_ground)) {
//         // joint's next position is invalid!
//         return jointOrder;
//       }
//     } else {
//       // determine position for tracer joint
//       this.determineTracerJoint(lastJoint, joint_with_neighboring_ground, unknown_joint);
//     }
//
//     // unknown has now been determined
//     jointOrder += unknown_joint.id;
//
//   }
//   cloned_unknownJointIndices  = Object.assign([], unknownJointIndices);
//   while (cloned_unknownJointIndices.length !== 0) {
//     // desired unknown joint
//     const unknown_joint_index = cloned_unknownJointIndices.pop();
//     const unknown_joint = simJoints[unknown_joint_index];
//     // determine if there are more unknown joints connected to the previous unknown joint
//     jointOrder = this.findNeighboringJoints(simJoints, simLinks, jointOrder, unknown_joint);
//   }
//
//   return jointOrder;
//   }

  // private static determineUnknownJoint(knownJoint1: Joint, knownJoint2: Joint, unknownJoint: Joint) {
  //   switch (unknownJoint.jointType) {
  //     case 'R' : {
  //       return this.twoCircleIntersectionPoints(knownJoint1, knownJoint2, unknownJoint);
  //     }
  //     case 'P' : {
  //       return this.circleLineIntersectionPoints(knownJoint1, knownJoint2, unknownJoint);
  //     }
  //   }
  // }

  private static incrementRevInput(inputJoint: Joint, unknownJoint: Joint, angVelDir: boolean) {
    const r = Math.sqrt(Math.pow(unknownJoint.xInitial - inputJoint.xInitial, 2) +
      Math.pow(unknownJoint.yInitial - inputJoint.yInitial, 2));
    const increment = angVelDir === true ? Math.PI / 180.0 : -Math.PI / 180.0;
    const angle = Math.atan2(unknownJoint.y - inputJoint.y, unknownJoint.x - inputJoint.x);
    const x = Math.cos(angle + increment) * r + inputJoint.x;
    const y = Math.sin(angle + increment) * r + inputJoint.y;
    this.jointMapPositions.set(inputJoint.id, [this.roundToHundredThousandth(inputJoint.x), this.roundToHundredThousandth(inputJoint.y)]);
    this.jointMapPositions.set(unknownJoint.id, [this.roundToHundredThousandth(x), this.roundToHundredThousandth(y)]);
  }

  private static incrementPrisInput(inputJoint: Joint, unknownJoint: Joint, angVelDir: boolean) {
    const increment = angVelDir === true ? 0.1 : -0.1; // 0.01 : -0.01;
    const xIncrement = increment * Math.cos(unknownJoint.angle);
    const yIncrement = increment * Math.sin(unknownJoint.angle);
    const x = unknownJoint.x + xIncrement;
    const y = unknownJoint.y + yIncrement;
    this.jointMapPositions.set(unknownJoint.id, [this.roundToHundredThousandth(x), this.roundToHundredThousandth(y)]);
  }

// https://www.petercollingridge.co.uk/tutorials/computational-geometry/circle-circle-intersections/
  private static twoCircleIntersectionPoints(j1: Joint, j2: Joint, unknownJoint: Joint) {
    let desiredIndex = this.desiredIndexWithinPosAnalysisMap.get(unknownJoint.id);
    if (desiredIndex === undefined || desiredIndex === -1) {
      desiredIndex = this.determineDesiredIndexTwoCircleIntersection(j1, j2, unknownJoint);
      this.desiredIndexWithinPosAnalysisMap.set(unknownJoint.id, desiredIndex);
    }
    const sols = this.TwoCircleIntersectionMethod(j1, j2, unknownJoint);
    if (!sols) {
      return false;
    }
    const x = sols[desiredIndex][0];
    const y = sols[desiredIndex][1];
    this.jointMapPositions.set(unknownJoint.id, [this.roundToHundredThousandth(x), this.roundToHundredThousandth(y)]);
    return true;
  }

  private static determineDesiredIndexTwoCircleIntersection(tempJ1: Joint, tempJ2: Joint,
                                                            tempUnknownJoint: Joint) {
    const sols = this.TwoCircleIntersectionMethod(tempJ1, tempJ2, tempUnknownJoint);
    if (sols === false) {
      return -1;
    }
    if (sols.length === 1) {
      return 0; // index is 0;
    }
    const intersection1Diff = (Math.abs(Math.sqrt(Math.pow(sols[0][0] - tempUnknownJoint.x, 2)
      + Math.pow(sols[0][1] - tempUnknownJoint.y, 2))));
    const intersection2Diff = (Math.abs(Math.sqrt(Math.pow(sols[1][0] - tempUnknownJoint.x, 2)
      + Math.pow(sols[1][1] - tempUnknownJoint.y, 2))));
    return intersection1Diff < intersection2Diff ?  0 : 1;
  }

  private static TwoCircleIntersectionMethod(j1: Joint, j2: Joint, unknownJoint: Joint) {
    if (!this.jointMapPositions.has(j1.id)) {
      this.jointMapPositions.set(j1.id, [j1.x, j1.y]);
    }
    if (!this.jointMapPositions.has(j2.id)) {
      this.jointMapPositions.set(j2.id, [j2.x, j2.y]);
    }
    const x0 = this.jointMapPositions.get(j1.id)[0];
    const y0 = this.jointMapPositions.get(j1.id)[1];
    const x1 = this.jointMapPositions.get(j2.id)[0];
    const y1 = this.jointMapPositions.get(j2.id)[1];
    const r0 = Math.sqrt(Math.pow(unknownJoint.xInitial - j1.xInitial, 2) +
      Math.pow(unknownJoint.yInitial - j1.yInitial, 2));
    const r1 = Math.sqrt(Math.pow(unknownJoint.xInitial - j2.xInitial, 2) + Math.pow(unknownJoint.yInitial - j2.yInitial, 2));
    let dx = x1 - x0;
    let dy = y1 - y0;
    const d = Math.sqrt(dx * dx + dy * dy);
    // Circles too far apart
    if (d > r0 + r1) {
      return false;
    }

// One circle completely inside the other
    if (d < Math.abs(r0 - r1)) {
      return false;
    }

    // const TOLERANCE = 0.001;
    if (d <= 0.001) {
      return false;
    }
    // if (d === 0) {
    //   return false;
    // }

    dx /= d;
    dy /= d;

    const a = (r0 * r0 - r1 * r1 + d * d) / (2 * d);
    const px = x0 + a * dx;
    const py = y0 + a * dy;

    const h = Math.sqrt(r0 * r0 - a * a);

    const p1x = px + h * dy;
    const p1y = py - h * dx;
    const p2x = px - h * dy;
    const p2y = py + h * dx;
    return [[p1x, p1y], [p2x, p2y]];
  }

// https://cscheng.info/2016/06/09/calculate-circle-line-intersection-with-javascript-and-p5js.html
  private static circleLineIntersectionPoints(j1: Joint, j2: Joint, unknownJoint: Joint) {
    if (!this.desiredIndexWithinPosAnalysisMap.has(unknownJoint.id)) {
      this.desiredIndexWithinPosAnalysisMap.set(unknownJoint.id, this.determineDesiredIndexCircleLineIntersection(j1, j2, unknownJoint));
    }
    const desiredIndex = this.desiredIndexWithinPosAnalysisMap.get(unknownJoint.id);
    const [a, b, c, d] = this.circleLineIntersectionMethod(j1, j2, unknownJoint);
    let x, y, r, curr_known_x, curr_unknown_y, curr_unknown_x, a_temp, b_temp, c_temp, curr_known_y, d_temp, y_1, y_2: number;
    if (isNaN(d) || !isFinite(d)) {
      // if (d === NaN || d === Infinity || d === -Infinity) {
      // switch (d) {
      //   case NaN:
      r = Math.sqrt(Math.pow(unknownJoint.xInitial - j1.xInitial, 2)
        + Math.pow(unknownJoint.yInitial - j1.yInitial, 2));
      curr_known_x = this.jointMapPositions.get(j1.id)[0];
      // const curr_unknown_x = this.jointMapPositions.get(unknownJoint.id)[0];
      curr_unknown_x = unknownJoint.x;
      curr_known_y = this.jointMapPositions.get(j1.id)[1];
      curr_unknown_y = unknownJoint.y;
      // const curr_unknown_y = this.jointMapPositions.get(unknownJoint.id)[1];
      a_temp = 1;
      b_temp = -2 * curr_known_y;
      c_temp = Math.pow(curr_known_y, 2) + Math.pow(curr_known_x - curr_unknown_x, 2) - Math.pow(r, 2);
      d_temp = Math.pow(b_temp, 2) - (4 * a_temp * c_temp);
      // utilize quadratic formula to solve for both y values
      if (d_temp < 0) {
        return false;
      }
      y_1 = (-b_temp + Math.sqrt(Math.pow(b_temp, 2) - (4 * a_temp * c_temp))) / (2 * a_temp);
      y_2 = (-b_temp - Math.sqrt(Math.pow(b_temp, 2) - (4 * a_temp * c_temp))) / (2 * a_temp);

      // y_1 = Math.abs((-b_temp + Math.sqrt(Math.pow(b_temp, 2) - (4 * a_temp * c_temp))) / (2 * a_temp));
      // y_2 = Math.abs((-b_temp - Math.sqrt(Math.pow(b_temp, 2) - (4 * a_temp * c_temp))) / (2 * a_temp));
      // determine which y is closer and use this y value
      if (Math.abs(curr_unknown_y - y_1) <= Math.abs(curr_unknown_y - y_2)) {
        y = y_1;
      } else {
        y = y_2;
      }
      x = curr_unknown_x;
    } else {
      if (d >= 0) {
        const sign = desiredIndex === 0 ? 1 : -1;
        x = (-b + sign * Math.sqrt(Math.pow(b, 2) - 4 * a * c)) / (2 * a);
        const vertical_line = 90 * Math.PI / 180;
        const horizontal_line = 180 * Math.PI / 180;
        const m = Math.tan(unknownJoint.angle);
        const b_intersect = unknownJoint.yInitial - m * unknownJoint.xInitial;
        y = m * x + b_intersect;
      } else {
        return false;
      }
    }
    // const y = Math.tan(unknownJoint.angle) * unknownJoint.x + unknownJoint.y;
    this.jointMapPositions.set(unknownJoint.id, [this.roundToHundredThousandth(x), this.roundToHundredThousandth(y)]);
    return true;
  }

  private static determineDesiredIndexCircleLineIntersection(j1: Joint, j2: Joint, unknownJoint: Joint) {
    const [a, b, c, _] = this.circleLineIntersectionMethod(j1, j2, unknownJoint);
    // const x_1 = Math.abs((-b + Math.sqrt(Math.pow(b, 2) - (4 * a * c))) / (2 * a));
    // const x_2 = Math.abs((-b - Math.sqrt(Math.pow(b, 2) - (4 * a * c))) / (2 * a));
    const x_1 = ((-b + Math.sqrt(Math.pow(b, 2) - (4 * a * c))) / (2 * a));
    const x_2 = ((-b - Math.sqrt(Math.pow(b, 2) - (4 * a * c))) / (2 * a));
    const m = Math.sin(unknownJoint.angle) / Math.cos(unknownJoint.angle);
    const b_intersect = unknownJoint.yInitial - m * unknownJoint.xInitial;
    const y_1 = m * x_1 + b_intersect;
    const y_2 = m * x_2 + b_intersect;
    const intersection1Diff = Math.sqrt(Math.pow(x_1 - unknownJoint.xInitial, 2) +
      Math.pow(y_1 - unknownJoint.yInitial, 2));
    const intersection2Diff = Math.sqrt(Math.pow(x_2 - unknownJoint.xInitial, 2) +
      Math.pow(y_2 - unknownJoint.yInitial, 2));
    return intersection1Diff < intersection2Diff ? 0 : 1;
  }

  private static circleLineIntersectionMethod(j1: Joint, j2: Joint, unknownJoint: Joint) {
    // circle: (x - h)^2 + (y - k)^2 = r^2
    // line: y = m * x + n
    // r: circle radius
    // const unknown_joint_x = this.jointMapPositions.get(unknownJoint.id)[0];
    // const unknown_joint_y = this.jointMapPositions.get(unknownJoint.id)[1];
    // const j1_x = this.jointMapPositions.get(j1.id)[0];
    // const j1_y = this.jointMapPositions.get(j1.id)[1];
    // const r = Math.sqrt(Math.pow(unknown_joint_x - j1_x, 2) + Math.pow(unknown_joint_y - j1_y, 2));
    const r = Math.sqrt(Math.pow(unknownJoint.xInitial - j1.xInitial, 2) + Math.pow(unknownJoint.yInitial - j1.yInitial, 2));
    // h: x value of circle centre
    // const h = j1.x;
    const h = this.jointMapPositions.get(j1.id)[0];
    // k: y value of circle centre
    // const k = j1.y;
    const k = this.jointMapPositions.get(j1.id)[1];
    // m: slope
    const radToDeg = 180 / Math.PI;
    let m = Math.tan(unknownJoint.angle);
    if (m > 1000 || m < -1000) {
      m = Number.MAX_VALUE;
    }
    // const m = (Math.round(unknownJoint.angle * radToDeg) % 90  === 0 && Math.round(unknownJoint.angle * radToDeg) % 180 !== 0) ?
    //   99999999999 : Math.tan(unknownJoint.angle);
    // const m = Math.tan(unknownJoint.angle);
    // n: y-intercept
    // const n = unknown_joint_y - m * unknown_joint_x;
    const n = unknownJoint.yInitial - m * unknownJoint.xInitial;
    // const n = unknownJoint.y;
    // const n = j2.y;
    // get a, b, c values
    const a = 1 + Math.pow(m, 2);
    const b = -h * 2 + (m * (n - k)) * 2;
    const c = Math.pow(h, 2) + Math.pow(n - k, 2) - Math.pow(r, 2);

    // get discriminant
    const d = Math.pow(b, 2) - (4 * a * c);
    return [a, b, c, d];
  }

  private static roundToHundredThousandth(num: number) {
    return Math.round(num * 10000) / 10000;
  }

// https://www.mathsisfun.com/algebra/trig-solving-sss-triangles.html
  private static determineTracerJoint(lastJoint: Joint, joint_with_neighboring_ground: Joint, unknown_joint: Joint) {
    let r1, r2, r3, internal_angle: number;
    if (!this.internalTriangleValuesMap.has(lastJoint.id + joint_with_neighboring_ground.id + unknown_joint.id)) {
      const a_x = lastJoint.xInitial;
      const a_y = lastJoint.yInitial;
      const b_x = joint_with_neighboring_ground.xInitial;
      const b_y = joint_with_neighboring_ground.yInitial;
      const c_x = unknown_joint.xInitial;
      const c_y = unknown_joint.yInitial;
      r1 = Math.sqrt(Math.pow(c_x - a_x, 2) + Math.pow(c_y - a_y, 2));
      r2 = Math.sqrt(Math.pow(c_x - b_x, 2) + Math.pow(c_y - b_y, 2));
      r3 = Math.sqrt(Math.pow(a_x - b_x, 2) + Math.pow(a_y - b_y, 2));
      internal_angle = Math.acos((Math.pow(r1, 2) + Math.pow(r3, 2) - Math.pow(r2, 2)) / (2 * r1 * r3));
      this.internalTriangleValuesMap.set(lastJoint.id + joint_with_neighboring_ground.id + unknown_joint.id,
        [r1, internal_angle]);
    }

    r1 = this.internalTriangleValuesMap.get(lastJoint.id + joint_with_neighboring_ground.id + unknown_joint.id)[0];
    internal_angle = this.internalTriangleValuesMap.get(lastJoint.id + joint_with_neighboring_ground.id + unknown_joint.id)[1];
    const x1 = this.jointMapPositions.get(lastJoint.id)[0];
    const y1 = this.jointMapPositions.get(lastJoint.id)[1];
    const x2 = this.jointMapPositions.get(joint_with_neighboring_ground.id)[0];
    const y2 = this.jointMapPositions.get(joint_with_neighboring_ground.id)[1];
    const angle = Math.atan2(y2 - y1, x2 - x1);
    let x_calc: number;
    let y_calc: number;
    let x_calc1: number;
    let y_calc1: number;
    let x_calc2: number;
    let y_calc2: number;
    if (x1 > x2) { // A to the right of B
      if (y1 > y2) { // A on top of B (good)
        x_calc1 = x1 + r1 * Math.cos( (Math.PI) + (internal_angle + (Math.PI + angle)));
        y_calc1 = y1 + r1 * Math.sin( (Math.PI) + (internal_angle + (Math.PI + angle)));
        x_calc2 = x1 + r1 * Math.cos((Math.PI) - (internal_angle - (Math.PI + angle)));
        y_calc2 = y1 + r1 * Math.sin((Math.PI) - (internal_angle - (Math.PI + angle)));
      } else { // A below B (good)
        x_calc1 = x1 + r1 * Math.cos((Math.PI) + (internal_angle - (Math.PI - angle)));
        y_calc1 = y1 + r1 * Math.sin((Math.PI) + (internal_angle - (Math.PI - angle)));
        x_calc2 = x1 + r1 * Math.cos(Math.PI - (internal_angle + (Math.PI - angle)));
        y_calc2 = y1 + r1 * Math.sin(Math.PI - (internal_angle + (Math.PI - angle)));
      }
    } else { // A to the left of B
      if (y1 > y2) { // A on top of B (good)
        x_calc1 = x1 + r1 * Math.cos((2 * Math.PI) - (Math.abs(angle) + internal_angle));
        y_calc1 = y1 + r1 * Math.sin((2 * Math.PI) - (Math.abs(angle) + internal_angle));
        x_calc2 = x1 + r1 * Math.cos(internal_angle - Math.abs(angle));
        y_calc2 = y1 + r1 * Math.sin(internal_angle - Math.abs(angle));
      } else { // A below B (good)
        x_calc1 = x1 + r1 * Math.cos((2 * Math.PI) - (angle - internal_angle));
        y_calc1 = y1 + r1 * Math.sin(angle - internal_angle);
        x_calc2 = x1 + r1 * Math.cos(internal_angle + angle);
        y_calc2 = y1 + r1 * Math.sin(internal_angle + angle);
      }
    }
    const prevJoint_x = unknown_joint.x;
    const prevJoint_y = unknown_joint.y;
    const dist1 = Math.abs(Math.pow(x_calc1 - prevJoint_x, 2) + Math.pow(y_calc1 - prevJoint_y, 2));
    const dist2 = Math.abs(Math.pow(x_calc2 - prevJoint_x, 2) + Math.pow(y_calc2 - prevJoint_y, 2));
    if (dist1 < dist2) {
      x_calc = x_calc1;
      y_calc = y_calc1;
    } else {
      x_calc = x_calc2;
      y_calc = y_calc2;
    }
    this.jointMapPositions.set(unknown_joint.id, [this.roundToHundredThousandth(x_calc), this.roundToHundredThousandth(y_calc)]);
  }
}
