import {Link} from './Link';

export class Joint {
  private _id: string; // Unique id of the joint (must be same as the one used on the app side)
  private _xInitial: number;
  private _yInitial: number;
  private _x: number; // X Location of the joint (before simulation)
  private _y: number; // Y Location of the joint (before simulation)
  private _vx: number; // X Velocity of a joint at the given timestamp
  private _vy: number; // Y Velocity of a joint at the given timestamp
  private _ax: number; // X Acceleration of a joint at the given timestamp
  private _ay: number; // Y Acceleration of a joint at the given timestamp
  private _jointType: string;
  private _connectedJoints: Array<Joint> = new Array<Joint>(); // Array of connected joints
  private _ground: boolean;
  private _input: boolean;
  private _connectedLinks: Array<Link> = new Array<Link>();
  private _angle: number;
  // private _desiredIndex: number;
  constructor(id: string, input: boolean, isGround: boolean, jointType: string,
                            currentJointPosition: number[] = null, angle?: number) {
    this._id = id;
    this._input = input;
    this._ground = isGround;
    this._jointType = jointType;
    this._x = currentJointPosition[0];
    this._y = currentJointPosition[1];
    this._xInitial = currentJointPosition[0];
    this._yInitial = currentJointPosition[1];
    const degToRad = Math.PI / 180;
    this._angle = angle * degToRad;
  }

  get id(): string {
    return this._id;
  }

  set id(value: string) {
    this._id = value;
  }

  get xInitial(): number {
    return this._xInitial;
  }

  set xInitial(value: number) {
    this._xInitial = value;
  }

  get yInitial(): number {
    return this._yInitial;
  }

  set yInitial(value: number) {
    this._yInitial = value;
  }

  get x(): number {
    return this._x;
  }

  set x(value: number) {
    this._x = value;
  }

  get y(): number {
    return this._y;
  }

  set y(value: number) {
    this._y = value;
  }

  get vx(): number {
    return this._vx;
  }

  set vx(value: number) {
    this._vx = value;
  }

  get vy(): number {
    return this._vy;
  }

  set vy(value: number) {
    this._vy = value;
  }

  get ax(): number {
    return this._ax;
  }

  set ax(value: number) {
    this._ax = value;
  }

  get ay(): number {
    return this._ay;
  }

  set ay(value: number) {
    this._ay = value;
  }

  get jointType(): string {
    return this._jointType;
  }

  set jointType(value: string) {
    this._jointType = value;
  }

  get connectedJoints(): Joint[] {
    return this._connectedJoints;
  }

  set connectedJoints(value: Joint[]) {
    this._connectedJoints = value;
  }

  get ground(): boolean {
    return this._ground;
  }

  set ground(value: boolean) {
    this._ground = value;
  }

  get input(): boolean {
    return this._input;
  }

  set input(value: boolean) {
    this._input = value;
  }

  get connectedLinks(): Array<Link> {
    return this._connectedLinks;
  }

  set connectedLinks(value: Array<Link>) {
    this._connectedLinks = value;
  }

  get angle(): number {
    return this._angle;
  }

  set angle(value: number) {
    const degToRad = Math.PI / 180;
    this._angle = value * degToRad;
  }

  // get desiredIndex(): number {
  //   return this._desiredIndex;
  // }
  //
  // set desiredIndex(value: number) {
  //   this._desiredIndex = value;
  // }
}

export class ImagJoint extends Joint {
  constructor(id: string, input: boolean, isGround: boolean, jointType: string, curJointPos: number[], connJoint: RealJoint) {
    super(id, input, isGround, jointType, curJointPos);
    this.connectedJoints.push(connJoint);
  }
}

export class RealJoint extends Joint {
  private _internalStaticXForce: number;
  private _internalStaticYForce: number;
  private _internalDynamicXForce: number;
  private _internalDynamicYForce: number;
  private _staticTorque: number;
  private _dynamicTorque: number;
  private _coefficientOfFriction: number;
  constructor(id: string, input: boolean, isGround: boolean, jointType: string, currentJointPosition: number[] = null, angle?: number) {
    super(id, input, isGround, jointType, currentJointPosition, angle);
  }

  get internalStaticXForce(): number {
    return this._internalStaticXForce;
  }

  set internalStaticXForce(value: number) {
    this._internalStaticXForce = value;
  }

  get internalStaticYForce(): number {
    return this._internalStaticYForce;
  }

  set internalStaticYForce(value: number) {
    this._internalStaticYForce = value;
  }

  get internalDynamicXForce(): number {
    return this._internalDynamicXForce;
  }

  set internalDynamicXForce(value: number) {
    this._internalDynamicXForce = value;
  }

  get internalDynamicYForce(): number {
    return this._internalDynamicYForce;
  }

  set internalDynamicYForce(value: number) {
    this._internalDynamicYForce = value;
  }

  get staticTorque(): number {
    return this._staticTorque;
  }

  set staticTorque(value: number) {
    this._staticTorque = value;
  }

  get dynamicTorque(): number {
    return this._dynamicTorque;
  }

  set dynamicTorque(value: number) {
    this._dynamicTorque = value;
  }

  get coefficientOfFriction(): number {
    return this._coefficientOfFriction;
  }

  set coefficientOfFriction(value: number) {
    this._coefficientOfFriction = value;
  }
}



// Joint as defined in the simulator class

// export class Joint {
//   private readonly _id: string; // Unique id of the joint (must be same as the one used on the app side)
//   private _xInitial: number;
//   private _yInitial: number;
//   private _x: number; // X Location of the joint (before simulation)
//   private _y: number; // Y Location of the joint (before simulation)
//   private _vx: number; // X Velocity of a joint at the given timestamp
//   private _vy: number; // Y Velocity of a joint at the given timestamp
//   private _ax: number; // X Acceleration of a joint at the given timestamp
//   private _ay: number; // Y Acceleration of a joint at the given timestamp
//   path: string;
//   private _jointType: string;
//   private _connectedJoints: Joint[]; // List of connected joints
//   desiredIndex: number;
//
//   // todo: used and needed for angValAcc solver, loop class, and static's Solver. Initialize on creation them properly ------------------
//   // indicates whether the joint is ground joint
//   protected _isGround: boolean;
//   // indicates whether the joint is imaginary joint and not user created joint -- should not be ground joint as well sliding crank code
//   private readonly _isImaginary: boolean;
//   // indicates of this joint is an input joint
//   protected _isInput = false;
//   // list of all connected links to this joint / do not include ground links or imaginary links in this list.
//   // (Use push or splice along with getter to updated this field)
//   // private readonly _connectedLinks: Array<Link> = new Array<Link>();
//   _connectedLinks: Array<Link> = new Array<Link>();
//   can be one link to all other ground joints or separate individual links. (Use push or splice along with getter to updated this field)
//   private readonly _groundLinksForThisJoint: Array<Link> = new Array<Link>();
//   // joint force along x axis and y axis
//   private _internalStaticXForce: number;
//   private _internalStaticYForce: number;
//   private _internalDynamicXForce: number;
//   private _internalDynamicYForce: number;
//   private _staticTorque: number;
//   private _dynamicTorque: number;
//   private _slideAngle: number;
//   private _coefficientOfFriction: number;
//   slope: number;
//   intercept: number;
//   private _justATracer: boolean;
//
//
//   // Main constructor
//   constructor(id: string, isGround: boolean, jointType: string,
//               currentJointPosition: number[] = null, angle?: number) { // , isImaginary: boolean) {
//     this._id = id;
//     this._isGround = isGround;
//     this._isImaginary = false; // this should be updated when implementing slider crank code with commented out code below
//     this._jointType = jointType;
//
//     if (currentJointPosition == null) {
//       return;
//     }
//     if (currentJointPosition.length < 2) {
//       throw new Error('Values for x and y must be provided for joint.');
//     }
//     this._x = currentJointPosition[0];
//     this._y = currentJointPosition[1];
//
//     // this.vx = 0;
//     // this.vy = 0;
//     // this.ax = 0;
//     // this.ay = 0;
//     this.slideAngle = angle * ((2 * Math.PI) / 360);
//
//     if (jointType === 'P' && this._slideAngle !== null) {
//       const slideAngleInDegrees = ((this._slideAngle * (180 / Math.PI)) % 180);
//       if (slideAngleInDegrees !== 90) {
//         this.slope = Math.tan(this._slideAngle);
//         this.intercept = (this._y) - (this._x * this.slope);
//       } else {
//         this.slope = undefined;
//         this.intercept = null;
//       }
//     }
//     this.path = '';
//   } // end of constructor
//
// // getters -------------------------------------------------------------------------------------------------------------------------
//   get isGrounded(): Boolean {
//     return this._isGround;
//   }
//
//   get isImaginary(): boolean {
//     return this._isImaginary;
//   }
//
//   get isInput(): boolean {
//     return this._isInput;
//   }
//
//   get connectedLinks(): Array<Link> {
//     return this._connectedLinks;
//   }
//
//   get groundLinksForThisJoint(): Array<Link> {
//     return this._groundLinksForThisJoint;
//   }
//   get internalStaticXForce(): number {
//     return this._internalStaticXForce;
//   }
//
//   get internalStaticYForce(): number {
//     return this._internalStaticYForce;
//   }
//
//   get internalDynamicXForce(): number {
//     return this._internalDynamicXForce;
//   }
//
//   get internalDynamicYForce(): number {
//     return this._internalDynamicYForce;
//   }
//   get staticTorque(): number {
//     return this._staticTorque;
//   }
//   get dynamicTorque(): number {
//     return this._dynamicTorque;
//   }
//
//   get slideAngle(): number {
//     return this._slideAngle;
//   }
//
//   get coefficientOfFriction(): number {
//     return this._coefficientOfFriction;
//   }
//
//   get justATracer(): boolean {
//     return this._justATracer;
//   }
//
//   get xInitial(): number {
//     return this._xInitial;
//   }
//
//   get yInitial(): number {
//     return this._yInitial;
//   }
//
// // setters ---------------------------------------------------------------------------------------
//   // todo: setter should be removed added moved into the constructor similar to the way of isGround variable
//   set isInput(input: boolean) {
//     this._isInput = input;
//   }
//
//   set internalStaticXForce(value: number) {
//     this._internalStaticXForce = value;
//   }
//
//   set internalStaticYForce(value: number) {
//     this._internalStaticYForce = value;
//   }
//
//   set internalDynamicXForce(value: number) {
//     this._internalDynamicXForce = value;
//   }
//
//   set internalDynamicYForce(value: number) {
//     this._internalDynamicYForce = value;
//   }
//
//   set staticTorque(value: number) {
//     this._staticTorque = value;
//   }
//
//   set dynamicTorque(value: number) {
//     this._dynamicTorque = value;
//   }
//
//   set slideAngle(slideAng: number) {
//     this._slideAngle = slideAng;
//   }
//
//   set coefficientOfFriction(coeffFrict: number) {
//     this._coefficientOfFriction = coeffFrict;
//   }
//
//   set justATracer(tracer: boolean) {
//     this._justATracer = tracer;
//   }
//
//   set xInitial(xInt: number) {
//     this._xInitial = xInt;
//   }
//
//   set yInitial(yInt: number) {
//     this._yInitial = yInt;
//   }
//
// // -----------------------------------------------------------------------------------------------------------------------
//   get id(): string {
//     return this._id;
//   }
//
//   get jointType(): string {
//     return this._jointType;
//   }
//
//   get x(): number {
//     return this._x;
//   }
//
//   set x(value: number) {
//     this._x = value;
//   }
//
//   get y(): number {
//     return this._y;
//   }
//
//   set y(value: number) {
//     this._y = value;
//   }
//   get ax(): number {
//     return this._ax;
//   }
//
//   set ax(value: number) {
//     this._ax = value;
//   }
//
//   get ay(): number {
//     return this._ay;
//   }
//
//   set ay(value: number) {
//     this._ay = value;
//   }
//
//   get vx(): number {
//     return this._vx;
//   }
//
//   set vx(value: number) {
//     this._vx = value;
//   }
//
//   get vy(): number {
//     return this._vy;
//   }
//
//   set vy(value: number) {
//     this._vy = value;
//   }
//
//   get connectedJoints(): Joint[] {
//     return this._connectedJoints;
//   }
//
//   set connectedJoints(value: Joint[]) {
//     this._connectedJoints = value;
//   }
//
//   setConnectedJoints(links: Link[]) {
//     let connected = [];
//     // loop through all links and find ones containing given joint
//     for (let j = 0; j < links.length; j++) {
//       const linkJoints = links[j].joints;
//       // Check link to see if given joint is in it
//       for (let k = 0; k < linkJoints.length; k++) {
//         if (linkJoints[k] === this) { // Look to see if the joint is in the link
//           connected = connected.concat(linkJoints); // If it is in the link, add all joints to the list of connected joints
//           break;
//         }
//       }
//     }
//
//     connected = this.removeDuplicates(connected); // Remove duplicate joints from the array
//     connected = this.removeIfInJointArray(this, connected); // Remove joint's self from array
//     this.connectedJoints = connected;
//   }
//
//   // Traverse a joint array and remove duplicate object references.
//   removeDuplicates(jointArray: Joint[]) {
//     const newArray: Joint[] = [];
//     for (let i = 0; i < jointArray.length; i++) {
//       const nextJoint = jointArray[i];
//       if (!this.isInJointArray(nextJoint, newArray)) {
//         newArray.push(nextJoint);
//       } else {
//       }
//     }
//     return newArray;
//   }
//
//   // Looks for a joint in the given array. If found, returns true, otherwise, returns false.
//   isInJointArray(joint: Joint, jointArray: Joint[]) {
//     for (let i = 0; i < jointArray.length; i++) {
//       if (joint === jointArray[i]) {
//         return true;
//       }
//     }
//     return false;
//   }
//
//   // Looks for a joint in the given array. If found, returns the index to the joint, otherwise, returns -1.
//   removeIfInJointArray(joint: Joint, jointArray) {
//     for (let i = 0; i < jointArray.length; i++) {
//       if (joint === jointArray[i]) {
//         jointArray.splice(i, 1);
//         return jointArray;
//       }
//     }
//     return jointArray;
//   }
// }
