import {Joint} from './Joints_Links_Forces/Joint';
import {RealJoint} from './Joints_Links_Forces/Joint';
import {ImagJoint} from './Joints_Links_Forces/Joint';
import {ImagLink, Link, RealLink} from './Joints_Links_Forces/Link';
import {Force} from './Joints_Links_Forces/Force';
import {TimeSortedList, TSLJoint, TSLRow} from './TimeSortedList';
import {SVGFuncs} from 'src/functions/SVGFuncs';
import {Coord} from 'src/classes/Coord';
// import {LinkMassAndMassMoment} from './Joints_Links_Forces/LinkMassAndMassMoment';
// import {LinkageSystemConfig} from './Position Solving/LinkageSystemConfig';
// import {ParallelFourBarPositionSolver} from './Position Solving/ParallelFourBarPositionSolver';
import {IndependentFuncs} from '../functions/IndependentFuncs';
// import {PositionSolver} from './Position Solving/PositionSolver';
// import {ExportAnalysis} from './ExportAnalysis';
import {cross, lusolve} from 'mathjs';
import {InstantCenter, PrimaryInstantCenter} from './Joints_Links_Forces/InstantCenter';
import {LoopSolver} from './Analysis/LoopSolver';
import {PositionSolver} from './Analysis/PositionSolver';
import {ICSolver} from './Analysis/ICSolver';
import {KinematicsSolver} from './Analysis/KinematicsSolver';
import {ForceSolver} from './Analysis/ForceSolver';
import {forEach} from '@angular/router/src/utils/collection';

export class Simulator {
  get dof(): number {
    return this._dof;
  }

  set dof(value: number) {
    this._dof = value;
  }

  constructor(simJoints: Joint[], simLinks: Link[], simForces: Force[], unit: string, gravity: boolean) {
    this.SimulationJoints = simJoints;
    this.SimulationLinks = simLinks;
    this.SimulationForces = simForces;
    this._unit = unit;
    this.gravity = gravity;
    this._dof = this.determineDegreesOfFreedom();
    // this.exporter = new ExportAnalysis(simJoints, simLinks, simForces);
    // this.SimulationInstantCenters = this.findRealICs(simJoints, simLinks, this.requiredLoops);
    // this.SimulationInstantCenters = this.determineICs();
    // [this._allLoops, this._requiredLoops] = LoopSolver.determineLoops(this.SimulationJoints, this.SimulationLinks);
    // this.SimulationInstantCenters = this.determineICs();
  }

  get requiredLoops(): string[] {
    return this._requiredLoops;
  }

  set requiredLoops(value: string[]) {
    this._requiredLoops = value;
  }

  get allLoops(): string[] {
    return this._allLoops;
  }

  set allLoops(value: string[]) {
    this._allLoops = value;
  }

  get unit(): string {
    return this._unit;
  }

  set unit (newUnit: string) {
    this._unit = newUnit;
  }

  get getGravity(): boolean {
    return this.gravity;
  }

  set setGravity (gravity: boolean) {
    this.gravity = gravity;
  }
  // private comAnalysisDeterminationMap = new Map<string, string>();
  private internalLineSimLinkMap = new Map<string, number>();
  private internalTriangleSimLinkMap = new Map<string, number[]>();

  SimulationJoints: Joint[]; // Joint array. Input ground will always be first joint listed
  SimulationLinks: Link[]; // Linkage array. Input linkage will always be first linkage listed
  SimulationForces: Force[];
  SimulationInstantCenters: InstantCenter[];
  _dof: number;
  private _unit: string;
  private gravity: boolean;
  private _requiredLoops: string[];
  private _allLoops: string[];
  private realJoints: number;
  private realLinks: number;
  numJointsAndLinks: Array<number>;

  // //   /**
  // //    * Layout of printing forceAnalysis
  // //    * forceAnalysis[colIndex][0] = time
  // //    * forceAnalysis[colIndex][1] = T
  // //    * forceAnalysis[colIndex][2*jointIndex + 2] = Joint1x
  // //    * forceAnalysis[colIndex][2*jointIndex + 3] = Joint1y
  // //    * ...
  // //    */

  // //   /**
  // //    * Layout of printing kinematics
  // //    * kinematicAnalysis[0][0] = time
  // //    * kinematicAnalysis[0][6 * jointIndex + 6 * linkLinIndex + 3 * linkAngIndex + 1] = Joint x
  // //    * kinematicAnalysis[0][6 * jointIndex + 6 * linkLinIndex + 3 * linkAngIndex + 2] = Joint y
  // //    * kinematicAnalysis[0][6 * jointIndex + 6 * linkLinIndex + 3 * linkAngIndex + 3] = Joint vx
  // //    * kinematicAnalysis[0][6 * jointIndex + 6 * linkLinIndex + 3 * linkAngIndex + 4] = Joint vy
  // //    * kinematicAnalysis[0][6 * jointIndex + 6 * linkLinIndex + 3 * linkAngIndex + 5] = Joint ax
  // //    * kinematicAnalysis[0][6 * jointIndex + 6 * linkLinIndex + 3 * linkAngIndex + 6] = Joint ay
  // //    * ...
  // //    * kinematicAnalysis[0][6 * jointIndex + 6 * linkLinIndex + 3 * linkAngIndex + 1] = Link angle
  // //    * kinematicAnalysis[0][6 * jointIndex + 6 * linkLinIndex + 3 * linkAngIndex + 2] = Link w
  // //    * kinematicAnalysis[0][6 * jointIndex + 6 * linkLinIndex + 3 * linkAngIndex + 3] = Link alpha
  // //    * ...
  // //    * kinematicAnalysis[0][6 * jointIndex + 6 * linkLinIndex + 3 * linkAngIndex + 1] = Link CoM x
  // //    * kinematicAnalysis[0][6 * jointIndex + 6 * linkLinIndex + 3 * linkAngIndex + 2] = Link CoM y
  // //    * kinematicAnalysis[0][6 * jointIndex + 6 * linkLinIndex + 3 * linkAngIndex + 3] = Link vx
  // //    * kinematicAnalysis[0][6 * jointIndex + 6 * linkLinIndex + 3 * linkAngIndex + 4] = Link vy
  // //    * kinematicAnalysis[0][6 * jointIndex + 6 * linkLinIndex + 3 * linkAngIndex + 5] = Link ax
  // //    * kinematicAnalysis[0][6 * jointIndex + 6 * linkLinIndex + 3 * linkAngIndex + 6] = Link ay
  // //    */

  protected startingAngle: number; // Temporary value to keep track of starting angle on input link
  private linksConnectedGroundMap = new Map<string, Joint[]>();
  private posTSL = new TimeSortedList();
  private ICTSL = new TimeSortedList();
  private tslJointIndexMap = new Map<string, number>();
  private tslICIndexMap = new Map<string, number>();

  private static roundToHundredThousandth(num: number) {
    return Math.round(num * 10000) / 10000;
  }

  /**
   * steps to determine DOF (Gruebler's Criteron with Exceptions):
   1.determine number of links + ground
   1a. If mechanismn contains parallel linkage, remove from the number of links(N) and number of joints (J1)
   2.determine number of ground joints
   3.determine number of slider joints
   */
  determineDegreesOfFreedom() {
    // let N = 1; // start with 1 to account for ground link
    // let J1 = 0;
    // let J2 = 0;
    // this.SimulationLinks.forEach(l => {
    //   if (l instanceof RealLink) {
    //     N++;
    //   }
    //   if (this.determineParallelLinkage(l)) {
    //     N -= l.joints.length - 2;
    //     J1 -= (l.joints.length - 2) * 2;
    //   }
    // });
    //
    // this.SimulationJoints.forEach(j => {
    //   if (j instanceof ImagJoint || (j.connectedLinks.length < 2 && !j.ground && j.jointType !== 'P')) {
    //     return;
    //   }
    //   switch (j.jointType) {
    //     case 'R':
    //       J1++;
    //       break;
    //     case 'P':
    //       J2++;
    //       break;
    //   }
    // });
    // this.realLinks = N;
    // this.realJoints = J1;
    let N = 0; // start with 1 to account for ground link
    let J1 = 0;
    const J2 = 0;
    let groundNotFound = true;
    this.SimulationLinks.forEach(l => {
      N++;
      if (this.determineParallelLinkage(l)) {
        N -= l.joints.length - 2;
        J1 -= (l.joints.length - 2) * 2;
      }
    });

    this.SimulationJoints.forEach(j => {
      if (j instanceof ImagJoint) {
        return;
      }
      switch (j.jointType) {
        case 'R':
          if (j.ground) {
            J1 += j.connectedLinks.length;
            if (groundNotFound) {
              N++;
              groundNotFound = false;
            }
          } else {
            J1 += j.connectedLinks.length - 1;
          }
          break;
        case 'P':
          // N++;
          J1 += j.connectedLinks.length;
          // J2++;
          break;
      }
    });
    this.realLinks = N;
    this.realJoints = J1;
    if (groundNotFound) {
      return NaN;
    }
    return (3 * (N - 1)) - (2 * J1) - J2;
  }

  // Setup function for finding the movement positions for each joint
  findFullMovementPos(inputAngularVelocity: number) {
    this.internalTriangleSimLinkMap = new Map<string, number[]>();
    [this._allLoops, this._requiredLoops] = LoopSolver.determineLoops(this.SimulationJoints, this.SimulationLinks);
    const jointIDToJointIndexMap = new Map<string, number>();
    this.SimulationJoints.forEach((j, i) => {
      jointIDToJointIndexMap.set(j.id, i);
    });
    // this should be within the constructor ...
    if (this.SimulationInstantCenters === undefined) {
      this.SimulationInstantCenters = this.createICs();
    }
    // determine center of mass for link
    this.SimulationLinks.forEach((l, i) => {
      if (l instanceof ImagLink) {
        return;
      }
      const link = l as RealLink;
      const joint1 = l.joints[0];
      const joint2 = l.joints[1];
      const a = Math.sqrt(Math.pow(joint2.x - link.CoM_x, 2) + Math.pow(joint2.y - link.CoM_y, 2));
      const b = Math.sqrt(Math.pow(joint1.x - link.CoM_x, 2) + Math.pow(joint1.y - link.CoM_y, 2));
      const c = Math.sqrt(Math.pow(joint1.x - joint2.x, 2) + Math.pow(joint1.y - joint2.y, 2));
      link.internal_CoM_angle = Math.acos( (Math.pow(b, 2) + (Math.pow(c, 2)) - (Math.pow(a, 2))) / (2 * b * c));
      if (isNaN(link.internal_CoM_angle)) {
        const angle1 = Math.atan2(link.CoM_y - joint1.y, link.CoM_x - joint1.x);
        const angle2 = Math.atan2(link.CoM_y - joint2.y, link.CoM_x - joint2.x);
        link.internal_CoM_angle = Math.abs(angle1 - angle2);
      }
      link.internal_distance = b;
    });
    // for (let l_index = 0; l_index < this.SimulationLinks.length; l_index++) {
    //   if (this.SimulationLinks[l_index] instanceof ImagLink) {
    //     continue;
    //   }
    //   const link = this.SimulationLinks[l_index] as RealLink;
    //   const joint1 = link.joints[0];
    //   const joint2 = link.joints[1];
    //   const a = Math.sqrt(Math.pow(joint2.x - link.CoM_x, 2) + Math.pow(joint2.y - link.CoM_y, 2));
    //   const b = Math.sqrt(Math.pow(joint1.x - link.CoM_x, 2) + Math.pow(joint1.y - link.CoM_y, 2));
    //   const c = Math.sqrt(Math.pow(joint1.x - joint2.x, 2) + Math.pow(joint1.y - joint2.y, 2));
    //   link.internal_CoM_angle = Math.acos( (Math.pow(b, 2) + (Math.pow(c, 2)) - (Math.pow(a, 2))) / (2 * b * c));
    //   if (isNaN(link.internal_CoM_angle)) {
    //     const angle1 = Math.atan2(link.CoM_y - joint1.y, link.CoM_x - joint1.x);
    //     const angle2 = Math.atan2(link.CoM_y - joint2.y, link.CoM_x - joint2.x);
    //     link.internal_CoM_angle = Math.abs(angle1 - angle2);
    //   }
    //   link.internal_distance = b;
    //   // const a = Math.sqrt(Math.pow(bounds.b2.x - coM_x, 2) + Math.pow(bounds.b2.y - coM_y, 2));
    //   // const b = Math.sqrt(Math.pow(bounds.b1.x - coM_x, 2) + Math.pow(bounds.b1.y - coM_y, 2));
    //   // const c = Math.sqrt(Math.pow(bounds.b1.x - bounds.b2.x, 2) + Math.pow(bounds.b1.y - bounds.b2.y, 2));
    //   // this._internal_CoM_angle = Math.acos( (Math.pow(a, 2) + (Math.pow(b, 2)) - (Math.pow(c, 2))) / (2 * a * b));
    //   // this._internal_distance = b;
    // }
    this.posTSL = new TimeSortedList();
    let increment = 0;
    let simForward = true;
    let falseTwice = 0;
    // let simForward = inputAngularVelocity > 0;
    let inputAngVelDirection = inputAngularVelocity > 0;
    let currentTimeStamp = 0;
    const timeIncrement = 1;
    const inputJoint = this.SimulationJoints.find(j => j.input);
    const desiredJoint = inputJoint.connectedJoints[0];
    const startingPositionX = desiredJoint.xInitial;
    const startingPositionY = desiredJoint.yInitial;
    // const TOLERANCE = 0.01;
    // const TOLERANCE = 0.05;
    // const TOLERANCE = 0.001;
    const TOLERANCE = 0.008;
    let ffs = this.calculateForces(this.SimulationLinks, this.SimulationForces);
    let xDiff = Math.abs(startingPositionX - (Math.round(desiredJoint.x * 100) / 100));
    let yDiff = Math.abs(startingPositionX - (Math.round(desiredJoint.x * 100) / 100));
    // let sec_ics = [];
    let ics = this.determineICPositions(this.SimulationInstantCenters, this.SimulationJoints);
    // const ics = [];
    // this.determineKinDataICs(this.SimulationJoints, this.SimulationLinks, this.SimulationInstantCenters,
    //   this.requiredLoops, inputAngularVelocity);
    this.posTSL.addRow(this.SimulationJoints, this.SimulationLinks, inputAngularVelocity, ffs, currentTimeStamp, increment, ics);
    PositionSolver.resetStaticVariables();
    // determine order in which joints are determined
    PositionSolver.determineJointOrder(this.SimulationJoints, this.SimulationLinks);
    // PositionSolver.determineJointOrderSolver(this.SimulationJoints, this.SimulationLinks);
    while (!simForward || currentTimeStamp === 0 || xDiff > TOLERANCE || yDiff > TOLERANCE) {
      // maybe instead of using simForward, just put in angular Velocity and use that value
      const [desiredMap, possible] = PositionSolver.determinePositionAnalysis(this.SimulationJoints,
        this.SimulationLinks, inputAngVelDirection);
      if (possible) {
        for (const entry of desiredMap.entries()) {
          const joint_index = jointIDToJointIndexMap.get(entry[0]);
          const joint = this.SimulationJoints[joint_index];
          // const joint = this.SimulationJoints.find(j => j.id === entry[0]);
          joint.x = entry[1][0];
          joint.y = entry[1][1];
        }
        falseTwice = 0;
        currentTimeStamp += timeIncrement;
        ffs = this.calculateForces(this.SimulationLinks, this.SimulationForces);
        increment++;
        // adjust for linkAngle (for center of mass)
        ics = this.determineICPositions(this.SimulationInstantCenters, this.SimulationJoints);
        this.determineLinkCoMLocations(this.SimulationLinks);
        // for (let l_index = 0; l_index < this.SimulationLinks.length; l_index++) {
        //   if (this.SimulationLinks[l_index] instanceof ImagLink) {
        //     continue;
        //   }
        //   const link = this.SimulationLinks[l_index] as RealLink;
        //   const joint1 = link.joints[0];
        //   const joint2 = link.joints[1];
        //   const angle = Math.atan2(joint2.y - joint1.y, joint2.x - joint1.x);
        //   link.CoM_x = Math.cos(link.internal_CoM_angle + angle) * link.internal_distance + joint1.x;
        //   link.CoM_y = Math.sin(link.internal_CoM_angle + angle) * link.internal_distance + joint1.y;
        // }
        this.posTSL.addRow(this.SimulationJoints, this.SimulationLinks, inputAngularVelocity, ffs, currentTimeStamp, increment, ics);
      } else {
        if ((simForward === false && increment === 0) || (falseTwice === 2)) {
          return this.posTSL = new TimeSortedList();
        }
        falseTwice += 1;
        simForward = !simForward;
        inputAngularVelocity = inputAngularVelocity * -1;
        inputAngVelDirection = !inputAngVelDirection;
      }
      xDiff = Math.abs(startingPositionX - (Math.round(desiredJoint.x * 100) / 100));
      yDiff = Math.abs(startingPositionY - (Math.round(desiredJoint.y * 100) / 100));
      if (increment === 750) {
        return this.posTSL = new TimeSortedList();
      }
    }
    return this.posTSL;
  }

  private calculateForces(links: Link[], forces: Force[]): Force[] {
    const res = [];

    forces.forEach(force => {

      const link = links.find(l => {
        return force.onlink === l.id;
      });
      if (!link) {
        return;
      }
      const j1 = link.joints[0];
      const j2 = link.joints[1];
      if (!j1 || !j2) {
        return;
      }

      const fakeForce = new Force(force.id, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, link.id, force.isGlobal);
      const tempJ1 = new Coord(j1.x, j1.y);
      const tempJ2 = new Coord(j2.x, j2.y);
      const rotation = SVGFuncs.getLinkRotation(tempJ1, tempJ2);
      const newStart = SVGFuncs.getPointByRelativeCoord(tempJ1, rotation, new Coord(force.srx, force.sry));

      let newEnd: Coord;
      if (!force.isGlobal) {
        newEnd = SVGFuncs.getPointByRelativeCoord(tempJ1, rotation, new Coord(force.erx, force.ery));
      } else {
        const xcomp = force.ex - force.sx;
        const ycomp = force.ey - force.sy;
        newEnd = new Coord(newStart.x + xcomp, newStart.y + ycomp);
      }
      fakeForce.sx = newStart.x;
      fakeForce.sy = newStart.y;
      fakeForce.ex = newEnd.x;
      fakeForce.ey = newEnd.y;

      res.push(fakeForce);
    });
    return res;
  }

  private determineParallelLinkage(l: Link) {
    if (!this.linksConnectedGroundMap.has(l.id)) {
      this.linksConnectedGroundMap.set(l.id, l.joints.filter(j => j.connectedJoints.find(jt => jt.ground) !== undefined));
    }
    const groundJoints = this.linksConnectedGroundMap.get(l.id);
    if (groundJoints.length <= 2) {
      return false;
    }
    const m1 = (l.joints[0].y - l.joints[1].y) / (l.joints[0].x - l.joints[1].x);
    const m2 = (groundJoints[0].y - groundJoints[1].y) / (groundJoints[0].x - groundJoints[1].x);
    const b1 = l.joints[0].y;
    const b2 = groundJoints[0].y;
    return m1 === m2 && m1 * l.joints[2].x + b1 === l.joints[2].y && m2 * groundJoints[2].x + b2 === groundJoints[2].y;
  }

  getNumJointsAndLinks() {
    this.numJointsAndLinks = new Array<number>();
    this.numJointsAndLinks.push(this.realJoints);
    this.numJointsAndLinks.push(this.realLinks);
    return this.numJointsAndLinks;
  }

  loopTitleRow() {
    const loopTitleRow = new Array<string>();
    loopTitleRow.push('Necessary Loops');
    loopTitleRow.push('All Loops');
    return loopTitleRow;
  }

  forceTitleRow(analysisType: string) {
    const forceTitleRow = new Array<string>();
    forceTitleRow.push('Current Time');
    let posUnit: string;
    let velUnit: string;
    let accUnit: string;
    let forceUnit: string;
    let torqueUnit: string;
    const angPosUnit = 'deg';
    const angVelUnit = 'rad/s';
    const angAccUnit = 'rad/s^2';
    switch (this._unit) {
      case 'cm':
        posUnit = 'cm';
        velUnit = 'cm/s';
        accUnit = 'cm/s^2';
        forceUnit = 'N';
        torqueUnit = 'N*m';
        break;
      case 'm':
        posUnit = 'm';
        velUnit = 'm/s';
        accUnit = 'm/s^2';
        forceUnit = 'N';
        torqueUnit = 'N*m';
        break;
      // case 'km':
      //   posUnit = 'km';
      //   forceUnit = 'N';
      //   torqueUnit = 'N*km';
      //   accUnit = 'km/s^2';
      //   break;
      // case 'in':
      //   posUnit = 'in';
      //   forceUnit = 'lbf';
      //   torqueUnit = 'lbf*in';
      //   accUnit = 'in/s^2';
      //   break;
      // case 'ft':
      //   posUnit = 'ft';
      //   forceUnit = 'lbf';
      //   torqueUnit = 'lbf*ft';
      //   accUnit = 'ft/s^2';
      //   break;
    }
    // switch (this._unit) {
    //   case 'Metric':
    //     posUnit = 'cm';
    //     forceUnit = 'N';
    //     torqueUnit = 'N*cm';
    //     accUnit = 'cm/s^2';
    //     break;
    //   case 'English':
    //     posUnit = 'ft';
    //     forceUnit = 'lbm*ft/s^2';
    //     torqueUnit = 'lbm*ft^2/s^2';
    //     accUnit = 'in/s^2';
    //     break;
    // }
    if (analysisType === 'dynamic') {
      // determine kinematic analysis
      KinematicsSolver.requiredLoops = this.requiredLoops;
      KinematicsSolver.determineKinematics(this.SimulationJoints, this.SimulationLinks, this.posTSL.TSL[0].angular_velocity);
    }
    ForceSolver.determineDesiredLoopLettersForce(this._requiredLoops);
    ForceSolver.determineForceAnalysis(this.SimulationJoints, this.SimulationLinks, analysisType, this.gravity, this.unit);
    for (const entry of ForceSolver.jointIdToJointIndexMap.entries()) {
      forceTitleRow.push('Joint ' + entry[0] + ' Force ' + ' x ' + '(' + forceUnit + ')');
      forceTitleRow.push('Joint ' + entry[0] + ' Force ' + ' y ' + '(' + forceUnit + ')');
    }
    forceTitleRow.push('Torque ' + torqueUnit);
    forceTitleRow.push(' ');
    this.SimulationForces.forEach(f => {
      forceTitleRow.push('Force ' + f.id + ' x ' + '(' + posUnit + ')');
      forceTitleRow.push('Force ' + f.id + ' y ' + '(' + posUnit + ')');
    });
    forceTitleRow.push(' ');
    switch (analysisType) {
      case 'static':
        this.SimulationJoints.forEach(j => {
          forceTitleRow.push('Joint ' + j.id + ' x ' + '(' + posUnit + ')');
          forceTitleRow.push('Joint ' + j.id + ' y ' + '(' + posUnit + ')');
        });
        break;
      case 'dynamic':
        this.SimulationJoints.forEach(j => {
          if (j instanceof ImagJoint) {
            return;
          }
          forceTitleRow.push('Joint ' + j.id + ' x ' + '(' + posUnit + ')');
          forceTitleRow.push('Joint ' + j.id + ' y ' + '(' + posUnit + ')');
          forceTitleRow.push('Joint ' + j.id + ' Vel x ' + '(' + velUnit + ')');
          forceTitleRow.push('Joint ' + j.id + ' Vel y ' + '(' + velUnit + ')');
          forceTitleRow.push('Joint ' + j.id + ' Acc x ' + '(' + accUnit + ')');
          forceTitleRow.push('Joint ' + j.id + ' Acc y' + '(' + accUnit + ')');
        });
        forceTitleRow.push(' ');
        this.SimulationLinks.forEach(l => {
          if (l instanceof ImagLink) {
            return;
          }
          forceTitleRow.push('Link ' + l.id + ' CoM x ' + posUnit);
          forceTitleRow.push('Link ' + l.id + ' CoM y ' + posUnit);
          forceTitleRow.push('Link ' + l.id + ' CoM Vel x ' + velUnit);
          forceTitleRow.push('Link ' + l.id + ' CoM Vel y ' + velUnit);
          forceTitleRow.push('Link ' + l.id + ' CoM Acc x ' + accUnit);
          forceTitleRow.push('Link ' + l.id + ' CoM Acc y ' + accUnit);
        });
        forceTitleRow.push(' ');
        this.SimulationLinks.forEach(l => {
          if (l instanceof ImagLink) {
            return;
          }
          // forceTitleRow.push('Link ' + l.id + ' angPos ' + angAccUnit);
          forceTitleRow.push('Link ' + l.id + ' angPos ' + angPosUnit);
          forceTitleRow.push('Link ' + l.id + ' angVel ' + angVelUnit);
          forceTitleRow.push('Link ' + l.id + ' angAcc ' + angAccUnit);
        });
        break;
    }

    // this.SimulationLinks.forEach(l => {
    //   if (l instanceof ImagLink) {
    //     return;
    //   }
    //   forceTitleRow.push('Link ' + l.id + ' CoM x ' + posUnit);
    //   forceTitleRow.push('Link ' + l.id + ' CoM y ' + posUnit);
    // });

    return forceTitleRow;
  }

  stressTitleRow() {
    const stressTitleRow = new Array<string>();
    stressTitleRow.push('Current Time');
    return stressTitleRow;
  }

  kinematicLoopTitleRow() {
    const kinematicTitleRow = new Array<string>();
    kinematicTitleRow.push('Current Time');
    let posUnit: string;
    let velUnit: string;
    let accUnit: string;
    const angPosUnit = 'degree';
    const angVelUnit = 'rad/s';
    const angAccUnit = 'rad/s^2';
    switch (this._unit) {
      case 'cm':
        posUnit = 'cm';
        velUnit = 'cm/s';
        accUnit = 'cm/s^2';
        break;
      case 'm':
        posUnit = 'm';
        velUnit = 'm/s';
        accUnit = 'm/s^2';
        break;
      // case 'km':
      //   posUnit = 'km';
      //   velUnit = 'km/s';
      //   accUnit = 'km/s^2';
      //   break;
      // case 'in':
      //   posUnit = 'in';
      //   velUnit = 'in/s';
      //   accUnit = 'in/s^2';
      //   break;
      // case 'ft':
      //   posUnit = 'ft';
      //   velUnit = 'ft/s';
      //   accUnit = 'ft/s^2';
      //   break;
    }
    // switch (this._unit) {
    //   case 'Metric':
    //     posUnit = 'cm';
    //     velUnit = 'cm/s';
    //     accUnit = 'cm/s^2';
    //     break;
    //   case 'English':
    //     posUnit = 'in';
    //     velUnit = 'in/s';
    //     accUnit = 'in/s^2';
    //     break;
    // }
    this.SimulationJoints.forEach(j => {
      if (j instanceof ImagJoint) {
        return;
      }
      kinematicTitleRow.push('Joint ' + j.id + ' x ' + posUnit);
      kinematicTitleRow.push('Joint ' + j.id + ' y ' + posUnit);
      kinematicTitleRow.push('Joint ' + j.id + ' vx ' + velUnit);
      kinematicTitleRow.push('Joint ' + j.id + ' vy ' + velUnit);
      kinematicTitleRow.push('Joint ' + j.id + ' ax ' + accUnit);
      kinematicTitleRow.push('Joint ' + j.id + ' ay ' + accUnit);
    });
    kinematicTitleRow.push(' ');
    this.SimulationLinks.forEach(l => {
      if (l instanceof ImagLink) {
        return;
      }
      kinematicTitleRow.push('Link ' + l.id + ' CoM ' + 'x ' + posUnit);
      kinematicTitleRow.push('Link ' + l.id + ' CoM ' + 'y ' + posUnit);
      kinematicTitleRow.push('Link ' + l.id + ' CoM ' + 'vx ' + velUnit);
      kinematicTitleRow.push('Link ' + l.id + ' CoM ' + 'vy ' + velUnit);
      kinematicTitleRow.push('Link ' + l.id + ' CoM ' + 'ax ' + accUnit);
      kinematicTitleRow.push('Link ' + l.id + ' CoM ' + 'ay ' + accUnit);
    });
    kinematicTitleRow.push(' ');
    this.SimulationLinks.forEach(l => {
      if (l instanceof ImagLink) {
        return;
      }
      kinematicTitleRow.push('Link ' + l.id + ' angle ' + angPosUnit);
      kinematicTitleRow.push('Link ' + l.id + ' angVel ' + angVelUnit);
      kinematicTitleRow.push('Link ' + l.id + ' angAcc ' + angAccUnit);
    });
    return kinematicTitleRow;
  }

  kinematicICTitleRow() {
    const kinematicTitleRow = new Array<string>();
    kinematicTitleRow.push('Current Time');
    let posUnit: string;
    let velUnit: string;
    switch (this._unit) {
      case 'cm':
        posUnit = 'cm';
        velUnit = 'cm/s';
        break;
      case 'm':
        posUnit = 'm';
        velUnit = 'm/s';
        break;
      // case 'km':
      //   posUnit = 'km';
      //   velUnit = 'km/s';
      //   break;
      // case 'in':
      //   posUnit = 'in';
      //   velUnit = 'in/s';
      //   break;
      // case 'ft':
      //   posUnit = 'ft';
      //   velUnit = 'ft/s';
      //   break;
    }
    // switch (this._unit) {
    //   case 'Metric':
    //     posUnit = 'cm';
    //     velUnit = 'cm/s';
    //     break;
    //   case 'English':
    //     posUnit = 'ft';
    //     velUnit = 'ft/s';
    //     break;
    // }
    // const angPosUnit = '(degrees)';
    const angVelUnit = 'rad/s';
    this.SimulationInstantCenters.forEach(ic => {
      const linkIndices = ic.id;
      const link_1_index = Number(linkIndices.charAt(0)) - 2;
      const link_2_index = Number(linkIndices.charAt(2)) - 2;
      let link_1_id: string;
      let link_2_id: string;
      if (link_1_index !== -1) {
        link_1_id = this.SimulationLinks[link_1_index].id;
      } else {
        link_1_id = 'g';
      }
      if (link_2_index !== -1) {
        link_2_id = this.SimulationLinks[link_2_index].id;
      } else {
        link_2_id = 'g';

      }
      kinematicTitleRow.push('Link ' + link_1_id + ',' + link_2_id + ' x ' + posUnit);
      kinematicTitleRow.push('Link ' + link_1_id + ',' + link_2_id + ' y ' + posUnit);
      // kinematicTitleRow.push('IC ' + ic.id + ' x cm');
      // kinematicTitleRow.push('IC ' + ic.id + ' y cm');
    });
    kinematicTitleRow.push(' ');
    this.SimulationJoints.forEach(j => {
      if (j instanceof ImagJoint) {
        return;
      }
      kinematicTitleRow.push('Joint ' + j.id + ' V_x ' + velUnit);
      kinematicTitleRow.push('Joint ' + j.id + ' V_y ' + velUnit);
    });
    kinematicTitleRow.push(' ');
    this.SimulationLinks.forEach(l => {
      if (l instanceof ImagLink) {
        return;
      }
      // kinematicTitleRow.push('Link ' + l.id + ' angle ' + angPosUnit);
      kinematicTitleRow.push('Link ' + l.id + ' angVel ' + angVelUnit);
    });
    return kinematicTitleRow;
  }

  loopAnalysis() {
    const loopAnalysis = new Array<Array<string>>();
    for (let i = 0; i < this._allLoops.length; i++) {
      const row = new Array<string>();
      if (i <= this._requiredLoops.length - 1) {
        row.push(this._requiredLoops[i]);
      } else {
        row.push('');
      }
      row.push(this._allLoops[i]);
      loopAnalysis.push(row);
    }
    return loopAnalysis;
  }

  forceAnalysis(analysisType: string) {
    const forceAnalysis = new Array<Array<string>>();
    let forceUnitConversion: number;
    let torqueUnitConversion: number;
    let posUnitConversion: number;
    let velUnitConversion: number;
    let accUnitConversion: number;
    switch (this._unit) {
      case 'cm':
        forceUnitConversion = 1; // convert from newtons -> newton
        torqueUnitConversion = 1; // convert from newton_meter -> newton_centimeter
        posUnitConversion = 1;
        velUnitConversion = 1;
        accUnitConversion = 1; // cm/s^2
        break;
      case 'm':
        forceUnitConversion = 1; // convert from newtons -> newton
        torqueUnitConversion = 1; // convert from newton_meter -> newton_centimeter
        posUnitConversion = 1;
        velUnitConversion = 1;
        accUnitConversion = 1; // cm/s^2
        break;
      // case 'km':
      //   forceUnitConversion = 1; // convert from newtons -> newton
      //   torqueUnitConversion = 1; // convert from newton_meter -> newton_centimeter
      //   accUnitConversion = 1; // cm/s^2
      //   break;
      // case 'in':
      //   forceUnitConversion = 1; // convert from newtons -> newton
      //   torqueUnitConversion = 1; // convert from newton_meter -> newton_centimeter
      //   accUnitConversion = 1; // cm/s^2
      //   break;
      // case 'ft':
      //   forceUnitConversion = 1; // convert from newtons -> newton
      //   torqueUnitConversion = 1; // convert from newton_meter -> newton_centimeter
      //   accUnitConversion = 1; // cm/s^2
      //   break;
    }
    // switch (this._unit) {
    //   case 'Metric':
    //     forceUnitConversion = 1; // convert from newtons -> newton
    //     torqueUnitConversion = 1; // convert from newton_meter -> newton_centimeter
    //     accUnitConversion = 1; // cm/s^2
    //     break;
    //   case 'English':
    //     forceUnitConversion = 0.2248089431; // convert from newton ->
    //     torqueUnitConversion = 0.73756214728 ; // convert from newton_meter ->
    //     // change this since conversion is starting at cm instead of m
    //     accUnitConversion = 3.2808399;
    //     break;
    // }
    // ForceSolver.resetStaticVariables();
    this.posTSL.TSL.forEach(tsl => {
      this.insertNewJointPos(tsl);
      const force_row = Array<string>();
      // const A_row = Array<Array<string>>(); // unknown array
      // const B_row = Array<string>(); // known array
      force_row.push((tsl.time * tsl.angular_velocity * Math.PI / 180).toString());
      if (analysisType === 'dynamic') {
        // determine kinematic analysis
        KinematicsSolver.requiredLoops = this.requiredLoops;
        KinematicsSolver.determineKinematics(this.SimulationJoints, this.SimulationLinks, tsl.angular_velocity);
      }
      ForceSolver.determineForceAnalysis(this.SimulationJoints, this.SimulationLinks, analysisType, this.gravity, this.unit);
      for (let simJointIndex = 0; simJointIndex < this.SimulationJoints.length; simJointIndex++) {
        const joint_id = this.SimulationJoints[simJointIndex].id;
        force_row.push(Simulator.roundToHundredThousandth(
          ForceSolver.unknownVariableForcesMap.get(joint_id)[0] * forceUnitConversion).toString());
        force_row.push(Simulator.roundToHundredThousandth(
          ForceSolver.unknownVariableForcesMap.get(joint_id)[1] * forceUnitConversion).toString());
      }
      force_row.push(Simulator.roundToHundredThousandth(ForceSolver.unknownVariableTorque * torqueUnitConversion).toString());
      force_row.push(' ');
      // this.SimulationJoints.forEach(j => {
      //   // force_row.push('(' + j.x.toString() + ',' + j.y.toString() + ')');
      //   force_row.push(j.x.toString());
      //   force_row.push(j.y.toString());
      // });
      // force_row.push(' ');
      // this.SimulationLinks.forEach(l => {
      //   if (l instanceof ImagLink) {
      //     return;
      //   }
      //   const cur_link = l as RealLink;
      //   force_row.push(Simulator.roundToHundredThousandth(cur_link.CoM_x).toString());
      //   force_row.push(Simulator.roundToHundredThousandth(cur_link.CoM_y).toString());
      //   // force_row.push(Simulator.roundToHundredThousandth(KinematicsSolver.linkCoMMap.get(l.id)[0]).toString());
      //   // force_row.push(Simulator.roundToHundredThousandth(KinematicsSolver.linkCoMMap.get(l.id)[1]).toString());
      //   // force_row.push('(' + Simulator.roundToHundredThousandth(KinematicsSolver.linkAccMap.get(l.id)[0] * accUnitConversion) +
      //   //   ',' + Simulator.roundToHundredThousandth(KinematicsSolver.linkAccMap.get(l.id)[1] * accUnitConversion) + ')');
      // });
      this.SimulationForces.forEach(f => {
        force_row.push(Simulator.roundToHundredThousandth(f.sx).toString());
        force_row.push(Simulator.roundToHundredThousandth(f.sy).toString());
        // force_row.push(Simulator.roundToHundredThousandth(f.xLocOfForceOnLink).toString());
        // force_row.push(Simulator.roundToHundredThousandth(f.yLocOfForceOnLink).toString());
      });
      force_row.push(' ');
      switch (analysisType) {
        case 'static':
          this.SimulationJoints.forEach(j => {
            if (j instanceof ImagJoint) {
              return;
            }
            force_row.push(Simulator.roundToHundredThousandth(j.x).toString());
            force_row.push(Simulator.roundToHundredThousandth(j.y).toString());
            // force_row.push(Simulator.roundToHundredThousandth(KinematicsSolver.jointIndexMap.get(j.id)[0] * accUnitConversion).toString());
            // force_row.push(Simulator.roundToHundredThousandth(KinematicsSolver.jointIndexMap.get(j.id)[1] * accUnitConversion).toString());
          });
          // force_row.push(' ');
          // this.SimulationLinks.forEach(l => {
          //   if (l instanceof ImagLink) {
          //     return;
          //   }
          // force_row.push(Simulator.roundToHundredThousandth())
          // force_row.push(Simulator.roundToHundredThousandth(KinematicsSolver.linkCoMMap.get(l.id)[0] * accUnitConversion).toString());
          // force_row.push(Simulator.roundToHundredThousandth(KinematicsSolver.linkCoMMap.get(l.id)[1] * accUnitConversion).toString());
          // });
          // force_row.push(' ');
          break;
        case 'dynamic':
          this.SimulationJoints.forEach(j => {
            if (j instanceof ImagJoint) {
              return;
            }
            force_row.push(Simulator.roundToHundredThousandth(j.x).toString());
            force_row.push(Simulator.roundToHundredThousandth(j.y).toString());
            // force_row.push(Simulator.roundToHundredThousandth(KinematicsSolver.jointIndexMap.get(j.id)[0] * accUnitConversion).toString());
            // force_row.push(Simulator.roundToHundredThousandth(KinematicsSolver.jointIndexMap.get(j.id)[1] * accUnitConversion).toString());
            force_row.push(Simulator.roundToHundredThousandth(KinematicsSolver.jointVelMap.get(j.id)[0] * velUnitConversion).toString());
            force_row.push(Simulator.roundToHundredThousandth(KinematicsSolver.jointVelMap.get(j.id)[1] * velUnitConversion).toString());
            force_row.push(Simulator.roundToHundredThousandth(KinematicsSolver.jointAccMap.get(j.id)[0] * accUnitConversion).toString());
            force_row.push(Simulator.roundToHundredThousandth(KinematicsSolver.jointAccMap.get(j.id)[1] * accUnitConversion).toString());
            // force_row.push('(' + Simulator.roundToHundredThousandth(KinematicsSolver.jointAccMap.get(j.id)[0] * accUnitConversion) +
            //   ',' + Simulator.roundToHundredThousandth(KinematicsSolver.jointAccMap.get(j.id)[1] * accUnitConversion) + ')');
          });
          force_row.push(' ');
          this.SimulationLinks.forEach(l => {
            if (l instanceof ImagLink) {
              return;
            }
            force_row.push(Simulator.roundToHundredThousandth(KinematicsSolver.linkCoMMap.get(l.id)[0] * posUnitConversion).toString());
            force_row.push(Simulator.roundToHundredThousandth(KinematicsSolver.linkCoMMap.get(l.id)[1] * posUnitConversion).toString());
            force_row.push(Simulator.roundToHundredThousandth(KinematicsSolver.linkVelMap.get(l.id)[0] * velUnitConversion).toString());
            force_row.push(Simulator.roundToHundredThousandth(KinematicsSolver.linkVelMap.get(l.id)[1] * velUnitConversion).toString());
            force_row.push(Simulator.roundToHundredThousandth(KinematicsSolver.linkAccMap.get(l.id)[0] * accUnitConversion).toString());
            force_row.push(Simulator.roundToHundredThousandth(KinematicsSolver.linkAccMap.get(l.id)[1] * accUnitConversion).toString());
            // force_row.push('(' + Simulator.roundToHundredThousandth(KinematicsSolver.linkAccMap.get(l.id)[0] * accUnitConversion) +
            //   ',' + Simulator.roundToHundredThousandth(KinematicsSolver.linkAccMap.get(l.id)[1] * accUnitConversion) + ')');
          });
          force_row.push(' ');
          this.SimulationLinks.forEach(l => {
            if (l instanceof ImagLink) {
              return;
            }
            force_row.push(Simulator.roundToHundredThousandth(KinematicsSolver.linkAngPosMap.get(l.id)).toString());
            force_row.push(Simulator.roundToHundredThousandth(KinematicsSolver.linkAngVelMap.get(l.id)).toString());
            force_row.push(Simulator.roundToHundredThousandth(KinematicsSolver.linkAngAccMap.get(l.id)).toString());
          });
          break;
      }
      forceAnalysis.push(force_row);
    });
    return forceAnalysis;
  }

  stressAnalysis() {
    const stressAnalysis = new Array<Array<number>>();
  }

  kinematicLoopAnalysis() {
    const kinematicAnalysis = Array<Array<string>>();
    // const kinematicAnalysis = Array<Array<number>>();
    // KinematicsSolver.resetVariables();
    this.posTSL.TSL.forEach(tsl => {
      this.insertNewJointPos(tsl);
      // const row = Array<number>();
      const row = Array<string>();
      row.push((tsl.time * tsl.angular_velocity * Math.PI / 180).toString());
      KinematicsSolver.requiredLoops = this.requiredLoops;
      KinematicsSolver.determineKinematics(this.SimulationJoints, this.SimulationLinks, tsl.angular_velocity);
      let posUnitConversion: number;
      let velUnitConversion: number;
      let accUnitConversion: number;
      switch (this._unit) {
        case 'cm':
          posUnitConversion = 1; // cm
          velUnitConversion = 1; // cm/s
          accUnitConversion = 1; // cm/s^2
          break;
        case 'm':
          posUnitConversion = 1; // cm
          velUnitConversion = 1; // cm/s
          accUnitConversion = 1; // cm/s^2
          break;
        // case 'km':
        //   posUnitConversion = 1; // cm
        //   velUnitConversion = 1; // cm/s
        //   accUnitConversion = 1; // cm/s^2
        //   break;
        // case 'in':
        //   posUnitConversion = 1; // cm
        //   velUnitConversion = 1; // cm/s
        //   accUnitConversion = 1; // cm/s^2
        //   break;
        // case 'ft':
        //   posUnitConversion = 1; // cm
        //   velUnitConversion = 1; // cm/s
        //   accUnitConversion = 1; // cm/s^2
        //   break;
      }
      // switch (this._unit) {
      //   case 'Metric':
      //     posUnitConversion = 1; // cm
      //     velUnitConversion = 1; // cm/s
      //     accUnitConversion = 1; // cm/s^2
      //     break;
      //   case 'English':
      //     // change this since conversion is starting at cm instead of m
      //     posUnitConversion = 0.3048;
      //     velUnitConversion = 3.28084;
      //     accUnitConversion = 3.2808399;
      //     break;
      // }

      this.SimulationJoints.forEach(j => {
        if (j instanceof ImagJoint) {
          return;
        }
        // row.push((this.SimulationJoints[KinematicsSolver.jointIndexMap.get(j.id)].x * posUnitConversion).toString());
        // row.push((this.SimulationJoints[KinematicsSolver.jointIndexMap.get(j.id)].y * posUnitConversion).toString());
        // row.push((KinematicsSolver.jointVelMap.get(j.id)[0] * velUnitConversion).toString());
        // row.push((KinematicsSolver.jointVelMap.get(j.id)[1] * velUnitConversion).toString());
        // row.push((KinematicsSolver.jointAccMap.get(j.id)[0] * accUnitConversion).toString());
        // row.push((KinematicsSolver.jointAccMap.get(j.id)[1] * accUnitConversion).toString());
        row.push(Simulator.roundToHundredThousandth(
          this.SimulationJoints[KinematicsSolver.jointIndexMap.get(j.id)].x * posUnitConversion).toString());
        row.push(Simulator.roundToHundredThousandth(
          this.SimulationJoints[KinematicsSolver.jointIndexMap.get(j.id)].y * posUnitConversion).toString());
        row.push(Simulator.roundToHundredThousandth(KinematicsSolver.jointVelMap.get(j.id)[0] * velUnitConversion).toString());
        row.push(Simulator.roundToHundredThousandth(KinematicsSolver.jointVelMap.get(j.id)[1] * velUnitConversion).toString());
        row.push(Simulator.roundToHundredThousandth(KinematicsSolver.jointAccMap.get(j.id)[0] * accUnitConversion).toString());
        row.push(Simulator.roundToHundredThousandth(KinematicsSolver.jointAccMap.get(j.id)[1] * accUnitConversion).toString());
      });
      row.push(' ');
      this.SimulationLinks.forEach(l => {
        if (l instanceof ImagLink) {
          return;
        }
        // row.push((KinematicsSolver.linkCoMMap.get(l.id)[0] * posUnitConversion).toString());
        // row.push((KinematicsSolver.linkCoMMap.get(l.id)[1] * posUnitConversion).toString());
        // row.push((KinematicsSolver.linkVelMap.get(l.id)[0] * velUnitConversion).toString());
        // row.push((KinematicsSolver.linkVelMap.get(l.id)[1] * velUnitConversion).toString());
        // row.push((KinematicsSolver.linkAccMap.get(l.id)[0] * accUnitConversion).toString());
        // row.push((KinematicsSolver.linkAccMap.get(l.id)[1] * accUnitConversion).toString());
        row.push(Simulator.roundToHundredThousandth(KinematicsSolver.linkCoMMap.get(l.id)[0] * posUnitConversion).toString());
        row.push(Simulator.roundToHundredThousandth(KinematicsSolver.linkCoMMap.get(l.id)[1] * posUnitConversion).toString());
        row.push(Simulator.roundToHundredThousandth(KinematicsSolver.linkVelMap.get(l.id)[0] * velUnitConversion).toString());
        row.push(Simulator.roundToHundredThousandth(KinematicsSolver.linkVelMap.get(l.id)[1] * velUnitConversion).toString());
        row.push(Simulator.roundToHundredThousandth(KinematicsSolver.linkAccMap.get(l.id)[0] * accUnitConversion).toString());
        row.push(Simulator.roundToHundredThousandth(KinematicsSolver.linkAccMap.get(l.id)[1] * accUnitConversion).toString());
      });
      row.push(' ');
      this.SimulationLinks.forEach(l => {
        if (l instanceof ImagLink) {
          return;
        }
        // row.push((KinematicsSolver.linkAngPosMap.get(l.id)).toString());
        // row.push((KinematicsSolver.linkAngVelMap.get(l.id)).toString());
        // row.push((KinematicsSolver.linkAngAccMap.get(l.id)).toString());
        row.push(Simulator.roundToHundredThousandth(KinematicsSolver.linkAngPosMap.get(l.id)).toString());
        row.push(Simulator.roundToHundredThousandth(KinematicsSolver.linkAngVelMap.get(l.id)).toString());
        row.push(Simulator.roundToHundredThousandth(KinematicsSolver.linkAngAccMap.get(l.id)).toString());
      });
      kinematicAnalysis.push(row);
    });
    return kinematicAnalysis;
  }

  kinematicICAnalysis() {
    const kinematicAnalysis = Array<Array<string>>();
    ICSolver.resetVariables();
    this.posTSL.TSL.forEach(tsl => {
      this.insertNewICPos(tsl);
      const row = Array<string>();
      row.push((tsl.time * tsl.angular_velocity * Math.PI / 180).toString());
      ICSolver.determineKinDataICs(this.SimulationJoints, this.SimulationLinks, this.SimulationInstantCenters,
        this.requiredLoops, tsl.angular_velocity);
      // const posUnit = 'm/s';
      // const velUnit = 'v/s';
      // const accUnit = 'a/s';
      let posUnitConversion: number;
      let velUnitConversion: number;
      switch (this._unit) {
        case 'cm':
          posUnitConversion = 1;
          velUnitConversion = 1;
          break;
        case 'm':
          posUnitConversion = 1;
          velUnitConversion = 1;
          break;
        // case 'km':
        //   posUnitConversion = 1;
        //   velUnitConversion = 1;
        //   break;
        // case 'in':
        //   posUnitConversion = 1;
        //   velUnitConversion = 1;
        //   break;
        // case 'ft':
        //   posUnitConversion = 1;
        //   velUnitConversion = 1;
        //   break;
      }
      // switch (this._unit) {
      //   case 'Metric':
      //     posUnitConversion = 1;
      //     velUnitConversion = 1;
      //     break;
      //   case 'English':
      //     posUnitConversion = 0.3048;
      //     velUnitConversion = 3.28084;
      //     break;
      // }
      this.SimulationInstantCenters.forEach(ic => {
        // row.push('(' + Simulator.roundToHundredThousandth(
        //   this.SimulationInstantCenters[ICSolver.instantCenterIndexMap.get(ic.id)].x * posUnitConversion) +
        // ',' + Simulator.roundToHundredThousandth(
        //   this.SimulationInstantCenters[ICSolver.instantCenterIndexMap.get(ic.id)].x * posUnitConversion) + ')');
        row.push(Simulator.roundToHundredThousandth(
          this.SimulationInstantCenters[ICSolver.instantCenterIndexMap.get(ic.id)].x * posUnitConversion).toString());
        row.push(Simulator.roundToHundredThousandth(
          this.SimulationInstantCenters[ICSolver.instantCenterIndexMap.get(ic.id)].y * posUnitConversion).toString());
      });
      row.push(' ');
      this.SimulationJoints.forEach(j => {
        if (j instanceof ImagJoint) {
          return;
        }
        // row.push('(' + Simulator.roundToHundredThousandth(
        //   ICSolver.jointVelMap.get(j.id)[0] * velUnitConversion) +
        // ',' + Simulator.roundToHundredThousandth(ICSolver.jointVelMap.get(j.id)[1] * velUnitConversion) + ')');
        row.push(Simulator.roundToHundredThousandth(j.x * posUnitConversion).toString());
          row.push(Simulator.roundToHundredThousandth(j.y * posUnitConversion).toString());
        row.push(Simulator.roundToHundredThousandth(ICSolver.jointVelMap.get(j.id)[0] * velUnitConversion).toString());
        row.push(Simulator.roundToHundredThousandth(ICSolver.jointVelMap.get(j.id)[1] * velUnitConversion).toString());
        // row.push(this.roundToHundredThousandth(ICSolver.jointVelMap.get(j.id)[0] * unitConversion));
        // row.push(this.roundToHundredThousandth(ICSolver.jointVelMap.get(j.id)[1] * unitConversion));
      });
      row.push(' ');
      this.SimulationLinks.forEach(l => {
        if (l instanceof ImagLink) {
          return;
        }
        row.push(Simulator.roundToHundredThousandth(ICSolver.linksAngVelMap.get(l.id)).toString());
      });
      kinematicAnalysis.push(row);
    });
    return kinematicAnalysis;
  }

  insertNewJointPos(tsl: TSLRow) {
    if (!this.tslJointIndexMap.has(this.SimulationJoints[0].id)) {
      this.SimulationJoints.forEach(j => {
        if (j instanceof ImagJoint) {
          return;
        }
        this.tslJointIndexMap.set(j.id, tsl.joints.findIndex(jt => jt.id === j.id));
      });
    }
    this.SimulationJoints.forEach(j => {
      if (j instanceof ImagJoint) {
        return;
      }
      j.x = tsl.joints[this.tslJointIndexMap.get(j.id)].x;
      j.y = tsl.joints[this.tslJointIndexMap.get(j.id)].y;
    });
    for (let link_index = 0; link_index < this.SimulationLinks.length; link_index++) {
      if (this.SimulationLinks[link_index] instanceof ImagLink) {
        return;
      }
      const link = this.SimulationLinks[link_index] as RealLink;
      link.CoM_x = tsl.links[link_index].CoM_x;
      link.CoM_y = tsl.links[link_index].CoM_y;
      this.SimulationLinks[link_index] = link;
      // const joint1 = link.joints[0];
      // const joint2 = link.joints[1];
      // const boundary_angle = Math.atan2(joint2.y - joint1.y, joint2.x - joint1.x);
      // link.CoM_x = Math.cos(link.internal_CoM_angle + boundary_angle) * link.internal_distance;
      // link.CoM_y = Math.cos(link.internal_CoM_angle + boundary_angle) * link.internal_distance;
    }
    for (let force_index = 0; force_index < this.SimulationForces.length; force_index++) {
      let link_index: number;
      const force_id = this.SimulationForces[force_index].id;
      const link_id = this.SimulationForces[force_index].onlink;
      link_index = this.SimulationLinks.findIndex(l => l.id === link_id);
      const link = this.SimulationLinks[link_index] as RealLink;
      const curr_force_index = link.forces.findIndex(f => f.id === force_id);
      link.forces[curr_force_index].sx = tsl.forces[curr_force_index].startx;
      link.forces[curr_force_index].sy = tsl.forces[curr_force_index].starty;
      // link.forces[curr_force_index].xLocOfForceOnLink = tsl.forces[curr_force_index].startx;
      // link.forces[curr_force_index].yLocOfForceOnLink = tsl.forces[curr_force_index].starty;
      this.SimulationLinks[link_index] = link;
    }
  }

  private insertNewICPos(tsl: TSLRow) {
    if (!this.tslICIndexMap.has(this.SimulationInstantCenters[0].id)) {
      this.SimulationInstantCenters.forEach(ic => {
        this.tslICIndexMap.set(ic.id, tsl.instant_centers.findIndex(desired_ic => desired_ic.id === ic.id));
      });
    }
    this.SimulationInstantCenters.forEach(ic => {
      ic.x = tsl.instant_centers[this.tslICIndexMap.get(ic.id)].x;
      ic.y = tsl.instant_centers[this.tslICIndexMap.get(ic.id)].y;
    });
  }

  // private determineICs() {
  private createICs() {
    // this.determineIC(this.SimulationJoints, this.SimulationLinks, requiredLoops);
    ICSolver.resetVariables();
    const ICLoops = [];
    this.requiredLoops.forEach(loop => ICLoops.push(loop + loop[1]));
    // return ICSolver.findICs(this.SimulationJoints, this.SimulationLinks, this.requiredLoops);
    return ICSolver.createICs(this.SimulationJoints, this.SimulationLinks, ICLoops);
  }

  determineICPositions(simICs, simJoints) {
    // const secondary_ics = [];
    // this.posTSL.TSL.forEach(tsl => {
    //   this.insertNewJointPos(tsl);
    //   const secondary_ic = ICSolver.determineICPos(this.SimulationInstantCenters, this.SimulationJoints);
    //   secondary_ics.push(secondary_ic);
    // });
    // return secondary_ics;
    const sec_ics = ICSolver.determineICPos(simICs, simJoints);
    const ics = [];
    simICs.forEach(ic => {
      ics.push(ic);
    });
    sec_ics.forEach(ic => {
      ics.push(ic);
    });
    return ics;
    // return ICSolver.determineICPos(simICs, simJoints);
  }

  private determineLinkCoMLocations(SimulationLinks: Link[]) {
    // for (let l_index = 0; l_index < SimulationLinks.length; l_index++) {
    //   if (SimulationLinks[l_index] instanceof ImagLink) {
    //   // if (SimulationLinks[l_index] instanceof ImagLink || SimulationLinks[l_index].joints.length <= 2) {
    //     continue;
    //   }
    //   const curr_link = SimulationLinks[l_index] as RealLink;
    //   const firstJoint = curr_link.joints[0];
    //   const secondJoint = curr_link.joints[1];
    //   const rotation = SVGFuncs.getLinkRotation(firstJoint, secondJoint);
    // this is the alternative logic for the tracer joint...
    // for (let j_index = 2; j_index < curr_link.joints.length; j_index++) {
    //   const joint = curr_link.joints[j_index];
    //   const newCoords = SVGFuncs.getPointByRelativeCoord(firstJoint, rotation, new Coord(joint.x, joint.y));
    //   SimulationLinks[l_index].joints[j_index].x = newCoords.x;
    //   SimulationLinks[l_index].joints[j_index].y = newCoords.y;
    // }
    // logic for determining center of mass
    // const newCoM = SVGFuncs.getPointByRelativeCoord(firstJoint, rotation, new Coord(curr_link.CoM_x, curr_link.CoM_y));
    // curr_link.CoM_x = newCoM.x;
    // curr_link.CoM_y = newCoM.y;
    // }

    // let r1, r2, r3, internal_angle: number;
    // if (!this.internalTriangleSimLinkMap.has(curr_link.id)) {
    //   const a_x = lastJoint.xInitial;
    //   const a_y = lastJoint.yInitial;
    //   const b_x = joint_with_neighboring_ground.xInitial;
    //   const b_y = joint_with_neighboring_ground.yInitial;
    //   const c_x = curr_link.CoM_x;
    //   const c_y = curr_link.CoM_y;
    //   const m = Math.atan2(b_y - a_y, b_x - a_x);
    //   const b = a_y - (m * a_x);
    //   r1 = Math.sqrt(Math.pow(c_x - a_x, 2) + Math.pow(c_y - a_y, 2));
    //   r2 = Math.sqrt(Math.pow(c_x - b_x, 2) + Math.pow(c_y - b_y, 2));
    //   r3 = Math.sqrt(Math.pow(a_x - b_x, 2) + Math.pow(a_y - b_y, 2));
    //   internal_angle = Math.acos((Math.pow(r1, 2) + Math.pow(r3, 2) - Math.pow(r2, 2)) / (2 * r1 * r3));
    //   if (isNaN(internal_angle)) {
    //     const angle1 = Math.atan2(c_y - a_y, c_x - a_x);
    //     internal_angle = Math.abs(angle1 - m);
    //   }
    //   this.internalTriangleSimLinkMap.set(curr_link.id, [r1, internal_angle]);
    // }
    //   const x1 = lastJoint.x;
    //   const y1 = lastJoint.y;
    //   const x2 = joint_with_neighboring_ground.x;
    //   const y2 = joint_with_neighboring_ground.y;
    //   r1 = this.internalTriangleSimLinkMap.get(curr_link.id)[0];
    //   internal_angle = this.internalTriangleSimLinkMap.get(curr_link.id)[1];
    //   const angle = Math.atan2(y2 - y1, x2 - x1);
    //   let x_calc1: number;
    //   let y_calc1: number;
    //   let x_calc2: number;
    //   let y_calc2: number;
    //   if (x1 > x2) { // A to the right of B
    //     if (y1 > y2) { // A on top of B (good)
    //       x_calc1 = x1 + r1 * Math.cos((Math.PI) + (internal_angle + (Math.PI + angle)));
    //       y_calc1 = y1 + r1 * Math.sin((Math.PI) + (internal_angle + (Math.PI + angle)));
    //       x_calc2 = x1 + r1 * Math.cos((Math.PI) - (internal_angle - (Math.PI + angle)));
    //       y_calc2 = y1 + r1 * Math.sin((Math.PI) - (internal_angle - (Math.PI + angle)));
    //     } else { // A below B (good)
    //       x_calc1 = x1 + r1 * Math.cos((Math.PI) + (internal_angle - (Math.PI - angle)));
    //       y_calc1 = y1 + r1 * Math.sin((Math.PI) + (internal_angle - (Math.PI - angle)));
    //       x_calc2 = x1 + r1 * Math.cos(Math.PI - (internal_angle + (Math.PI - angle)));
    //       y_calc2 = y1 + r1 * Math.sin(Math.PI - (internal_angle + (Math.PI - angle)));
    //     }
    //   } else { // A to the left of B
    //     if (y1 > y2) { // A on top of B (good)
    //       x_calc1 = x1 + r1 * Math.cos((2 * Math.PI) - (Math.abs(angle) + internal_angle));
    //       y_calc1 = y1 + r1 * Math.sin((2 * Math.PI) - (Math.abs(angle) + internal_angle));
    //       x_calc2 = x1 + r1 * Math.cos(internal_angle - Math.abs(angle));
    //       y_calc2 = y1 + r1 * Math.sin(internal_angle - Math.abs(angle));
    //     } else { // A below B (good)
    //       x_calc1 = x1 + r1 * Math.cos((2 * Math.PI) - (angle - internal_angle));
    //       y_calc1 = y1 + r1 * Math.sin(angle - internal_angle);
    //       x_calc2 = x1 + r1 * Math.cos(internal_angle + angle);
    //       y_calc2 = y1 + r1 * Math.sin(internal_angle + angle);
    //     }
    //     const prevJoint_x = curr_link.CoM_x;
    //     const prevJoint_y = curr_link.CoM_y;
    //     const dist1 = Math.abs(Math.pow(x_calc1 - prevJoint_x, 2) + Math.pow(y_calc1 - prevJoint_y, 2));
    //     const dist2 = Math.abs(Math.pow(x_calc2 - prevJoint_x, 2) + Math.pow(y_calc2 - prevJoint_y, 2));
    //     if (dist1 < dist2) {
    //       curr_link.CoM_x = x_calc1;
    //       curr_link.CoM_y = y_calc1;
    //     } else {
    //       curr_link.CoM_x = x_calc2;
    //       curr_link.CoM_y = y_calc2;
    //     }
    //     break;
    //   }
    // }
    for (let l_index = 0; l_index < SimulationLinks.length; l_index++) {
      if (SimulationLinks[l_index] instanceof ImagLink) {
        continue;
      }
      const curr_link = SimulationLinks[l_index] as RealLink;
      const lastJoint = SimulationLinks[l_index].joints[0];
      const joint_with_neighboring_ground = SimulationLinks[l_index].joints[1];
      let r1, r2, r3, internal_angle: number;
      if (!this.internalTriangleSimLinkMap.has(curr_link.id)) {
        const a_x = lastJoint.xInitial;
        const a_y = lastJoint.yInitial;
        const b_x = joint_with_neighboring_ground.xInitial;
        const b_y = joint_with_neighboring_ground.yInitial;
        const c_x = curr_link.CoM_x;
        const c_y = curr_link.CoM_y;
        const m = Math.atan2(b_y - a_y, b_x - a_x);
        const b = a_y - (m * a_x);
        r1 = Math.sqrt(Math.pow(c_x - a_x, 2) + Math.pow(c_y - a_y, 2));
        r2 = Math.sqrt(Math.pow(c_x - b_x, 2) + Math.pow(c_y - b_y, 2));
        r3 = Math.sqrt(Math.pow(a_x - b_x, 2) + Math.pow(a_y - b_y, 2));
        internal_angle = Math.acos((Math.pow(r1, 2) + Math.pow(r3, 2) - Math.pow(r2, 2)) / (2 * r1 * r3));
        if (isNaN(internal_angle)) {
          const angle1 = Math.atan2(c_y - a_y, c_x - a_x);
          internal_angle = Math.abs(angle1 - m);
        }
        this.internalTriangleSimLinkMap.set(curr_link.id, [r1, internal_angle]);
      }
      const x1 = lastJoint.x;
      const y1 = lastJoint.y;
      const x2 = joint_with_neighboring_ground.x;
      const y2 = joint_with_neighboring_ground.y;
      r1 = this.internalTriangleSimLinkMap.get(curr_link.id)[0];
      internal_angle = this.internalTriangleSimLinkMap.get(curr_link.id)[1];
      const angle = Math.atan2(y2 - y1, x2 - x1);
      let x_calc1: number;
      let y_calc1: number;
      let x_calc2: number;
      let y_calc2: number;
      if (x1 > x2) { // A to the right of B
        if (y1 > y2) { // A on top of B (good)
          x_calc1 = x1 + r1 * Math.cos((Math.PI) + (internal_angle + (Math.PI + angle)));
          y_calc1 = y1 + r1 * Math.sin((Math.PI) + (internal_angle + (Math.PI + angle)));
          x_calc2 = x1 + r1 * Math.cos((Math.PI) - (internal_angle - (Math.PI + angle)));
          y_calc2 = y1 + r1 * Math.sin((Math.PI) - (internal_angle - (Math.PI + angle)));
        } else { // A below B (good)
          x_calc1 = x1 + r1 * Math.cos((Math.PI) + (internal_angle - (Math.PI - angle)));
          y_calc1 = y1 + r1 * Math.sin((Math.PI) + (internal_angle - (Math.PI - angle)));
          x_calc2 = x1 + r1 * Math.cos(Math.PI - (internal_angle + (Math.PI - angle)));
          y_calc2 = y1 + r1 * Math.sin(Math.PI - (internal_angle + (Math.PI - angle)));
        }
      } else { // A to the left of B
        if (y1 > y2) { // A on top of B (good)
          x_calc1 = x1 + r1 * Math.cos((2 * Math.PI) - (Math.abs(angle) + internal_angle));
          y_calc1 = y1 + r1 * Math.sin((2 * Math.PI) - (Math.abs(angle) + internal_angle));
          x_calc2 = x1 + r1 * Math.cos(internal_angle - Math.abs(angle));
          y_calc2 = y1 + r1 * Math.sin(internal_angle - Math.abs(angle));
        } else { // A below B (good)
          x_calc1 = x1 + r1 * Math.cos((2 * Math.PI) - (angle - internal_angle));
          y_calc1 = y1 + r1 * Math.sin(angle - internal_angle);
          x_calc2 = x1 + r1 * Math.cos(internal_angle + angle);
          y_calc2 = y1 + r1 * Math.sin(internal_angle + angle);
        }
      }
      const prevJoint_x = curr_link.CoM_x;
      const prevJoint_y = curr_link.CoM_y;
      const dist1 = Math.abs(Math.pow(x_calc1 - prevJoint_x, 2) + Math.pow(y_calc1 - prevJoint_y, 2));
      const dist2 = Math.abs(Math.pow(x_calc2 - prevJoint_x, 2) + Math.pow(y_calc2 - prevJoint_y, 2));
      if (dist1 < dist2) {
        curr_link.CoM_x = x_calc1;
        curr_link.CoM_y = y_calc1;
      } else {
        curr_link.CoM_x = x_calc2;
        curr_link.CoM_y = y_calc2;
      }
      SimulationLinks[l_index] = curr_link;
      // break;
    }
  }
}
