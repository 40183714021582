// Functions related to the creation of SVG's

// import { stringifyElement } from '@angular/platform-browser/testing/src/browser_util';
import {AppConstants} from '../classes/AppConstants';
import {Coord} from 'src/classes/Coord';
import psip from '../functions/point-in-svg-polygon.js';
import {BoundDirectivePropertyAst} from '@angular/compiler';

export enum Shape {
  line = 'line',
  bar = 'bar',
  eTriangle = 'eTriangle',
  rTriangle = 'rTriangle',
  rectangle = 'rectangle',
  square = 'square',
  circle = 'circle',
  cShape = 'cShape',
  tShape = 'tShape',
  lShape = 'lShape',
  horizontalLine = 'horizontalLine',
  verticalLine = 'verticalLine',
  slantedLineForward = 'slantedLineForward',
  slantedLineBackward = 'slantedLineBackward',
  beanShape = 'beanShape',
  infinityShape = 'infinityShape',
  eightShape = 'eightShape',
  customShape = 'customShape'
}

export interface Bounds {
  b1: Coord;
  b2: Coord;
  b3: Coord;
  b4: Coord;
  arrow: Coord;
}

export enum editorID {
  b1 = 'b1',
  b2 = 'b2',
  b3 = 'b3',
  b4 = 'b4',
  arrow = 'arrow'
}

export interface PaddingOptions {
  offset?: {
    b1?: Coord,
    b2?: Coord,
    b3?: Coord,
    b4?: Coord,
    arrow?: Coord,
  };
  padding?: number;
}

export const SVGSettings = {
  // radius of the joint
  jointRadius: 5,
  // radius of the rounded corner for link shapes
  cornerRadius: 5,
  // width of arm of C, T, L shape relative to width of the entire shape
  widthRatio: 1 / 5
};

class SVGFuncColle {
  // Create a circle object on the canvas at the given X and Y
  createJoint(id: string, x: number, y: number) {
    const SVGHolder = this.createSVGHolder(x, y, 10, 10);
    const jointSize = SVGSettings.jointRadius * AppConstants.scaleFactor;
    const jointSVG = this.createSVGCircle(
      'joint_' + id,
      'joint',
      '0%', '0%',
      jointSize.toString(),
      'gray',
      '#00000000',
      (jointSize * 2).toString()
    );
    const jointRingSVG = this.createSVGCircle(
      'joint_' + id + '_ring',
      'joint',
      '0%', '0%',
      (jointSize / 5 * 7).toString(),
      'black'
    );
    SVGHolder.append(jointRingSVG);
    SVGHolder.append(jointSVG);
    SVGHolder.setAttribute('cursor', 'move');
    return SVGHolder;
  }

  // create circle on canvas
  createThreePosition(id: string, x: number, y: number) {
    const SVGHolder = this.createSVGHolder(x, y, 10, 10);
    const circleSVG = document.createElementNS('http://www.w3.org/2000/svg', 'circle');
    circleSVG.setAttributeNS(undefined, 'id', id);
    circleSVG.setAttributeNS(undefined, 'cx', '0%');
    circleSVG.setAttributeNS(undefined, 'cy', '0%');
    circleSVG.setAttributeNS(undefined, 'r', '0.2');
    circleSVG.setAttributeNS(undefined, 'fill', 'green');
    SVGHolder.appendChild(circleSVG);
    SVGHolder.setAttribute('cursor', 'move');
    return SVGHolder;
  }

  // create circle on canvas
  createPathPoint(id: string, x: number, y: number) {
    const SVGHolder = this.createSVGHolder(x, y, 10, 10);
    const circleSVG = document.createElementNS('http://www.w3.org/2000/svg', 'circle');
    circleSVG.setAttributeNS(undefined, 'id', id);
    circleSVG.setAttributeNS(undefined, 'cx', '0%');
    circleSVG.setAttributeNS(undefined, 'cy', '0%');
    circleSVG.setAttributeNS(undefined, 'r', '0.2');
    circleSVG.setAttributeNS(undefined, 'fill', 'red');
    SVGHolder.appendChild(circleSVG);
    SVGHolder.setAttribute('cursor', 'move');
    return SVGHolder;
  }

  // Create a circle object on the canvas at the given X and Y
  createForceEndpoint(id: string, x: number, y: number) {
    const SVGHolder = this.createSVGHolder(x, y, 10, 10);
    const jointSize = SVGSettings.jointRadius * AppConstants.scaleFactor;
    const jointSVG = this.createSVGCircle(
      id,
      'force',
      '0%', '0%',
      jointSize.toString(),
      'blue'
    );
    SVGHolder.append(jointSVG);
    return SVGHolder;
  }

  createShape(id: string, bounds: Bounds, shape: Shape, color?: string): SVGElement {
    const classname = 'link';
    const fill = color || '#' + (0x1000000 + (Math.random()) * 0xffffff).toString(16).substr(1, 6);
    let r = SVGSettings.cornerRadius * 2 * AppConstants.scaleFactor;
    const points = this.getPointsFromBounds(bounds, shape);
    if (shape === Shape.circle) {
      const xdiff = points[1].x - points[0].x;
      const ydiff = points[1].y - points[0].y;
      r = Math.sqrt(xdiff * xdiff + ydiff * ydiff) / 2;
    }
    return this.createSVGPath(id, classname, points, fill, r);
  }

  createBounds(id: string, bounds: Bounds): SVGElement {
    const classname = 'bounds';
    const pointsBound = [bounds.b1, bounds.b2, bounds.b3, bounds.b4];
    return this.createSVGPoly(id, classname, pointsBound, 'none', 'stroke:purple;stroke-width:' + AppConstants.scaleFactor);
  }

  // Create a circle object on the canvas at the given X and Y
  createEditor(id: string, x: number, y: number) {
    const SVGHolder = this.createSVGHolder(x, y, 5, 5);
    const classname = 'editor';
    const cx = '0%';
    const cy = '0%';
    const r = (5 * AppConstants.scaleFactor).toString();
    const fill = 'white';
    const stroke = 'black';
    const strokewidth = (AppConstants.scaleFactor).toString();
    const editorSVG = this.createSVGCircle(id, classname, cx, cy, r, fill, stroke, strokewidth);
    SVGHolder.append(editorSVG);
    return SVGHolder;
  }

  createEditorArrow(id: string, x: number, y: number) {
    const SVGHolder = this.createSVGHolder(x, y, 5, 5);
    const classname = 'editor';
    const cx = '0%';
    const cy = '0%';
    const r = (5 * AppConstants.scaleFactor).toString();
    const fill = 'white';
    const stroke = 'black';
    const strokewidth = (AppConstants.scaleFactor).toString();
    const editorSVG = this.createSVGRotateCircle(id, classname, cx, cy, r, fill, stroke, strokewidth);
    SVGHolder.append(editorSVG);
    return SVGHolder;
  }

  getBounds(refCoord1: Coord, refCoord2: Coord, shape: Shape): Bounds {
    let bounds: Bounds;
    switch (shape) {
      case Shape.line: {
        bounds = this.getLineBounds(refCoord1, refCoord2);
        break;
      }
      case Shape.bar: {
        bounds = this.getRectBoundsByRatio(refCoord1, refCoord2, 0);
        bounds = this.applyPadding(refCoord1, refCoord2, bounds,
          {
            padding: SVGSettings.jointRadius * 2
          }
        );
        break;
      }
      case Shape.eTriangle: {
        const angle = Math.PI / 6;
        bounds = this.getRectBoundsByRatio(refCoord1, refCoord2, Math.cos(angle));
        const leftRightPad = SVGSettings.jointRadius * 2 / Math.tan(angle);
        const topBotPad = leftRightPad / 2 * Math.sqrt(3);
        bounds = this.applyPadding(refCoord1, refCoord2, bounds,
          {
            offset: {
              b1: { x: -leftRightPad, y: SVGSettings.jointRadius * 2 },
              b2: { x: leftRightPad, y: SVGSettings.jointRadius * 2 },
              b3: { x: leftRightPad, y: SVGSettings.jointRadius * 2 - topBotPad * 2 },
              b4: { x: -leftRightPad, y: SVGSettings.jointRadius * 2 - topBotPad * 2 }
            }
          }
        );
        break;
      }
      case Shape.rTriangle: {
        const angle = Math.PI / 8;
        bounds = this.getRectBoundsByRatio(refCoord1, refCoord2, 1);
        const leftRightPad = SVGSettings.jointRadius * 2 / Math.tan(angle);
        bounds = this.applyPadding(refCoord1, refCoord2, bounds,
          {
            offset: {
              b1: { x: -SVGSettings.jointRadius * 2, y: SVGSettings.jointRadius * 2 },
              b2: { x: leftRightPad, y: SVGSettings.jointRadius * 2 },
              b3: { x: leftRightPad, y: -leftRightPad },
              b4: { x: -SVGSettings.jointRadius * 2, y: -leftRightPad }
            }
          }
        );
        break;
      }
      case Shape.rectangle: {
        bounds = this.getRectBoundsByRatio(refCoord1, refCoord2, 1 / 2);
        bounds = this.applyPadding(refCoord1, refCoord2, bounds, { padding: SVGSettings.jointRadius * 2 });
        break;
      }
      case Shape.square: {
        bounds = this.getRectBoundsByRatio(refCoord1, refCoord2, 1);
        bounds = this.applyPadding(refCoord1, refCoord2, bounds, { padding: SVGSettings.jointRadius * 2 });
        break;
      }
      case Shape.circle: {
        bounds = this.getRectBoundsByRatio(refCoord1, refCoord2, 1);
        const dx = bounds.b2.x - bounds.b1.x;
        const dy = bounds.b2.y - bounds.b1.y;
        const r = Math.sqrt(dx * dx + dy * dy) / AppConstants.scaleFactor;
        bounds = this.applyPadding(refCoord1, refCoord2, bounds,
          {
            offset: {
              b1: { x: -r, y: r },
              b2: { x: 0, y: r },
              b3: { x: 0, y: 0 },
              b4: { x: -r, y: 0 }
            },
            padding: SVGSettings.jointRadius * 2
          }
        );
        break;
      }
      case Shape.cShape: {
        bounds = this.getRectBoundsByRatio(refCoord1, refCoord2, 2 / 3);
        bounds = this.applyPadding(refCoord1, refCoord2, bounds, { padding: SVGSettings.jointRadius * 2 });
        break;
      }
      case Shape.tShape: {
        bounds = this.getRectBoundsByRatio(refCoord1, refCoord2, 1);
        bounds = this.applyPadding(refCoord1, refCoord2, bounds, { padding: SVGSettings.jointRadius * 2 });
        break;
      }
      case Shape.lShape: {
        bounds = this.getRectBoundsByRatio(refCoord1, refCoord2, 2 / 3);
        bounds = this.applyPadding(refCoord1, refCoord2, bounds, { padding: SVGSettings.jointRadius * 2 });
        break;
      }
      case Shape.horizontalLine: {
        bounds = this.getRectBoundsByRatio(refCoord1, refCoord2, 0);
        bounds = this.applyPadding(refCoord1, refCoord2, bounds, { padding: SVGSettings.jointRadius * 2 });
        break;
      }
      case Shape.verticalLine: {
        bounds = this.getRectBoundsByRatio(refCoord1, refCoord2, 0);
        bounds = this.applyPadding(refCoord1, refCoord2, bounds, { padding: SVGSettings.jointRadius * 2 });
        break;
      }
      case Shape.slantedLineForward: {
        bounds = this.getRectBoundsByRatio(refCoord1, refCoord2, 0);
        bounds = this.applyPadding(refCoord1, refCoord2, bounds, { padding: SVGSettings.jointRadius * 2 });
        break;
      }
      case Shape.slantedLineBackward: {
        bounds = this.getRectBoundsByRatio(refCoord1, refCoord2, 0);
        bounds = this.applyPadding(refCoord1, refCoord2, bounds, { padding: SVGSettings.jointRadius * 2 });
        break;
      }
      case Shape.beanShape: {
        bounds = this.getRectBoundsByRatio(refCoord1, refCoord2, 1);
        bounds = this.applyPadding(refCoord1, refCoord2, bounds, { padding: SVGSettings.jointRadius * 2 });
        break;
      }
      case Shape.eightShape: {
        bounds = this.getRectBoundsByRatio(refCoord1, refCoord2, 1);
        bounds = this.applyPadding(refCoord1, refCoord2, bounds, { padding: SVGSettings.jointRadius * 2 });
        break;
      }
      case Shape.infinityShape: {
        bounds = this.getRectBoundsByRatio(refCoord1, refCoord2, 1);
        bounds = this.applyPadding(refCoord1, refCoord2, bounds, { padding: SVGSettings.jointRadius * 2 });
        break;
      }
      case Shape.customShape: {
        bounds = this.getRectBoundsByRatio(refCoord1, refCoord2, 1);
        bounds = this.applyPadding(refCoord1, refCoord2, bounds, { padding: SVGSettings.jointRadius * 2 });
        break;
      }
    }
    return bounds;
  }

  getRectBoundsByRatio(refCoord1: Coord, refCoord2: Coord, ratio: number) {
    const x1 = refCoord1.x;
    const y1 = refCoord1.y;
    const x2 = refCoord2.x;
    const y2 = refCoord2.y;
    const dx = x2 - x1;
    const dy = y2 - y1;
    const rotation = Math.atan2(dy, dx);

    const dist = Math.sqrt(dx * dx + dy * dy);
    const distBound = ratio * dist; // Math.cos(Math.PI / 6) * dist;
    const xChangeBound = Math.sin(rotation) * distBound;
    const yChangeBound = Math.cos(rotation) * distBound;

    return {
      b1: {x: x1, y: y1},
      b2: {x: x2, y: y2},
      b3: {x: x2 + xChangeBound, y: y2 - yChangeBound},
      b4: {x: x1 + xChangeBound, y: y1 - yChangeBound},
      arrow: {x: 1, y: 1}
    };
  }


  getLineBounds(refCoord1: Coord, refCoord2: Coord): Bounds {
    const x1 = refCoord1.x;
    const y1 = refCoord1.y;
    const x2 = refCoord2.x;
    const y2 = refCoord2.y;
    const dx = x2 - x1;
    const dy = y2 - y1;
    const rotation = Math.atan2(dy, dx);

    const dist = Math.sqrt(dx * dx + dy * dy);
    const xChange = Math.cos(rotation) * dist;

    return {
      b1: {x: x1 + xChange, y: y1},
      b2: {x: x1, y: y1},
      b3: {x: x2 - xChange, y: y2},
      b4: {x: x2, y: y2},
      arrow: {x: 1, y: 1}
    };
  }


  getPointsFromBounds(bounds: Bounds, shape: Shape): Coord[] {
    let points: Coord[];
    switch (shape) {
      // condition when the shape is a link shape
      case Shape.line: {
        points = this.linePointsFromBounds(bounds);
        break;
      }
      case Shape.bar: {
        points = this.rectanglePointsFromBounds(bounds);
        break;
      }
      case Shape.eTriangle: {
        points = this.eTrianglePointsFromBounds(bounds);
        break;
      }
      case Shape.rTriangle: {
        points = this.rTrianglePointsFromBounds(bounds);
        break;
      }
      case Shape.rectangle: {
        points = this.rectanglePointsFromBounds(bounds);
        break;
      }
      case Shape.square: {
        points = this.rectanglePointsFromBounds(bounds);
        break;
      }
      case Shape.circle: {
        points = this.rectanglePointsFromBounds(bounds);
        break;
      }
      case Shape.cShape: {
        points = this.cShapePointsFromBounds(bounds);
        break;
      }
      case Shape.tShape: {
        points = this.tShapePointsFromBounds(bounds);
        break;
      }
      case Shape.lShape: {
        points = this.lShapePointsFromBounds(bounds);
        break;
      }
      // condition when the shape is a path_point
      case Shape.horizontalLine: {
        points = this.rectanglePointsFromBounds(bounds);
        // points = this.horizontalLineShapeFromBounds(bounds);
        break;
      }
      case Shape.verticalLine: {
        points = this.rectanglePointsFromBounds(bounds);
        // points = this.verticalLineShapeFromBounds(bounds);
        break;
      }
      case Shape.slantedLineForward: {
        // points = this.slantedForwardLineShapeFromBounds(bounds);
        // points = this.linePointsFromBounds(bounds);
        points = this.rectanglePointsFromBounds(bounds);
        break;
      }
      case Shape.slantedLineBackward: {
        points = this.rectanglePointsFromBounds(bounds);
        break;
      }
      case Shape.beanShape: {
        points = this.rectanglePointsFromBounds(bounds);
        break;
      }
      case Shape.infinityShape: {
        // points = this.eTrianglePointsFromBounds(bounds);
        points = this.infinityShapeFromBounds(bounds);
        break;
      }
      case Shape.eightShape: {
        points = this.rectanglePointsFromBounds(bounds);
        break;
      }
      case Shape.customShape: {
        points = this.customShapeFromBounds(bounds);
        break;
      }
    }
    return points;
  }


  eTrianglePointsFromBounds(bounds: Bounds): Coord[] {
    const b1 = bounds.b1;
    const b2 = bounds.b2;
    const b3 = bounds.b3;
    const b4 = bounds.b4;
    const p3 = { x: (b3.x - b4.x) / 2 + b4.x, y: (b3.y - b4.y) / 2 + b4.y };
    return [b1, b2, p3];
  }

  rTrianglePointsFromBounds(bounds: Bounds): Coord[] {
    const b1 = bounds.b1;
    const b2 = bounds.b2;
    const b4 = bounds.b4;
    return [b1, b2, b4];
  }

  rectanglePointsFromBounds(bounds: Bounds): Coord[] {
    return [bounds.b1, bounds.b2, bounds.b3, bounds.b4];
  }

  cShapePointsFromBounds(bounds: Bounds): Coord[] {
    const widthRatio = 1 / SVGSettings.widthRatio;
    const dx = bounds.b2.x - bounds.b1.x;
    const dy = bounds.b2.y - bounds.b1.y;
    const angle = Math.atan2(dy, dx);
    const width = Math.sqrt(dx * dx + dy * dy);
    const cx = Math.cos(angle) * width / widthRatio;
    const cy = Math.sin(angle) * width / widthRatio;
    const low2 = new Coord(bounds.b3.x - cx, bounds.b3.y - cy);
    const low1 = new Coord(bounds.b4.x + cx, bounds.b4.y + cy);
    const high2 = new Coord(bounds.b2.x - cx + cy, bounds.b2.y - cy - cx);
    const high1 = new Coord(bounds.b1.x + cx + cy, bounds.b1.y + cy - cx);
    return [bounds.b1, bounds.b2, bounds.b3, low2, high2, high1, low1, bounds.b4];
  }

  tShapePointsFromBounds(bounds: Bounds): Coord[] {
    const widthRatio = 1 / SVGSettings.widthRatio;
    const dx = bounds.b2.x - bounds.b1.x;
    const dy = bounds.b2.y - bounds.b1.y;
    const angle = Math.atan2(dy, dx);
    const width = Math.sqrt(dx * dx + dy * dy);
    const cx = Math.cos(angle) * width / widthRatio;
    const cy = Math.sin(angle) * width / widthRatio;
    const high4 = new Coord(bounds.b2.x + cy, bounds.b2.y - cx);
    const high3 = new Coord(high4.x - cx * (widthRatio - 1) / 2, high4.y - cy * (widthRatio - 1) / 2);
    const low2 = new Coord(bounds.b3.x - cx * (widthRatio - 1) / 2, bounds.b3.y - cy * (widthRatio - 1) / 2);
    const low1 = new Coord(bounds.b4.x + cx * (widthRatio - 1) / 2, bounds.b4.y + cy * (widthRatio - 1) / 2);
    const high1 = new Coord(bounds.b1.x + cy, bounds.b1.y - cx);
    const high2 = new Coord(high1.x + cx * (widthRatio - 1) / 2, high1.y + cy * (widthRatio - 1) / 2);
    return [bounds.b1, bounds.b2, high4, high3, low2, low1, high2, high1];
  }

  lShapePointsFromBounds(bounds: Bounds): Coord[] {
    const widthRatio = 1 / SVGSettings.widthRatio;
    const dx = bounds.b2.x - bounds.b1.x;
    const dy = bounds.b2.y - bounds.b1.y;
    const angle = Math.atan2(dy, dx);
    const width = Math.sqrt(dx * dx + dy * dy);
    const cx = Math.cos(angle) * width / widthRatio;
    const cy = Math.sin(angle) * width / widthRatio;
    const high2 = new Coord(bounds.b2.x + cy, bounds.b2.y - cx);
    const high1 = new Coord(high2.x - cx * (widthRatio - 1), high2.y - cy * (widthRatio - 1));
    const low1 = new Coord(bounds.b4.x + cx, bounds.b4.y + cy);
    return [bounds.b1, bounds.b2, high2, high1, low1, bounds.b4];
  }

  horizontalLineShapeFromBounds(bounds: Bounds): Coord[] {
    const b1_x = (bounds.b1.x + bounds.b2.x) / 2;
    const b1_y = (bounds.b1.y + bounds.b2.y) / 2;
    const b1 = new Coord (b1_x, b1_y);
    const b2_x = (bounds.b3.x + bounds.b4.x) / 2;
    const b2_y = (bounds.b3.y + bounds.b4.y) / 2;
    const b2 = new Coord (b2_x, b2_y);
    return [b1, b2];
    // const widthRatio = 1 / SVGSettings.widthRatio;
    // const dx = bounds.b2.x - bounds.b1.x;
    // const dy = bounds.b2.y - bounds.b1.y;
    // const angle = Math.atan2(dy, dx);
    // const width = Math.sqrt(dx * dx + dy * dy);
    // const cx = Math.cos(angle) * width / widthRatio;
    // const cy = Math.sin(angle) * width / widthRatio;
    // const high2 = new Coord(bounds.b2.x + cy, bounds.b2.y - cx);
    // const high1 = new Coord(high2.x - cx * (widthRatio - 1), high2.y - cy * (widthRatio - 1));
    // const low1 = new Coord(bounds.b4.x + cx, bounds.b4.y + cy);
    // return [bounds.b1, bounds.b2, high2, high1, low1, bounds.b4];
  }
  // verticalLineShapeFromBounds(bounds: Bounds): Coord[] {
    // const x_val = (bounds.b1.x + bounds.b2.x + bounds.b3.x + bounds.b4.x) / 4;
    // const b1_y = (bounds.b1.y + bounds.b2.y) / 2;
    // const b1 = new Coord (x_val, b1_y);
    // const b4_y = (bounds.b3.y + bounds.b4.y) / 2;
    // const b4 = new Coord (x_val, b4_y);
    // const y_diff = b4_y - b1_y;
    // const b2 = new Coord(x_val, b1_y + (y_diff / 3));
    // const b3 = new Coord(x_val, b1_y + (2 * (y_diff / 3)));
    // return [b1, b2, b3, b4];
    // const widthRatio = 1 / SVGSettings.widthRatio;
    // const dx = bounds.b2.x - bounds.b1.x;
    // const dy = bounds.b2.y - bounds.b1.y;
    // const angle = Math.atan2(dy, dx);
    // const width = Math.sqrt(dx * dx + dy * dy);
    // const cx = Math.cos(angle) * width / widthRatio;
    // const cy = Math.sin(angle) * width / widthRatio;
    // const high2 = new Coord(bounds.b2.x + cy, bounds.b2.y - cx);
    // const high1 = new Coord(high2.x - cx * (widthRatio - 1), high2.y - cy * (widthRatio - 1));
    // const low1 = new Coord(bounds.b4.x + cx, bounds.b4.y + cy);
    // return [bounds.b1, bounds.b2, high2, high1, low1, bounds.b4];
  // }
  slantedForwardLineShapeFromBounds(bounds: Bounds): Coord[] {
    const widthRatio = 1 / SVGSettings.widthRatio;
    const dx = bounds.b2.x - bounds.b1.x;
    const dy = bounds.b2.y - bounds.b1.y;
    const angle = Math.atan2(dy, dx);
    const width = Math.sqrt(dx * dx + dy * dy);
    const cx = Math.cos(angle) * width / widthRatio;
    const cy = Math.sin(angle) * width / widthRatio;
    const high2 = new Coord(bounds.b2.x + cy, bounds.b2.y - cx);
    const high1 = new Coord(high2.x - cx * (widthRatio - 1), high2.y - cy * (widthRatio - 1));
    const low1 = new Coord(bounds.b4.x + cx, bounds.b4.y + cy);
    return [bounds.b1, bounds.b2, high2, high1, low1, bounds.b4];
  }
  slantedBackwardLineShapeFromBounds(bounds: Bounds): Coord[] {
    const widthRatio = 1 / SVGSettings.widthRatio;
    const dx = bounds.b2.x - bounds.b1.x;
    const dy = bounds.b2.y - bounds.b1.y;
    const angle = Math.atan2(dy, dx);
    const width = Math.sqrt(dx * dx + dy * dy);
    const cx = Math.cos(angle) * width / widthRatio;
    const cy = Math.sin(angle) * width / widthRatio;
    const high2 = new Coord(bounds.b2.x + cy, bounds.b2.y - cx);
    const high1 = new Coord(high2.x - cx * (widthRatio - 1), high2.y - cy * (widthRatio - 1));
    const low1 = new Coord(bounds.b4.x + cx, bounds.b4.y + cy);
    return [bounds.b1, bounds.b2, high2, high1, low1, bounds.b4];
  }
  beanShapeFromBounds(bounds: Bounds): Coord[] {
    const widthRatio = 1 / SVGSettings.widthRatio;
    const dx = bounds.b2.x - bounds.b1.x;
    const dy = bounds.b2.y - bounds.b1.y;
    const angle = Math.atan2(dy, dx);
    const width = Math.sqrt(dx * dx + dy * dy);
    const cx = Math.cos(angle) * width / widthRatio;
    const cy = Math.sin(angle) * width / widthRatio;
    const high2 = new Coord(bounds.b2.x + cy, bounds.b2.y - cx);
    const high1 = new Coord(high2.x - cx * (widthRatio - 1), high2.y - cy * (widthRatio - 1));
    const low1 = new Coord(bounds.b4.x + cx, bounds.b4.y + cy);
    return [bounds.b1, bounds.b2, high2, high1, low1, bounds.b4];
  }
  infinityShapeFromBounds(bounds: Bounds): Coord[] {
    const widthRatio = 1 / SVGSettings.widthRatio;
    const dx = bounds.b2.x - bounds.b1.x;
    const dy = bounds.b2.y - bounds.b1.y;
    const angle = Math.atan2(dy, dx);
    const width = Math.sqrt(dx * dx + dy * dy);
    const cx = Math.cos(angle) * width / widthRatio;
    const cy = Math.sin(angle) * width / widthRatio;
    const high2 = new Coord(bounds.b2.x + cy, bounds.b2.y - cx);
    const high1 = new Coord(high2.x - cx * (widthRatio - 1), high2.y - cy * (widthRatio - 1));
    const low1 = new Coord(bounds.b4.x + cx, bounds.b4.y + cy);
    return [bounds.b1, bounds.b2, high2, high1, low1, bounds.b4];
  }
  eightShapeFromBounds(bounds: Bounds): Coord[] {
    const widthRatio = 1 / SVGSettings.widthRatio;
    const dx = bounds.b2.x - bounds.b1.x;
    const dy = bounds.b2.y - bounds.b1.y;
    const angle = Math.atan2(dy, dx);
    const width = Math.sqrt(dx * dx + dy * dy);
    const cx = Math.cos(angle) * width / widthRatio;
    const cy = Math.sin(angle) * width / widthRatio;
    const high2 = new Coord(bounds.b2.x + cy, bounds.b2.y - cx);
    const high1 = new Coord(high2.x - cx * (widthRatio - 1), high2.y - cy * (widthRatio - 1));
    const low1 = new Coord(bounds.b4.x + cx, bounds.b4.y + cy);
    return [bounds.b1, bounds.b2, high2, high1, low1, bounds.b4];
  }
  customShapeFromBounds(bounds: Bounds): Coord[] {
    const widthRatio = 1 / SVGSettings.widthRatio;
    const dx = bounds.b2.x - bounds.b1.x;
    const dy = bounds.b2.y - bounds.b1.y;
    const angle = Math.atan2(dy, dx);
    const width = Math.sqrt(dx * dx + dy * dy);
    const cx = Math.cos(angle) * width / widthRatio;
    const cy = Math.sin(angle) * width / widthRatio;
    const high2 = new Coord(bounds.b2.x + cy, bounds.b2.y - cx);
    const high1 = new Coord(high2.x - cx * (widthRatio - 1), high2.y - cy * (widthRatio - 1));
    const low1 = new Coord(bounds.b4.x + cx, bounds.b4.y + cy);
    return [bounds.b1, bounds.b2, high2, high1, low1, bounds.b4];
  }

  linePointsFromBounds(bounds: Bounds): Coord[] {
    const x1 = bounds.b4.x;
    const y1 = bounds.b4.y;
    const x2 = bounds.b2.x;
    const y2 = bounds.b2.y;
    const width = SVGSettings.jointRadius * 2 * AppConstants.scaleFactor;
    // Find angle of rotation for link
    const dx = x2 - x1;
    const dy = y2 - y1;
    const rotation = Math.atan2(dy, dx);

    // Use angle of rotation to calculate endpoint locations
    const xChange = Math.sin(rotation) * width;
    const yChange = Math.cos(rotation) * width;

    // Create endpoints of SVG's
    const p1 = { x: x1 - xChange - yChange, y: y1 + yChange - xChange };
    const p2 = { x: x1 + xChange - yChange, y: y1 - yChange - xChange };
    const p3 = { x: x2 + xChange + yChange, y: y2 - yChange + xChange };
    const p4 = { x: x2 - xChange + yChange, y: y2 + yChange + xChange };
    return [p1, p2, p3, p4];
  }


  applyPadding(refCoord1: Coord, refCoord2: Coord, bounds: Bounds, paddingOptions: PaddingOptions): Bounds {
    const originalRC = SVGFuncs.getBoundsRelativeCoords(refCoord1, refCoord2, bounds);
    if (paddingOptions.offset) {
      const keyArray = [editorID.b1, editorID.b2, editorID.b3, editorID.b4];
      keyArray.forEach(key => {
        const val = paddingOptions.offset[key];
        if (!val) { return; }
        originalRC[key].x += val.x * AppConstants.scaleFactor;
        originalRC[key].y += val.y * AppConstants.scaleFactor;
      });
    }
    if (paddingOptions.padding) {
      originalRC.b1.x -= paddingOptions.padding * AppConstants.scaleFactor;
      originalRC.b1.y += paddingOptions.padding * AppConstants.scaleFactor;
      originalRC.b2.x += paddingOptions.padding * AppConstants.scaleFactor;
      originalRC.b2.y += paddingOptions.padding * AppConstants.scaleFactor;
      originalRC.b3.x += paddingOptions.padding * AppConstants.scaleFactor;
      originalRC.b3.y -= paddingOptions.padding * AppConstants.scaleFactor;
      originalRC.b4.x -= paddingOptions.padding * AppConstants.scaleFactor;
      originalRC.b4.y -= paddingOptions.padding * AppConstants.scaleFactor;
    }
    return SVGFuncs.getBoundsByRelativeCoords(refCoord1, refCoord2, originalRC);
  }

  // use 1 editor (controlled by mouse) and the oldbounds, determine the new bounds
  // scale by the distance of editor vs. the center
  // rotate by editor compared to its old position vs. the center
  getNewBounds(newBound: Coord, eid: editorID, oldBounds: Bounds): Bounds {
    let b1n, b2n, b3n, b4n, arrow5n: {x: number, y: number};

    let drag_coord_x, side_coord_x_1, side_coord_x_2: number;
    let drag_coord_y, side_coord_y_1, side_coord_y_2: number;

    let arrow5n_x, arrow5n_y: number;

    let m1, closest_m, m2, m3, m4, m5: number;
    let b1, closest_b, b2, b3, b4, b5: number;

    const typeOfBoundToCoordMap = new Map<string, Coord>();
    let fixedBound: string;

    switch (Number(eid.substr(1))) {
      case 1:
        typeOfBoundToCoordMap.set('fixed', oldBounds.b3);
        fixedBound = 'b3';
        typeOfBoundToCoordMap.set('drag', oldBounds.b1);
        typeOfBoundToCoordMap.set('sideCoord1', oldBounds.b2);
        typeOfBoundToCoordMap.set('sideCoord2', oldBounds.b4);
        break;
      case 2:
        typeOfBoundToCoordMap.set('fixed', oldBounds.b4);
        fixedBound = 'b4';
        typeOfBoundToCoordMap.set('drag', oldBounds.b2);
        typeOfBoundToCoordMap.set('sideCoord1', oldBounds.b1);
        typeOfBoundToCoordMap.set('sideCoord2', oldBounds.b3);
        break;
      case 3:
        typeOfBoundToCoordMap.set('fixed', oldBounds.b1);
        fixedBound = 'b1';
        typeOfBoundToCoordMap.set('drag', oldBounds.b3);
        typeOfBoundToCoordMap.set('sideCoord1', oldBounds.b2);
        typeOfBoundToCoordMap.set('sideCoord2', oldBounds.b4);
        break;
      case 4:
        typeOfBoundToCoordMap.set('fixed', oldBounds.b2);
        fixedBound = 'b2';
        typeOfBoundToCoordMap.set('drag', oldBounds.b4);
        typeOfBoundToCoordMap.set('sideCoord1', oldBounds.b1);
        typeOfBoundToCoordMap.set('sideCoord2', oldBounds.b3);
        break;
      default:
        // this is an arrow
        // joint_tag_SVG.setAttribute('transform', 'rotate (180), scale(-1, 1)');
        // if ()
        // const prev_angle =
        // arrow5n_x = (oldBounds.b1.x + oldBounds.b2.x + oldBounds.b3.x + oldBounds.b4.x) / 4;
        // arrow5n_y = (oldBounds.b1.y + oldBounds.b2.y + oldBounds.b3.y + oldBounds.b4.y) / 4;
        // arrow5n = { x: arrow5n_x, y: arrow5n_y};
        // return { b1: oldBounds.b1, b2: oldBounds.b2, b3: oldBounds.b3, b4: oldBounds.b4, arrow: arrow5n };
        const centerx = (oldBounds.b1.x + oldBounds.b2.x + oldBounds.b3.x + oldBounds.b4.x) / 4;
        const centery = (oldBounds.b1.y + oldBounds.b2.y + oldBounds.b3.y + oldBounds.b4.y) / 4;

        // const dox = oldBounds[eid].x - centerx;
        // const doy = oldBounds[eid].y - centery;
        const dox = oldBounds['b1'].x - centerx;
        const doy = oldBounds['b1'].y - centery;
        // const disto = Math.sqrt(dox * dox + doy * doy);
        const orotation = Math.atan2(doy, dox);

        const dnx = newBound.x - centerx;
        const dny = newBound.y - centery;
        // const dnx = oldBounds['b1'].x - centerx;
        // const dny = oldBounds['b1'].y - centery;
        const distn = Math.sqrt(dox * dox + doy * doy);
        const nrotation = Math.atan2(dny, dnx);

        const drotation = nrotation - orotation;

        const d1x = oldBounds.b1.x - centerx;
        const d1y = oldBounds.b1.y - centery;
        const rot1 = Math.atan2(d1y, d1x);
        const xc1 = Math.cos(rot1 + drotation) * distn;
        const yc1 = Math.sin(rot1 + drotation) * distn;
        // b1n = { x: xc1, y: yc1};
        b1n = { x: centerx + xc1, y: centery + yc1 };

        const d2x = oldBounds.b2.x - centerx;
        const d2y = oldBounds.b2.y - centery;
        const rot2 = Math.atan2(d2y, d2x);
        const xc2 = Math.cos(rot2 + drotation) * distn;
        const yc2 = Math.sin(rot2 + drotation) * distn;
        // b2n = { x: xc2, y: yc2 };
        b2n = { x: centerx + xc2, y: centery + yc2 };

        const d3x = oldBounds.b3.x - centerx;
        const d3y = oldBounds.b3.y - centery;
        const rot3 = Math.atan2(d3y, d3x);
        const xc3 = Math.cos(rot3 + drotation) * distn;
        const yc3 = Math.sin(rot3 + drotation) * distn;
        // b3n = { x: xc3, y: yc3 };
        b3n = { x: centerx + xc3, y: centery + yc3 };

        const d4x = oldBounds.b4.x - centerx;
        const d4y = oldBounds.b4.y - centery;
        const rot4 = Math.atan2(d4y, d4x);
        const xc4 = Math.cos(rot4 + drotation) * distn;
        const yc4 = Math.sin(rot4 + drotation) * distn;
        // b4n = { x: xc4, y: yc4 };
        b4n = { x: centerx + xc4, y: centery + yc4 };

        // arrow5n_x = (b1n.x + b2n.x + b3n.x + b4n.x) / 4;
        // arrow5n_y = (b1n.y + b2n.y + b3n.y + b4n.y) / 4;
        arrow5n = { x: centerx, y: centery };

        return { b1: b1n, b2: b2n, b3: b3n, b4: b4n, arrow: arrow5n };
    }

    const fixedCoord = typeOfBoundToCoordMap.get('fixed');
    const dragCoord = typeOfBoundToCoordMap.get('drag');
    const sideCoord1 = typeOfBoundToCoordMap.get('sideCoord1');
    const sideCoord2 = typeOfBoundToCoordMap.get('sideCoord2');

    // determine line from b1 to b3
    m1 = this.determineSlope(fixedCoord.x, fixedCoord.y, dragCoord.x, dragCoord.y);
    b1 = this.determineYIntersect(fixedCoord.x, fixedCoord.y, m1);
    // determine the point within this line that is closest to where the mouse is
    closest_m = -1 * Math.pow(m1, -1);
    closest_b = this.determineYIntersect(newBound.x, newBound.y, closest_m);
    drag_coord_x = this.determineX(closest_m, closest_b, m1, b1);
    drag_coord_y = this.determineY(drag_coord_x, closest_m, closest_b);
    // determine the other 2 points
    m2 = this.determineSlope(fixedCoord.x, fixedCoord.y, sideCoord1.x, sideCoord1.y);
    b2 = this.determineYIntersect(fixedCoord.x, fixedCoord.y, m2);
    m3 = this.determineSlope(dragCoord.x, dragCoord.y, sideCoord1.x, sideCoord1.y);
    b3 = this.determineYIntersect(drag_coord_x, drag_coord_y, m3);
    side_coord_x_1 = this.determineX(m2, b2, m3, b3);
    side_coord_y_1 = this.determineY(side_coord_x_1, m2, b2);

    m4 = this.determineSlope(fixedCoord.x, fixedCoord.y, sideCoord2.x, sideCoord2.y);
    b4 = this.determineYIntersect(fixedCoord.x, fixedCoord.y, m4);
    m5 = this.determineSlope(dragCoord.x, dragCoord.y, sideCoord2.x, sideCoord2.y);
    b5 = this.determineYIntersect(drag_coord_x, drag_coord_y, m5);
    side_coord_x_2 = this.determineX(m4, b4, m5, b5);
    side_coord_y_2 = this.determineY(side_coord_x_2, m4, b4);

    switch (fixedBound) {
      case 'b1':
        b1n = { x: fixedCoord.x, y: fixedCoord.y};
        b2n = { x: side_coord_x_1, y: side_coord_y_1};
        b3n = { x: drag_coord_x, y: drag_coord_y};
        b4n = { x: side_coord_x_2, y: side_coord_y_2};
        break;
      case 'b2':
        b1n = { x: side_coord_x_1, y: side_coord_y_1};
        b2n = { x: fixedCoord.x, y: fixedCoord.y};
        b3n = { x: side_coord_x_2, y: side_coord_y_2};
        b4n = { x: drag_coord_x, y: drag_coord_y};
        break;
      case 'b3':
        b1n = { x: drag_coord_x, y: drag_coord_y};
        b2n = { x: side_coord_x_1, y: side_coord_y_1};
        b3n = { x: fixedCoord.x, y: fixedCoord.y};
        b4n = { x: side_coord_x_2, y: side_coord_y_2};
        break;
      case 'b4':
        b1n = { x: side_coord_x_1, y: side_coord_y_1};
        b2n = { x: drag_coord_x, y: drag_coord_y};
        b3n = { x: side_coord_x_2, y: side_coord_y_2};
        b4n = { x: fixedCoord.x, y: fixedCoord.y};
        break;
    }
    arrow5n_x = (b1n.x + b2n.x + b3n.x + b4n.x) / 4;
    arrow5n_y = (b1n.y + b2n.y + b3n.y + b4n.y) / 4;
    arrow5n = { x: arrow5n_x, y: arrow5n_y};
    return { b1: b1n, b2: b2n, b3: b3n, b4: b4n, arrow: arrow5n };
  }

  determineSlope(x1: number, y1: number, x2: number, y2: number) {
    return (y2 - y1) / (x2 - x1);
  }

  determineYIntersect(x: number, y: number, m: number) {
    return y - (m * x);
  }

  determineX(m1, b1, m2, b2) {
    return (b2 - b1) /  (m1 - m2);
  }

  determineY(x, m, b) {
    return (m * x) + b;
  }


  // @deprecated
  // given the coordinate and id of the dragging dot, construct the new bound
  // ratio stays the same
  getNewBoundsKeepRatio(newBound: Coord, eid: editorID, oldBounds: Bounds): Bounds {

    let oppositePointId: editorID;
    // mp1 is supposed to be the "horizontal" adjacent point
    let movePoint1Id: editorID;
    let movePoint2Id: editorID;

    switch (eid) {
      case editorID.b1: {
        oppositePointId = editorID.b3;
        movePoint1Id = editorID.b2;
        movePoint2Id = editorID.b4;
        break;
      }
      case editorID.b2: {
        oppositePointId = editorID.b4;
        movePoint1Id = editorID.b1;
        movePoint2Id = editorID.b3;
        break;
      }
      case editorID.b3: {
        oppositePointId = editorID.b1;
        movePoint1Id = editorID.b4;
        movePoint2Id = editorID.b2;
        break;
      }
      case editorID.b4: {
        oppositePointId = editorID.b2;
        movePoint1Id = editorID.b3;
        movePoint2Id = editorID.b1;
        break;
      }
    }
    // because we use scaling of original shape, original bounds has to be kept
    const oppositePoint = oldBounds[oppositePointId];
    const movePoint1 = oldBounds[movePoint1Id];

    /// revise the position of the dragging dot
    // so the ratio is kept the same
    const dox = oldBounds[eid].x - oppositePoint.x;
    const doy = oldBounds[eid].y - oppositePoint.y;

    const disto = Math.sqrt(dox * dox + doy * doy);

    const dnx = newBound.x - oppositePoint.x;
    const dny = newBound.y - oppositePoint.y;
    const distn = Math.sqrt(dnx * dnx + dny * dny);

    // the scale depends on mouse's distance vs. the opposite dot
    const scale = distn / disto;

    newBound.x = oppositePoint.x + dox * scale;
    newBound.y = oppositePoint.y + doy * scale;
    ///

    /// get the position of two other moving points
    const dx = oldBounds[eid].x - movePoint1.x;
    const dy = oldBounds[eid].y - movePoint1.y;

    // the rotation of the shape (vs. the coordinate)
    const rotation = Math.atan2(dy, dx);

    // the angle of dragging dot -> the opposite point (vs. the coordinate)
    const orotation = Math.atan2(doy, dox);

    // the ratio of the shape
    const ratio = orotation - rotation;

    // length of the side of the shape
    const dists = Math.sin(ratio) * distn;

    // xy component of the side
    const xChange = Math.sin(rotation) * dists;
    const yChange = Math.cos(rotation) * dists;
    ///

    const resultBounds = {} as Bounds;
    resultBounds[eid] = newBound;
    resultBounds[movePoint1Id] = { x: oppositePoint.x - xChange, y: oppositePoint.y + yChange };
    resultBounds[movePoint2Id] = { x: newBound.x + xChange, y: newBound.y - yChange };
    resultBounds[oppositePointId] = oppositePoint;
    return resultBounds;
  }


  // acquire the relative coordinates of bounds vs. the first joint
  // regardless of rotation (see first joint -> second joint as horizontal)
  // refCoord1 is joint 1, refCoord2 is joint 2, we need joint 2 to calculate the
  // rotation of the shape itself
  getBoundsRelativeCoords(refCoord1: Coord, refCoord2: Coord, bounds: Bounds): Bounds {
    const rotation = this.getLinkRotation(refCoord1, refCoord2);
    const result = {};
    const keyArray = [editorID.b1, editorID.b2, editorID.b3, editorID.b4];
    keyArray.forEach(eid => {
      result[eid] = this.getRelativeCoord(refCoord1, rotation, bounds[eid]);
    });
    return result as Bounds;
  }

  getLinkRotation(j1: Coord, j2: Coord): number {
    const dx = j2.x - j1.x;
    const dy = j2.y - j1.y;
    return Math.atan2(dy, dx);
  }

  getRelativeCoord(refCoord1: Coord, rotation: number, point: Coord): Coord {
    const bdx = point.x - refCoord1.x;
    const bdy = point.y - refCoord1.y;
    // distance of this bound vs. joint 1
    const dist = Math.sqrt(bdx * bdx + bdy * bdy);
    // angle of bound vs. joint 1 on raw XY Coordinate
    const angle = Math.atan2(bdy, bdx);
    // angle of bound vs. joint 1 compensated for the rotation of the shape
    const brotation = angle - rotation;

    const ry = Math.sin(brotation) * dist;
    const rx = Math.cos(brotation) * dist;
    return new Coord(rx, ry);
  }

  // get the bounds by applying relative rotation on the first joint
  getBoundsByRelativeCoords(refCoord1: Coord, refCoord2: Coord, relativeCoords: Bounds): Bounds {
    const rotation = this.getLinkRotation(refCoord1, refCoord2);
    const result = {};
    Object.keys(relativeCoords).forEach(eid => {
      result[eid] = this.getPointByRelativeCoord(refCoord1, rotation, relativeCoords[eid]);
    });
    return result as Bounds;
  }

  getPointByRelativeCoord(refCoord1: Coord, rotation: number, relativeCoord: Coord): Coord {
    const bdx = relativeCoord.x;
    const bdy = relativeCoord.y;
    const dist = Math.sqrt(bdx * bdx + bdy * bdy);

    // angle of bound vs. the first joint on raw XY Coordinate
    const angle = Math.atan2(bdy, bdx);
    // the angle of bound vs. the first joint compensated for the rotation of the shape
    const brotation = angle + rotation;

    const ry = Math.sin(brotation) * dist;
    const rx = Math.cos(brotation) * dist;
    return { x: refCoord1.x + rx, y: refCoord1.y + ry };
  }

  createSVGPath(id: string, classname: string, points: Coord[], fill: string, r?: number, style?: string): SVGElement {
    const pathString = this.arrayToPathString(points, r);
    const linkSVG = document.createElementNS('http://www.w3.org/2000/svg', 'path');
    linkSVG.setAttributeNS(undefined, 'id', id);
    linkSVG.setAttribute('class', classname);
    linkSVG.setAttributeNS(undefined, 'd', pathString);
    linkSVG.setAttributeNS(undefined, 'fill', fill);
    // linkSVG.setAttributeNS(undefined, 'cursor', );
    linkSVG.style.opacity = '0.8';
    if (style !== undefined) {
      linkSVG.setAttribute('style', style);
      // style && linkSVG.setAttribute('style', style);
    }
    return linkSVG;
  }


  createSVGPoly(id: string, classname: string, points: Coord[], fill: string, style?: string): SVGElement {
    const pointString = this.arrayToPointsString(points);
    const linkSVG = document.createElementNS('http://www.w3.org/2000/svg', 'polygon');
    linkSVG.setAttributeNS(undefined, 'id', id);
    linkSVG.setAttribute('class', classname);
    linkSVG.setAttributeNS(undefined, 'points', pointString);
    linkSVG.setAttributeNS(undefined, 'fill', fill);
    // linkSVG.setAttributeNS(undefined, 'cursor', 'move');
    if (style !== undefined) {
      linkSVG.setAttribute('style', style);
      // style && linkSVG.setAttribute('style', style);
    }
    return linkSVG;
  }

  setJointTempCreated(svg: SVGElement) {
    const cir = svg.children[0];
    cir.setAttributeNS(undefined, 'fill-opacity', '0.5');
    cir.setAttributeNS(undefined, 'stroke-opacity', '0.5');
  }

  // setTagCreated(svg:SVGElement) {}

  setJointColor(svg: SVGElement, color: string) {
    const circleRing = svg.children[0];
    circleRing.setAttribute('fill', color);
    for (let i = 2; i < svg.children.length; i++) {
      svg.children[i].setAttribute('stroke', color);
    }
  }

  setCircleMoveSelected(svg: SVGElement) {
    this.setJointColor(svg, 'red');
  }

  unhighlightCircle(svg: SVGElement) {
    this.setJointColor(svg, 'black');
  }

  setCircleAsGround(svg: SVGElement) {
    // circle ring is an opaque ring around joint to make user select it easier
    const circleRing = svg.children[0] as HTMLElement;
    circleRing.style.display = 'none';

    // remove everything other than circle and ring
    this.removeGroundInputSVG(svg);

    const circle = svg.children[1];
    const currentR = SVGSettings.jointRadius * AppConstants.scaleFactor;
    const sw = parseFloat(circle.getAttribute('stroke-width'));
    const realR = currentR + sw / 2;
    const grounds = this.createGroundSVG(realR * 0.75);

    // add ground svg components
    for (let i = 0; i < grounds.length; i++) {
      svg.appendChild(grounds[i]);
    }

    // shrink the circle size
    circle.setAttribute('r', (currentR / 3 * 2).toString());
    circle.setAttribute('fill', 'black');
  }

  setCircleAsRGroundInput(svg: SVGElement) {
    // circle ring is an opaque ring around joint to make user select it easier
    const circleRing = svg.children[0] as HTMLElement;
    circleRing.style.display = 'none';

    // remove everything other than circle and ring
    this.removeGroundInputSVG(svg);

    const circle = svg.children[1];
    const currentR = SVGSettings.jointRadius * AppConstants.scaleFactor;
    const sw = parseFloat(circle.getAttribute('stroke-width'));
    const realR = currentR + sw / 2;
    const grounds = this.createGroundSVG(realR * 0.75);

    // add ground svg components
    for (let i = 0; i < grounds.length; i++) {
      svg.appendChild(grounds[i]);
    }

    // shrink the circle size
    circle.setAttribute('r', (currentR / 3 * 2).toString());
    circle.setAttribute('fill', 'green');
  }

  setCircleAsRevoluteInput(svg: SVGElement) {
    const circle = svg.children[1];
    const currentR = SVGSettings.jointRadius * AppConstants.scaleFactor;
    circle.setAttribute('r', (currentR / 3 * 2).toString());
    circle.setAttribute('fill', 'green');
  }

  setCircleAsSliderInput(svg: SVGElement) {
    // const circleRing = svg.children[0] as HTMLElement;
    // circleRing.style.display = 'none';

    // this.removeGroundInputSVG(svg);

    const circle = svg.children[1];
    const currentR = SVGSettings.jointRadius * AppConstants.scaleFactor;
    // const sw = parseFloat(circle.getAttribute('stroke-width'));
    // const realR = currentR + sw / 2;
    // const grounds = this.createGroundSVG(realR * 0.75);
    // for (let i = 0; i < grounds.length; i++) {
    //   svg.appendChild(grounds[i]);
    // }

    circle.setAttribute('r', (currentR / 3 * 2).toString());
    circle.setAttribute('fill', 'blue');
  }

  // angle in radians
  setCircleAsSlider(svg: SVGElement, angle?: number) {
    const circleRing = svg.children[0] as HTMLElement;
    circleRing.style.display = 'none';

    const sliderSVGs = this.createSlider(angle);
    this.removeGroundInputSVG(svg);
    const circle = svg.children[1];
    const currentR = SVGSettings.jointRadius * AppConstants.scaleFactor;
    circle.setAttribute('r', (currentR / 3 * 2).toString());
    circle.setAttribute('fill', 'black');
    sliderSVGs.forEach(ssvg => {
      svg.appendChild(ssvg);
    });
  }
  createSlider(angle?: number): SVGElement[] {
    angle = angle ? angle : 0;
    const x = 0;
    const y = 0;
    const ry = Math.sin(angle) * SVGSettings.jointRadius * 2 * AppConstants.scaleFactor;
    const rx = Math.cos(angle) * SVGSettings.jointRadius * 2 * AppConstants.scaleFactor;
    const ps = new Coord(x + rx, y + ry);
    const pe = new Coord(x - rx, y - ry);
    const p1 = new Coord(ps.x + ry * 0.8, ps.y - rx * 0.8);
    const p2 = new Coord(ps.x - ry * 0.8, ps.y + rx * 0.8);
    const p3 = new Coord(pe.x - ry * 0.8, pe.y + rx * 0.8);
    const p4 = new Coord(pe.x + ry * 0.8, pe.y - rx * 0.8);
    const path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
    const pathString = this.arrayToPathString([p1, p2, p3, p4], 2 * AppConstants.scaleFactor);
    path.setAttribute('stroke', 'black');
    path.setAttribute('fill-opacity', '0');
    path.setAttribute('stroke-width', `${2 * AppConstants.scaleFactor}`);
    path.setAttribute('d', pathString);
    return [path];
  }

  setCircleAsJoint(svg: SVGElement) {
    const circleRing = svg.children[0] as HTMLElement;
    circleRing.style.display = 'block';
    this.removeGroundInputSVG(svg);
    const circle = svg.children[1];
    const currentR = SVGSettings.jointRadius * AppConstants.scaleFactor;
    circle.setAttributeNS(undefined, 'r', currentR.toString());
    circle.setAttribute('fill', 'gray');
  }

  removeGroundInputSVG(svg: SVGElement) {
    const removeList = [];
    if (svg.children.length > 2) {
      for (let i = 2; i < svg.children.length; i++) {
        removeList.push(svg.children[i]);
      }
    }
    removeList.forEach(item => {
      svg.removeChild(item);
    });
  }

  createGroundSVG(r: number) {

    const box = document.createElementNS('http://www.w3.org/2000/svg', 'path');

    box.setAttribute('d', `M ${-1.5 * r} ${-r} L ${-r} ${r} L ${r} ${r} L ${1.5 * r} ${-r}`);

    box.setAttribute('stroke', 'black');
    box.setAttribute('stroke-width', '.05');
    box.setAttribute('fill', 'transparent');

    const grounds = [];
    for (let i = 0; i < 6; i++) {
      const ground = document.createElementNS('http://www.w3.org/2000/svg', 'line');
      ground.setAttribute('stroke-width', '0.03');
      ground.setAttribute('stroke', 'black');

      ground.setAttribute('x1', (-r + r / 2 * (i)).toString());
      ground.setAttribute('x2', (-r + r / 2 * (i - 1)).toString());
      ground.setAttribute('y1', (-r).toString());
      ground.setAttribute('y2', (-1.5 * r).toString());
      grounds.push(ground);
    }

    const groundSurface = document.createElementNS('http://www.w3.org/2000/svg', 'line');
    groundSurface.setAttribute('stroke-width', '0.05');
    groundSurface.setAttribute('stroke', 'black');
    groundSurface.setAttribute('x1', (-2 * r).toString());
    groundSurface.setAttribute('x2', (2 * r).toString());
    groundSurface.setAttribute('y1', (-r).toString());
    groundSurface.setAttribute('y2', (-r).toString());

    return [...grounds, groundSurface, box];
  }

  highlightCircle(svg: SVGElement) {
    this.setJointColor(svg, 'yellow');
  }

  createSVGHolder(x?: number, y?: number, height?: number, width?: number) {
    const holder = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    if (x !== undefined) {
      holder.setAttributeNS(undefined, 'x', x.toString());
    }
    if (y !== undefined) {
      holder.setAttributeNS(undefined, 'y', y.toString());
    }
    if (height !== undefined) {
      holder.setAttributeNS(undefined, 'height', height.toString());
    }
    if (width !== undefined) {
      holder.setAttributeNS(undefined, 'width', width.toString());
    }
    // x !== undefined && holder.setAttributeNS(undefined, 'x', x.toString());
    // y !== undefined && holder.setAttributeNS(undefined, 'y', y.toString());
    // height !== undefined && holder.setAttributeNS(undefined, 'height', height.toString());
    // width !== undefined && holder.setAttributeNS(undefined, 'width', width.toString());
    holder.setAttributeNS(undefined, 'style', 'overflow: visible');
    return holder;
  }

  // general function for creating a svg circle and necessary parameters
  createSVGCircle(id: string, classname: string, cx: string, cy: string, r: string, fill: string, stroke?: string, strokewidth?: string) {
    const jointSVG = document.createElementNS('http://www.w3.org/2000/svg', 'circle');
    if (id !== undefined) {
      jointSVG.setAttributeNS(undefined, 'id', id);
    }
    if (classname !== undefined) {
      jointSVG.setAttribute('class', classname);
    }
    if (cx !== undefined) {
      jointSVG.setAttributeNS(undefined, 'cx', cx);
    }
    if (cy !== undefined) {
      jointSVG.setAttributeNS(undefined, 'cy', cy);
    }
    // id && jointSVG.setAttributeNS(undefined, 'id', id);
    // classname && jointSVG.setAttribute('class', classname);
    // cx && jointSVG.setAttributeNS(undefined, 'cx', cx);
    // cy && jointSVG.setAttributeNS(undefined, 'cy', cy);
    jointSVG.setAttributeNS(undefined, 'r', r);
    jointSVG.setAttributeNS(undefined, 'fill', fill);
    if (stroke !== undefined) {
      jointSVG.setAttributeNS(undefined, 'stroke', stroke);
    }
    if (strokewidth !== undefined) {
      jointSVG.setAttributeNS(undefined, 'stroke-width', strokewidth);
    }
    // stroke && jointSVG.setAttributeNS(undefined, 'stroke', stroke);
    // strokewidth && jointSVG.setAttributeNS(undefined, 'stroke-width', strokewidth);
    return jointSVG;
  }

  createSVGRotateCircle(id: string, classname: string, cx: string, cy: string, r: string, fill: string, stroke?: string,
                        strokewidth?: string) {
    const rotateSVG = document.createElementNS('http://www.w3.org/2000/svg', 'path');
    if (id !== undefined) {
      rotateSVG.setAttributeNS(undefined, 'id', id);
    }
    if (classname !== undefined) {
      rotateSVG.setAttribute('class', classname);
    }
    if (cx !== undefined) {
      // rotateSVG.setAttributeNS(undefined, 'd', 'M0 0 Q0.25 0.25, 0.5 0');
      rotateSVG.setAttributeNS(undefined, 'd', 'M0 0 Q0.25 0.25, 0.5 0, 0.25 -0.25, 0.125 -0.125');
    }
    // if (cx !== undefined) {
    //   rotateSVG.setAttributeNS(undefined, 'cx', cx);
    // }
    // if (cy !== undefined) {
    //   rotateSVG.setAttributeNS(undefined, 'cy', cy);
    // }
    // id && rotateSVG.setAttributeNS(undefined, 'id', id);
    // classname && rotateSVG.setAttribute('class', classname);
    // cx && rotateSVG.setAttributeNS(undefined, 'cx', cx);
    // cy && rotateSVG.setAttributeNS(undefined, 'cy', cy);
    // rotateSVG.setAttributeNS(undefined, 'r', r);
    // rotateSVG.setAttributeNS(undefined, 'fill', fill);
    rotateSVG.setAttributeNS(undefined, 'fill', 'none');
    rotateSVG.setAttributeNS(undefined, 'stroke', 'black');
    // if (stroke !== undefined) {
    //   rotateSVG.setAttributeNS(undefined, 'stroke', stroke);
    // }
    if (strokewidth !== undefined) {
      rotateSVG.setAttributeNS(undefined, 'stroke-width', '0.5');
      // rotateSVG.setAttributeNS(undefined, 'stroke-width', strokewidth);
    }
    // stroke && rotateSVG.setAttributeNS(undefined, 'stroke', stroke);
    // strokewidth && rotateSVG.setAttributeNS(undefined, 'stroke-width', strokewidth);
    return rotateSVG;
  }

  // r is the rounded edge radius
  arrayToPathString(array: Coord[], rawR?: number): string {
    // TODO: Have logic for determining a lot of this stored somewhere to be used for determining mass and mass moment of inertia
    const r = rawR === undefined ? 0 : rawR;
    let pathString = '';

    // array of angles of next point relative to current point
    const rotationArray = [];
    // array of angles of current point in radians
    const angleArray = [];

    // pre-process, fill the arrays
    for (let i = 0; i < array.length; i++) {
      let npHolder: Coord, lpHolder: Coord;
      npHolder = (i + 1) < array.length ? array[i + 1] : array[0];
      lpHolder = (i - 1) >= 0 ? array[i - 1] : array[array.length - 1];
      // last point
      const lp = lpHolder;
      // current point
      const cp = array[i];
      // next point
      const np = npHolder;

      const lastDx = cp.x - lp.x;
      const lastDy = cp.y - lp.y;
      const lastRot = Math.atan2(lastDy, lastDx);

      const nextDx = np.x - cp.x;
      const nextDy = np.y - cp.y;
      const nextRot = Math.atan2(nextDy, nextDx);
      const angle = Math.PI - (lastRot - nextRot);
      angleArray.push(angle);
      rotationArray.push(nextRot);
    }

    for (let i = 0; i < array.length; i++) {
      // current point
      const cp = array[i];
      // current angle
      const ca = angleArray[i];
      // next rotation
      const nr = rotationArray[i];
      // last point, last angle, last rotation
      let lp: Coord, la: number, lr: number;
      if (i - 1 >= 0) {
        lp = array[i - 1];
        la = angleArray[i - 1];
        lr = rotationArray[i - 1];
      } else {
        lp = array[array.length - 1];
        la = angleArray[array.length - 1];
        lr = rotationArray[array.length - 1];
      }

      // path for each point should be like: start (last point curve end point) -> mid (current point curve start point) ->
      // curve -> end (current point curve end point)

      // this offset indicates the distance from last point where the line can begin
      // (can't begin at last point cuz we have rounded corner)
      // offset = right-angle distance to the center-line of last angle is r
      const lastOffset = Math.abs(r / Math.tan(la / 2));

      const lastXC = Math.cos(lr) * lastOffset;
      const lastYC = Math.sin(lr) * lastOffset;
      // apply the offset
      const startX = lp.x + lastXC;
      const startY = lp.y + lastYC;

      // same as above, except for current point
      // (must end before current point to draw rounded corner)
      const nextOffset = Math.abs(r / Math.tan(ca / 2));
      const midXC = Math.cos(lr) * nextOffset;
      const midYC = Math.sin(lr) * nextOffset;

      // mid is the point where the curve of the current point starts
      const midX = cp.x - midXC;
      const midY = cp.y - midYC;

      // construct the path from start to mid
      pathString += (i === 0) ? `M ${startX} ${startY} L ${midX} ${midY} ` : `L ${startX} ${startY} L ${midX} ${midY} `;
      // if (i === 0) {
      //   pathString += `M ${startX} ${startY} L ${midX} ${midY} `;
      // } else {
      //   pathString += `L ${startX} ${startY} L ${midX} ${midY} `;
      // }

      // the offset distance should be the same for the other half of the curve
      // just the rotation is now relative to the next point
      const nextXC = Math.cos(nr) * nextOffset;
      const nextYC = Math.sin(nr) * nextOffset;

      // end is the coord where the curve of the current point ends
      const endX = cp.x + nextXC;
      const endY = cp.y + nextYC;

      // construct the mid curve
      // the control points for bezier curves are simply pointed
      // towards the corner, but stops at 0.55x the distance
      // 0.55 is the percentage to create a perfect circle
      // TODO: 0.55 is only good for constructing circle with 4 points, not optimal for triangles, etc
      const cp1x1 = Math.cos(lr) * r;
      const cp1y1 = Math.sin(lr) * r;
      const cp1x2 = midXC * 0.551915;
      const cp1y2 = midYC * 0.551915;

      const cp2x1 = Math.cos(nr) * r;
      const cp2y1 = Math.sin(nr) * r;
      const cp2x2 = nextXC * 0.551915;
      const cp2y2 = nextYC * 0.551915;

      // find the shorter control line
      pathString += (Math.sqrt(cp1x1 * cp1x1 + cp1y1 * cp1y1) < Math.sqrt(cp1x2 * cp1x2 + cp1y2 * cp1y2)) ?
        pathString += `C ${cp.x - midXC + cp1x1} ${cp.y - midYC + cp1y1} ${cp.x + nextXC - cp2x1} ${cp.y + nextYC - cp2y1} ` :
        `C ${cp.x - midXC + cp1x2} ${cp.y - midYC + cp1y2} ${cp.x + nextXC - cp2x2} ${cp.y + nextYC - cp2y2} `;
      // if (Math.sqrt(cp1x1 * cp1x1 + cp1y1 * cp1y1) < Math.sqrt(cp1x2 * cp1x2 + cp1y2 * cp1y2)) {
      //   pathString += `C ${cp.x - midXC + cp1x1} ${cp.y - midYC + cp1y1} ${cp.x + nextXC - cp2x1} ${cp.y + nextYC - cp2y1} `;
      // } else {
      //   pathString += `C ${cp.x - midXC + cp1x2} ${cp.y - midYC + cp1y2} ${cp.x + nextXC - cp2x2} ${cp.y + nextYC - cp2y2} `;
      // }
      pathString += `${endX} ${endY} `;
    }

    pathString += `Z`;
    return pathString;
  }

  arrayToPointsString(array: Coord[]): string {
    let pointsString = '';
    for (let i = 0; i < array.length; i++) {
      pointsString = pointsString + array[i].x + ',' + array[i].y;
      if (i !== array.length - 1) {
        pointsString = pointsString + ' ';
      }
    }
    return pointsString;
  }

  // Create an arrow from the start coordinates to the finish coordinates
  createArrow(id: string, startX: number, startY: number, endX: number, endY: number) {
    // line of the arrow
    const lineSVG = document.createElementNS('http://www.w3.org/2000/svg', 'path');
    const lineString = `M ${startX} ${startY} L ${endX} ${endY} Z`;
    lineSVG.setAttribute('d', lineString);
    lineSVG.setAttribute('stroke-width', (2 * AppConstants.scaleFactor).toString());
    lineSVG.setAttribute('stroke', 'black');

    // highlight of the arrow
    const lineHighlightSVG = document.createElementNS('http://www.w3.org/2000/svg', 'path');
    lineHighlightSVG.setAttribute('d', lineString);
    lineHighlightSVG.setAttribute('stroke-width', (2 * SVGSettings.jointRadius * AppConstants.scaleFactor).toString());
    lineHighlightSVG.setAttribute('stroke', 'yellow');
    lineHighlightSVG.setAttribute('stroke-opacity', '0');

    // triangle (pointer) of the arrow
    const angle = Math.atan2(endY - startY, endX - startX);
    const a1 = angle - Math.PI / 6;
    const a2 = angle + Math.PI / 6;
    const triLen = 12 * AppConstants.scaleFactor;
    const dx1 = Math.cos(a1) * triLen;
    const dy1 = Math.sin(a1) * triLen;
    const dx2 = Math.cos(a2) * triLen;
    const dy2 = Math.sin(a2) * triLen;

    const triString = `M ${endX} ${endY} L ${endX - dx1} ${endY - dy1} L ${endX - dx2} ${endY - dy2} Z`;
    const triSVG = document.createElementNS('http://www.w3.org/2000/svg', 'path');
    triSVG.setAttribute('d', triString);
    triSVG.setAttribute('fill', 'black');

    // wrapper
    const pathSVG = document.createElementNS('http://www.w3.org/2000/svg', 'g');
    pathSVG.appendChild(lineHighlightSVG);
    pathSVG.appendChild(lineSVG);
    pathSVG.appendChild(triSVG);
    pathSVG.setAttribute('id', id);

    pathSVG.addEventListener('mouseover', e => {
      pathSVG.children[0].setAttribute('stroke-opacity', '0.5');
    });
    pathSVG.addEventListener('mouseout', e => {
      pathSVG.children[0].setAttribute('stroke-opacity', '0');
    });
    return pathSVG;
  }

  updateArrow(svg: SVGElement, startX: number, startY: number, endX: number, endY: number) {
    const newArrow = this.createArrow('', startX, startY, endX, endY);
    for (let i = 0; i < svg.children.length; i++) {
      svg.children[i].setAttribute('d', newArrow.children[i].getAttribute('d'));
    }
  }

  changeArrowToLocal(svg: SVGElement) {
    svg.children[1].setAttribute('stroke', 'blue');
    svg.children[2].setAttribute('fill', 'blue');
  }

  changeArrowToGlobal(svg: SVGElement) {
    svg.children[1].setAttribute('stroke', 'black');
    svg.children[2].setAttribute('fill', 'black');
  }

  // Create a line from the start coordinates to the finish coordinates
  createSVGTempLineOpaque(startX: number, startY: number, endX: number, endY: number) {
    const linkSVG = document.createElementNS('http://www.w3.org/2000/svg', 'line');
    linkSVG.setAttribute('x1', startX.toString());
    linkSVG.setAttribute('y1', startY.toString());
    linkSVG.setAttribute('x2', endX.toString());
    linkSVG.setAttribute('y2', endY.toString());
    linkSVG.setAttribute('stroke', 'black');
    linkSVG.setAttribute('stroke-width', (AppConstants.scaleFactor).toString());
    linkSVG.setAttributeNS(undefined, 'stroke-opacity', '0.5');
    return linkSVG;
  }

  // Update the coordinates of an SVG element
  updateSVGCoords(svg: SVGElement,
                  x: number = parseInt(svg.getAttributeNS(undefined, 'x'), 10),
                  y: number = parseInt(svg.getAttributeNS(undefined, 'y'), 10)) {
    svg.setAttributeNS(undefined, 'x', x.toString());
    svg.setAttributeNS(undefined, 'y', y.toString());
  }

  // Update the dimensions of an SVG element
  updateSVGDimensions(svg: SVGElement,
                      height: number = parseInt(svg.getAttributeNS(undefined, 'height'), 10),
                      width: number = parseInt(svg.getAttributeNS(undefined, 'width'), 10)) {
    svg.setAttributeNS(undefined, 'height', height.toString());
    svg.setAttributeNS(undefined, 'width', width.toString());
  }

  // Update the coordinates of an SVG element
  updateSVGCoordsLine(svg: SVGElement,
                      x1: number = parseInt(svg.getAttributeNS(undefined, 'x1'), 10),
                      y1: number = parseInt(svg.getAttributeNS(undefined, 'y1'), 10),
                      x2: number = parseInt(svg.getAttributeNS(undefined, 'x2'), 10),
                      y2: number = parseInt(svg.getAttributeNS(undefined, 'x2'), 10)) {
    svg.setAttributeNS(undefined, 'x1', x1.toString());
    svg.setAttributeNS(undefined, 'y1', y1.toString());
    svg.setAttributeNS(undefined, 'x2', x2.toString());
    svg.setAttributeNS(undefined, 'y2', y2.toString());
  }

  updateSVGCoordsPoly(svg: SVGElement, points: Coord[]) {
    const pointString = this.arrayToPointsString(points);
    svg.setAttributeNS(undefined, 'points', pointString);
    return svg;
  }

  updateSVGRadius(svg: SVGElement, radius: number) {
    svg.setAttributeNS(undefined, 'r', radius.toString());
  }

  getPositionOfSVG(element: Element) {
    const rect = element.getBoundingClientRect();
    return { x: (rect.right + rect.left) / 2, y: (rect.top + rect.bottom) / 2 };
  }

  pointInsideSVGPath(point: Coord, svg: SVGGeometryElement): Boolean {
    // isPointInFill is not supported on firefox mobile, check first
    if (svg.isPointInFill) {
      const fakeGrid = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
      const svgp = fakeGrid.createSVGPoint();
      svgp.x = point.x;
      svgp.y = point.y;
      const isin = svg.isPointInFill(svgp);
      return isin;
    } else {
      return psip.isInside([point.x, point.y], svg.getAttribute('d'));
    }
  }

  setTagTempCreated(svg: SVGElement, id) {
    const tag = svg.children[0];
    tag.setAttributeNS(undefined, 'id', id);
  }

  createJointLinkTag(id: string, x: number, y: number) {
    const SVGHolder = this.createSVGHolder(x, y, 10, 10);
    const joint_tag_SVG = document.createElementNS('http://www.w3.org/2000/svg', 'text');
    // joint_tag_SVG.setAttribute('transform', 'rotate (180), scale(-1, 1)');
    joint_tag_SVG.setAttribute('transform', 'rotate (180), scale(-0.5, 0.5)');
    joint_tag_SVG.textContent = id;
    joint_tag_SVG.style.fontSize = '0.7';
    joint_tag_SVG.style.textAnchor = 'middle';
    SVGHolder.append(joint_tag_SVG);
    return SVGHolder;
  }

  // https://stackoverflow.com/questions/3492322/javascript-createelementns-and-svg
  createCoMTag(ID: string, x: number, y: number) {
    const SVGHolder = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    SVGHolder.setAttributeNS(undefined, 'style', 'overflow: visible');
    const r = 0.25;
    const width = x > 0 ? (x + r).toString() : (x - r).toString();
    const height = y > 0 ? (y + r).toString() : (y - r).toString();
    SVGHolder.setAttributeNS(undefined, 'width', width);
    SVGHolder.setAttributeNS(undefined, 'height', height);
    const c_x = x;
    const c_y = y;

    const coords1 = 'M' + c_x.toString() + ' ' + c_y.toString() + ' ' +
      (c_x - r).toString() + ' ' + c_y.toString() + ' ' +
      'A0.25 0.25 0 0 0 ' +
      c_x.toString() + ' ' + (c_y + r).toString() + 'Z';
    const coords2 = 'M' + c_x.toString() + ' ' + c_y.toString() + ' ' +
      c_x.toString() + ' ' + (c_y + r).toString() + ' ' +
      'A0.25 0.25 0 0 0 ' +
      (c_x + r).toString() + ' ' +  c_y.toString() + 'Z';
    const coords3 = 'M' + c_x.toString() + ' ' + c_y.toString() + ' ' +
      (c_x + r).toString() + ' ' + c_y.toString() + ' ' +
      'A0.25 0.25 0 0 0 ' +
      c_x.toString() + ' ' + (c_y - r).toString() + 'Z';
    const coords4 = 'M' + c_x.toString() + ' ' + c_y.toString() + ' ' +
      c_x.toString() + ' ' + (c_y - r).toString() + ' ' +
      'A0.25 0.25 0 0 0 ' +
      (c_x - r).toString() + ' ' +  c_y.toString() + 'Z';

    const path1 = document.createElementNS('http://www.w3.org/2000/svg', 'path');
    path1.setAttributeNS(null, 'stroke-width', '0.05');
    path1.setAttributeNS(null, 'stroke-width', '2');
    path1.setAttributeNS(null, 'stroke-linejoin', 'round');
    path1.setAttributeNS(null, 'd', coords1);
    path1.setAttributeNS(null, 'fill', 'black');
    path1.setAttributeNS(null, 'opacity', '1.0');

    const path2 = document.createElementNS('http://www.w3.org/2000/svg', 'path');
    path2.setAttributeNS(null, 'stroke-width', '0.05');
    path2.setAttributeNS(null, 'stroke-width', '2');
    path2.setAttributeNS(null, 'stroke-linejoin', 'round');
    path2.setAttributeNS(null, 'd', coords2);
    path2.setAttributeNS(null, 'fill', 'white');
    path2.setAttributeNS(null, 'opacity', '1.0');

    const path3 = document.createElementNS('http://www.w3.org/2000/svg', 'path');
    path3.setAttributeNS(null, 'stroke-width', '0.5');
    path3.setAttributeNS(null, 'stroke-width', '0.05');
    path3.setAttributeNS(null, 'stroke-linejoin', 'round');
    path3.setAttributeNS(null, 'd', coords3);
    path3.setAttributeNS(null, 'fill', 'black');
    path3.setAttributeNS(null, 'opacity', '1.0');

    const path4 = document.createElementNS('http://www.w3.org/2000/svg', 'path');
    // path4.setAttributeNS(null, 'stroke', '#FFFFFF');
    path4.setAttributeNS(null, 'stroke-width', '0.5');
    path4.setAttributeNS(null, 'stroke-width', '0.05');
    path4.setAttributeNS(null, 'stroke-linejoin', 'round');
    path4.setAttributeNS(null, 'd', coords4);
    path4.setAttributeNS(null, 'fill', 'white');
    path4.setAttributeNS(null, 'opacity', '1.0');

    SVGHolder.append(path1);
    SVGHolder.append(path2);
    SVGHolder.append(path3);
    SVGHolder.append(path4);
    return SVGHolder;
  }
}

export const SVGFuncs = new SVGFuncColle();
