import { AfterViewInit, OnChanges, Component, SimpleChanges, Input, Output, EventEmitter } from '@angular/core';
import { SVGFuncs, Shape } from '../../functions/SVGFuncs';
import {elementEnd} from '@angular/core/src/render3';

@Component({
  selector: 'app-shapeselector',
  templateUrl: './shapeselector.component.html',
  styleUrls: ['./shapeselector.component.css']
})

export class ShapeSelectorComponent implements AfterViewInit, OnChanges {
  shapeSelector: HTMLElement;
  @Input() synthesisMode = 'none';
  @Output() selectShape = new EventEmitter<Shape>();
  @Output() selectPath = new EventEmitter<Shape>();
  @Output() saveEdit = new EventEmitter();
  @Output() cancelEdit = new EventEmitter();
  @Output() revertEdit = new EventEmitter();

  constructor() { }

  // ngOnInit() {}

  ngAfterViewInit() {
    this.shapeSelector = document.getElementById('shape-selector-table');
    const lineSelection = this.createShapeSelection(Shape.line);
    const barSelection = this.createShapeSelection(Shape.bar);
    const etriangleSelection = this.createShapeSelection(Shape.eTriangle);
    const rtriangleSelection = this.createShapeSelection(Shape.rTriangle);
    const rectangleSelection = this.createShapeSelection(Shape.rectangle);
    const squareSelection = this.createShapeSelection(Shape.square);
    const circleSelection = this.createShapeSelection(Shape.circle);
    const cShapeSelection = this.createShapeSelection(Shape.cShape);
    const tShapeSelection = this.createShapeSelection(Shape.tShape);
    const lShapeSelection = this.createShapeSelection(Shape.lShape);
    const horizontalLineShapeSelection = this.createShapeSelection(Shape.horizontalLine);
    const verticalLineShapeSelection = this.createShapeSelection(Shape.verticalLine);
    const slantedLineForwardShapeSelection = this.createShapeSelection(Shape.slantedLineForward);
    const slantedLineBackwardShapeSelection = this.createShapeSelection(Shape.slantedLineBackward);
    const beanShapeSelection = this.createShapeSelection(Shape.beanShape);
    const eightShapeSelection = this.createShapeSelection(Shape.eightShape);
    const infinityShapeSelection = this.createShapeSelection(Shape.infinityShape);
    const customShapeSelection = this.createShapeSelection(Shape.customShape);
    this.shapeSelector.appendChild(lineSelection);
    this.shapeSelector.appendChild(barSelection);
    this.shapeSelector.appendChild(etriangleSelection);
    this.shapeSelector.appendChild(rtriangleSelection);
    this.shapeSelector.appendChild(rectangleSelection);
    this.shapeSelector.appendChild(squareSelection);
    this.shapeSelector.appendChild(circleSelection);
    this.shapeSelector.appendChild(cShapeSelection);
    this.shapeSelector.appendChild(tShapeSelection);
    this.shapeSelector.appendChild(lShapeSelection);
    this.shapeSelector.appendChild(horizontalLineShapeSelection);
    this.shapeSelector.appendChild(verticalLineShapeSelection);
    this.shapeSelector.appendChild(slantedLineForwardShapeSelection);
    this.shapeSelector.appendChild(slantedLineBackwardShapeSelection);
    this.shapeSelector.appendChild(beanShapeSelection);
    this.shapeSelector.appendChild(eightShapeSelection);
    this.shapeSelector.appendChild(infinityShapeSelection);
    this.shapeSelector.appendChild(customShapeSelection);
  }


  ngOnChanges(changes: SimpleChanges) {
    if (changes.show && !changes.show.isFirstChange()) {
      this.shapeSelector.style.display = changes.show.currentValue ? 'block' : 'none';
    }
    if (changes.synthesisMode) {}
  }

  createShapeSelection (shape: Shape): HTMLElement {
    const that = this;
    const shapeContainer = document.createElement('tr');
    const shapeSelection = document.createElement('td');

    shapeSelection.setAttribute('style', 'height: 120px; border-bottom: 1px solid black; text-align: center;');
    shapeSelection.className = 'shape-icon-wrapper';

    // shapeSelection.innerText = shape;
    shapeSelection.addEventListener('mouseup', e => {
      if (e.button === 0) {
        switch (this.synthesisMode) {
          case 'none':
            that.selectShape.emit(shape);
            break;
          case 'path_point':
            that.selectPath.emit(shape);
            break;
        }
      }
    });
    const icon = document.createElement('img');
    icon.setAttribute('src', this.getImageUrl(shape));
    shapeSelection.appendChild(icon);
    shapeContainer.appendChild(shapeSelection);
    return shapeContainer;
  }

  showShapeSelector (e: [boolean, string]) {
    const wrapper = document.getElementById('shape-selector');
    if (e[0]) {
      this.shapeSelector.innerHTML = '';
      // determine which mode you are in
      // if (this.synthesisMode === 'none') {
      if (e[1] === 'none') {
        const lineSelection = this.createShapeSelection(Shape.line);
        const barSelection = this.createShapeSelection(Shape.bar);
        const etriangleSelection = this.createShapeSelection(Shape.eTriangle);
        const rtriangleSelection = this.createShapeSelection(Shape.rTriangle);
        const rectangleSelection = this.createShapeSelection(Shape.rectangle);
        const squareSelection = this.createShapeSelection(Shape.square);
        const circleSelection = this.createShapeSelection(Shape.circle);
        const cShapeSelection = this.createShapeSelection(Shape.cShape);
        const tShapeSelection = this.createShapeSelection(Shape.tShape);
        const lShapeSelection = this.createShapeSelection(Shape.lShape);
        this.shapeSelector.appendChild(lineSelection);
        this.shapeSelector.appendChild(barSelection);
        this.shapeSelector.appendChild(etriangleSelection);
        this.shapeSelector.appendChild(rtriangleSelection);
        this.shapeSelector.appendChild(rectangleSelection);
        this.shapeSelector.appendChild(squareSelection);
        this.shapeSelector.appendChild(circleSelection);
        this.shapeSelector.appendChild(cShapeSelection);
        this.shapeSelector.appendChild(tShapeSelection);
        this.shapeSelector.appendChild(lShapeSelection);
      } else {
        const horizontalLineShapeSelection = this.createShapeSelection(Shape.horizontalLine);
        const verticalLineShapeSelection = this.createShapeSelection(Shape.verticalLine);
        const slantedLineForwardShapeSelection = this.createShapeSelection(Shape.slantedLineForward);
        const slantedLineBackwardShapeSelection = this.createShapeSelection(Shape.slantedLineBackward);
        const beanShapeSelection = this.createShapeSelection(Shape.beanShape);
        const eightShapeSelection = this.createShapeSelection(Shape.eightShape);
        const infinityShapeSelection = this.createShapeSelection(Shape.infinityShape);
        const customShapeSelection = this.createShapeSelection(Shape.customShape);
        this.shapeSelector.appendChild(horizontalLineShapeSelection);
        this.shapeSelector.appendChild(verticalLineShapeSelection);
        this.shapeSelector.appendChild(slantedLineForwardShapeSelection);
        this.shapeSelector.appendChild(slantedLineBackwardShapeSelection);
        this.shapeSelector.appendChild(beanShapeSelection);
        this.shapeSelector.appendChild(eightShapeSelection);
        this.shapeSelector.appendChild(infinityShapeSelection);
        this.shapeSelector.appendChild(horizontalLineShapeSelection);
        this.shapeSelector.appendChild(customShapeSelection);
      }
      wrapper.style.display = 'block';
    } else {
      wrapper.style.display = 'none';
    }
  }

  save() {
    this.saveEdit.emit();
  }

  cancel() {
    this.cancelEdit.emit();
  }

  revert() {
    this.revertEdit.emit();
  }

  edit() {}

  getImageUrl(shape: Shape): string {
    return `../../assets/shapes/${shape}.svg`;
  }
}
