import {Joint} from './Joint';
import {Force} from './Force';
import {Bounds} from '../../functions/SVGFuncs';
// import {Bounds} from '../functions/SVGFuncs';
import {LinkMassAndMassMoment} from './LinkMassAndMassMoment';

export class Link {
  private _id: string; // id of this link
  // list of joint for this link. Use push or splice along with getter to updated this field.
  private _joints: Array<Joint>;
  // states if this is a ground link and not a physical/user generated link
  // private _isGround: boolean;
  // states if this is a input link (AKA: link connected to input joint)
  private _isInput = false;
  // mass for this link and all other properties related to the link's mass (ie. force due to mass, mass moment of inertia, and location)
  private _mass: number;
  private _massMomentOfInertia: number;
  private _bounds: Bounds;

  // constructor(id: string, joints: Array<Joint>, isGround: boolean, mass?: number, moi?: number) { // , isImaginary: boolean) {
  constructor(id: string, joints: Array<Joint>, mass?: number, mmoi?: number, bound?: Bounds) {
    this._id = id;
    this._joints = joints;
    // this._isGround = isGround;
    this._mass = mass;
    this._massMomentOfInertia = mmoi;
    this._bounds = bound;
  } // end of constructor

  get id(): string {
    return this._id;
  }

  set id(value: string) {
    this._id = value;
  }

  get joints(): Array<Joint> {
    return this._joints;
  }

  set joints(value: Array<Joint>) {
    this._joints = value;
  }

  // get isGround(): boolean {
  //   return this._isGround;
  // }
  //
  // set isGround(value: boolean) {
  //   this._isGround = value;
  // }

  get isInput(): boolean {
    return this._isInput;
  }

  set isInput(value: boolean) {
    this._isInput = value;
  }

  get mass(): number {
    return this._mass;
  }

  set mass(value: number) {
    this._mass = value;
  }

  get massMomentOfInertia(): number {
    return this._massMomentOfInertia;
  }

  set massMomentOfInertia(value: number) {
    this._massMomentOfInertia = value;
  }

  get bounds(): Bounds {
    return this._bounds;
  }

  set bounds(bounds: Bounds) {
    this._bounds = bounds;
  }
}

export class RealLink extends  Link {
  // list of forces for this link. Use push or splice along with getter to updated this field. (link already existed before forces added)
  // private _externalForcesOnLink: Array<Force> = new Array<Force>();
  // holds the angular velocity for this link given a particular position of the linkage system in time
  // private _angularVelocity: number;
  // holds the angular velocity for this link given a particular position of the linkage system in time
  private _angularAcceleration: number;
  private _angle: number;
  private _forces: Array<Force>;
  private _CoM_x: number;
  private _CoM_y: number;
  // utilized to determine center of mass based from inserted center of mass
  private _internal_CoM_angle: number;
  private _internal_distance: number;
  constructor(id: string, joints: Array<Joint>, mass: number, mmoi: number, bounds, coM_x, coM_y) {
    super(id, joints, mass, mmoi, bounds);
    this.forces = [];
    this._CoM_x = coM_x;
    this._CoM_y = coM_y;
    // const a = Math.sqrt(Math.pow(bounds.b2.x - coM_x, 2) + Math.pow(bounds.b2.y - coM_y, 2));
    // const b = Math.sqrt(Math.pow(bounds.b1.x - coM_x, 2) + Math.pow(bounds.b1.y - coM_y, 2));
    // const c = Math.sqrt(Math.pow(bounds.b1.x - bounds.b2.x, 2) + Math.pow(bounds.b1.y - bounds.b2.y, 2));
    // this._internal_CoM_angle = Math.acos( (Math.pow(a, 2) + (Math.pow(b, 2)) - (Math.pow(c, 2))) / (2 * a * b));
    // this._internal_distance = b;
    // if (bounds !== undefined) {
    //   let link_x = 0;
    //   let link_y = 0;
    //   link_x += bounds.b1.x;
    //   link_y += bounds.b1.y;
    //   link_x += bounds.b2.x;
    //   link_y += bounds.b2.y;
    //   link_x += bounds.b3.x;
    //   link_y += bounds.b3.y;
    //   link_x += bounds.b4.x;
    //   link_y += bounds.b4.y;
    //   link_x /= 4;
    //   link_y /= 4;
    //   this._CoM_x = link_x;
    //   this._CoM_y = link_y;
    // }
  } // end of constructor

  // get externalForcesOnLink(): Array<Force> {
  //   return this._externalForcesOnLink;
  // }
  //
  // set externalForcesOnLink(value: Array<Force>) {
  //   this._externalForcesOnLink = value;
  // }

  // get angularVelocity(): number {
  //   return this._angularVelocity;
  // }
  //
  // set angularVelocity(value: number) {
  //   this._angularVelocity = value;
  // }

  get angularAcceleration(): number {
    return this._angularAcceleration;
  }

  set angularAcceleration(value: number) {
    this._angularAcceleration = value;
  }

  get angle(): number {
    return this._angle;
  }

  set angle(value: number) {
    this._angle = value;
  }

  get forces(): Array<Force> {
    return this._forces;
  }

  set forces(value: Array<Force>) {
    this._forces = value;
  }

  get CoM_x(): number {
    return this._CoM_x;
  }

  get CoM_y(): number {
    return this._CoM_y;
  }

  set CoM_x(com_x: number) {
    this._CoM_x = com_x;
  }

  set CoM_y(com_y: number) {
    this._CoM_y = com_y;
  }

  get internal_distance(): number {
    return this._internal_distance;
  }

  get internal_CoM_angle(): number {
    return this._internal_CoM_angle;
  }

  set internal_distance(distance: number) {
    this._internal_distance = distance;
  }

  set internal_CoM_angle(angle: number) {
    this._internal_CoM_angle = angle;
  }
}

export class ImagLink extends Link {
  constructor(id: string, joints: Array<Joint>, mass?: number, mmoi?: number, bounds?: Bounds) {
    super(id, joints, mass, mmoi, bounds);
  } // end of constructor
}

// Link as defined in the simulator class

// export class Link {
//
//   // todo: used and needed for angValAcc solver, loop class, and static's Solver. Initialize on creation them properly ------------------
//   // id of this link
//   private _id: string;
//   // list of joint for this link. Use push or splice along with getter to updated this field.
//   private readonly _joints: Array<Joint>;
//   // list of forces for this link. Use push or splice along with getter to updated this field. (link already existed before forces added)
//   private readonly _externalForcesOnLink: Array<Force> = new Array<Force>();
//   // mass for this link and all other properties related to the link's mass (ie. force due to mass, mass moment of inertia, and location)
//   private _linkMass: number; /** Milap, I changed LinkMassAndMassMoment to number. We should put LinkMassAndMassMoment into this class */
//   private _linkMassMomentOfInertia: number;
//   // states if this is a ground link and not a physical/user generated link
//   private readonly _isGround: boolean;
//   states if this is an imaginary link and not a physical/user generated link -- should not be ground link as well for sliding crank code
//   private readonly _isImaginary: boolean;
//   // states if this is a input link (AKA: link connected to input joint)
//   private _isInput = false;
//   // holds the angular velocity for this link given a particular position of the linkage system in time
//   private _angularVelocity: number;
//   // holds the angular velocity for this link given a particular position of the linkage system in time
//   private _angularAcceleration: number;
//   private _angle: number;
//   private _mainJoint: Joint;
//
//   constructor(id: string, Joints: Array<Joint>, isGround: boolean, mass?: number, moi?: number) { // , isImaginary: boolean) {
//     this._id = id;
//     this._joints = Joints;
//     this._isGround = isGround;
//     this._isImaginary = false; // this should be updated when implementing slider crank code with commented out code below
//     this._linkMass = mass;
//     this._linkMassMomentOfInertia = moi;
//     // this.isImaginary = isImaginary;
//   } // end of constructor
//
//   // getters ---------------------------------------------------------------------------------------------------------
//   /** Following getters needed for loop and angular vel and acc solver **/
//   get id(): string {
//     return this._id;
//   }
//
//   get joints() {
//     return this._joints;
//   }
//
//   get externalForcesOnLink(): Array<Force> {
//     return this._externalForcesOnLink;
//   }
//
//   get isGround(): Boolean {
//     return this._isGround;
//   }
//
//   get isImaginary(): boolean {
//     return this._isImaginary;
//   }
//
//   get angularVelocity(): number {
//     return this._angularVelocity;
//   }
//
//   set angularVelocity(angularVelocity: number) {
//     this._angularVelocity = angularVelocity;
//   }
//
//   get angularAcceleration(): number {
//     return this._angularAcceleration;
//   }
//
//   set angularAcceleration(angularAcceleration: number) {
//     this._angularAcceleration = angularAcceleration;
//   }
//
//   get isInput(): boolean {
//     return this._isInput;
//   }
//
//   get angle(): number {
//     return this._angle;
//   }
//
//   get mainJoint(): Joint {
//     return this._mainJoint;
//   }
//
//   get linkMass(): number {
//     return this._linkMass;
//   }
//
//   get linkMassMomentOfInertia(): number {
//     return this._linkMassMomentOfInertia;
//   }
//
//   // setters ---------------------------------------------------------------------------------------------------------
//   // todo: this should be removed and initialized in the constructor if possible and field access changed to readonly
//   set id(id: string) {
//     this._id = id;
//   }
//
//   set linkMass(value: number) {
//     this._linkMass = value;
//   }
//
//   set linkMassMomentOfInertia(value: number) {
//     this._linkMassMomentOfInertia = value;
//   }
//
//   set isInput(input: boolean) {
//     this._isInput = input;
//   }
//
//   set angle(angle: number) {
//     this._angle = angle;
//   }
//
//   set mainJoint(joint: Joint) {
//     this._mainJoint = joint;
//   }
// }
