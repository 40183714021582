import {AfterViewInit, Component, DoCheck, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import * as XLSX from 'xlsx';
import {IndependentFuncs as IndiFuncs} from 'src/functions/IndependentFuncs';
import {TimeSortedList} from '../../simulator/TimeSortedList';
import {Joint} from '../../classes/Joint';
import {Link} from '../../classes/Link';
import {Force} from '../../classes/Force';
import {ForceSolver} from '../../simulator/Analysis/ForceSolver';
import {KinematicsSolver} from '../../simulator/Analysis/KinematicsSolver';
import {ThreePosition} from '../../classes/ThreePosition';
import {PathPoint} from '../../classes/PathPoint';
import {Tag} from '../../classes/Tag';
import {GridComponent} from '../grid/grid.component';
import {InstantCenter} from '../../simulator/Joints_Links_Forces/InstantCenter';
import deepcopy from 'deepcopy';
import { DatePipe } from '@angular/common';

import {
  ChartComponent,
  ApexAxisChartSeries,
  ApexChart,
  ApexXAxis,
  ApexYAxis,
  ApexDataLabels,
  ApexTitleSubtitle,
  ApexStroke,
  ApexGrid
} from 'ng-apexcharts';
import {ImagLink, RealLink} from '../../simulator/Joints_Links_Forces/Link';
import {Simulator} from '../../simulator/Simulator';
import {forEach} from '@angular/router/src/utils/collection';
import {first} from 'rxjs/operators';

export interface ChartOptions {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis;
  dataLabels: ApexDataLabels;
  grid: ApexGrid;
  stroke: ApexStroke;
  title: ApexTitleSubtitle;
}

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.css'],
  // encapsulation: ViewEncapsulation.Emulated
})

export class ToolbarComponent implements OnInit, OnChanges {

  // Utilize links to add filtering and ultimately show absolute value
  // https://www.youtube.com/watch?v=FoazvbvWL4A&ab_channel=StudyMash
  // https://www.youtube.com/watch?v=1TFSibbnkj0&ab_channel=kudvenkat
  // angular.module('',[]).filter('absValue', function() {
  //   return function(num) {return Math.abs(num); }
  // });
  // private chosenForceMag: string;
  // private chosenForcePos: string;
  // private chosenJointPos: string;
  // private chosenLinearJoint: string;
  // private chosenLinearLink: string;
  // private chosenAngularLink: string;
  constructor() {
    // this.clockwise = false;
    // this.gravity = false;
    this.inputAngularVelocity = 10;
    this.clockwise = false;
    this.desiredPosition = 0;
    this.unit.selectedUnit = 'cm';
    // this.unit.selectedUnit = 'Metric';
    this.link.selectedLinkMaterial = 'Aluminum 6061';
    this.pin.selectedPinMaterial = 'Carbon Steel 1065';
    this.dynamicTabSelected = 0;
    // this.titleStaticChart = 'Model';
    // this.metricUnits.push('centimeter (cm)');
    // this.metricUnits.push('meter (m)');
    // this.metricUnits.push('gram (g)');
    // this.metricUnits.push('kilograms (kg)');
    // this.metricUnits.push('newton (kg*m/s^2)');
    // this.metricUnits.push('grams per cubic centimeter (g/cm^3)');
    // this.metricUnits.push('megapascal (MPa)');
    // this.metricUnits.push('g/cm^3');
    // this.metricUnits.push('MPa');
    // this.metricUnits.push('g/cm^3');
    // this.metricUnits.push('MPa');
    // this.metricUnits.push('cm');
    // this.metricUnits.push('cm');
    // this.metricUnits.push('cm');
    // this.englishUnits.push('inch (in)');
    // this.englishUnits.push('pound-mass (lbm)');
    // this.englishUnits.push('pound-force (lbm*ft/s^2)');
    // this.englishUnits.push('pounds-mass per cubic inch (lbm/in^3)');
    // this.englishUnits.push('pound-force per square inch (psi)');
    // this.englishUnits.push('lbm/in^3');
    // this.englishUnits.push('psi');
    // this.englishUnits.push('lbm/in^3');
    // this.englishUnits.push('psi');
    // this.englishUnits.push('in');
    // this.englishUnits.push('in');
    // this.englishUnits.push('in');
    // this.desiredUnits = this.metricUnits;
    // this.desiredUnits = this.cmUnits;
    // this.Aluminum6061.push(0.0975);
    // this.Aluminum6061.push(40000);
    // this.CarbonSteel1065.push(0.284);
    // this.CarbonSteel1065.push(71100);
    // this.CarbonSteel1018.push(0.284);
    // this.CarbonSteel1018.push(53700);
    // this.ABSPlastic.push(0.0376);
    // this.ABSPlastic.push(6160);
    // this.PLAPlastic.push(0.0452);
    // this.PLAPlastic.push(8700);
    // this.CastAcrylic.push(0.0426);
    // this.CastAcrylic.push(10900);
    // this.Plywood.push(0.0222);
    // this.Plywood.push(4500);
    this.linkName = 'Aluminum 6061';
    // this.linkDensity = this.Aluminum6061[0];
    // this.linkYieldStrength = this.Aluminum6061[1];
    this.pinName = 'Carbon Steel 1065';

    this.chartOptions = {
      series: [
        {
          name: 'Desktops',
          data: [10, 41, 35, 51, 49, 62, 69, 91, 148]
        }
      ],
      chart: {
        height: 350,
        type: 'line',
        zoom: {
          enabled: false
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'straight'
      },
      title: {
        text: 'Product Trends by Month',
        align: 'left'
      },
      grid: {
        row: {
          colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
          opacity: 0.5
        }
      },
      xaxis: {
        categories: [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep'
        ]
      },
      yaxis: {
        labels: {},
        title: {
          text: 'DoesThisWork',
          // style: {
          //   color: 'blue'
          // }
        }
      }
    };
    // this.pinDensity = this.CarbonSteel1065[0];
    // this.pinYieldStrength = this.CarbonSteel1065[1];
    /** Note, conversion from metric to english unit for density is 0.0361111 (13/360) and for yield strength is 144.92753 (10000/69) **/
  }

  @ViewChild('chart') static_line_chart: ChartComponent;
  @ViewChild('chart') dynamic_line_chart: ChartComponent;
  @ViewChild('chart') kinematic_line_chart: ChartComponent;
  @ViewChild('chart') ic_kinematic_line_chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;
  // public chartOptions: ChartOptions;
  showChart: boolean;
  // showChart: string;
  @Input() inputAngularVelocity: number;
  @Input() clockwise: boolean;
  @Input() gravity: boolean;
  @Input() mainUnit: string;
  @Input() jointArray: Joint[]; // The array (from main) containing all joints
  @Input() linkArray: Link[]; // The array (from main) containing all links
  @Input() forceArray: Force[];
  @Input() icArray: InstantCenter[];
  @Input() threePositionArray: ThreePosition[];
  @Input() pathPointArray: PathPoint[];
  @Input() comTagArray: Tag[];
  @Input() posTimeSortedList: TimeSortedList;
  @Input() analysis: Array<Array<string>>;
  @Input() titleRow: Array<string>;
  @Input() analysisLength: number;
  @Input() numJointsAndLinks: Array<number>;
  @Input() mechanismValid: boolean;
  @Input() mechanismValidForceAnalysis: boolean;
  @Input() mechanismValidKinematicAnalysis: boolean;
  @Input() simulator: Simulator;
  @Output() newCSVEmit = new EventEmitter<object>();
  @Output() downloadLinkageEmit = new EventEmitter<undefined>();
  @Output() copyURLEmit = new EventEmitter<undefined>();
  @Output() sendInitialVel: EventEmitter<number> = new EventEmitter<number>();
  @Output() sendAngVelDir: EventEmitter<string> = new EventEmitter<string>();
  @Output() sendUnit: EventEmitter<string> = new EventEmitter<string>();
  @Output() sendGravityToggle: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() sendSynthesisDisplay: EventEmitter<string> = new EventEmitter<string>();
  @Output() redrawPathPointsEmit: EventEmitter<string> = new EventEmitter<string>();
  @Output() IdTagStatusEmit: EventEmitter<string> = new EventEmitter<string>();
  @Output() CoMTagStatusEmit: EventEmitter<string> = new EventEmitter<string>();
  @Output() requestAnalysis: EventEmitter<string> = new EventEmitter<string>();
  @Output() refreshPathPointArrayEmit = new EventEmitter();
  @Output() showShapeSelector = new EventEmitter<[boolean, string]>();
  @Output() pathsPathPointHolder = new EventEmitter<string>();
  @Output() hideEditingShape = new EventEmitter();

  chart: Array<Array<string>>;
  analysis_diagram: SVGElement;
  // analysis_diagram: HTMLElement;
  // mechanism_diagrams: SVGElement;
  fileName = 'ExcelSheet.xlsx';
  // inputAngularVelocity: number;
  desiredPosition: number;
  // clockwise: boolean;
  // gravity: boolean;
  // desiredForceText: string;
  // desiredKinematicsText: string;
  utilizedLoops: string;
  staticForcesCheck: boolean;
  staticTorqueCheck: boolean;
  dynamicForcesCheck: boolean;
  dynamicTorqueCheck: boolean;

  linKinJointCheck: boolean;
  linKinJointPos: boolean;
  linKinJointVel: boolean;
  linKinJointAcc: boolean;
  linKinLinkCheck: boolean;
  dynamicAngLinkCheck: boolean;

  linKinLinkPos: boolean;
  linKinLinkVel: boolean;
  linKinLinkAcc: boolean;
  angKinLinkPos: boolean;
  angKinLinkVel: boolean;
  angKinLinkAcc: boolean;

  icPositionsCheck: boolean;
  linKinJointICCheck: boolean;
  linKinJointICPos: boolean;
  linKinJointICVel: boolean;
  linKinLinkICPos: boolean;
  linKinLinkICVel: boolean;
  angKinLinkICCheck: boolean;
  angKinLinkCheck: boolean;
  angKinLinkICPos: boolean;
  angKinLinkICVel: boolean;

  staticJointPositionsCheck: boolean;
  staticForcePositionsCheck: boolean;
  dynamicForcePositionsCheck: boolean;
  dynamicJointKinematicsCheck: boolean;
  dynamicLinkKinematicsCheck: boolean;

  // dynamicJointPositionsCheck: boolean;
  // dynamicLinkPositionsCheck: boolean;
  // dynamicJointVelocityCheck: boolean;
  // dynamicLinkVelocityCheck: boolean;
  // dynamicJointAccelerationCheck: boolean;
  // dynamicLinkAccelerationCheck: boolean;

  dynamicLinKinJointPos: boolean;
  dynamicLinKinJointVel: boolean;
  dynamicLinKinJointAcc: boolean;

  dynamicLinKinLinkPos: boolean;
  dynamicLinKinLinkVel: boolean;
  dynamicLinKinLinkAcc: boolean;

  dynamicAngKinLinkPos: boolean;
  dynamicAngKinLinkVel: boolean;
  dynamicAngKinLinkAcc: boolean;

  allLoopCheck: boolean;
  requiredLoopCheck: boolean;

  unit = {
    // selectedUnit: 'Metric'
    selectedUnit: 'cm'
  };

  link = {
    selectedLinkMaterial: 'Aluminum 6061'
  };

  pin = {
    selectedPinMaterial: 'Carbon Steel 1065'
  };

  staticTabSelected = 0;
  dynamicTabSelected = 0;
  kinematicLoopTabSelected = 0;
  kinematicICTabSelected = 0;

  synthesis = {
    selectedSynthesis: 'none'
  };

  jointPlot = {
    jointID: 'none'
  };

  forcePosPlot = {
    force: 'none'
  };

  forceMagPlot = {
    force: 'none'
  };

  kinematicLoopLinearJoint = {
    joint: 'none'
  };

  kinematicLoopLinearLink = {
    link: 'none'
  };

  kinematicLoopAngularLink = {
    link: 'none'
  };

  forceAnalysis = {
    selectedAnalysis: 'none'
  };
  // staticAnalysis = {
  //   selectedAnalysis: 'none'
  // };
  //
  // dynamicAnalysis = {
  //   selectedAnalysis: 'none'
  // };

  kinematicLoopAnalysis = {
    selectedAnalysis: 'none'
  };

  synthesisDisplay = {
    selectedSynthesisDisplay: 'none'
  };

  connection = {
    selectedConnection: 'none'
  };

  static_joints = [
    {id: 'none', label: 'none'},
  ];

  jointAnalyses = [
    {id: 'Joint Forces', label: 'Joint Forces'},
    {id: 'Input Torque', label: 'Input Torque'},
    // {id: 'force_position', label: 'force_position'},
    // {id: 'joint_position', label: 'joint_position'},
    // { id: 'position', label: 'position'},
    // { id: 'velocity', label: 'velocity'},
    // { id: 'acceleration', label: 'acceleration'},
  ];

  kinematicLoopAnalyses = [
    {id: 'Linear Joint Pos', label: 'Linear Joint Pos'},
    {id: 'Linear Joint Vel', label: 'Linear Joint Vel'},
    {id: 'Linear Joint Acc', label: 'Linear Joint Acc'},
    {id: 'Linear Link\'s CoM Pos', label: 'Linear Link\'s CoM Pos'},
    {id: 'Linear Link\'s CoM Vel', label: 'Linear Link\'s CoM Vel'},
    {id: 'Linear Link\'s CoM Acc', label: 'Linear Link\'s CoM Acc'},
    {id: 'Angular Link Pos', label: 'Angular Link Pos'},
    {id: 'Angular Link Vel', label: 'Angular Link Vel'},
    {id: 'Angular Link Acc', label: 'Angular Link Acc'},
    // {id: 'Angular Velocity', label: 'Angular Velocity'},
    // {id: 'Angular Acceleration', label: 'Angular Acceleration'},
    // {id: 'Linear Velocity', label: 'Linear Velocity'},
    // {id: 'Linear Acceleration', label: 'Linear Acceleration'},
  ];

  syntheses = [
    {id: 'none', label: 'none'},
    {id: 'path_point', label: 'path_point'},
    // { id: 'three_pos', label: 'three_pos'},
    // { id: 'gear_syn', labeL: 'gear_syn'},
  ];

  synthesisDisplays = [
    {id: 'none', label: 'none'},
    {id: 'path_point', label: 'path_point'},
    // { id: 'three_pos', label: 'three_pos'},
    // { id: 'gear_syn', labeL: 'gear_syn'},
  ];

  connections = [
    {id: 'none', label: 'none'},
    {id: 'line', label: 'line'},
    {id: 'curve', label: 'curve'},
  ];

  units = [
    {id: 'cm', label: 'cm'},
    {id: 'm', label: 'm'},
    // { id: 'km', label: 'km'},
    // { id: 'in', label: 'in'},
    // { id: 'ft', label: 'ft'}
    // { id: 'Metric', label: 'Metric'},
    // { id: 'English', label: 'English'}
  ];

  linkMaterials = [
    {id: 'Aluminum 6061', label: 'Aluminum 6061'},
    {id: 'Carbon Steel 1065', label: 'Carbon Steel 1065'},
    {id: 'Carbon Steel 1018', label: 'Carbon Steel 1018'},
    {id: 'ABS Plastic', label: 'ABS Plastic'},
    {id: 'PLA Plastic', label: 'PLA Plastic'},
    {id: 'Cast Acrylic', label: 'Cast Acrylic'},
    {id: 'Plywood', label: 'Plywood'}
  ];

  pinMaterials = [
    {id: 'Aluminum 6061', label: 'Aluminum 6061'},
    {id: 'Carbon Steel 1065', label: 'Carbon Steel 1065'},
    {id: 'Carbon Steel 1018', label: 'Carbon Steel 1018'},
    {id: 'ABS Plastic', label: 'ABS Plastic'},
    {id: 'PLA Plastic', label: 'PLA Plastic'},
    {id: 'Cast Acrylic', label: 'Cast Acrylic'},
    {id: 'Plywood', label: 'Plywood'}
  ];

  // cmUnits = Array<string>();
  // mUnits = Array<string>();
  // kmUnits = Array<string>();
  // inUnits = Array<string>();
  // ftUnits = Array<string>();
  // metricUnits = Array<string>();
  // englishUnits = Array<string>();
  // desiredUnits = Array<string>();

  linkName: string;
  // linkDensity: number;
  // linkYieldStrength: number;
  pinName: string;
  // pinDensity: number;
  // pinYieldStrength: number;

  threePosMode = false;
  pathPointMode = false;
  gearSynMode = false;
  showThreePosition = false;
  showPathPoint = false;
  showGear = false;
  pathPointsConnected = true;
  // Aluminum6061 = Array<number>();
  // CarbonSteel1065 = Array<number>();
  // CarbonSteel1018 = Array<number>();
  // ABSPlastic = Array<number>();
  // PLAPlastic = Array<number>();
  // CastAcrylic = Array<number>();
  // Plywood = Array<number>();

  ngOnInit() {
    // this.clockwise = false;
    // this.gravity = false;
    localStorage.setItem('clockwise', JSON.stringify(this.clockwise));
    localStorage.setItem('gravity', JSON.stringify(this.gravity));
    // this.chosenForceMag = '';
    // this.chosenForcePos = '';
    // this.chosenJointPos = '';
    this.linKinJointCheck = true;
    this.linKinJointPos = true;
    this.linKinJointVel = true;
    this.linKinJointAcc = true;
    this.linKinLinkCheck = true;
    this.linKinLinkPos = true;
    this.linKinLinkVel = true;
    this.linKinLinkAcc = true;
    this.angKinLinkPos = true;
    this.angKinLinkVel = true;
    this.angKinLinkAcc = true;

    this.linKinJointICCheck = true;
    this.linKinJointICPos = true;
    this.linKinJointICVel = true;

    this.linKinLinkICPos = true;
    this.linKinLinkICVel = true;

    this.angKinLinkICCheck = true;
    this.angKinLinkICPos = true;
    this.angKinLinkICVel = true;

    this.staticForcesCheck = true;
    this.staticTorqueCheck = true;
    this.dynamicForcesCheck = true;
    this.dynamicTorqueCheck = true;

    this.staticJointPositionsCheck = true;
    this.staticForcePositionsCheck = true;

    this.dynamicForcePositionsCheck = true;

    this.dynamicJointKinematicsCheck = true;
    this.dynamicLinKinJointPos = true;
    this.dynamicLinKinJointVel = true;
    this.dynamicLinKinJointAcc = true;

    this.dynamicLinkKinematicsCheck = true;
    this.dynamicLinKinLinkPos = true;
    this.dynamicLinKinLinkVel = true;
    this.dynamicLinKinLinkAcc = true;

    this.dynamicAngLinkCheck = true;
    this.dynamicAngKinLinkPos = true;
    this.dynamicAngKinLinkVel = true;
    this.dynamicAngKinLinkAcc = true;

    this.angKinLinkCheck = true;

    this.allLoopCheck = true;
    this.requiredLoopCheck = true;

    this.icPositionsCheck = true;
    this.showChart = false;

    // this.dynamicJointPositionsCheck = true;
    // this.dynamicLinkPositionsCheck = true;
    // this.dynamicJointVelocityCheck = true;
    // this.dynamicLinkVelocityCheck = true;
    // this.dynamicJointAccelerationCheck = true;
    // this.dynamicLinkAccelerationCheck = true;
    // this.clockwise = JSON.parse(localStorage.getItem('clockwise'));
    // this.gravity = JSON.parse(localStorage.getItem('gravity'));
    // const clockwiseBoolean = JSON.parse(localStorage.getItem('clockwise'));
    // const counterclockwiseBoolean = JSON.parse(localStorage.getItem('counterclockwise'));
    // const gravityBoolean = JSON.parse(localStorage.getItem('gravity'));

    // if (JSON.parse(localStorage.getItem('clockwise'))) {
    //   this.clockwise = JSON.parse(localStorage.getItem('clockwise'));
    // } else {
    //   localStorage.setItem('clockwise', JSON.stringify(false));
    // }
    // if (JSON.parse(localStorage.getItem('gravity'))) {
    //   this.gravity = JSON.parse(localStorage.getItem('gravity'));
    // } else {
    //   localStorage.setItem('gravity', JSON.stringify(false));
    // }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.mainUnit) {
      this.unit.selectedUnit = this.mainUnit;
    }
  }

  setInputMagnitudeAngVel(item) {
    // if (item.key !== '.') {
    this.inputAngularVelocity = item.target.value;
    // }
    const clockDirection = this.clockwise === true ? 'clockwise' : 'counterclockwise';
    const directionClockwise = <HTMLButtonElement>document.getElementById('clockwise');
    const directionCounterClockwise = <HTMLButtonElement>document.getElementById('counterclockwise');
    switch (clockDirection) {
      case 'clockwise':
        if (directionClockwise === null) {
          directionCounterClockwise.id = 'clockwise';
        }
        break;
      case 'counterclockwise':
        if (directionCounterClockwise === null) {
          directionClockwise.id = 'counterclockwise';
        }
        break;
    }
    // const angVel = this.inputAngularVelocity;
    // let angVel: number;
    // angVel = this.clockwise === true ? -1 * Math.abs(this.inputAngularVelocity) : Math.abs(this.inputAngularVelocity);
    // angVel = angVel * (2 * Math.PI) / 60;
    // alert('The initial angular velocity has been set to ' + this.inputAngularVelocity + ' rpm going ' + clockDirection + '.');
    this.sendInitialVel.emit(this.inputAngularVelocity);
    // this.sendInitialVel.emit(angVel);
    this.sendAngVelDir.emit(clockDirection);
  }

  // changeAngularVelocity(angularVelocity) {
  //   this.inputAngularVelocity = angularVelocity;
  //   this.clockwise = angularVelocity > 0;
  // }

  // changeGravity(gravity) {
  //   gravity = this.gravity;
  // }

  setClockwise(event) {
    this.clockwise = event.target.checked === true;
    const clockwise_string = this.clockwise ? 'clockwise' : 'counterclockwise';

    const directionClockwise = <HTMLButtonElement>document.getElementById('clockwise');
    const directionCounterClockwise = <HTMLButtonElement>document.getElementById('counterclockwise');
    switch (clockwise_string) {
      case 'clockwise':
        if (directionClockwise === null) {
          directionCounterClockwise.id = 'clockwise';
        }
        break;
      case 'counterclockwise':
        if (directionCounterClockwise === null) {
          directionClockwise.id = 'counterclockwise';
        }
    }
    localStorage.setItem('clockwise', JSON.stringify(true));
    this.sendAngVelDir.emit(clockwise_string);
  }

  setGravity(event) {
    this.gravity = event.target.checked === true;
    localStorage.setItem('gravity', JSON.stringify(true));
    this.sendGravityToggle.emit(this.gravity);
  }

  changeInitialVelDirection(direction) {
    this.clockwise = direction === 'clockwise';
    localStorage.setItem(direction, JSON.stringify(false));
  }

  setCounterClockwise(event) {
    this.clockwise = event.target.checked !== true;

    const clockwise_string = this.clockwise ? 'clockwise' : 'counterclockwise';

    const directionClockwise = <HTMLButtonElement>document.getElementById('clockwise');
    const directionCounterClockwise = <HTMLButtonElement>document.getElementById('counterclockwise');
    switch (clockwise_string) {
      case 'clockwise':
        if (directionClockwise === null) {
          directionCounterClockwise.id = 'clockwise';
        }
        break;
      case 'counterclockwise':
        if (directionCounterClockwise === null) {
          directionClockwise.id = 'counterclockwise';
        }
    }
    this.sendAngVelDir.emit(clockwise_string);
    localStorage.setItem('clockwise', JSON.stringify(false));
  }

  setGravityOff(event) {
    this.gravity = event.target.checked !== true;
    localStorage.setItem('gravity', JSON.stringify(false));
    this.sendGravityToggle.emit(this.gravity);
  }

  idTagPressed() {
    const dd = <HTMLButtonElement>document.getElementById('showIDOn');
    return dd !== null;
  }

  comTagPressed() {
    const dd = <HTMLButtonElement>document.getElementById('showCoMOn');
    return dd !== null;
  }

  setOption(e) {
    switch (e.target.value) {
      // kinematics section
      // kin for joints
      case 'linKinJointCheck':
        this.linKinJointCheck = !this.linKinJointCheck;
        break;
      case 'linKinJointPos':
        this.linKinJointPos = !this.linKinJointPos;
        break;
      case 'linKinJointVel':
        this.linKinJointVel = !this.linKinJointVel;
        break;
      case 'linKinJointAcc':
        this.linKinJointAcc = !this.linKinJointAcc;
        break;
      // kin for linear links
      case 'linKinLinkCheck':
        this.linKinLinkCheck = !this.linKinLinkCheck;
        break;
      case 'linKinLinkPos':
        this.linKinLinkPos = !this.linKinLinkPos;
        break;
      case 'linKinLinkVel':
        this.linKinLinkVel = !this.linKinLinkVel;
        break;
      case 'linKinLinkAcc':
        this.linKinLinkAcc = !this.linKinLinkAcc;
        break;
      // kin for angular links
      case 'angKinLinkCheck':
        this.angKinLinkCheck = !this.angKinLinkCheck;
        break;
      case 'angKinLinkPos':
        this.angKinLinkPos = !this.angKinLinkPos;
        break;
      case 'angKinLinkVel':
        this.angKinLinkVel = !this.angKinLinkVel;
        break;
      case 'angKinLinkAcc':
        this.angKinLinkAcc = !this.angKinLinkAcc;
        break;
      ///////////////////////////
      // check for statics
      case 'staticForcesCheck':
        this.staticForcesCheck = !this.staticForcesCheck;
        break;
      case 'staticTorqueCheck':
        this.staticTorqueCheck = !this.staticTorqueCheck;
        break;
      case 'staticForcePositionsCheck':
        this.staticForcePositionsCheck = !this.staticForcePositionsCheck;
        break;
      case 'staticJointPositionsCheck':
        this.staticJointPositionsCheck = !this.staticJointPositionsCheck;
        break;
      ///////////////
      // check for dynamics
      case 'dynamicForcesCheck':
        this.dynamicForcesCheck = !this.dynamicForcesCheck;
        break;
      case 'dynamicTorqueCheck':
        this.dynamicTorqueCheck = !this.dynamicTorqueCheck;
        break;
      case 'dynamicForcePositionsCheck':
        this.dynamicForcePositionsCheck = !this.dynamicForcePositionsCheck;
        break;
      case 'dynamicJointKinematicsCheck':
        this.dynamicJointKinematicsCheck = !this.dynamicJointKinematicsCheck;
        break;
      case 'dynamicLinKinJointPos':
        this.dynamicLinKinJointPos = !this.dynamicLinKinJointPos;
        break;
      case 'dynamicLinKinJointVel':
        this.dynamicLinKinJointVel = !this.dynamicLinKinJointVel;
        break;
      case 'dynamicLinKinJointAcc':
        this.dynamicLinKinJointAcc = !this.dynamicLinKinJointAcc;
        break;

      case 'dynamicLinkKinematicsCheck':
        this.dynamicLinkKinematicsCheck = !this.dynamicLinkKinematicsCheck;
        break;
      case 'dynamicLinKinLinkPos':
        this.dynamicLinKinLinkPos = !this.dynamicLinKinLinkPos;
        break;
      case 'dynamicLinKinLinkVel':
        this.dynamicLinKinLinkVel = !this.dynamicLinKinLinkVel;
        break;
      case 'dynamicLinKinLinkAcc':
        this.dynamicLinKinLinkAcc = !this.dynamicLinKinLinkAcc;
        break;

      case 'dynamicAngLinkCheck':
        this.dynamicAngLinkCheck = !this.dynamicAngLinkCheck;
        break;
      case 'dynamicAngKinLinkPos':
        this.dynamicAngKinLinkPos = !this.dynamicAngKinLinkPos;
        break;
      case 'dynamicAngKinLinkVel':
        this.dynamicAngKinLinkVel = !this.dynamicAngKinLinkVel;
        break;
      case 'dynamicAngKinLinkAcc':
        this.dynamicAngKinLinkAcc = !this.dynamicAngKinLinkAcc;
        break;
      /////////
      // check for IC
      case 'icPositionsCheck':
        this.icPositionsCheck = !this.icPositionsCheck;
        break;
      case 'linKinJointICCheck':
        this.linKinJointICCheck = !this.linKinJointICCheck;
        break;
      case 'linKinJointICPos':
        this.linKinJointICPos = !this.linKinJointICPos;
        break;
      case 'linKinJointICVel':
        this.linKinJointICVel = !this.linKinJointICVel;
        break;

      case 'angKinLinkICCheck':
        this.angKinLinkICCheck = !this.angKinLinkICCheck;
        break;
      case 'angKinLinkICPos':
        this.angKinLinkICPos = !this.angKinLinkICPos;
        break;
      case 'angKinLinkICVel':
        this.angKinLinkICVel = !this.angKinLinkICVel;
        break;
      ////////////
      // check for loops
      case 'allLoopCheck':
        this.allLoopCheck = !this.allLoopCheck;
        break;
      case 'requiredLoopCheck':
        this.requiredLoopCheck = !this.requiredLoopCheck;
        break;
    }
  }

  setSynthesis(synthesisType: string) {
    // const animation_bar = <HTMLElement>document.getElementById('animationBar');
    const dof = <HTMLElement>document.getElementById('dofWrapper');
    const animationButtons = <HTMLElement>document.getElementById('animationBtns');
    const slider = <HTMLElement>document.getElementById('slidecontainer');
    const comOn = <HTMLElement>document.getElementById('showCoM');
    const comOff = <HTMLElement>document.getElementById('showCoMOn');
    const linkage_table = <HTMLElement>document.getElementById('linkageTable');
    const three_position_table = <HTMLElement>document.getElementById('threePositionTable');
    const path_point_table = <HTMLElement>document.getElementById('pathPointTable');
    const gear_syn_table = <HTMLElement>document.getElementById('gearSynTable');
    // const links_selection_table = <HTMLElement>document.getElementById('shape-selector-table');
    // const links_selection_table = <HTMLElement>document.getElementById('shape-selector');
    // const shape_selector = document.getElementById('shape-selector');
    switch (synthesisType) {
      case 'none':
        linkage_table.style.visibility = 'visible';
        three_position_table.style.visibility = 'hidden';
        path_point_table.style.visibility = 'hidden';
        gear_syn_table.style.visibility = 'hidden';
        // animation_bar.style.visibility = 'visible';
        dof.style.visibility = 'visible';
        animationButtons.style.visibility = 'visible';
        slider.style.visibility = 'visible';
        if (comOn === null) {
          comOff.style.visibility = 'visible';
        } else {
          comOn.style.visibility = 'visible';
        }
        // show_table_button.
        this.jointArray.forEach(joint => {
          joint.svg.setAttribute('visibility', 'visible');
        });
        this.hideEditingShape.emit();
        this.linkArray.forEach(link => {
          link.linkSVG.setAttribute('visibility', 'visible');
        });
        this.forceArray.forEach(force => {
          force.svg.setAttribute('visibility', 'visible');
          force.start.svg.setAttribute('visibility', 'visible');
          force.end.svg.setAttribute('visibility', 'visible');
        });
        this.threePositionArray.forEach(threePosition => {
          threePosition.svg.setAttribute('visibility', 'hidden');
        });
        this.pathPointArray.forEach(pathPoint => {
          pathPoint.svg.setAttribute('visibility', 'hidden');
        });
        // if (this.idTagPressed()) {
        //   this.IdTagStatusEmit.emit('show');
        // } else {
        //   this.IdTagStatusEmit.emit('hide');
        // }
        this.IdTagStatusEmit.emit('hide');
        if (this.idTagPressed()) {
          this.IdTagStatusEmit.emit('show');
        }
        if (this.comTagPressed()) {
          this.CoMTagStatusEmit.emit('show');
        } else {
          this.CoMTagStatusEmit.emit('hide');
        }
        this.showShapeSelector.emit([false, synthesisType]);
        // shape_selector.style.display = 'none';
        // this.comTagArray.forEach(comTag => {
        //   comTag.svg.setAttribute('visibility', 'visible');
        // });
        // this.jointLinkTagArray.forEach(jointLinkTag => {
        //   jointLinkTag.svg.setAttribute('visibility', 'visible');
        // });
        this.pathsPathPointHolder.emit('hide');
        break;
      case 'path_point':
        linkage_table.style.visibility = 'hidden';
        three_position_table.style.visibility = 'hidden';
        path_point_table.style.visibility = 'visible';
        gear_syn_table.style.visibility = 'hidden';
        // animation_bar.style.visibility = 'hidden';
        dof.style.visibility = 'hidden';
        animationButtons.style.visibility = 'hidden';
        slider.style.visibility = 'hidden';
        if (comOn === null) {
          comOff.style.visibility = 'hidden';
        } else {
          comOn.style.visibility = 'hidden';
        }
        // show_table_button.
        this.jointArray.forEach(joint => {
          joint.svg.setAttribute('visibility', 'hidden');
        });
        this.hideEditingShape.emit();
        this.linkArray.forEach(link => {
          link.linkSVG.setAttribute('visibility', 'hidden');
        });
        this.forceArray.forEach(force => {
          force.svg.setAttribute('visibility', 'hidden');
          force.start.svg.setAttribute('visibility', 'hidden');
          force.end.svg.setAttribute('visibility', 'hidden');
        });
        this.threePositionArray.forEach(threePosition => {
          threePosition.svg.setAttribute('visibility', 'hidden');
        });
        this.pathPointArray.forEach(pathPoint => {
          pathPoint.svg.setAttribute('visibility', 'visible');
        });
        // this.comTagArray.forEach(comTag => {
        //   comTag.svg.setAttribute('visibility', 'hidden');
        // });
        // this.jointLinkTagArray.forEach(jointLinkTag => {
        //   jointLinkTag.svg.setAttribute('visibility', 'hidden');
        // });
        this.IdTagStatusEmit.emit('hide');
        if (this.idTagPressed()) {
          this.IdTagStatusEmit.emit('show');
        }
        this.CoMTagStatusEmit.emit('hide');
        this.showShapeSelector.emit([true, synthesisType]);
        // this.hideEditingShape.emit();
        // shape_selector.style.display = 'block';
        this.pathsPathPointHolder.emit('visible');
        break;
      case 'three_pos':
        linkage_table.style.visibility = 'hidden';
        three_position_table.style.visibility = 'visible';
        path_point_table.style.visibility = 'hidden';
        gear_syn_table.style.visibility = 'hidden';
        dof.style.visibility = 'hidden';
        animationButtons.style.visibility = 'hidden';
        slider.style.visibility = 'hidden';
        if (comOn === null) {
          comOff.style.visibility = 'hidden';
        } else {
          comOn.style.visibility = 'hidden';
        }
        // show_table_button.
        this.jointArray.forEach(joint => {
          joint.svg.setAttribute('visibility', 'hidden');
        });
        this.hideEditingShape.emit();
        this.linkArray.forEach(link => {
          link.linkSVG.setAttribute('visibility', 'hidden');
        });
        this.forceArray.forEach(force => {
          force.svg.setAttribute('visibility', 'hidden');
          force.start.svg.setAttribute('visibility', 'hidden');
          force.end.svg.setAttribute('visibility', 'hidden');
        });
        this.threePositionArray.forEach(threePosition => {
          threePosition.svg.setAttribute('visibility', 'visible');
        });
        this.pathPointArray.forEach(pathPoint => {
          pathPoint.svg.setAttribute('visibility', 'hidden');
        });
        this.IdTagStatusEmit.emit('hide');
        if (this.idTagPressed()) {
          this.IdTagStatusEmit.emit('show');
        }
        this.CoMTagStatusEmit.emit('hide');
        // this.comTagArray.forEach(comTag => {
        //   comTag.svg.setAttribute('visibility', 'hidden');
        // });
        // this.jointLinkTagArray.forEach(jointLinkTag => {
        //   jointLinkTag.svg.setAttribute('visibility', 'hidden');
        // });
        this.showShapeSelector.emit([true, synthesisType]);
        // this.hideEditingShape.emit();
        // shape_selector.style.display = 'none';
        break;
      case 'gear_syn':
        linkage_table.style.visibility = 'hidden';
        three_position_table.style.visibility = 'hidden';
        path_point_table.style.visibility = 'hidden';
        gear_syn_table.style.visibility = 'visible';
        dof.style.visibility = 'hidden';
        animationButtons.style.visibility = 'hidden';
        slider.style.visibility = 'hidden';
        if (comOn === null) {
          comOff.style.visibility = 'hidden';
        } else {
          comOn.style.visibility = 'hidden';
        }
        // show_table_button.
        this.jointArray.forEach(joint => {
          joint.svg.setAttribute('visibility', 'hidden');
        });
        this.hideEditingShape.emit();
        this.linkArray.forEach(link => {
          link.linkSVG.setAttribute('visibility', 'hidden');
        });
        this.forceArray.forEach(force => {
          force.svg.setAttribute('visibility', 'hidden');
          force.start.svg.setAttribute('visibility', 'hidden');
          force.end.svg.setAttribute('visibility', 'hidden');
        });
        this.threePositionArray.forEach(threePosition => {
          threePosition.svg.setAttribute('visibility', 'hidden');
        });
        this.pathPointArray.forEach(pathPoint => {
          pathPoint.svg.setAttribute('visibility', 'hidden');
        });
        this.IdTagStatusEmit.emit('hide');
        if (this.idTagPressed()) {
          this.IdTagStatusEmit.emit('show');
        }
        this.CoMTagStatusEmit.emit('hide');
        this.showShapeSelector.emit([true, synthesisType]);
        // this.hideEditingShape.emit();
        // shape_selector.style.display = 'none';
        // this.comTagArray.forEach(comTag => {
        //   comTag.svg.setAttribute('visibility', 'hidden');
        // });
        // this.jointLinkTagArray.forEach(jointLinkTag => {
        //   jointLinkTag.svg.setAttribute('visibility', 'hidden');
        // });
        break;
    }
  }

  setConnection(connection: string) {
    this.redrawPathPointsEmit.emit(connection);
    this.IdTagStatusEmit.emit('hide');
    if (this.idTagPressed()) {
      this.IdTagStatusEmit.emit('show');
    }
  }

  setSynthesisDisplay(syn_type: string) {
    this.refreshPathPointArrayEmit.emit();
    switch (syn_type) {
      case 'none':
        this.redrawPathPointsEmit.emit();
        this.IdTagStatusEmit.emit('hide');
        if (this.idTagPressed()) {
          this.IdTagStatusEmit.emit('show');
        }
        // this.pathsPathPointHolder.innerHTML = '';
        break;
      case 'path_point':
        // this.redrawPathPointsEmit.emit();
        this.IdTagStatusEmit.emit('hide');
        if (this.idTagPressed()) {
          this.IdTagStatusEmit.emit('show');
        }
        // this.pathsPathPointHolder.innerHTML = '';
        break;
      case 'three_pos':
        this.redrawPathPointsEmit.emit();
        break;
      case 'gear_syn':
        this.redrawPathPointsEmit.emit();
        break;
    }
  }

  changeInitialVel() {
    const clockDirection = this.clockwise === true ? 'clockwise' : 'counterclockwise';
    const directionClockwise = <HTMLButtonElement>document.getElementById('clockwise');
    const directionCounterClockwise = <HTMLButtonElement>document.getElementById('counterclockwise');
    switch (clockDirection) {
      case 'clockwise':
        if (directionClockwise === null) {
          directionCounterClockwise.id = 'clockwise';
        }
        break;
      case 'counterclockwise':
        if (directionCounterClockwise === null) {
          directionClockwise.id = 'counterclockwise';
        }
        break;
    }
    const angVel = this.inputAngularVelocity;
    // let angVel: number;
    // angVel = this.clockwise === true ? -1 * Math.abs(this.inputAngularVelocity) : Math.abs(this.inputAngularVelocity);
    // angVel = angVel * (2 * Math.PI) / 60;
    alert('The initial angular velocity has been set to ' + this.inputAngularVelocity + ' rpm going ' + clockDirection + '.');
    this.sendInitialVel.emit(angVel);
    this.sendAngVelDir.emit(clockDirection);
  }

  //
  // showTabs(elementName) {
  //   console.log('Hey');
  //   const x = <HTMLElement>document.getElementById(elementName);
  //   if (x.style.display === 'none') {
  //     x.style.display = 'block';
  //   } else {
  //     x.style.display = 'none';
  //   }
  // }

  pop(div) {
    if (div !== 'popUIProperties' && div !== 'popLinkProperties') {
      if (this.mechanismValid === true) {
        this.desiredPosition = 0;
        // let text = '';
        // let inputJoint: Joint;
        if ((div === 'popStatic' || div === 'popDynamic') && !this.mechanismValidForceAnalysis) {
          alert('Insert a force or turn on gravity');
          return;
        }
        if ((div === 'popStatic') && this.gravity) {
          let dontRecheckWithUser = true;
          this.linkArray.forEach(l => {
            if (!dontRecheckWithUser) {
              return;
            }
            if (l.mass === 1 || l.massMomentOfInertia === 1) {
              dontRecheckWithUser = false;
            }
          });
          if (!dontRecheckWithUser) {
            if (confirm('Mass or mass moment of inertia is still its original default value. Still continue?')) {
            } else {
              // Do nothing!
              return;
            }
          }
        }
        if (div === 'popDynamic') {
          let dontRecheckWithUser = true;
          this.linkArray.forEach(l => {
            if (!dontRecheckWithUser) {
              return;
            }
            if (l.mass === 1 || l.massMomentOfInertia === 1) {
              dontRecheckWithUser = false;
            }
          });
          if (!dontRecheckWithUser) {
            if (confirm('Mass or mass moment of inertia is still its original default value. Still continue?')) {
            } else {
              // Do nothing!
              return;
            }
          }
        }
        switch (div) {
          case 'popLoop':
            this.requestAnalysis.emit('loops');
            break;
          case 'popStatic':
            this.requestAnalysis.emit('statics');

            // this.showcaseForceEquations();
            break;
          case 'popDynamic':
            this.requestAnalysis.emit('dynamics');
            // this.showcaseForceEquations();
            // this.linkArray.forEach(l => {
            //   l.joints.forEach(j => {
            //       const val = ForceSolver.jointsForcesDirectionsMap.get(l.id + j.id) as string;
            //       // text += '\n' + 'Forces on link ' + l.id + ', joint ' + j.id + ':' + val;
            //       if (j.input) {
            //         inputJoint = j;
            //       }
            //     }
            //   );
            // });
            // text += '\n' + 'Torque on joint ' + inputJoint.id + ': +';
            // this.desiredForceText = text;
            break;
          case 'popKinematicIC':
            this.requestAnalysis.emit('kinematic_ics');
            break;
          case 'popKinematicLoop':
            this.requestAnalysis.emit('kinematic_loops');
            // this.desiredKinematicsText = 'Kin Eqs Ang Vel' + '\n';
            // this.desiredKinematicsText = 'Will be available in the future';
            break;
        }
        document.getElementById(div).style.display = 'block';
        document.getElementById(div).addEventListener('keydown', function () { // triggers on pressing enter in table to update then refresh
            const e = <KeyboardEvent>event;
            const KeyID = e.keyCode;
            switch (KeyID) {
              case 27:
                console.log('escape');
                break;
              default:
                break;
            }
          }
        );
      } else {
        IndiFuncs.showErrorNotification('DOF not = 1 or no input joint');
      }
    } else {
      document.getElementById(div).style.display = 'block';
      document.getElementById(div).addEventListener('keydown', function () {
        const e = <KeyboardEvent>event;
        const KeyID = e.keyCode;
        switch (KeyID) {
          case 27:
            console.log('escape');
            break;
          default:
            break;
        }
      });
    }
    switch (div) {
      case 'popStatic':
        // this.showcaseForceEquations();
        break;
      default:
        break;
    }
  }

  // changeLinkMaterial(linkMaterial) {
  //   this.link.selectedLinkMaterial = linkMaterial;
  //   this.linkName = linkMaterial;
  //   this.changeProperties('link');
  // }

  // changePinMaterial(pinMaterial) {
  //   this.pin.selectedPinMaterial = pinMaterial;
  //   this.pinName = pinMaterial;
  //   this.changeProperties('pin');
  // }

  changeUnit(unit) {
    this.unit.selectedUnit = unit;
    // this.changeProperties('link');
    // this.changeProperties('pin');
    this.sendUnit.emit(unit);
  }

  determineChart(analysis, type_of, more_type) {
    // const datum_X = [];
    // const datum_Y = [];
    // const datum_Z = [];
    let data1Title = '';
    let data2Title = '';
    let data3Title = '';
    let chartTitle = '';
    const xAxisTitle = 'Time-steps';
    let yAxisTitle = '';
    let datum = [];
    let categories = [];
    const seriesData =  [];
    let posLinUnit = '(cm)';
    let velLinUnit = '(cm/s)';
    let accLinUnit = '(cm/s^2)';
    const posAngUnit = '(degrees)';
    // const posAngUnit = '(rad)';
    const velAngUnit = '((rad)/s)';
    const accAngUnit = '((rad)/s^2)';
    if (this.unit.selectedUnit === 'm') {
      posLinUnit = 'm';
      velLinUnit = 'm/s';
      accLinUnit = 'm/s^2';
    }
    switch (analysis) {
      case 'Input Torque':
        chartTitle = 'Torque for Mechanism';
        data1Title = 'Torque (Nm)';
        yAxisTitle = 'Torque (Nm)';
        [datum, categories] = this.determineAnalysis(analysis, type_of, more_type);
        seriesData.push({name: data1Title, type: 'line', data: datum[0]});
        break;
      case 'Joint Forces':
        chartTitle = 'Force Magnitudes';
        data1Title = 'Force ' + type_of + ' X-Magnitude (N)';
        data2Title = 'Force ' + type_of + ' Y-Magnitude (N)';
        data3Title = 'Abs Force (N)';
        yAxisTitle = 'Force (N)';
        [datum, categories] = this.determineAnalysis(analysis, type_of, more_type);
        seriesData.push({name: data1Title, type: 'line', data: datum[0]});
        seriesData.push({name: data2Title, type: 'line', data: datum[1]});
        seriesData.push({name: data3Title, type: 'line', data: datum[2]});
        break;
      case 'Linear Joint Pos':
        chartTitle = 'Joint\'s Linear Position';
        data1Title = 'Joint ' + type_of + ' X Position ' + posLinUnit;
        data2Title = 'Joint ' + type_of + ' Y Position ' + posLinUnit;
        yAxisTitle = 'Position ' + posLinUnit;
        [datum, categories] = this.determineAnalysis(analysis, type_of, more_type);
        seriesData.push({name: data1Title, type: 'line', data: datum[0]});
        seriesData.push({name: data2Title, type: 'line', data: datum[1]});
        break;
      case 'Linear Joint Vel':
        chartTitle = 'Joint\'s Linear Velocity';
        data1Title = 'Joint ' + type_of + ' X Velocity ' + velLinUnit;
        data2Title = 'Joint ' + type_of + ' Y Velocity ' + velLinUnit;
        data3Title = 'Absolute Velocity ' + velLinUnit;
        yAxisTitle = 'Velocity ' + velLinUnit;
        [datum, categories] = this.determineAnalysis(analysis, type_of, more_type);
        seriesData.push({name: data1Title, type: 'line', data: datum[0]});
        seriesData.push({name: data2Title, type: 'line', data: datum[1]});
        seriesData.push({name: data3Title, type: 'line', data: datum[2]});
        break;
      case 'Linear Joint Acc':
        chartTitle = 'Joint\'s Linear Acceleration';
        data1Title = 'Joint ' + type_of + ' X Acceleration ' + accLinUnit;
        data2Title = 'Joint ' + type_of + ' Y Acceleration ' + accLinUnit;
        data3Title = 'Absolute Acceleration ' + accLinUnit;
        yAxisTitle = 'Acceleration ' + accLinUnit;
        [datum, categories] = this.determineAnalysis(analysis, type_of, more_type);
        seriesData.push({name: data1Title, type: 'line', data: datum[0]});
        seriesData.push({name: data2Title, type: 'line', data: datum[1]});
        seriesData.push({name: data3Title, type: 'line', data: datum[2]});
        break;
      case 'Linear Link\'s CoM Pos':
        chartTitle = 'Link\'s Center of Mass Linear Position';
        data1Title = 'Link ' + type_of + ' (CoM) X Position ' + posLinUnit;
        data2Title = 'Link ' + type_of + ' (CoM) Y Position ' + posLinUnit;
        yAxisTitle = 'Position (CoM) ' + posLinUnit;
        [datum, categories] = this.determineAnalysis(analysis, type_of, more_type);
        seriesData.push({name: data1Title, type: 'line', data: datum[0]});
        seriesData.push({name: data2Title, type: 'line', data: datum[1]});
        break;
      case 'Linear Link\'s CoM Vel':
        chartTitle = 'Link\'s Center of Mass Linear Velocity';
        data1Title = 'Link ' + type_of + ' (CoM) X Velocity ' + velLinUnit;
        data2Title = 'Link ' + type_of + ' (CoM) Y Velocity ' + velLinUnit;
        data3Title = 'Absolute Velocity ' + velLinUnit;
        yAxisTitle = 'Velocity ' + velLinUnit;
        [datum, categories] = this.determineAnalysis(analysis, type_of, more_type);
        seriesData.push({name: data1Title, type: 'line', data: datum[0]});
        seriesData.push({name: data2Title, type: 'line', data: datum[1]});
        seriesData.push({name: data3Title, type: 'line', data: datum[2]});
        break;
      case 'Linear Link\'s CoM Acc':
        chartTitle = 'Link\'s Center of Mass Linear Acceleration';
        data1Title = 'Link ' + type_of + ' (CoM) X Acceleration ' + accLinUnit;
        data2Title = 'Link ' + type_of + ' (CoM) Y Acceleration ' + accLinUnit;
        data3Title = 'Link Absolute Acceleration ' + accLinUnit;
        yAxisTitle = 'Acceleration ' + accLinUnit;
        [datum, categories] = this.determineAnalysis(analysis, type_of, more_type);
        seriesData.push({name: data1Title, type: 'line', data: datum[0]});
        seriesData.push({name: data2Title, type: 'line', data: datum[1]});
        seriesData.push({name: data3Title, type: 'line', data: datum[2]});
        break;
      case 'Angular Link Pos':
        chartTitle = 'Link\'s Angular Position';
        data1Title = 'Link ' + type_of + ' Angle ' + posAngUnit;
        // data2Title = 'Link ' + type_of + ' Y Position ' + posAngUnit;
        yAxisTitle = 'Position ' + posAngUnit;
        [datum, categories] = this.determineAnalysis(analysis, type_of, more_type);
        seriesData.push({name: data1Title, type: 'line', data: datum[0]});
        // seriesData.push({name: data2Title, type: 'line', data: datum[1]});
        break;
      case 'Angular Link Vel':
        chartTitle = 'Link\'s Angular Velocity';
        data1Title = 'Link ' + type_of + ' Angular Velocity ' + velAngUnit;
        // data2Title = 'Link ' + type_of + ' Y Velocity ' + velAngUnit;
        // data3Title = 'Absolute Velocity ' + velAngUnit;
        yAxisTitle = 'Velocity ' + velAngUnit;
        [datum, categories] = this.determineAnalysis(analysis, type_of, more_type);
        seriesData.push({name: data1Title, type: 'line', data: datum[0]});
        // seriesData.push({name: data2Title, type: 'line', data: datum[1]});
        // seriesData.push({name: data3Title, type: 'line', data: datum[2]});
        break;
      case 'Angular Link Acc':
        chartTitle = 'Link\'s Angular Acceleration';
        data1Title = 'Link ' + type_of + ' Angular Acceleration ' + accAngUnit;
        // data2Title = 'Link ' + type_of + ' Y Acceleration ' + accAngUnit;
        // data3Title = 'Absolute Acceleration ' + accAngUnit;
        yAxisTitle = 'Acceleration ' + accAngUnit;
        [datum, categories] = this.determineAnalysis(analysis, type_of, more_type);
        seriesData.push({name: data1Title, type: 'line', data: datum[0]});
        // seriesData.push({name: data2Title, type: 'line', data: datum[1]});
        // seriesData.push({name: data3Title, type: 'line', data: datum[2]});
        break;
    }
    this.chartOptions = {

      series: seriesData,
      chart: {
        height: 350,
        type: 'line',
        zoom: {
          enabled: false
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'straight'
      },
      title: {
        text: chartTitle,
        align: 'left'
      },
      grid: {
        row: {
          colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
          opacity: 0.5
        }
      },
      xaxis: {
        categories: categories,
        title: {
          text: xAxisTitle
        }
      },
      yaxis: {
        title: {
          text: yAxisTitle
        },
      }
    };
  }

  determineAnalysis(analysis, type_of, more_type) {
    const datum_X = [];
    const datum_Y = [];
    const datum_Z = [];
    let x = 0;
    let y = 0;
    let z = 0;
    const categories = [];
    this.simulator[0].posTSL.TSL.forEach(tsl => {
      this.simulator[0].insertNewJointPos(tsl);
      switch (analysis) {
        case 'Input Torque':
          if (more_type === 'dyn') {
            KinematicsSolver.determineKinematics(this.simulator[0].SimulationJoints,
              this.simulator[0].SimulationLinks, tsl.angular_velocity);
          }
          ForceSolver.determineForceAnalysis(this.simulator[0].SimulationJoints,
            this.simulator[0].SimulationLinks, more_type, this.gravity, this.unit.selectedUnit);
          datum_X.push(Math.round(ForceSolver.unknownVariableTorque[0] * 1000) / 1000);
          break;
        case 'Joint Forces':
          if (more_type === 'dyn') {
            KinematicsSolver.determineKinematics(this.simulator[0].SimulationJoints,
              this.simulator[0].SimulationLinks, tsl.angular_velocity);
          }
          ForceSolver.determineForceAnalysis(this.simulator[0].SimulationJoints,
            this.simulator[0].SimulationLinks, more_type, this.gravity, this.unit.selectedUnit);
          x = ForceSolver.unknownVariableForcesMap.get(type_of)[0];
          y = ForceSolver.unknownVariableForcesMap.get(type_of)[1];
          z = Math.sqrt(Math.pow(x, 2) + Math.pow(y, 2));
          datum_X.push(Math.round(x * 1000) / 1000);
          datum_Y.push(Math.round(y * 1000) / 1000);
          datum_Z.push(Math.round(z * 1000) / 1000);
          break;
        case 'Linear Joint Pos':
          const jt = tsl.joints.find(j => j.id === type_of);
          x = jt.x;
          y = jt.y;
          datum_X.push(Math.round(x * 1000) / 1000);
          datum_Y.push(Math.round(y * 1000) / 1000);
          break;
        case 'Linear Joint Vel':
          KinematicsSolver.determineKinematics(this.simulator[0].SimulationJoints,
            this.simulator[0].SimulationLinks, tsl.angular_velocity);
          x = KinematicsSolver.jointVelMap.get(type_of)[0];
          y = KinematicsSolver.jointVelMap.get(type_of)[1];
          z = Math.sqrt(Math.pow(x, 2) + Math.pow(y, 2));
          datum_X.push(Math.round(x * 1000) / 1000);
          datum_Y.push(Math.round(y * 1000) / 1000);
          datum_Z.push(Math.round(z * 1000) / 1000);
          break;
        case 'Linear Joint Acc':
          KinematicsSolver.determineKinematics(this.simulator[0].SimulationJoints,
            this.simulator[0].SimulationLinks, tsl.angular_velocity);
          x = KinematicsSolver.jointAccMap.get(type_of)[0];
          y = KinematicsSolver.jointAccMap.get(type_of)[1];
          z = Math.sqrt(Math.pow(x, 2) + Math.pow(y, 2));
          datum_X.push(Math.round(x * 1000) / 1000);
          datum_Y.push(Math.round(y * 1000) / 1000);
          datum_Z.push(Math.round(z * 1000) / 1000);
          break;
        case 'Linear Link\'s CoM Pos':
          KinematicsSolver.determineKinematics(this.simulator[0].SimulationJoints,
            this.simulator[0].SimulationLinks, tsl.angular_velocity);
          x = KinematicsSolver.linkCoMMap.get(type_of)[0];
          y = KinematicsSolver.linkCoMMap.get(type_of)[1];
          datum_X.push(Math.round(x * 1000) / 1000);
          datum_Y.push(Math.round(y * 1000) / 1000);
          break;
        case 'Linear Link\'s CoM Vel':
          KinematicsSolver.determineKinematics(this.simulator[0].SimulationJoints,
            this.simulator[0].SimulationLinks, tsl.angular_velocity);
          x = KinematicsSolver.linkVelMap.get(type_of)[0];
          y = KinematicsSolver.linkVelMap.get(type_of)[1];
          z = Math.sqrt(Math.pow(x, 2) + Math.pow(y, 2));
          datum_X.push(Math.round(x * 1000) / 1000);
          datum_Y.push(Math.round(y * 1000) / 1000);
          datum_Z.push(Math.round(z * 1000) / 1000);
          break;
        case 'Linear Link\'s CoM Acc':
          KinematicsSolver.determineKinematics(this.simulator[0].SimulationJoints,
            this.simulator[0].SimulationLinks, tsl.angular_velocity);
          x = KinematicsSolver.linkAccMap.get(type_of)[0];
          y = KinematicsSolver.linkAccMap.get(type_of)[1];
          z = Math.sqrt(Math.pow(x, 2) + Math.pow(y, 2));
          datum_X.push(Math.round(x * 1000) / 1000);
          datum_Y.push(Math.round(y * 1000) / 1000);
          datum_Z.push(Math.round(z * 1000) / 1000);
          break;
        case 'Angular Link Pos':
          KinematicsSolver.determineKinematics(this.simulator[0].SimulationJoints,
            this.simulator[0].SimulationLinks, tsl.angular_velocity);
          x = KinematicsSolver.linkAngPosMap.get(type_of);
          // y = KinematicsSolver.linkAngPosMap.get(type_of);
          datum_X.push(Math.round(x * 1000) / 1000);
          // datum_Y.push(Math.round(y * 1000) / 1000);
          break;
        case 'Angular Link Vel':
          KinematicsSolver.determineKinematics(this.simulator[0].SimulationJoints,
            this.simulator[0].SimulationLinks, tsl.angular_velocity);
          x = KinematicsSolver.linkAngVelMap.get(type_of);
          // y = KinematicsSolver.linkAngVelMap.get(type_of);
          // z = Math.sqrt(Math.pow(x, 2) + Math.pow(y, 2));
          datum_X.push(Math.round(x * 1000) / 1000);
          // datum_Y.push(Math.round(y * 1000) / 1000);
          // datum_Z.push(Math.round(z * 1000) / 1000);
          break;
        case 'Angular Link Acc':
          KinematicsSolver.determineKinematics(this.simulator[0].SimulationJoints,
            this.simulator[0].SimulationLinks, tsl.angular_velocity);
          // const val = KinematicsSolver.linkAngAccMap.get(type_of);
          x = KinematicsSolver.linkAngAccMap.get(type_of);
          // y = KinematicsSolver.linkAngAccMap.get(type_of);
          // z = Math.sqrt(Math.pow(x, 2) + Math.pow(y, 2));
          datum_X.push(Math.round(x * 1000) / 1000);
          // datum_Y.push(Math.round(y * 1000) / 1000);
          // datum_Z.push(Math.round(z * 1000) / 1000);
          break;
        case 'ic':
          break;
      }
      categories.push('Timestep ' + tsl.time);
    });
    return [[datum_X, datum_Y, datum_Z], categories];
  }

  changePlotAnalysis(analysis, type_of, more_type) {
    this.showChart = false;
    switch (analysis) {
      case 'Input Torque':
        this.showChart = true;
        this.determineChart(analysis, type_of, more_type);
        break;
      default:
        switch (type_of) {
          case '':
            break;
          default:
            this.showChart = true;
            this.determineChart(analysis, type_of, more_type);
        }
    }
  }

  changeSynthesis(synthesis) {
    this.synthesis.selectedSynthesis = synthesis;
    this.setSynthesis(synthesis);
  }

  changeConnection(connection) {
    this.connection.selectedConnection = connection;
    this.setConnection(connection);
  }

  changeSynthesisDisplay(synthesisDisplay) {
    this.synthesisDisplay.selectedSynthesisDisplay = synthesisDisplay;
    this.setSynthesisDisplay(synthesisDisplay);
  }

  checkPathPointsConnected() {
    this.pathPointsConnected = true;
    if (this.pathPointArray.length < 3) {
      this.pathPointsConnected = false;
      return;
    }
    // const pathPointNodeArray = [];
    // logic to determine if all pathPoints have been traveled
    const idToPathPointMap = new Map<string, PathPointNode>();
    this.pathPointArray.forEach(pp => {
      idToPathPointMap.set(pp.id, new PathPointNode(pp.id, pp.neighbor_one.id, pp.neighbor_two.id));
    });
    let prevNode = idToPathPointMap.get(this.pathPointArray[0].id);
    let currNode = idToPathPointMap.get(prevNode.neighborOne);
    let tempNodeId: string;
    let numPathPointsTraveled = 1;
    if (prevNode.neighborOne === prevNode.neighborTwo) {
      this.pathPointsConnected = false;
      return;
    }
    while (currNode.id !== this.pathPointArray[0].id) {
      // already traveled to this node before
      if (currNode.visited) {
        this.pathPointsConnected = false;
        return;
      }
      currNode.visited = true;
      // current node does not have previous node as neighborOne or neighborTwo
      if (currNode.neighborOne !== prevNode.id && currNode.neighborTwo !== prevNode.id) {
        this.pathPointsConnected = false;
        return;
      }
      // determine which node to travel to next
      if (currNode.neighborOne === prevNode.id) {
        tempNodeId = currNode.id;
        currNode = idToPathPointMap.get(currNode.neighborTwo);
        prevNode = idToPathPointMap.get(tempNodeId);
      } else {
        tempNodeId = currNode.id;
        currNode = idToPathPointMap.get(currNode.neighborOne);
        prevNode = idToPathPointMap.get(tempNodeId);
      }
      // increment the number of pathPoints traveled
      numPathPointsTraveled++;
    }
    // not all of the path points have been traveled to
    if (numPathPointsTraveled !== idToPathPointMap.size) {
      this.pathPointsConnected = false;
      return;
    }
  }

  // changeProperties(linkOrPin: string) {
  //   let densityConversion: number;
  //   let yieldStrengthConversion: number;
  //   switch (this.unit.selectedUnit) {
  //     case 'cm':
  //       // this.desiredUnits = this.metricUnits;
  //       densityConversion = 1.0;
  //       yieldStrengthConversion = 1.0;
  //       break;
  //     case 'm':
  //       densityConversion = 1.0;
  //       yieldStrengthConversion = 1.0;
  //       break;
  //     case 'km':
  //       densityConversion = 1.0;
  //       yieldStrengthConversion = 1.0;
  //       break;
  //     case 'in':
  //       densityConversion = 1.0;
  //       yieldStrengthConversion = 1.0;
  //       break;
  //     case 'ft':
  //       densityConversion = 1.0;
  //       yieldStrengthConversion = 1.0;
  //       break;
  //   }
  //   // this.desiredUnits = this.unit.selectedUnit === 'Metric' ? this.metricUnits : this.englishUnits;
  //   // const densityConversion = this.unit.selectedUnit === 'Metric' ? 1.0 : (13 / 360);
  //   // const yieldStrengthConversion = this.unit.selectedUnit === 'Metric' ? 1.0 : (10000 / 69);
  //   const desiredName = linkOrPin === 'link' ? this.linkName : this.pinName;
  //   // let density: number;
  //   // let yieldStrength: number;
  //   switch (desiredName) {
  //     case 'Aluminum 6061':
  //       // density = Math.round(this.Aluminum6061[0] * densityConversion * 10000) / 10000;
  //       // yieldStrength = Math.round(this.Aluminum6061[1] * yieldStrengthConversion * 1000) / 1000;
  //       break;
  //     case 'Carbon Steel 1065':
  //       // density = Math.round(this.CarbonSteel1065[0] * densityConversion * 10000) / 10000;
  //       // yieldStrength = Math.round(this.CarbonSteel1065[1] * yieldStrengthConversion * 1000) / 1000;
  //       break;
  //     case 'Carbon Steel 1018':
  //       // density = Math.round(this.CarbonSteel1018[0] * densityConversion * 10000) / 10000;
  //       // yieldStrength = Math.round(this.CarbonSteel1018[1] * yieldStrengthConversion * 1000) / 1000;
  //       break;
  //     case 'ABS Plastic':
  //       // density = Math.round(this.ABSPlastic[0] * densityConversion * 10000) / 10000;
  //       // yieldStrength = Math.round(this.ABSPlastic[1] * yieldStrengthConversion * 1000) / 1000;
  //       break;
  //     case 'PLA Plastic':
  //       // density = Math.round(this.PLAPlastic[0] * densityConversion * 10000) / 10000;
  //       // yieldStrength = Math.round(this.PLAPlastic[1] * yieldStrengthConversion * 1000) / 1000;
  //       break;
  //     case 'Cast Acrylic':
  //       // density = Math.round(this.CastAcrylic[0] * densityConversion * 10000) / 10000;
  //       // yieldStrength = Math.round(this.CastAcrylic[1] * yieldStrengthConversion * 1000) / 1000;
  //       break;
  //     case 'Plywood':
  //       // density = Math.round(this.Plywood[0] * densityConversion * 10000) / 10000;
  //       // yieldStrength = Math.round(this.Plywood[1] * yieldStrengthConversion * 1000) / 1000;
  //       break;
  //   }
  //   // linkOrPin === 'link' ? this.linkDensity = density : this.pinDensity = density;
  //   // linkOrPin === 'link' ? this.linkYieldStrength = yieldStrength : this.pinYieldStrength = yieldStrength;
  // }

  hide(div) {
    document.getElementById(div).style.display = 'none';
    this.chart = new Array<Array<string>>();
    this.showChart = false;
    //
    this.forceAnalysis.selectedAnalysis = 'none';
    this.kinematicLoopAnalysis.selectedAnalysis = 'none';
  }

  tutorial() {
    const modal = document.getElementById('myModal');
    modal.style.display = 'table';
  }

  help() {
    const helpTab = document.getElementById('helpModal');
    helpTab.style.display = 'flex';
  }

  templates() {
    const basic = document.getElementById('myLink');
    basic.style.display = 'table';
  }


  upload(event) {
    const input = event.target;
    if (input.files.length !== 1) {
      return;
    }
    const reader = new FileReader();
    const that = this;

    reader.onload = function () {
      const newFile = reader.result;
      that.newCSVEmit.emit(newFile as String);
    };
    reader.readAsText(input.files[0]);
  }

  downloadLinkage() {
    this.downloadLinkageEmit.emit();
  }

  copyURL() {
    this.copyURLEmit.emit();
  }

  changeDesiredPosition(event): void {
    this.desiredPosition = event.target.value;
  }

  createChart(event?): void {
    /** Don't forget to make StaticsChart-Table **/
    let position;
    event !== undefined ? position = event.target.value : position = this.desiredPosition;
    this.chart = new Array<Array<string>>();
    const rows = this.analysis[0].length;
    const cols = 2;
    for (let i = 1; i < rows; i++) { // i = 1 so we skip timestep within titleRow
      const row = new Array<string>();
      for (let j = 0; j < cols; j++) {
        if (j === 0) { // description
          const variableLabel = this.titleRow[i];
          row.push(variableLabel);
        } else { // value
          const variableValue = this.analysis[position][i].toString();
          row.push(variableValue);
        }
      }
      this.chart.push(row);
    }
  }

  exportExcel(analysisType: string): void {
    // first, determine what information will not be put within the table
    const includeMapIndex = new Map<number, boolean>();
    includeMapIndex.set(0, true); // time step number should be included
    let sub_increment: number;
    let condition: boolean;
    let increment = 1;
    switch (analysisType) {
      case 'loops':
        increment = 0;
        this.requiredLoopCheck ? includeMapIndex.set(increment++, true) : includeMapIndex.set(increment++, false);
        this.allLoopCheck ? includeMapIndex.set(increment++, true) : includeMapIndex.set(increment++, false);
        break;
      case 'statics':
        // determine whether to export force
        while (increment < (1 + (this.jointArray.length * 2))) {
          this.staticForcesCheck ? includeMapIndex.set(increment++, true) : includeMapIndex.set(increment++, false);
        }
        // determine whether to export torque
        this.staticTorqueCheck ? includeMapIndex.set(increment++, true) : includeMapIndex.set(increment++, false);
        // determine whether to leave space in between analyses
        ((this.staticForcesCheck || this.staticTorqueCheck) &&
          (this.staticForcePositionsCheck || this.staticJointPositionsCheck)) ?
          includeMapIndex.set(increment++, true) : includeMapIndex.set(increment++, false);
        // determine whether to export force positions
        while (increment < (1 + (this.jointArray.length * 2) + (1) + 1 +
          (this.forceArray.length * 2))) {
          this.staticForcePositionsCheck ? includeMapIndex.set(increment++, true) : includeMapIndex.set(increment++, false);
        }
        // determine whether to leave space in between analyses
        (this.staticForcePositionsCheck && this.staticJointPositionsCheck) ?
          includeMapIndex.set(increment++, true) : includeMapIndex.set(increment++, false);
        // determine whether to export joint positions
        while (increment < (1 + (this.jointArray.length * 2) + (1) + 1 +
          (this.forceArray.length * 2) + 1 + (this.jointArray.length * 2))) {
          this.staticJointPositionsCheck ? includeMapIndex.set(increment++, true) : includeMapIndex.set(increment++, false);
        }
        break;
      case 'dynamics':
        // check whether to put the internal force analysis occurring at joints
        while (increment < (1 + (this.jointArray.length * 2))) {
          this.dynamicForcesCheck ? includeMapIndex.set(increment++, true) : includeMapIndex.set(increment++, false);
        }
        // check whether to put the torque analysis occurring for mechanism
        this.dynamicTorqueCheck ? includeMapIndex.set(increment++, true) : includeMapIndex.set(increment++, false);
        // check whether to put space between analyses
        (this.dynamicForcesCheck || this.dynamicTorqueCheck) && (this.dynamicForcePositionsCheck || this.dynamicJointKinematicsCheck ||
          this.dynamicLinkKinematicsCheck || this.dynamicAngLinkCheck) ?
          includeMapIndex.set(increment++, true) : includeMapIndex.set(increment++, false);
        // check whether to put force positions
        while (increment < (1 + (this.jointArray.length * 2) + (1) + 1 + (this.forceArray.length * 2))) {
          this.dynamicForcePositionsCheck ? includeMapIndex.set(increment++, true) : includeMapIndex.set(increment++, false);
        }
        // check whether to put space between analyses
        (this.dynamicForcePositionsCheck && (this.dynamicJointKinematicsCheck ||
          this.dynamicLinkKinematicsCheck || this.dynamicAngLinkCheck)) ?
          includeMapIndex.set(increment++, true) : includeMapIndex.set(increment++, false);

        sub_increment = 0;
        // check whether linear kinematics for joints (p,v,a) have been asked for
        while (increment < (1 + (this.jointArray.length * 2) + (1) + 1 + (this.forceArray.length * 2) + 1 +
          (this.jointArray.length * 6))) {
          if (this.dynamicJointKinematicsCheck) {
            switch (sub_increment % 6) {
              case 0:
                condition = this.dynamicLinKinJointPos;
                break;
              case 1:
                condition = this.dynamicLinKinJointPos;
                break;
              case 2:
                condition = this.dynamicLinKinJointVel;
                break;
              case 3:
                condition = this.dynamicLinKinJointVel;
                break;
              case 4:
                condition = this.dynamicLinKinJointAcc;
                break;
              case 5:
                condition = this.dynamicLinKinJointAcc;
                break;
            }
            includeMapIndex.set(increment++, condition);
          } else {
            includeMapIndex.set(increment++, false);
          }
          sub_increment++;
        }
        // check whether to add another space or not between analyses
        (this.dynamicJointKinematicsCheck && (this.dynamicLinkKinematicsCheck || this.dynamicAngLinkCheck)) ?
          includeMapIndex.set(increment++, true) : includeMapIndex.set(increment++, false);
        // check whether linear kinematics for links have been asked for
        sub_increment = 0;
        while (increment < (1 + (this.jointArray.length * 2) + (1) + 1 + (this.forceArray.length * 2) + 1 +
          (this.jointArray.length * 6) + 1 + (this.linkArray.length * 6))) {
          if (this.dynamicLinkKinematicsCheck) {
            switch (sub_increment % 6) {
              case 0:
                condition = this.dynamicLinKinLinkPos;
                break;
              case 1:
                condition = this.dynamicLinKinLinkPos;
                break;
              case 2:
                condition = this.dynamicLinKinLinkVel;
                break;
              case 3:
                condition = this.dynamicLinKinLinkVel;
                break;
              case 4:
                condition = this.dynamicLinKinLinkAcc;
                break;
              case 5:
                condition = this.dynamicLinKinLinkAcc;
                break;
            }
            includeMapIndex.set(increment++, condition);
          } else {
            includeMapIndex.set(increment++, false);
          }
          sub_increment++;
        }
        // account for empty space
        (this.dynamicLinkKinematicsCheck && this.dynamicAngLinkCheck) ?
          includeMapIndex.set(increment++, true) : includeMapIndex.set(increment++, false);
        sub_increment = 0;
        // check whether angular kinematics have been asked for
        while (increment < (1 + (this.jointArray.length * 2) + (1) + 1 + (this.forceArray.length * 2) + 1 +
          (this.jointArray.length * 6) + 1 + (this.linkArray.length * 6) + 1 + (this.linkArray.length * 3))) {
          if (this.dynamicAngLinkCheck) {
            switch (sub_increment % 3) {
              case 0:
                condition = this.dynamicAngKinLinkPos;
                break;
              case 1:
                condition = this.dynamicAngKinLinkVel;
                break;
              case 2:
                condition = this.dynamicAngKinLinkAcc;
                break;
            }
            includeMapIndex.set(increment++, condition);
          } else {
            includeMapIndex.set(increment++, false);
          }
          sub_increment++;
        }
        break;
      case 'kinematics_loops':
        // check whether linear kinematics for joints have been asked for
        while (increment < (1 + (this.jointArray.length * 6))) {
          if (this.linKinJointCheck) {
            switch (increment % 6) {
              case 0:
                condition = this.linKinJointAcc;
                break;
              case 1:
                condition = this.linKinJointPos;
                break;
              case 2:
                condition = this.linKinJointPos;
                break;
              case 3:
                condition = this.linKinJointVel;
                break;
              case 4:
                condition = this.linKinJointVel;
                break;
              case 5:
                condition = this.linKinJointAcc;
                break;
            }
            includeMapIndex.set(increment++, condition);
          } else {
            includeMapIndex.set(increment++, false);
          }
        }
        // check whether to add another space or not between analyses
        (this.linKinJointCheck && (this.linKinLinkCheck || this.dynamicAngLinkCheck)) ?
          includeMapIndex.set(increment++, true) : includeMapIndex.set(increment++, false);
        // check whether linear kinematics for links have been asked for
        while (increment < (1 + (this.jointArray.length * 6) + 1 + (this.linkArray.length * 6))) {
          if (this.linKinLinkCheck) {
            switch (increment % 6) {
              case 0:
                condition = this.linKinLinkAcc;
                break;
              case 1:
                condition = this.linKinLinkAcc;
                break;
              case 2:
                condition = this.linKinLinkPos;
                break;
              case 3:
                condition = this.linKinLinkPos;
                break;
              case 4:
                condition = this.linKinLinkVel;
                break;
              case 5:
                condition = this.linKinLinkVel;
                break;
            }
            includeMapIndex.set(increment++, condition);
          } else {
            includeMapIndex.set(increment++, false);
          }
        }
        // account for empty space
        (this.linKinLinkCheck && this.dynamicAngLinkCheck) ?
          includeMapIndex.set(increment++, true) : includeMapIndex.set(increment++, false);
        // check whether angular kinematics have been asked for
        sub_increment = 0;
        while (increment < (1 + (this.jointArray.length * 6) + 1 + (this.linkArray.length * 6) + 1 + (this.linkArray.length * 3))) {
          if (this.dynamicAngLinkCheck) {
            switch (sub_increment % 3) {
              case 0:
                condition = this.angKinLinkPos;
                break;
              case 1:
                condition = this.angKinLinkVel;
                break;
              case 2:
                condition = this.angKinLinkAcc;
                break;
            }
            includeMapIndex.set(increment++, condition);
          } else {
            includeMapIndex.set(increment++, false);
          }
          sub_increment++;
        }
        break;
      case 'kinematics_ic':

        // check whether to export position of ICs
        while (increment < (1 + (this.icArray.length * 2))) {
          this.icPositionsCheck ? includeMapIndex.set(increment++, true) : includeMapIndex.set(increment, false);
        }
        // check whether to put space in between analyses
        (this.icPositionsCheck && (this.linKinJointICCheck || this.angKinLinkICCheck)) ?
          includeMapIndex.set(increment++, true) : includeMapIndex.set(increment++, false);
        // check whether linear kinematics for joints have been asked for
        sub_increment = 0;
        while (increment < (1 + (this.icArray.length * 2) + 1 + (this.jointArray.length * 2))) {
          if (this.linKinJointICCheck) {
            switch (sub_increment % 4) {
              case 0:
                condition = this.linKinJointICPos;
                break;
              case 1:
                condition = this.linKinJointICPos;
                break;
              case 2:
                condition = this.linKinJointICVel;
                break;
              case 3:
                condition = this.linKinJointICVel;
                break;
            }
            includeMapIndex.set(increment++, condition);
          } else {
            includeMapIndex.set(increment++, false);
          }
          sub_increment++;
        }
// check whether to add another space or not between analyses
        (this.linKinJointICCheck && this.angKinLinkICCheck) ?
          includeMapIndex.set(increment++, true) : includeMapIndex.set(increment++, false);
// check Kin Lin Velocities
        sub_increment = 0;
        while (increment < (1 + (this.icArray.length * 2) + 1 + (this.jointArray.length * 2) + 1 + (this.linkArray.length * 2))) {
          if (this.angKinLinkICCheck) {
            switch (sub_increment % 2) {
              // TODO: Be sure to account for angular displacement
              // case 0:
              //   condition = this.angKinLinkICPos
              case 0:
                condition = this.angKinLinkICVel;
                break;
              case 1:
                condition = this.angKinLinkICVel;
                break;
            }
            includeMapIndex.set(increment++, condition);
          } else {
            includeMapIndex.set(increment++, false);
          }
          sub_increment++;
        }
        break;
//           (this.linKinJointICCheck && this.linKinJointICVel) ?
//             includeMapIndex.set(increment++, true) : includeMapIndex.set(increment++, false);
//         }
// // account for empty space
//         ((this.linKinLinkCheck && this.linKinLinkICVel) && (this.angKinLinkICCheck)) ?
//           includeMapIndex.set(increment++, true) : includeMapIndex.set(increment++, false);
// // check whether angular kinematics have been asked for
//
//         while (increment < (1 + (this.icArray.length * 2) + 1 + (this.icArray.length * 2) + 1
//           + (this.linkArray.length * 2))) {
//           (this.angKinLinkICCheck && this.angKinLinkICVel) ?
//             includeMapIndex.set(increment++, true) : includeMapIndex.set(increment++, false);
//         }
//         break;
    }
    const tbl = document.getElementById('Excel-Table');
    while (tbl.firstChild) {
      tbl.removeChild(tbl.lastChild);
    }
    const table = document.createElement('table'); /*Create `table` element*/
    const rows = this.analysis.length;
    const cols = this.analysis[0].length;
    const statTitle = document.createElement('tr');
    for (let i = 0; i < cols; i++) {
      // have a map here to check whether to consider this element or not
      if (!includeMapIndex.get(i)) {
        continue;
      }
      const td = document.createElement('td');
      const cellText = document.createTextNode('\t' + this.titleRow[i]);
      td.appendChild(cellText);
      statTitle.appendChild(td);
    }
    table.appendChild(statTitle);
    for (let i = 0; i < rows; i++) {
      const tr = document.createElement('tr');                 /*Create `tr` element*/
      for (let j = 0; j < cols; j++) {
        // have a map here to check whether to consider this element or not
        if (!includeMapIndex.get(j)) {
          continue;
        }
        const arr = this.analysis[i][j];
        const td = document.createElement('td');             /*Create `td` element*/
        const cellText = document.createTextNode('\t' + arr.toString());   /*Create text for `td` element*/
        td.appendChild(cellText);                          /*Append text to `td` element*/
        tr.appendChild(td);                                /*Append `td` to `tr` element*/
      }
      table.appendChild(tr);                                 /*Append `tr` to `table` element*/
    }
    tbl.appendChild(table);
    // /* table id is passed over here */
    // const element = document.getElementById('StaticExcel-Table');
    // const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(tbl);


    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    // const time = performance.now();
    const date = Date.now();
    // const date = Date.toLocaleString();
    const datepipe: DatePipe = new DatePipe('en-US');
    // const formattedDate = datepipe.transform(date, 'dd-MMM-YYYY HH:mm:ss');
    const formattedDate = datepipe.transform(date, 'dd-MMM HH:mm:ss');

    this.fileName = analysisType + this.numJointsAndLinks[0] + 'Joints' + this.numJointsAndLinks[1] + 'Links' +
      formattedDate + '.xlsx';
    /* save to file*/
    XLSX.writeFile(wb, this.fileName);
  }

  checkValue(value: string, cut: boolean) {
    const sign = value[0] === '-' ? ' - ' : ' + ';
    let mag: string;
    if (cut) {
      switch (value) {
        case '-1':
          mag = '';
          break;
        case '1':
          mag = '';
          break;
        default:
          mag = value[0] === '-' ? value.substring(1) : value;
          break;
      }
    } else {
      mag = value[0] === '-' ? value.substring(1) : value;
    }

    return [sign, mag];
    // return (value[0] === '-') ? [' - ', value.substring(1)] : [' + ', value];
  }

  adjustSignSpacing(sign: string) {
    return sign === ' - ' ? '-' : '';
  }

  changeTabs($event, analysisInfo) {
    let table_tabs: any;
    let check: any;
    const analysis = analysisInfo[0];
    const type_of_analysis = analysisInfo[1];
    this.simulator[0].insertNewJointPos(this.posTimeSortedList.TSL[0]);
    let selectedTab = 0;
    switch (type_of_analysis) {
      case 'static':
        table_tabs = document.getElementById('staticTableTabs');
        check = document.getElementById('staticForceEquations');
        selectedTab = this.staticTabSelected;
        break;
      case 'dynamic':
        table_tabs = document.getElementById('dynamicTableTabs');
        check = document.getElementById('dynamicForceEquations');
        selectedTab = this.dynamicTabSelected;
        break;
      case 'loops': //
        table_tabs = document.getElementById('kinematicTableTabs');
        check = document.getElementById('kinematicEquations');
        selectedTab = this.kinematicLoopTabSelected;
        break;
      case 'ics':
        table_tabs = document.getElementById('kinematicICsTableTabs');
        check = document.getElementById('kinematicICsEquations');
        selectedTab = this.kinematicICTabSelected;
    }
    if (check !== null) {
      table_tabs.removeChild(check);
    }

    switch (selectedTab) {
    // switch ($event.index) {
      case 0:
        break;
      case 1:
        break;
      case 2:
        const total_part = document.createElement('div');
        total_part.style.margin = '4px 4px';
        total_part.style.padding = '4px';
        total_part.style.width = '500px';
        total_part.style.height = '500px';
        total_part.style.overflowX = 'hidden';
        total_part.style.overflowY = 'auto';
        total_part.style.textAlign = 'justify';
        let mass_conv = 1;
        let dist_conv = 1;
        if (this.unit.selectedUnit === 'cm') {
          mass_conv = 1 / 1000;
          dist_conv = 1 / 100;
        }

        switch (analysis) {
          case 'force':
            switch (type_of_analysis) {
              case 'static':
                total_part.setAttribute('id', 'staticForceEquations');
                ForceSolver.determineForceAnalysis(this.simulator[0].SimulationJoints,
                  this.simulator[0].SimulationLinks, 'static', this.gravity, this.unit.selectedUnit);
                break;
              case 'dynamic':
                total_part.setAttribute('id', 'dynamicForceEquations');
                KinematicsSolver.determineKinematics(this.simulator[0].SimulationJoints, this.simulator[0].SimulationLinks,
                  this.posTimeSortedList.TSL[0].angular_velocity);
                ForceSolver.determineForceAnalysis(this.simulator[0].SimulationJoints,
                  this.simulator[0].SimulationLinks, 'dynamic', this.gravity, this.unit.selectedUnit);
                break;
            }

            this.simulator[0].SimulationLinks.forEach((l, l_index) => {
              const svgBlackLine = document.createElement('hr');
              svgBlackLine.style.display = 'none';
              const svgBlueLine = document.createElement('hr');
              svgBlueLine.style.borderColor = 'Blue';
              svgBlueLine.style.display = 'none';

              const sum_forces_gen = document.createElement('div');
              sum_forces_gen.setAttribute('id', 'Sum_Forces_Layout');
              sum_forces_gen.style.display = 'none';
              const sum_moments_gen = document.createElement('div');
              sum_moments_gen.setAttribute('id', 'Sum_Moment_Layout');
              sum_moments_gen.style.display = 'none';
              const sum_forces_eqs = document.createElement('div');
              sum_forces_eqs.setAttribute('id', 'Sum_Forces_Eqs');
              sum_forces_eqs.style.display = 'none';
              const sum_moments_eqs = document.createElement('div');
              sum_moments_eqs.setAttribute('id', 'Sum_Moment_Eqs');
              sum_moments_eqs.style.display = 'none';
              const sum_forces_sol_x = document.createElement('div');
              sum_forces_sol_x.setAttribute('id', 'Sum_Forces_x');
              sum_forces_sol_x.style.display = 'none';
              const sum_forces_sol_y = document.createElement('div');
              sum_forces_sol_y.setAttribute('id', 'Sum_Forces_y');
              sum_forces_sol_y.style.display = 'none';
              const sum_moments_sol = document.createElement('div');
              sum_moments_sol.setAttribute('id', 'Sum_Moment');
              sum_moments_sol.style.display = 'none';

              let fixed_moment_joint: any;
              ForceSolver.desiredLoopLetters.forEach(letters => {
                if (l.id.indexOf(letters[0][0]) > -1 && l.id.indexOf(letters[0][1]) > -1) {
                  const fixed_moment_jt_string = letters[0][0];
                  fixed_moment_joint = this.simulator[0].SimulationJoints.find(j => j.id === fixed_moment_jt_string);
                  return;
                }
              });

              this.addSummationTextElement(sum_forces_gen);
              this.addArrowTopTextElement('F', sum_forces_gen);
              this.addTextElement(' = ', sum_forces_gen);
              switch (type_of_analysis) {
                case 'static':
                  this.addArrowTopTextElement('0', sum_forces_gen);
                  break;
                case 'dynamic':
                  this.addTextElement('m', sum_forces_gen);
                  this.addSubscriptTextElement(l.id, sum_forces_gen);
                  this.addArrowTopTextElement('a', sum_forces_gen);
                  this.addSubscriptTextElement('com_' + l.id, sum_forces_gen);
                  break;
              }

              this.addSummationTextElement(sum_moments_gen);
              this.addArrowTopTextElement('M', sum_moments_gen);
              this.addSubscriptTextElement(fixed_moment_joint.id, sum_moments_gen);
              this.addTextElement(' = ', sum_moments_gen);
              switch (type_of_analysis) {
                case 'static':
                  this.addArrowTopTextElement('0', sum_moments_gen);
                  break;
                case 'dynamic':
                  this.addTextElement('I', sum_moments_gen);
                  this.addSubscriptTextElement(fixed_moment_joint.id, sum_moments_gen);
                  this.addArrowTopTextElement('\u03B1', sum_moments_gen);
                  this.addSubscriptTextElement(l.id, sum_moments_gen);
                  break;
              }

              let first_sign = '';
              let second_sign = '';
              let third_sign = '';
              let fourth_sign = '';
              let first_value = '';
              let second_value = '';
              let third_value = '';
              let fourth_value = '';
              const linkIndex = ForceSolver.linkIDToUnknownArrayIndexMap.get(l.id);
              let jointXIndex = 0;
              let jointYIndex = 0;

              let sum_forces_x_B_value = ForceSolver.B_matrix[linkIndex];
              let sum_forces_y_B_value = ForceSolver.B_matrix[linkIndex + 1];
              let sum_moments_B_value = ForceSolver.B_matrix[linkIndex + 2];

              // sum of Forces in X, Y direction and sum of Moments around first joint
              l.joints.forEach((j, j_index) => {
                  if (ForceSolver.jointIDToUnknownArrayIndexMap.get(j.id) === undefined) {
                    return;
                  }
                  jointXIndex = ForceSolver.jointIDToUnknownArrayIndexMap.get(j.id);
                  jointYIndex = jointXIndex + 1;
                  first_value = (Math.round(ForceSolver.A_matrix[linkIndex][jointXIndex] * 1000) / 1000).toString();
                  second_value = (Math.round(ForceSolver.A_matrix[linkIndex + 1][jointYIndex] * 1000) / 1000).toString();
                  third_value = (Math.round(ForceSolver.A_matrix[linkIndex + 2][jointXIndex] * 1000) / 1000).toString();
                  fourth_value = (Math.round(ForceSolver.A_matrix[linkIndex + 2][jointYIndex] * 1000) / 1000).toString();

                  [first_sign, first_value] = this.checkValue(first_value, true);
                  [second_sign, second_value] = this.checkValue(second_value, true);
                  [third_sign, third_value] = this.checkValue(third_value, false);
                  [fourth_sign, fourth_value] = this.checkValue(fourth_value, false);
                  if (sum_forces_sol_x.innerHTML === '') {
                    first_sign = this.adjustSignSpacing(first_sign);
                  } else {
                    this.addTextElement(' + ', sum_forces_eqs);
                  }
                  if (sum_forces_sol_y.innerHTML === '') {
                    second_sign = this.adjustSignSpacing(second_sign);
                  }
                  if (sum_moments_sol.innerHTML === '') {
                    third_sign = this.adjustSignSpacing(third_sign);
                  }
                  // else {
                  //   this.addTextElement(' + ', sum_moments_sol);
                  // }

                  this.addArrowTopTextElement('F', sum_forces_eqs);
                  this.addSubscriptTextElement(j.id, sum_forces_eqs);

                  if (j.id !== fixed_moment_joint.id) {
                    if (sum_moments_eqs.innerHTML !== '') {
                      this.addTextElement(' + ', sum_moments_eqs);
                    }
                    this.addArrowTopTextElement('r', sum_moments_eqs);
                    this.addSubscriptTextElement(fixed_moment_joint.id + j.id, sum_moments_eqs);
                    // this.addSubscriptTextElement(fixed_moment_joint.id + j.id + 'W', sum_moments_eqs);
                    this.addTextElement(' X ', sum_moments_eqs);
                    this.addArrowTopTextElement('F', sum_moments_eqs);
                    this.addSubscriptTextElement(j.id, sum_moments_eqs);
                  }

                  this.addTextElement(first_sign + first_value + 'F', sum_forces_sol_x);
                  this.addSubscriptTextElement(j.id + 'x', sum_forces_sol_x);
                  this.addTextElement(second_sign + second_value + 'F', sum_forces_sol_y);
                  this.addSubscriptTextElement(j.id + 'y', sum_forces_sol_y);

                  if (third_value !== '0') {
                    // if (sum_moments_sol.innerHTML === '') {
                    //   this.addTextElement(third_value + 'F', sum_moments_sol);
                    //   this.addSubscriptTextElement(j.id + 'x', sum_moments_sol);
                    // } else {
                    //   this.addTextElement(third_sign + third_value + 'F', sum_moments_sol);
                    //   this.addSubscriptTextElement(j.id + 'x', sum_moments_sol);
                    // }
                    this.addTextElement(third_sign + third_value + 'F', sum_moments_sol);
                    this.addSubscriptTextElement(j.id + 'x', sum_moments_sol);
                    this.addTextElement(fourth_sign + fourth_value + 'F', sum_moments_sol);
                    this.addSubscriptTextElement(j.id + 'y', sum_moments_sol);
                  }
                }
              );


              l.forces.forEach(f => {
                this.addTextElement(' + ', sum_forces_eqs);
                this.addArrowTopTextElement('F', sum_forces_eqs);
                this.addSubscriptTextElement(f.id, sum_forces_eqs);

                this.addTextElement(' + ', sum_forces_sol_x);
                this.addTextElement(f.xForce, sum_forces_sol_x);
                sum_forces_x_B_value = sum_forces_x_B_value + f.xForce;

                this.addTextElement(' + ', sum_forces_sol_y);
                this.addTextElement(f.yForce, sum_forces_sol_y);
                sum_forces_y_B_value = sum_forces_y_B_value + f.yForce;

                this.addTextElement(' + ', sum_moments_eqs);
                this.addArrowTopTextElement('r', sum_moments_eqs);
                this.addSubscriptTextElement(f.id + fixed_moment_joint.id, sum_moments_eqs);
                this.addTextElement(' X ', sum_moments_eqs);
                this.addArrowTopTextElement('F', sum_moments_eqs);
                this.addSubscriptTextElement(f.id, sum_moments_eqs);

                // const moment_from_ext_force = ForceSolver.determineMoment(fixed_moment_joint, f.xLocOfForceOnLink, f.yLocOfForceOnLink,
                //   f.xForce, f.yForce);
                const moment_from_ext_force = ForceSolver.determineMoment(fixed_moment_joint, f.sx, f.sy, f.xForce, f.yForce);
                const moment_from_ext_force_i = moment_from_ext_force[0] * dist_conv;
                const moment_from_ext_force_j = moment_from_ext_force[1] * dist_conv;
                let sev_first_sign = ' + ';
                let sev_second_sign = ' + ';
                if (moment_from_ext_force[0] < 0) {
                  sev_first_sign = ' - ';
                }
                if (moment_from_ext_force[1] < 0) {
                  sev_second_sign = ' - ';
                }

                this.addTextElement(sev_first_sign, sum_moments_sol);
                this.addTextElement(Math.abs((Math.round(moment_from_ext_force_i * 1000) / 1000)).toString(), sum_moments_sol);
                this.addTextElement(sev_second_sign, sum_moments_sol);
                this.addTextElement(Math.abs((Math.round(moment_from_ext_force_j * 1000) / 1000)).toString(), sum_moments_sol);
                sum_moments_B_value = sum_moments_B_value + moment_from_ext_force_i + moment_from_ext_force_j;
              });

              if (this.gravity) {
                this.addTextElement(' + ', sum_forces_eqs);
                this.addArrowTopTextElement('F', sum_forces_eqs);
                this.addSubscriptTextElement('W', sum_forces_eqs);

                this.addTextElement(' + ', sum_moments_eqs);
                this.addArrowTopTextElement('r', sum_moments_eqs);
                this.addSubscriptTextElement(fixed_moment_joint.id + '_' + l.id + 'com', sum_moments_eqs);
                this.addTextElement(' X ', sum_moments_eqs);
                this.addArrowTopTextElement('F', sum_moments_eqs);
                this.addSubscriptTextElement('W', sum_moments_eqs);

                const force_gravity_val = -9.81 * l.mass * mass_conv;
                const moment_val = ForceSolver.determineMoment(fixed_moment_joint, l._CoM_x, l._CoM_y,
                // const moment_val = ForceSolver.determineMoment(fixed_moment_joint, l._CoM_x, l._CoM_y,
                  0, force_gravity_val)[0] * dist_conv;
                let first_sign_moment = ' + ';
                // if (moment_val[0] === '-') {
                if (moment_val < 0) {
                  first_sign_moment = ' - ';
                }
                sum_forces_y_B_value = sum_forces_y_B_value + force_gravity_val;
                sum_moments_B_value = sum_moments_B_value + moment_val;
                this.addTextElement(' - ' + Math.abs(Math.round(force_gravity_val * 1000) / 1000).toString(), sum_forces_sol_y);
                this.addTextElement(first_sign_moment + Math.abs(Math.round(moment_val * 1000) / 1000), sum_moments_sol);
              }

              this.addTextElement(' = ', sum_forces_eqs);
              switch (type_of_analysis) {
                case 'static':
                  this.addArrowTopTextElement('0', sum_forces_eqs);
                  break;
                case 'dynamic':
                  this.addTextElement('m', sum_forces_eqs);
                  this.addSubscriptTextElement(l.id, sum_forces_eqs);
                  this.addArrowTopTextElement('a', sum_forces_eqs);
                  this.addSubscriptTextElement('com_' + l.id, sum_forces_eqs);
                  break;
              }

              this.addTextElement(' = ' + (Math.round(sum_forces_x_B_value * 1000) / 1000).toString(), sum_forces_sol_x);
              this.addTextElement(' = ' + (Math.round(sum_forces_y_B_value * 1000) / 1000).toString(), sum_forces_sol_y);

              if (l.joints.findIndex(j => j.input) === -1) {
                // if (linkIndex !== ForceSolver.inputLinkIndex) {
                this.addTextElement(' = ' + (Math.round(
                  sum_moments_B_value * 1000) / 1000).toString(), sum_moments_sol);
                this.addTextElement(' = ', sum_moments_eqs);
                switch (type_of_analysis) {
                  case 'static':
                    this.addArrowTopTextElement('0', sum_moments_eqs);
                    break;
                  case 'dynamic':
                    this.addTextElement('I', sum_moments_eqs);
                    this.addSubscriptTextElement(fixed_moment_joint.id, sum_moments_eqs);
                    this.addArrowTopTextElement('\u03B1', sum_moments_eqs);
                    this.addSubscriptTextElement(l.id, sum_moments_eqs);
                    break;
                }
              } else {
                this.addTextElement(' + T = ' + (Math.round(
                  sum_moments_B_value * 1000) / 1000).toString(), sum_moments_sol);
                this.addTextElement(' + ', sum_moments_eqs);
                this.addArrowTopTextElement('T', sum_moments_eqs);
                this.addTextElement(' = ', sum_moments_eqs);
                switch (type_of_analysis) {
                  case 'static':
                    this.addArrowTopTextElement('0', sum_moments_eqs);
                    break;
                  case 'dynamic':
                    this.addTextElement('I', sum_moments_eqs);
                    this.addSubscriptTextElement(fixed_moment_joint.id, sum_moments_eqs);
                    this.addArrowTopTextElement('\u03B1', sum_moments_eqs);
                    this.addSubscriptTextElement(l.id, sum_moments_eqs);
                    break;
                }
              }

              const dropDownLabel = this.createLabelElement('Fixed Position')
              const dropDown = this.createDropdownElement(l.joints);

              // if (table_tabs !== 0) {
              if (l_index !== this.simulator[0].SimulationLinks.length - 1) {
                this.createCheckboxElement('Link ' + l.id, total_part, [sum_forces_gen, sum_forces_eqs,
                  sum_forces_sol_x, sum_forces_sol_y, svgBlueLine, dropDownLabel, dropDown, sum_moments_gen, sum_moments_eqs,
                  sum_moments_sol, svgBlackLine]);
              } else {
                this.createCheckboxElement('Link ' + l.id, total_part, [sum_forces_gen, sum_forces_eqs,
                  sum_forces_sol_x, sum_forces_sol_y, svgBlueLine, dropDownLabel, dropDown, sum_moments_gen, sum_moments_eqs,
                  sum_moments_sol]);
              }

              // total_part.appendChild(svgLine);
              // total_part.appendChild(svgBlackLine);
              total_part.appendChild(sum_forces_gen);
              total_part.appendChild(sum_forces_eqs);
              total_part.appendChild(sum_forces_sol_x);
              total_part.appendChild(sum_forces_sol_y);
              total_part.appendChild(svgBlueLine);

              // total_part.appendChild(dropDownLabel);
              // total_part.appendChild(dropDown);
              // this.createDropdownElement(l.joints, total_part);

              total_part.appendChild(sum_moments_gen);
              total_part.appendChild(sum_moments_eqs);
              total_part.appendChild(sum_moments_sol);
              if (l_index !== this.simulator[0].SimulationLinks.length - 1) {
                total_part.appendChild(svgBlackLine);
              }
              table_tabs.appendChild(total_part);
            });
            break;
          case 'stress':
            break;
          case 'kinematic':
            switch (type_of_analysis) {
              case 'ic':
                total_part.setAttribute('id', 'kinematicICsEquations');
                break;
              case 'loops':
                total_part.setAttribute('id', 'kinematicEquations');
                break;
            }

            KinematicsSolver.requiredLoops.forEach((loop, loop_index) => {
              const svgBlackLine = document.createElement('hr');
              svgBlackLine.style.display = 'none';
              const svgBlueLine = document.createElement('hr');
              svgBlueLine.style.borderColor = 'Blue';
              svgBlueLine.style.display = 'none';

              const sum_vel_gen = document.createElement('div');
              sum_vel_gen.setAttribute('id', 'Sum_Vel_Layout');
              sum_vel_gen.style.display = 'none';
              const sum_acc_gen = document.createElement('div');
              sum_acc_gen.setAttribute('id', 'Sum_Accel_Layout');
              sum_acc_gen.style.display = 'none';
              const sum_vel_eqs = document.createElement('div');
              sum_vel_eqs.setAttribute('id', 'Sum_Vel_Eqs');
              sum_vel_eqs.style.display = 'none';
              const sum_acc_eqs = document.createElement('div');
              sum_acc_eqs.setAttribute('id', 'Sum_Accel_Eqs');
              sum_acc_eqs.style.display = 'none';
              const sum_vel_sol_x = document.createElement('div');
              sum_vel_sol_x.setAttribute('id', 'Sum_Vel_x');
              sum_vel_sol_x.style.display = 'none';
              const sum_vel_sol_y = document.createElement('div');
              sum_vel_sol_y.setAttribute('id', 'Sum_Vel_y');
              sum_vel_sol_y.style.display = 'none';
              const sum_acc_sol_x = document.createElement('div');
              sum_acc_sol_x.setAttribute('id', 'Sum_Acc_x');
              sum_acc_sol_x.style.display = 'none';
              const sum_acc_sol_y = document.createElement('div');
              sum_acc_sol_y.setAttribute('id', 'Sum_Acc_y');
              sum_acc_sol_y.style.display = 'none';

              for (let letter_index = 1; letter_index < loop.length; letter_index++) {
                // Set up general equation
                // Velocity
                this.addArrowTopTextElement('V', sum_vel_gen);
                this.addSubscriptTextElement(loop[letter_index] + '/' + loop[letter_index - 1], sum_vel_gen);
                // Acceleration
                this.addArrowTopTextElement('A', sum_acc_gen);
                this.addSubscriptTextElement(loop[letter_index] + '/' + loop[letter_index - 1], sum_acc_gen);
                if (letter_index === loop.length - 1) {
                  continue;
                }
                // Set up cross product equations
                // Velocity
                this.addArrowTopTextElement('\u03C9', sum_vel_eqs);
                this.addSubscriptTextElement(loop[letter_index] + '/' + loop[letter_index - 1], sum_vel_eqs);
                this.addTextElement(' X ', sum_vel_eqs);
                this.addArrowTopTextElement('r', sum_vel_eqs);
                this.addSubscriptTextElement(loop[letter_index - 1] + loop[letter_index], sum_vel_eqs);
                // Acceleration
                this.addArrowTopTextElement('\u03C9', sum_acc_eqs);
                this.addSubscriptTextElement(loop[letter_index] + '/' + loop[letter_index - 1], sum_acc_eqs);
                this.addTextElement(' X ', sum_acc_eqs);
                this.addArrowTopTextElement('\u03C9', sum_acc_eqs);
                this.addSubscriptTextElement(loop[letter_index] + '/' + loop[letter_index - 1], sum_acc_eqs);
                this.addTextElement(' X ', sum_acc_eqs);
                this.addArrowTopTextElement('r', sum_acc_eqs);
                this.addSubscriptTextElement(loop[letter_index - 1] + loop[letter_index], sum_acc_eqs);
                this.addTextElement(' + ', sum_acc_eqs);
                this.addArrowTopTextElement('\u03B1', sum_acc_eqs);
                this.addSubscriptTextElement(loop[letter_index] + '/' + loop[letter_index - 1], sum_acc_eqs);
                this.addTextElement(' X ', sum_acc_eqs);
                this.addArrowTopTextElement('r', sum_acc_eqs);
                this.addSubscriptTextElement(loop[letter_index - 1] + loop[letter_index], sum_acc_eqs);
                // Set up 2 equations
                const j1 = this.simulator[0].SimulationJoints.find(jt => jt.id === loop[letter_index - 1]);
                const j2 = this.simulator[0].SimulationJoints.find(jt => jt.id === loop[letter_index]);
                // const link = this.simulator[0].SimulationLinks.find(l => l.id.indexOf(j1.id) !== -1 && l.id.indexOf(j2.id) !== -1);
                const r = [j2.x - j1.x, j2.y - j1.y, 0];

                let first_value = '';
                let second_value = '';
                let third_value = '';
                let fourth_value = '';
                let first_sign = '';
                let second_sign = '';
                let third_sign = '';
                let fourth_sign = '';
                const ang_vel = this.simulator[0].posTSL.TSL[0].angular_velocity;
                if (!j1.input && !j2.input) {
                  first_value = (Math.round(r[1] * ang_vel * 1000) / 1000).toString();
                  second_value = (Math.round(r[0] * ang_vel * 1000) / 1000).toString();
                  third_value = (Math.round(-1 * r[0] * ang_vel * ang_vel * 1000) / 1000).toString();
                  fourth_value = (Math.round(-1 * r[1] * ang_vel * ang_vel * 1000) / 1000).toString();
                } else {
                  first_value = (Math.round(r[1] * 1000) / 1000).toString();
                  second_value = (Math.round(r[0] * 1000) / 1000).toString();
                  third_value = (Math.round(-1 * r[0] * 1000) / 1000).toString();
                  fourth_value = (Math.round(-1 * r[1] * 1000) / 1000).toString();
                }

                [first_sign, first_value] = this.checkValue(first_value, false);
                [second_sign, second_value] = this.checkValue(second_value, false);
                [third_sign, third_value] = this.checkValue(third_value, false);
                [fourth_sign, fourth_value] = this.checkValue(fourth_value, false);
                if (sum_vel_sol_x.innerHTML === '') {
                  first_sign = this.adjustSignSpacing(first_sign);
                }
                if (sum_vel_sol_y.innerHTML === '') {
                  second_sign = this.adjustSignSpacing(second_sign);
                }
                if (sum_acc_sol_x.innerHTML === '') {
                  third_sign = this.adjustSignSpacing(third_sign);
                }
                if (sum_acc_sol_y.innerHTML === '') {
                  fourth_sign = this.adjustSignSpacing(fourth_sign);
                }
                // Velocity
                // Acceleration
                this.addTextElement(first_sign + first_value, sum_vel_sol_x);
                this.addTextElement(second_sign + second_value, sum_vel_sol_y);
                this.addTextElement(third_sign + third_value, sum_acc_sol_x);
                this.addTextElement(fourth_sign + fourth_value, sum_acc_sol_y);
                if (!j1.input && !j2.input) {
                  this.addTextElement('\u03C9', sum_vel_sol_x);
                  this.addSubscriptTextElement(loop[letter_index] + '/' + loop[letter_index - 1], sum_vel_sol_x);
                  this.addTextElement('\u03C9', sum_vel_sol_y);
                  this.addSubscriptTextElement(loop[letter_index] + '/' + loop[letter_index - 1], sum_vel_sol_y);

                  let alpha_sign_1 = '';
                  let alpha_sign_2 = '';
                  let alpha_val_1 = (Math.round(r[0] * 1000) / 1000).toString();
                  let alpha_val_2 = (Math.round(r[1] * 1000) / 1000).toString();

                  [alpha_sign_1, alpha_val_1] = this.checkValue(alpha_val_1, false);
                  [alpha_sign_2, alpha_val_2] = this.checkValue(alpha_val_2, false);

                  if (sum_acc_sol_x.innerHTML === '') {
                    alpha_sign_1 = this.adjustSignSpacing(alpha_sign_1);
                  }
                  if (sum_acc_sol_y.innerHTML === '') {
                    alpha_sign_2 = this.adjustSignSpacing(alpha_sign_2);
                  }

                  this.addTextElement('\u03C9', sum_acc_sol_x);
                  this.addSuperscriptTextElement('2', sum_acc_sol_x);
                  this.addSubscriptTextElement(loop[letter_index] + '/' + loop[letter_index - 1], sum_acc_sol_x); // need ^2
                  this.addTextElement('\u03C9', sum_acc_sol_y);
                  this.addSuperscriptTextElement('2', sum_acc_sol_y);
                  this.addSubscriptTextElement(loop[letter_index] + '/' + loop[letter_index - 1], sum_acc_sol_y); // need ^2

                  this.addTextElement(alpha_sign_1 + alpha_val_1, sum_acc_sol_x);
                  this.addTextElement(alpha_sign_2 + alpha_val_2, sum_acc_sol_y);

                  this.addTextElement('\u03B1', sum_acc_sol_x);
                  this.addSubscriptTextElement(loop[letter_index] + '/' + loop[letter_index - 1], sum_acc_sol_x); // need ^2
                  this.addTextElement('\u03B1', sum_acc_sol_y);
                  this.addSubscriptTextElement(loop[letter_index] + '/' + loop[letter_index - 1], sum_acc_sol_y); // need ^2
                }

                if (letter_index !== loop.length - 1) {
                  this.addTextElement(' + ', sum_vel_gen);
                  this.addTextElement(' + ', sum_acc_gen);
                }
                if (letter_index !== loop.length - 2) {
                  this.addTextElement(' + ', sum_vel_eqs);
                  this.addTextElement(' + ', sum_acc_eqs);
                }
              }

              // = 0
              this.addTextElement(' = ', sum_vel_gen);
              this.addTextElement(' = ', sum_vel_eqs);
              this.addTextElement(' = 0', sum_vel_sol_x);
              this.addTextElement(' = 0', sum_vel_sol_y);
              this.addTextElement(' = ', sum_acc_gen);
              this.addTextElement(' = ', sum_acc_eqs);
              this.addTextElement(' = 0', sum_acc_sol_x);
              this.addTextElement(' = 0', sum_acc_sol_y);

              this.addArrowTopTextElement('0', sum_vel_gen);
              this.addArrowTopTextElement('0', sum_acc_gen);
              this.addArrowTopTextElement('0', sum_vel_eqs);
              this.addArrowTopTextElement('0', sum_acc_eqs);

              if (loop_index !== KinematicsSolver.requiredLoops.length - 1) {
                this.createCheckboxElement('Loop ' + loop, total_part, [sum_vel_gen, sum_vel_eqs,
                  sum_vel_sol_x, sum_vel_sol_y, svgBlueLine, sum_acc_gen, sum_acc_eqs, sum_acc_sol_x, sum_acc_sol_y, svgBlackLine]);
              } else {
                this.createCheckboxElement('Loop ' + loop, total_part, [sum_vel_gen, sum_vel_eqs,
                  sum_vel_sol_x, sum_vel_sol_y, svgBlueLine, sum_acc_gen, sum_acc_eqs, sum_acc_sol_x, sum_acc_sol_y]);
              }
              total_part.appendChild(sum_vel_gen);
              total_part.appendChild(sum_vel_eqs);
              total_part.appendChild(sum_vel_sol_x);
              total_part.appendChild(sum_vel_sol_y);
              total_part.appendChild(svgBlueLine);
              total_part.appendChild(sum_acc_gen);
              total_part.appendChild(sum_acc_eqs);
              total_part.appendChild(sum_acc_sol_x);
              total_part.appendChild(sum_acc_sol_y);
              if (loop_index !== KinematicsSolver.requiredLoops.length - 1) {
                total_part.appendChild(svgBlackLine);
              }

              table_tabs.appendChild(total_part);
            });
        }
        break;
    }
  }

  addSummationTextElement(doc: any) {
    const summationText = document.createTextNode('\u2211');
    return doc.appendChild(summationText);
  }

  addTextElement(text: string, doc: any) {
    const textNode = document.createTextNode(text);
    doc.appendChild(textNode);
    return doc;
  }

  addArrowTopTextElement(text: string, doc: any) {
    // const textNode = document.createTextNode(text.italics());
    const textNode = document.createTextNode(text);
    // const textElement = document.createElement('div');
    // textElement.innerHTML = text;
    // textElement.style.fontStyle = 'italics';
    // const p = document.createElement('p');
    // p.innerHTML = text.italics();
    const spanElement = document.createElement('span');
    spanElement.style.verticalAlign = '8px';
    spanElement.innerHTML = '\u20d7';
    // p.normalize();
    // doc.appendChild(p);
    doc.appendChild(textNode);
    // doc.appendChild(textElement);
    // doc.appendChild(text.italics());
    doc.appendChild(spanElement);
    doc.normalize();
    return doc;
    // return doc.appendChild(span);
  }

  // insert radio buttons to choose fixed moment
  createCheckboxElement(desired_info: string, doc: any, elements: any[]) {
    const sub_div = document.createElement('div');
    sub_div.className = 'form-row';

    const form = document.createElement('FORM');
    form.id = 'form';

    const system = document.createElement('INPUT');
    system.setAttribute('type', 'checkbox');
    system.style.display = 'block';
    system.id = 'not_check';

    system.onclick = function() {
      if (system.id === 'not_check') {
        system.id = 'check';
        elements.forEach(e => {
          e.style.display = 'block';
        });
      } else {
        system.id = 'not_check';
        elements.forEach(e => {
          e.style.display = 'none';
        });
      }
    };

    const system_label = document.createElement('LABEL');
    const system_label_text = document.createTextNode(desired_info);
    system_label.className = 'col-sm-2 col-form-label';
    system_label.setAttribute('for', 'not_check');
    system_label.appendChild(system_label_text);

    form.appendChild(system);
    form.appendChild(system_label);
    sub_div.appendChild(form);
    doc.appendChild(sub_div);
  }

  // createDropdownElement(joints: Joint[], doc: any) {
  createDropdownElement(joints: Joint[]) {
    const select_div = document.createElement('select');
    select_div.value = '0';
    select_div.style.display = 'none';

    // for (let i = 0; i < joints.length; i++) {
    joints.forEach(j => {
      const option = document.createElement('option');
      option.innerHTML = j.id;
      select_div.appendChild(option);
    });
    const com_option = document.createElement('option');
    com_option.innerHTML = 'com';
    select_div.appendChild(com_option);

    return select_div;
    // doc.appendChild(label);
    // doc.appendChild(select_div);
  }

  createLabelElement(text: string) {
    const label = document.createElement('label');
    label.innerHTML = text;
    label.style.display = 'none';
    return label;
  }

  addSubscriptTextElement(text: string, doc: any) {
    const first_sub = document.createElement('SUB');
    const first_sub_text = document.createTextNode(text);
    first_sub.appendChild(first_sub_text);
    doc.appendChild(first_sub);
    doc.normalize();
  }

  addSuperscriptTextElement(text: string, doc: any) {
    const first_sup = document.createElement('SUP');
    const first_sup_text = document.createTextNode(text);
    first_sup.appendChild(first_sup_text);
    doc.appendChild(first_sup);
    doc.normalize();
  }
}

class PathPointNode {
  private _id: string;
  private _neighborOne: string;
  private _neighborTwo: string;
  private _visited: boolean;

  constructor(id: string, neighborOne: string, neighborTwo: string) {
    this._id = id;
    this._neighborOne = neighborOne;
    this._neighborTwo = neighborTwo;
    this._visited = false;
  }


  get id(): string {
    return this._id;
  }

  set id(value: string) {
    this._id = value;
  }

  get neighborOne(): string {
    return this._neighborOne;
  }

  set neighborOne(value: string) {
    this._neighborOne = value;
  }

  get neighborTwo(): string {
    return this._neighborTwo;
  }

  set neighborTwo(value: string) {
    this._neighborTwo = value;
  }

  get visited(): boolean {
    return this._visited;
  }

  set visited(value: boolean) {
    this._visited = value;
  }
}
