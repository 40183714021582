import {Joint, RealJoint} from '../Joints_Links_Forces/Joint';
import {ImagLink, Link, RealLink} from '../Joints_Links_Forces/Link';
import {Force} from '../Joints_Links_Forces/Force';
import {cross, lusolve} from 'mathjs';
import {forEach} from '@angular/router/src/utils/collection';
import {KinematicsSolver} from './KinematicsSolver';

export class ForceSolver {
  private static loopLettersToLinkIndexMap = new Map<string, number>();
  private static containsInputIndexMap = new Map<string, number>();
  private static jointIDToNotUtilizedYetBooleanMap = new Map<string, boolean>();
  private static jointIDToTracerBooleanMap = new Map<string, boolean>();
  private static unknownVariableNum: number;
  private static jointIDToUsedBooleanMap = new Map<string, boolean>();

  static jointIdToJointIndexMap = new Map<string, number>();
  static unknownVariableForcesMap = new Map<string, [number, number]>();
  static jointIDToUnknownArrayIndexMap = new Map<string, number>();
  static linkIDToUnknownArrayIndexMap = new Map<string, number>();
  static jointsForcesDirectionsMap = new Map<string, string>();
  static unknownVariableTorque: number;
  // static A_matrix = Array<Array<string>>();
  static A_matrix = [];
  static B_matrix = [];
  // static B_matrix = Array<string>();
  static desiredLoopLetters: Array<string>;
  static inputLinkIndex: number;

  static resetVariables() {
    this.loopLettersToLinkIndexMap = new Map<string, number>();
    this.containsInputIndexMap = new Map<string, number>();
    this.jointIDToNotUtilizedYetBooleanMap = new Map<string, boolean>();
    this.jointIDToTracerBooleanMap = new Map<string, boolean>();
    this.unknownVariableNum = undefined;
    this.jointIDToUsedBooleanMap = new Map<string, boolean>();

    this.jointIdToJointIndexMap = new Map<string, number>();
    this.unknownVariableForcesMap = new Map<string, [number, number]>();
    this.jointIDToUnknownArrayIndexMap = new Map<string, number>();
    this.jointsForcesDirectionsMap = new Map<string, string>();
    this.unknownVariableTorque = undefined;
    this.inputLinkIndex = undefined;
    // this.A_matrix = Array<Array<string>>();
    // this.B_matrix = Array<string>();
    this.A_matrix = [];
    this.B_matrix = [];
    this.desiredLoopLetters = Array<string>();
  }

  static determineForceAnalysis(simJoints: Joint[], simLinks: Link[], analysisType: string, gravity: boolean, unit: string) {
    // const desiredLoopLetters = this.determineDesiredLoopLettersForce(requiredLoops);
    // const [knownArray, unknownArray] = this.determineArraysForce(simJoints, simLinks, this.desiredLoopLetters, analysisType, gravity);
    this.determineArraysForce(simJoints, simLinks, analysisType, gravity, unit);
    const sol = lusolve(this.A_matrix, this.B_matrix);
    simJoints.forEach(j => {
      this.unknownVariableForcesMap.set(j.id, [0, 0]);
    });
      // for (let i = 0; i < this.JointIDToUnknownArrayIndex.size; i++) {
    // for (const entry of this.JointIDToUnknownArrayIndex.entries()) {
    // this should not be jointIDtoUnknownArray... choose another map
    for (const [jointID, jointIndex] of this.jointIDToUnknownArrayIndexMap.entries()) {
      // const jointIndex = this.jointIDToNotUtilizedYetBooleanMap.get(key);
      this.unknownVariableForcesMap.set(jointID, [sol[jointIndex][0], sol[jointIndex + 1][0]]);
      // this.unknownVariableForces.set(jointID, [sol[2 * jointIndex], sol[2 * jointIndex + 1]]);
    }
        // const joint = simJoints[i]
        // this.unknownVariableForces.set()
        // this.unknownVariableForces[i] = [sol[2 * i], sol[2 * i + 1]];
      // }
    this.unknownVariableTorque = sol[2 * this.jointIDToUnknownArrayIndexMap.size];
  }

  private static determineArraysForce(simJoints: Joint[], simLinks: Link[], analysisType: string, gravity: boolean, unit: string) {
    if (this.unknownVariableNum === undefined) {
      let realJointCount = 0;
      simJoints.forEach(j => {
        const tracerJointBoolean = (j.connectedLinks.length === 1 && !j.ground);
        this.jointIDToTracerBooleanMap.set(j.id, tracerJointBoolean);
        if (j instanceof RealJoint && !this.jointIDToTracerBooleanMap.get(j.id)) {
          this.jointIDToUsedBooleanMap.set(j.id, true);
          realJointCount++;
        } else {
          this.jointIDToUsedBooleanMap.set(j.id, false);
        }
      });
      this.unknownVariableNum = (realJointCount * 2) + 1;
    }

    // const knownArray = [];
    // const unknownArray = [];
    // set up the A and B matrices
    this.A_matrix = [];
    this.B_matrix = [];
    for (let i = 0; i < this.unknownVariableNum; i++) {
      const arr = [];
      for (let j = 0; j < this.unknownVariableNum; j++) {
        arr.push(0);
      }
      // unknownArray.push(arr);
      // knownArray.push(0);
      this.A_matrix.push(arr);
      this.B_matrix.push(0);
    }

    if (this.jointIDToUnknownArrayIndexMap.size === 0) {
    let unknown_variable_index = 0;
    simJoints.forEach((joint, joint_index) => {
      const joint_id = simJoints[joint_index].id;
      this.jointIdToJointIndexMap.set(joint_id, joint_index);
      if (this.jointIDToUsedBooleanMap.get(joint_id)) {
        this.jointIDToUnknownArrayIndexMap.set(joint_id, 2 * unknown_variable_index);
        // if (simJoints[joint_index].input) {
        //   this.inputLinkIndex = 2 * unknown_variable_index;
        // }
        this.jointIDToNotUtilizedYetBooleanMap.set(joint_id, true);
        unknown_variable_index++;
      }
    });
    // for (let simJointIndex = 0; simJointIndex < simJoints.length; simJointIndex++) {
    //   const joint_id = simJoints[simJointIndex].id;
    //   this.jointIdToJointIndexMap.set(joint_id, simJointIndex);
    //   if (this.jointIDToUsedBooleanMap.get(joint_id)) {
    //     this.JointIDToUnknownArrayIndexMap.set(joint_id, 2 * unknown_variable_index);
    //     this.jointIDToNotUtilizedYetBooleanMap.set(joint_id, true);
    //     unknown_variable_index++;
    //   }
    // }
      // simJoints.forEach(j => {
      //   if (this.jointIDToUsedBooleanMap) {
      //     this.jointIdToJointIndexMap.set(j.id, simJoints.findIndex(jt => jt === j));
      //     this.JointIDToUnknownArrayIndex.set(j.id, 2 * unknown_variable_index);
      //     this.jointIDToNotUtilizedYetBooleanMap.push(true);
      //     unknown_variable_index++;
      //   }
      // });
    } else {
      for (const entry of this.jointIDToNotUtilizedYetBooleanMap.entries()) {
        this.jointIDToNotUtilizedYetBooleanMap.set(entry[0], true);
        // this.jointIDToNotUtilizedYetBooleanMap[entry[0]] = true;
      }
    }
    let realLinkCount = 0;
    let imagLinkCount = 0;
    // if (this.inputLinkIndex === undefined) {
    //   for (const cur_link of simLinks) {
    //     if (cur_link.joints.findIndex(jt => jt.input) !== -1) {
    //       this.inputLinkIndex = 3 * realLinkCount + imagLinkCount;
    //       break;
    //     }
    //     switch (cur_link.constructor) {
    //       case RealLink:
    //         realLinkCount++;
    //         break;
    //       case ImagLink:
    //         imagLinkCount++;
    //         break;
    //     }
    //   }
    // }

    // realLinkCount = 0;
    // imagLinkCount = 0;
    // const mass_conversion = 0.001; // convert from g -> kg
    let distance_conversion = 1; // kg
    let mass_conversion = 1;
    // let mmoI_conversion = 1;

    if (unit === 'cm') {
      distance_conversion = 1 / 100;
      mass_conversion = 1 / 1000;
      // mmoI_conversion = 1 / 10000;
    }
    this.desiredLoopLetters.forEach(letters => {
    // desiredLoopLetters.forEach(letters => {
      // if (!this.jointIDToIndexMap.has(letters[0].charAt(0))) {
      //   this.jointIDToIndexMap.set(letters[0].charAt(0), simJoints.findIndex(j => j.id === letters[0].charAt(0)));
      //   this.jointIDToIndexMap.set(letters[0].charAt(1), simJoints.findIndex(j => j.id === letters[0].charAt(1)));
      // }
      const joint1 = simJoints[this.jointIdToJointIndexMap.get(letters[0].charAt(0))];
      const joint2 = simJoints[this.jointIdToJointIndexMap.get(letters[0].charAt(1))];
      if (!this.loopLettersToLinkIndexMap.has(letters[0].charAt(0) + letters[0].charAt(1))) {
        this.loopLettersToLinkIndexMap.set(letters[0].charAt(0) + letters[0].charAt(1),
          simLinks.findIndex(l => l.id.includes(letters[0].charAt(0)) && l.id.includes(letters[0].charAt(1))));
      }
      const link = simLinks[this.loopLettersToLinkIndexMap.get(letters[0].charAt(0) + letters[0].charAt(1))];
      // commented out this part. Possibly this can be solved by utilizing initializing maps refresh
      // if (!this.linkIDToUnknownArrayIndexMap.has(link.id)) {
        this.linkIDToUnknownArrayIndexMap.set(link.id, 3 * realLinkCount + imagLinkCount);
        if (joint1.input || joint2.input) {
          this.inputLinkIndex = 3 * realLinkCount + imagLinkCount + 2;
        }
      // }
      // const torqueConversion = 0.01; // changes moment of inertia units (kg*m^2/s^2 -> kg*cm*m) to keep units consistent
      link.joints.forEach(j => {
        if (this.jointIDToTracerBooleanMap.get(j.id)) {
          this.jointsForcesDirectionsMap.set(link.id + j.id, ' ');
          return;
        }
        let xForce: number;
        let yForce: number;
        // const jointIndex = this.jointIdToJointIndexMap.get(j.id);
        const xIndex = this.jointIDToUnknownArrayIndexMap.get(j.id);
        const yIndex = xIndex + 1;
        if (this.jointIDToNotUtilizedYetBooleanMap.get(j.id)) {
          xForce = 1;
          yForce = 1;
          this.jointsForcesDirectionsMap.set(link.id + j.id, '+');
          this.jointIDToNotUtilizedYetBooleanMap.set(j.id, false);
          // this.jointIDToNotUtilizedYetBooleanMap[jointIndex] = false;
        } else {
          xForce = -1;
          yForce = -1;
          this.jointsForcesDirectionsMap.set(link.id + j.id, '-');
        }
        switch (link.constructor) {
          case RealLink:
            const torqueVal = this.determineMoment(joint1, j.x, j.y, xForce, yForce);
            this.A_matrix[3 * realLinkCount + imagLinkCount][xIndex] += xForce;
            this.A_matrix[3 * realLinkCount + imagLinkCount + 1][yIndex] += yForce;
            this.A_matrix[3 * realLinkCount + imagLinkCount + 2][xIndex] += torqueVal[1] * distance_conversion;
            this.A_matrix[3 * realLinkCount + imagLinkCount + 2][yIndex] += torqueVal[0] * distance_conversion;
            // unknownArray[3 * realLinkCount + imagLinkCount][xIndex] += xForce;
            // unknownArray[3 * realLinkCount + imagLinkCount + 1][yIndex] += yForce;
            // unknownArray[3 * realLinkCount + imagLinkCount + 2][xIndex] += torqueVal[1];
            // unknownArray[3 * realLinkCount + imagLinkCount + 2][yIndex] += torqueVal[0];
            break;
          case ImagLink:
            const mu = 0.1;
            const desiredJoint = joint1 instanceof RealJoint ? joint1 : joint2;
            const constant = xForce / (-mu * Math.cos(desiredJoint.angle) + Math.sin(desiredJoint.angle));
            this.B_matrix[3 * realLinkCount + imagLinkCount][xIndex] = -mu * constant * Math.sin(desiredJoint.angle) +
              constant * Math.cos(desiredJoint.angle);
            this.B_matrix[3 * realLinkCount + imagLinkCount][yIndex] = yForce;
            // unknownArray[3 * realLinkCount + imagLinkCount][xIndex] = -mu * constant * Math.sin(desiredJoint.angle) +
            //   constant * Math.cos(desiredJoint.angle);
            // unknownArray[3 * realLinkCount + imagLinkCount][yIndex] = yForce;
            break;
        }
      });
      switch (link.constructor) {
        case RealLink:
          const currLink = link as RealLink;
          if (gravity) {
            // const gravity = 9.80665
            const gravity_val = -9.81;
            const calc_mass = link.mass * mass_conversion;
            // const gravity_val = 980.665; // cm/s^2
            const torque_from_gravity = this.determineMoment(joint1, currLink.CoM_x, currLink.CoM_y,
              0, gravity_val * calc_mass);
            // const torque_from_gravity = this.determineMoment(joint1, currLink.CoM_x, currLink.CoM_y,
            //   0, gravity_val * link.mass * mass_conversion);
            // this.B_matrix[3 * realLinkCount + imagLinkCount + 1] += (gravity_val * link.mass * mass_conversion * -1);
            this.B_matrix[3 * realLinkCount + imagLinkCount + 1] += (gravity_val * calc_mass * -1);
            this.B_matrix[3 * realLinkCount + imagLinkCount + 2] += (torque_from_gravity[0] * distance_conversion * -1);
            // knownArray[3 * realLinkCount + imagLinkCount + 1] += (gravity_val * link.mass * mass_conversion * -1);
            // knownArray[3 * realLinkCount + imagLinkCount + 2] += (torque_from_gravity[0] * -1);
          }
          // if (analysisType === 'dynamic' && consideredLink.findIndex(l => l === link.id) === -1) {
          if (analysisType === 'dynamic') {
            // kg * m / s ^ 2 => kg * cm/s^2
            // const centimetersToMeters = 1 / 100;
            // const calc_mass = link.mass * mass_conversion;
            const calc_mass = link.mass * mass_conversion;
            const acc_x = KinematicsSolver.linkAccMap.get(link.id)[0] * distance_conversion;
            const acc_y = KinematicsSolver.linkAccMap.get(link.id)[1] * distance_conversion;
            // const acc_x = KinematicsSolver.linkAccMap.get(link.id)[0] * centimetersToMeters;
            // const acc_y = KinematicsSolver.linkAccMap.get(link.id)[1] * centimetersToMeters;
            const calc_mmoi = link.massMomentOfInertia * Math.pow(distance_conversion, 2);
            // const calc_mmoi = link.massMomentOfInertia * torqueConversion;
            const desired_joint_index = this.jointIdToJointIndexMap.get(letters[0].charAt(0));
            const desired_joint = simJoints[desired_joint_index];
            const link_com = KinematicsSolver.linkCoMMap.get(link.id);
            const dist = Math.sqrt(Math.pow(link_com[0] - desired_joint.x, 2) +
              Math.pow(link_com[1] - desired_joint.y, 2)) * distance_conversion;
            const angular_acc = KinematicsSolver.linkAngAccMap.get(link.id);
            // const J = calc_mmoi * angular_acc;
            const J = calc_mmoi;
            // const m_d_2 = calc_mass * Math.pow(dist, 2) * centimetersToMeters;
            const m_d_2 = calc_mass * Math.pow(dist, 2);
            const J_m_d_2 = J + m_d_2;
            const total_mmoi = J_m_d_2 * angular_acc;
            this.B_matrix[3 * realLinkCount + imagLinkCount] += calc_mass * acc_x;
            this.B_matrix[3 * realLinkCount + imagLinkCount + 1] += calc_mass * acc_y;
            this.B_matrix[3 * realLinkCount + imagLinkCount + 2] += total_mmoi;
            // knownArray[3 * realLinkCount + imagLinkCount] += calc_mass * acc_x;
            // knownArray[3 * realLinkCount + imagLinkCount + 1] += calc_mass * acc_y;
            // kg * m / s ^ 2 * cm = kg * cm ^2 * (rot)/s^2
            // knownArray[3 * realLinkCount + imagLinkCount + 2] += total_mmoi;
          }
          if (currLink.forces.length === 0) {
            realLinkCount++;
            break;
          }
          currLink.forces.forEach(f => {
            const torqueNum = this.determineMoment(joint1, f.sx, f.sy, f.xForce, f.yForce);
            // const torqueNum = this.determineMoment(joint1, f.xLocOfForceOnLink, f.yLocOfForceOnLink, f.xForce, f.yForce);
            this.B_matrix[3 * realLinkCount + imagLinkCount] += (f.xForce * -1);
            this.B_matrix[3 * realLinkCount + imagLinkCount + 1] += (f.yForce * -1);
            this.B_matrix[3 * realLinkCount + imagLinkCount + 2] += (torqueNum[1] * -1 * distance_conversion);
            this.B_matrix[3 * realLinkCount + imagLinkCount + 2] += (torqueNum[0] * -1 * distance_conversion);
            // knownArray[3 * realLinkCount + imagLinkCount] += (f.xForce * -1);
            // knownArray[3 * realLinkCount + imagLinkCount + 1] += (f.yForce * -1);
            // knownArray[3 * realLinkCount + imagLinkCount + 2] += (torqueNum[1] * -1);
            // knownArray[3 * realLinkCount + imagLinkCount + 2] += (torqueNum[0] * -1);
          });
          realLinkCount++;
          break;
        case ImagLink:
          imagLinkCount++;
          break;
      }
    });
    this.A_matrix[this.inputLinkIndex][this.unknownVariableNum - 1] += 1;
    // this.A_matrix[this.inputLinkIndex + 2][this.unknownVariableNum - 1] += 1;
    // unknownArray[this.inputLinkIndex + 2][this.unknownVariableNum - 1] += 1;

    // return [knownArray, unknownArray];
  }

  static determineMoment(joint1: Joint, joint2X: number, joint2Y: number, xForce, yForce): number[] {
    const xDist = joint2X - joint1.x;
    const yDist = joint2Y - joint1.y;
    if (xDist === 0 && yDist === 0) {
      return [0, 0, 0];
    }
    const arr1 = cross([xDist, yDist, 0], [0, yForce, 0]);
    const arr2 = cross([xDist, yDist, 0], [xForce, 0, 0]);
    return [arr1[2], arr2[2], 0];
  }

  static determineDesiredLoopLettersForce(requiredLoops: string[]) {
    const desiredForceLoops = [];
    const utilizedFirstJointMap = new Map <string, number>();
    const utilizedSecondJointMap = new Map <string, number>();
    requiredLoops.forEach(loop => {
      for (let i = 1; i < loop.length - 1; i++) {
        if (utilizedFirstJointMap.has(loop[i - 1]) || utilizedSecondJointMap.has(loop[i])) {
          continue;
        }
        utilizedFirstJointMap.set(loop[i - 1], 1);
        utilizedSecondJointMap.set(loop[i], 1);
        desiredForceLoops.push([loop[i - 1] + loop[i]]);
      }
    });
    this.desiredLoopLetters = desiredForceLoops;
    // return desiredForceLoops;
  }
}
