// Standalone functions that does not require any dependency (definition)
// This is intentially separated from StaticFuncs.ts to prevent circular dependencies

class IndiFuncColle {

  // More accurate Rounding function
  roundNumber(num: number, scale: number): number {
    const tens = Math.pow(10, scale);
    return Math.round(num * tens) / tens;
  }


  showErrorNotification(text: string, duration?: number) {
    const notificationWrapper = document.getElementById('notificationWrapper');

    const notificationTextWrapper = document.createElement('div');
    const notificationText = document.createElement('p');
    notificationText.innerText = text;
    notificationText.style.backgroundColor = 'white';
    notificationText.style.color = 'black';
    notificationText.style.display = 'inline-block';
    notificationText.style.padding = '3px';
    notificationText.style.margin = '1px';
    notificationText.style.border = '2px solid red';
    notificationTextWrapper.appendChild(notificationText);

    notificationWrapper.appendChild(notificationTextWrapper);
    setTimeout(() => {
      notificationWrapper.removeChild(notificationTextWrapper);
    }, duration === undefined ? 3000 : duration);
  }

  showNotification(text: string, duration?: number) {
    const notificationWrapper = document.getElementById('notificationWrapper');

    const notificationTextWrapper = document.createElement('div');
    const notificationText = document.createElement('p');
    notificationText.innerText = text;
    notificationText.style.backgroundColor = 'black';
    notificationText.style.color = 'white';
    notificationText.style.display = 'inline-block';
    notificationText.style.padding = '3px';
    notificationText.style.margin = '1px';
    notificationText.style.border = '2px solid black';
    notificationTextWrapper.appendChild(notificationText);

    notificationWrapper.appendChild(notificationTextWrapper);
    setTimeout(() => {
      notificationWrapper.removeChild(notificationTextWrapper);
    }, duration === undefined ? 3000 : duration);
  }

  jointIncrementToId(increment: number): string {
    let res = '';
    let remainder = increment;
    while (remainder > 26) {
      let quotient = remainder / 26;
      let level = 1;
      while (quotient > 26) {
        quotient = quotient / 26;
        level++;
      }
      quotient = Math.floor(quotient);
      res += String.fromCharCode(64 + quotient);
      remainder -= quotient * Math.pow(26, level);
    }
    remainder = Math.floor(remainder);
    res += String.fromCharCode(64 + remainder);
    return res;
  }

  log(x: number, y: number) {
    return Math.log(y) / Math.log(x);
  }


  getURL(): string {
    const protocol = window.location.protocol;
    const hostname = window.location.hostname;
    const pathname = window.location.pathname;
    const port = window.location.port;
    const loc = `${protocol}//${hostname}${port ? `:${port}` : ''}${pathname}`;
    return loc;
  }

  openURL(url: string) {
    // fake an <a> to click on
    const toolman = document.createElement('a');
    toolman.setAttribute('href', url);
    toolman.setAttribute('target', '_blank');
    toolman.style.display = 'none';
    document.body.appendChild(toolman);
    toolman.click();
    document.body.removeChild(toolman);
  }
}

export const IndependentFuncs = new IndiFuncColle();
