import { Vertex } from './Vertex';
import { Joint as SimJoint } from '../simulator/Joints_Links_Forces/Joint';
import { RealJoint as SimRealJoint} from '../simulator/Joints_Links_Forces/Joint';
import { ImagJoint as SimImagJoint} from '../simulator/Joints_Links_Forces/Joint';
import { SVGFuncs, Shape } from '../functions/SVGFuncs';
import { Link } from './Link';
import {Tag} from './Tag';


export class Joint extends Vertex {
  private readonly _svg: SVGElement;
  private _idTag: SVGElement;
  private _links: Link[];
  private _type: string;
  private _grounded: boolean;
  // input: boolean;
  private _input = false;
  private _angle: number;
  private _coeffFriction: number;

  // Main constructor
  constructor(ID: string, x: number, y: number, type: string, angle = 0,
              coeffFriction = 0.1) {
    super(ID, x, y);
    this._links = [];
    this._type = type;
    this._grounded = false;
    this._angle = angle;
    this._coeffFriction = coeffFriction;
    this._svg = SVGFuncs.createJoint(ID, x, y);
    this._idTag = SVGFuncs.createJointLinkTag(ID, x - 0.1, y + 0.1);
  }

  get idTag(): SVGElement {
    return this._idTag;
  }

  get svg(): SVGElement {
    return this._svg;
  }

  get links() {
    return this._links;
  }

  set grounded(value: boolean) {
    this._grounded = value;
  }

  set input(value: boolean) {
    this._input = value;
  }

  set links(value: Link[]) {
    this._links = value;
  }

  get type(): string {
    return this._type;
  }

  set type(value: string) {
    this._type = value;
  }

  getSlider() {
    return this._type === 'P';
  }

  get angle(): number {
    return this._angle;
  }


  set angle(value: number) {
    this._angle = value;
  }

  get coeffFriction() {
    return this._coeffFriction;
  }


  set coeffFriction(value: number) {
    this._coeffFriction = value;
  }

  getLinkIndex(link: Link): number {
    return this.links.indexOf(link);
  }

  deleteLink(index: number) {
    this.links.splice(index, 1);
  }

  setGrounded(grounded: boolean, type?: string) {
    this._grounded = grounded;
    if (grounded === true || type === 'P') {
      this.type = type;
      type === 'R' ? SVGFuncs.setCircleAsGround(this.svg) : SVGFuncs.setCircleAsSlider(this.svg);
    } else {
      this.type = 'R';
      SVGFuncs.setCircleAsJoint(this.svg);
    }
  }

  get input(): boolean {
    return this._input;
  }

  setInput(input: boolean, joint?: Joint) {
    this._input = input;
    this.type = joint.type;
    if (input === true) {
      switch (joint.type) {
        case 'R':
          joint.grounded === true ? SVGFuncs.setCircleAsRGroundInput(this.svg) : SVGFuncs.setCircleAsRevoluteInput(this.svg);
          break;
        case 'P':
          joint._grounded === true ? SVGFuncs.setCircleAsSliderInput(this.svg) : SVGFuncs.setCircleAsSlider(this.svg);
          break;
      }
    } else {
      switch (joint.type) {
        case 'R':
          // SVGFuncs.setCircleAsGround(this.svg);
          joint.grounded === true ? SVGFuncs.setCircleAsGround(this.svg) : SVGFuncs.setCircleAsJoint(this.svg);
          break;
        case 'P':
          SVGFuncs.setCircleAsSlider(this.svg);
          // joint.getGrounded() === true ? SVGFuncs.setCircleAsJoint(this.svg) : SVGFuncs.setCircleAsJoint(this.svg);
          break;
      }
    }
  }

  setSlider(slider: boolean) {
    if (slider) {
      this._grounded = true;
      SVGFuncs.setCircleAsSlider(this.svg);
      this.type = 'P';
    } else {
      this._grounded = false;
      SVGFuncs.setCircleAsJoint(this.svg);
      this.type = 'R';
    }
  }

  get grounded(): boolean {
    return this._grounded;
  }

  convertToSimulatorJoint() {
    if (this.type === 'P') {
      // fix the ground for this later
      // return new SimRealJoint(this.ID, this.input, this.grounded, this.type, [this.getX(), this.getY()], this.angle);
      return new SimRealJoint(this.id, this.input, false, this.type, [this.x, this.y], this.angle);
    } else {
      return new SimRealJoint(this.id, this.input, this.grounded, this.type, [this.x, this.y]);
    }
  }

  removeLinks() {
    let i;
    for (i = 0; i < this.links.length; i++) {
      this.links[i].removeJoint(this);
    }
  }

  // Deletes all relevant joint info to prepare for joint deletion
  removeLinksAndSVG(canvas: SVGElement) {
    let i;
    for (i = 0; i < this.links.length; i++) {
      this.links[i].removeJointAndSVG(this, canvas);
    }
    canvas.removeChild(this.svg);
  }

  relocate(x: number, y: number) {
    super.relocate(x, y);
    this.svg.setAttributeNS(undefined, 'x', x.toString());
    this.svg.setAttributeNS(undefined, 'y', y.toString());

    this.links.forEach(link => {
      if (link.uiShape === Shape.line) {
        const firstJoint = link.joints[0];
        const secondJoint = link.joints[1];
        const newBounds = SVGFuncs.getLineBounds(
          { x: firstJoint.x, y: firstJoint.y },
          { x: secondJoint.x, y: secondJoint.y });
        link.tryNewBounds(newBounds);

        link.forces.forEach(force => {
          force.updateSVG();
        });
      }
    });
  }


  // Add a link to the list of links
  addLink(newLink: Link) {
    this.links.push(newLink);
  }

  // Remove a joint from the list of links
  removeLink(remVertex: Vertex) {
    for (let i = 0; i < this.links.length; i++) {
      if (this.links[i].joints[0].id === remVertex.id ||
        this.links[i].joints[1].id === remVertex.id) {
        this.links.splice(i, 1);
      }
    }
  }

  createIDTag() {
    const x_offset = 0.1;
    const y_offset = 0.1;
    this._idTag = SVGFuncs.createJointLinkTag(this.id, (this.x - x_offset), (this.y + y_offset));
    // const joint_tag = new Tag(id, (x - x_offset), ( y + y_offset), 'jointLink');
    // this._idTag = SVGFuncs.setTagTempCreated(joint_svg, this.id);
  }
}
