import {AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {Joint} from '../../classes/Joint';
import {Link} from '../../classes/Link';
import {Bounds, editorID, Shape, SVGFuncs} from '../../functions/SVGFuncs';
import {TimeSortedList} from '../../simulator/TimeSortedList';
import {StaticFuncs} from '../../functions/StaticFuncs';
import {IndependentFuncs as IndiFuncs} from '../../functions/IndependentFuncs';
import {TransformMatrix} from '../../classes/2DTransformMatrix';
import {Coord} from '../../classes/Coord';
import {AppConstants} from '../../classes/AppConstants';
import {Force} from 'src/classes/Force';
import {contextSelector} from '../contextmenu/contextmenu.component';
import {ForceEndpoint, ForceEndpointType} from 'src/classes/ForceEndpoint';
import {Tag} from '../../classes/Tag';
import {PathPoint} from '../../classes/PathPoint';
import {ThreePosition} from '../../classes/ThreePosition';
import {Simulator} from '../../simulator/Simulator';

// The possible states the program could be in.
enum states {
  init,
  waiting,
  creating,
  moving,
  panning,
  zooming,
  editing,
  processing
}

enum shapeEditModes {
  move,
  resize
}

enum createModes {
  link,
  force
}

enum moveModes {
  joint,
  forceEndpoint,
  threePosition,
  pathPoint
}

@Component({
  selector: 'app-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.css'],
})

export class GridComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() synthesis: string;
  @Input() connection: string;
  @Input() synthesisDislay: string;
  @Input() jointArray: Joint[]; // The array (from main) containing all joints
  @Input() linkArray: Link[]; // The array (from main) containing all links
  @Input() forceArray: Force[];
  // @Input() jointLinkTagArray: Tag[]; // put this within joint and link class
  @Input() comTagArray: Tag[]; // put this within link class
  @Input() threePositionArray: ThreePosition[];
  @Input() pathPointArray: PathPoint[] = [];
  // @Input() pathPointArray: PathPoint[];
  @Input() posTimeSortedList: TimeSortedList; // The array (from main) containing all links
  inputJoint: Joint; // The array (from main) containing all links
  @Input() sliderJoint: Joint;

  // Context Menu Inputs
  @Input() menuClicked: { type: String, event: MouseEvent };
  @Input() newContextElement: Joint | Link | GridComponent;
  @Input() menuCoords: Coord;

  // Emitter to add joint or link to the main array
  @Output() newLinkEmit = new EventEmitter<any>();
  @Output() newJointEmit = new EventEmitter<Joint>();
  @Output() newPathPointEmit = new EventEmitter<PathPoint>();
  @Output() newThreePositionEmit = new EventEmitter<ThreePosition>();
  @Output() newInputBooleanEmit = new EventEmitter<Boolean>();
  @Output() newForceEmit = new EventEmitter<Force>();
  @Output() newGroundEmit = new EventEmitter();
  @Output() newTwoJointLinkRemoteEmit = new EventEmitter<{
    joint1X: number, joint1Y: number, joint1ID: string, joint2X: number,
    joint2Y: number, joint2ID: string
  }>();
  @Output() newJointAndLinkToExistingJointEmit = new EventEmitter<{ joint1X: number, joint1Y: number, joint1ID: string, joint2: Joint }>();
  // @Output() newJointLinkTagEmit = new EventEmitter<Tag>();
  @Output() newCoMTagEmit = new EventEmitter<Tag>();
  // Emitter to deleteAndDeleteSVG joint or link from the main array
  @Output() delJointEmit = new EventEmitter<Joint>();
  @Output() delThreePositionEmit = new EventEmitter<ThreePosition>();
  @Output() delPathPointEmit = new EventEmitter<PathPoint>();
  @Output() delLinkEmit = new EventEmitter<Link>();
  @Output() delForceEmit = new EventEmitter<Force>();
  // @Output() delJointLinkTagEmit = new EventEmitter();
  @Output() delCoMTagEmit = new EventEmitter();
  @Output() changeForceDirectionEmit = new EventEmitter<Force>();
  @Output() changeForceLocalEmit = new EventEmitter<Force>();
  // Emitter to update joint, link, force to the main array
  @Output() refreshJointAndLinkArrayEmit = new EventEmitter<undefined>(); // Tells mainpage to refresh joint and link array
  @Output() refreshThreePositionArrayEmit = new EventEmitter();
  @Output() refreshPathPointArrayEmit = new EventEmitter();
  @Output() refreshForceArrayEmit = new EventEmitter<undefined>(); // Tells mainpage to refresh force array
  // Emitters for animation
  @Output() sliderPosEmit = new EventEmitter<number>();
  @Output() newZoomEmit = new EventEmitter<number>();
  @Output() updateSimulatorEmit = new EventEmitter();

  // Context menu Emitters
  @Output() contextMenuFocus = new EventEmitter<{
    // newCoords: Coord, section: contextSelector, showSelectionOptions: boolean
    newCoords: Coord, section: contextSelector, object: any
  }>(); // Tells the context menu to focus on a new element
  @Output() hideMenu = new EventEmitter<boolean>(); // Tells the context menu to hide
  @Output() showShapeSelector = new EventEmitter<[boolean, string]>();
  // @Output() deleteJointLinkTagsEmit = new EventEmitter();

  // context menu items
  contextJoint: Joint; // The joint that triggered the context menu to open
  contextLink: Link;  // The link that triggered the context menu to open
  contextForce: Force;
  contextMenuCoords: Coord;
  contextThreePosition: ThreePosition;
  contextPathPoint: PathPoint;

  // canvas
  SVGCanvas: SVGElement; // Reference to the SVG canvas (coordinate grid)
  SVGCanvasTM: SVGElement; // The transpose matrix under the SVG canvas.
  transformMatrix: TransformMatrix;
  ZeroSVG: SVGElement;
  panOffset = {
    x: 0,
    y: 0
  };

  // holders
  linkageHolder: SVGElement;
  pathsHolder: SVGElement;
  pathsPathPointHolder: SVGElement;
  forcesHolder: SVGElement;
  jointLinkTagHolder: SVGElement;
  comTagHolder: SVGElement;
  pathPointHolder: SVGElement;
  threePositionHolder: SVGElement;
  tempHolder: SVGElement;

  // animation things
  animationRunning: boolean; // is animation playing?
  animationProgress: number;


  // shape editing stuffs
  initialMouseCoord: Coord;
  editingLink: Link;
  editingMode: shapeEditModes;
  editingDot: editorID;

  // not used atm, should be used to highlight items on the table
  selectedJoint: Joint;

  // temp items
  tempLink: SVGElement;
  tempJoint: Joint;
  tempForce: SVGElement;
  tempForceEndpoint: SVGElement;
  // tempJointTag: SVGElement;
  // tempLinkTag: SVGElement;

  // states
  state: states;
  createMode: createModes;
  moveMode: moveModes;

  // instance states
  isAnimating = false; // true if in animation mode
  isEditing = false; // in edit shape mode

  // linkage editing temp vals
  draggingJoint: Joint;
  draggingEndpoint: ForceEndpoint;
  draggingThreePosition: ThreePosition;
  draggingPathPoint: PathPoint;
  initialJointCoord: Coord;
  initialThreePositionCoord: Coord;
  initialPathPointCoord: Coord;
  initialEndpointCoord: Coord;
  initialState: {
    jointArray: Map<string, SimpleJoint>,
    linkArray: Map<string, SimpleLink>,
    forceArray: Map<string, SimpleForce>
  };

  // callbacks
  linkageChangeCallback: Function;
  simulationCallback: Function;

  // speed and direction
  speedChangedList: TimeSortedList;
  currentSpeed: string;
  directionSortedList: TimeSortedList;

  // jointTags: Array<string>;
  // linkTags: Array<string>;
  // jointTagPositions: Array<Array<string>>; // x, y
  // linkTagPositions: Array<Array<string>>;
  // private jointLinkTagPressed: boolean;
  // private comTagPressed: boolean;

  constructor() {
    this.state = states.init;
    this.animationRunning = false;

    this.speedChangedList = new TimeSortedList;
    this.currentSpeed = '2';
    // this.jointLinkTagPressed = false;
    // this.comTagPressed = false;

    this.directionSortedList = new TimeSortedList;
  }

  // Initial function ran on startup
  ngOnInit() {
    // Get the holder in the associated HTML file
    const canvasGET = document.getElementById('SVGCanvas') as unknown;
    this.SVGCanvas = canvasGET as SVGElement; // Make it referencable in the entire component.
    // Add event listeners to the SVG Holder
    this.addCanvasEventHandlers();
    this.state = states.waiting;
  }

  ngAfterViewInit() {
    console.log('ngAfterViewInit in grid called');
    // Get the transform matrix from the document
    const transformMatrixSVG = document.getElementById('transformMatrix') as unknown;
    const SVGtransformMatrixSVG = transformMatrixSVG as SVGElement;
    const transformMatrixGridSVG = document.getElementById('transformMatrixGrid') as unknown;
    const SVGtransformMatrixGridSVG = transformMatrixGridSVG as SVGElement;
    this.transformMatrix = new TransformMatrix(1, 0, 0, SVGtransformMatrixSVG, SVGtransformMatrixGridSVG, this.SVGCanvas);
    this.ZeroSVG = document.createElementNS('http://www.w3.org/2000/svg', 'g');
    this.ZeroSVG.setAttribute('id', 'zeroSVG');
    this.linkageHolder = document.createElementNS('http://www.w3.org/2000/svg', 'g');
    this.linkageHolder.setAttribute('id', 'linkageHolder');
    this.pathsHolder = document.createElementNS('http://www.w3.org/2000/svg', 'g');
    this.pathsHolder.setAttribute('id', 'pathsHolder');
    this.pathsHolder.style.pointerEvents = 'none';
    this.pathsPathPointHolder = document.createElementNS('http://www.w3.org/2000/svg', 'g');
    this.pathsPathPointHolder.setAttribute('id', 'pathsPathPointHolder');
    this.pathsPathPointHolder.style.pointerEvents = 'none';
    this.forcesHolder = document.createElementNS('http://www.w3.org/2000/svg', 'g');
    this.forcesHolder.setAttribute('id', 'forcesHolder');
    this.jointLinkTagHolder = document.createElementNS('http://www.w3.org/2000/svg', 'g');
    this.jointLinkTagHolder.setAttribute('id', 'jointLinkTagHolder');
    this.comTagHolder = document.createElementNS('http://www.w3.org/2000/svg', 'g');
    this.comTagHolder.setAttribute('id', 'comTagHolder');
    this.pathPointHolder = document.createElementNS('http://www.w3.org/2000/svg', 'g');
    this.pathPointHolder.setAttribute('id', 'pathPointHolder');
    this.threePositionHolder = document.createElementNS('http://www.w3.org/2000/svg', 'g');
    this.threePositionHolder.setAttribute('id', 'threePositionHolder');
    this.tempHolder = document.createElementNS('http://www.w3.org/2000/svg', 'g');
    this.tempHolder.setAttribute('id', 'tempHolder');

    this.SVGCanvasTM = this.transformMatrix.matrixSVG;
    this.SVGCanvasTM.appendChild(this.ZeroSVG);
    this.SVGCanvasTM.appendChild(this.linkageHolder);
    this.SVGCanvasTM.appendChild(this.pathsHolder);
    this.SVGCanvasTM.appendChild(this.pathsPathPointHolder);
    this.SVGCanvasTM.appendChild(this.forcesHolder);
    this.SVGCanvasTM.appendChild(this.jointLinkTagHolder);
    this.SVGCanvasTM.appendChild(this.comTagHolder);
    this.SVGCanvasTM.appendChild(this.pathPointHolder);
    this.SVGCanvasTM.appendChild(this.threePositionHolder);
    this.SVGCanvasTM.appendChild(this.tempHolder);

    this.transformMatrix.reset();

    this.refreshLinkage();
    this.refreshForces();
    // this.refreshJointLinkTags();
  }

  // Function to handle which menu item was clicked in the context menu
  handleContextMenuClick(menuInfo: { type: String, event: MouseEvent }) {
    const type = menuInfo.type;
    const event = menuInfo.event;
    switch (type) {
      case 'groundSet': this.setGroundedMenu(event);
        break;
      case 'inputSet': this.setInputMenu(event);
        break;
      case 'sliderSet': this.setSliderMenu(event);
        break;
      case 'createTracerJoint': this.createTracerJointContextMenu(event);
        break;
      case 'deleteJoint': this.setDeleteJoint(event);
        break;
      case 'positionPath': this.toggleJointPositionPath(event);
        break;
      case 'jointKinematic': this.showJointKinematics(event);
        break;
      case 'linkSet': this.setFirstJointFromExistingJointContextMenu(event);
        break;
      case 'createLink': this.setFirstJointContextMenu(event);
        break;
      case 'createLinkOnLink': this.setFirstJointContextMenu(event);
        break;
      case 'editLink': this.editLinkContextMenu(event);
        break;
      case 'deleteLink': this.deleteLinkContextMenu(event);
        break;
      case 'addForce': this.createForceContextMenu(event);
        break;
      case 'deleteForce': this.deleteForceContextMenu(event);
        break;
      case 'toggleGlobal': this.toggleForceGlobalContextMenu(event);
        break;
      case 'toggleForceDirection': this.toggleForceDirectionContextMenu(event);
        break;
      case 'createThreePosition': this.setThreePositionMenu(event);
        break;
      case 'createPathPoint': this.setPathPointMenu(event);
        break;
      case 'deleteThreePosition': this.deleteThreePositionMenu(event);
        break;
      case 'deletePathPoint': this.deletePathPointMenu(event);
        break;
      default:
        throw new Error(menuInfo.type + ' is not a valid context menu type');
    }
  }

  // Context menu event handlers
  // They may appear unused but they are called in the mainpage HTML
  handleNewCoords($event) {
    if (this.transformMatrix === undefined) {
      return;
    }
  }


  handleMenuClicked($event) {
    this.handleContextMenuClick($event);
  }

  deleteLinkContextMenu(e) {
    this.delLinkEmit.emit(this.contextLink);
    this.contextLink = undefined;
  }

  editLinkContextMenu(e) {
    e.stopPropagation();
    e.preventDefault();
    if (this.isEditing) { return; }
    this.isEditing = true;
    this.saveCurrentState();
    this.editingLink = this.contextLink;
    this.editingLink.startEdit();
    this.showShapeSelector.emit([true, 'none']);
    this.contextLink = undefined;
  }

  hideEditingLink() {
    if (this.editingLink !== undefined) {
      // this.isEditing = true;
      // this.revertEditShape();
      // this.endEditState();
      this.cancelEditShape();
      // this.isEditing = false;
    }
  }

  editLinkShape(e) {
    if (this.isEditing) { return; }
    // if (this.jointLinkTagPressed) {
    //   this.refreshTags();
    // }
    this.isEditing = true;
    this.saveCurrentState();
    this.editingLink = e;
    this.editingLink.startEdit();
    this.showShapeSelector.emit([true, 'none']);
    this.contextLink = undefined;
  }

  setFirstJointFromExistingJointContextMenu(e: MouseEvent) {
    if (this.state !== states.waiting) {
      this.contextJoint = undefined;
      return;
    }

    const rawCoord = StaticFuncs.getMousePosition(e);
    const trueCoord = this.transformMatrix.screenToGrid(rawCoord.x, rawCoord.y);
    this.setFirstJointFromExistingJoint(this.contextJoint, trueCoord.x, trueCoord.y);
    this.contextJoint = undefined;
  }

  setFirstJointContextMenu(e: MouseEvent) {
    if (this.state !== states.waiting) { return; }
    const coords = this.contextMenuCoords;
    const rawCoord = StaticFuncs.getMousePosition(e);
    const trueCoord = this.transformMatrix.screenToGrid(rawCoord.x, rawCoord.y);
    this.setFirstJoint(coords.x, coords.y, trueCoord.x, trueCoord.y);
  }

  setThreePositionMenu(e: MouseEvent) {
    // if () { return; }
    const coords = this.contextMenuCoords;
    const rawCoord = StaticFuncs.getMousePosition(e);
    const trueCoord = this.transformMatrix.screenToGrid(rawCoord.x, rawCoord.y);
    this.setThreePosition(coords.x, coords.y, trueCoord.x, trueCoord.y);
  }

  setPathPointMenu(e: MouseEvent) {
    const coords = this.contextMenuCoords;
    const rawCoord = StaticFuncs.getMousePosition(e);
    const trueCoord = this.transformMatrix.screenToGrid(rawCoord.x, rawCoord.y);
    this.setPathPoint(coords.x, coords.y, trueCoord.x, trueCoord.y);
  }

  createTracerJointContextMenu(e: MouseEvent) {
    if (this.state !== states.waiting) { return; }
    // this.state = states.creating;
    const coords = this.contextMenuCoords;
    const rawCoord = StaticFuncs.getMousePosition(e);
    const trueCoord = this.transformMatrix.screenToGrid(rawCoord.x, rawCoord.y);
    // this.setTracerJoint(coords.x, coords.y, trueCoord.x, trueCoord.y);
    // this.secondJointOnCanvas(trueCoord.x, trueCoord.y);
    const tracerJoint = this.createTracerJoint(coords.x, coords.y);
    this.newJointEmit.emit(tracerJoint);
    this.addJointToLink(tracerJoint, this.contextLink);
    this.contextLink = undefined;
  }

  toggleJointPositionPath(e: MouseEvent) {
    if (this.state !== states.waiting) { return; }
    // toggle joint position path
  }

  showJointKinematics(e: MouseEvent) {
    if (this.state !== states.waiting) { return; }
    // open graphs within toolbar
  }

  createForceContextMenu(e: MouseEvent) {
    if (!this.contextLink || this.state !== states.waiting) { return; }
    const coords = this.contextMenuCoords;

    const rawCoord = StaticFuncs.getMousePosition(e);
    const trueCoord = this.transformMatrix.screenToGrid(rawCoord.x, rawCoord.y);

    this.setForceStartEndpoint(coords.x, coords.y, trueCoord.x, trueCoord.y);
  }

  setGroundedMenu(ev) {
    const joint = this.contextJoint;
    this.toggleJointGrounded(joint);
    this.contextJoint = undefined;
  }
  setInputMenu(ev) {
    const joint = this.contextJoint;
    this.setJointAsInput(joint);
    this.contextJoint = undefined;
  }
  setSliderMenu(ev) {
    const joint = this.contextJoint;
    this.toggleJointSlider(joint);
    this.contextJoint = undefined;
  }

  setDeleteJoint(ev) {
    const joint = this.contextJoint;
    this.deleteJoint(joint);
    this.contextJoint = undefined;
  }

  deleteThreePositionMenu (ev) {
    const threePosition = this.contextThreePosition;
    this.deleteThreePosition(threePosition);
    this.contextThreePosition = undefined;
  }

  deletePathPointMenu(ev) {
    const pathPoint = this.contextPathPoint;
    this.deletePathPoint(pathPoint);
    this.contextPathPoint = undefined;
  }

  deleteForceContextMenu(e) {
    const force = this.contextForce;
    this.deleteForce(force);
    this.contextForce = undefined;
  }

  toggleForceGlobalContextMenu(e) {
    // not sure if any of these methods are actually needed...
    const force = this.contextForce;
    // this.contextForce.isGlobal = !this.contextForce.isGlobal;
    this.changeForceLocal(force);
    this.contextForce.updateGlobalSVG();
    this.updateSimulatorEmit.emit();
    this.contextForce = undefined;
  }

  toggleForceDirectionContextMenu(e) {
    // enter stuff here
    const force = this.contextForce;
    force.directionOutward = force.directionOutward !== true;
    this.changeForceDirection(force);
    this.contextForce.updateGlobalSVG();
    this.contextForce.updateArrowSVG();
    this.updateSimulatorEmit.emit();
    this.contextForce = undefined;
  }

  addJointToLink(joint: Joint, link: Link) {
    const shape = link.uiShape;
    const bounds = link.uiBounds;
    const color = link.linkSVG.getAttribute('fill');
    const joints = link.joints;
    const forces = link.forces;
    this.createLink([...joints, joint], { shape, bounds, color, forces});
  }

  // Add all necessary event listener to the SVG Canvas
  addCanvasEventHandlers() {
    const that = this; // Store the scope of the component. (For when the scope changes in event handlers)
    this.SVGCanvas.addEventListener('mousedown', function (e: MouseEvent) {
      e.preventDefault();
      e.stopPropagation();
      const rawCoords = StaticFuncs.getMousePosition(e);
      const trueCoords = that.transformMatrix.screenToGrid(rawCoords.x, rawCoords.y);
      switch (e.button) {
        case 0: // Handle Left-Click on canvas
          that.hideMenu.emit(true); // Hide the context menu
          const state = that.state;
          switch (state) {
            case states.panning:
              break;
            case states.waiting:
              const mPos = StaticFuncs.getMousePosition(e);
              that.panOffset.x = mPos.x;
              that.panOffset.y = mPos.y;
              that.state = states.panning;
              break;
            case states.creating:
              if (that.createMode === createModes.link) {
                that.secondJointOnCanvas(trueCoords.x, trueCoords.y);
              } else if (that.createMode === createModes.force) {
                that.setForceEndEndpoint(trueCoords.x, trueCoords.y);
                that.cancelCreation();
                that.state = states.waiting;
              }
              break;
            default:
          }
          break;
        case 1: // Handle Middle-Click on canvas
          return;
        case 2: // Handle Right-Click on canvas
          break;
        default:
          return;
      }
    });
    // Event handler when dragging elements
    this.SVGCanvas.addEventListener('mousemove', function (ev) {
      const e = ev;
      e.preventDefault();
      e.stopPropagation();
      // Check if we are creating a link
      const rawCoord = StaticFuncs.getMousePosition(e);
      const trueCoord = that.transformMatrix.screenToGrid(rawCoord.x, rawCoord.y);

      that.updateXYPos(trueCoord.x, trueCoord.y);

      switch (that.state) {
        case states.moving:
          switch (that.moveMode) {
            case moveModes.joint:
              that.dragJoint(e, that.draggingJoint);
              break;
            case moveModes.forceEndpoint:
              that.dragForceEndpoint(e, that.draggingEndpoint);
              break;
            case moveModes.pathPoint:
              that.dragPathPoint(e, that.draggingPathPoint);
              break;
            case moveModes.threePosition:
              that.dragThreePosition(e, that.draggingThreePosition);
              break;
          }
          break;
        case states.editing:
          if (that.editingMode === shapeEditModes.move) {
            const delta = {
              x: trueCoord.x - that.initialMouseCoord.x,
              y: trueCoord.y - that.initialMouseCoord.y
            };
            that.editingLink.drag(delta);
          } else if (that.editingMode === shapeEditModes.resize) {
            that.editingLink.tryNewBound(trueCoord, that.editingDot);
          }
          break;

        case states.panning:
          that.panCanvas(rawCoord.x, rawCoord.y);
          break;
        case states.creating:
          if (that.createMode === createModes.link) {
            const line = that.tempLink;
            if (!line) { break; }
            line.setAttribute('x2', `${trueCoord.x}`);
            line.setAttribute('y2', `${trueCoord.y}`);
          } else if (that.createMode === createModes.force) {
            const startX = parseFloat(that.tempForceEndpoint.getAttribute('x'));
            const startY = parseFloat(that.tempForceEndpoint.getAttribute('y'));
            SVGFuncs.updateArrow(that.tempForce, startX, startY, trueCoord.x, trueCoord.y);
          }
          break;
      }
    });
    this.SVGCanvas.addEventListener('mouseover', function (e) {});
    this.SVGCanvas.addEventListener('mouseup', function (e) {
      // Deselect the selected link.
      switch (that.state) {
        case states.moving:
          switch (that.moveMode) {
            case moveModes.joint:
              that.endMoveJoint(that.draggingJoint);
              break;
            case moveModes.forceEndpoint:
              if (that.draggingEndpoint.type === ForceEndpointType.start &&
                !that.draggingEndpoint.force.link.checkCoordInLink(that.draggingEndpoint)) {
                that.draggingEndpoint.relocate(that.initialEndpointCoord.x, that.initialEndpointCoord.y);
              }
              that.endDragForceEndpoint(that.draggingEndpoint);
              break;
            case moveModes.pathPoint:
              that.endMovePathPoint(that.draggingPathPoint);
              break;
            case moveModes.threePosition:
              that.endMoveThreePosition(that.draggingThreePosition);
              break;
          }
          // if (that.moveMode === moveModes.joint) {
          //   that.endMoveJoint(that.draggingJoint);
          // } else {
          //   if (that.draggingEndpoint.type === ForceEndpointType.start &&
          //     !that.draggingEndpoint.force.link.checkCoordInLink(that.draggingEndpoint)) {
          //     that.draggingEndpoint.relocate(that.initialEndpointCoord.x, that.initialEndpointCoord.y);
          //   }
          //   that.endDragForceEndpoint(that.draggingEndpoint);
          // }
          break;
        case states.panning:
          that.state = states.waiting;
          break;
        case states.creating:
          break;
        case states.editing:
          that.state = states.waiting;
          that.editingLink.cacheBounds();
          break;
      }
    });
    this.SVGCanvas.addEventListener('contextmenu', function (e) {
      e.preventDefault();
      e.stopPropagation();
      if (that.animationMode()) {
        IndiFuncs.showErrorNotification('stop the animation before making changes');
        return;
      }
      if (that.isEditing) { return; }

      if (that.state === states.creating) {
        that.cancelCreation();
        that.state = states.waiting;
      } else {
        that.recordMousePosition(e);
        const screenCoords = new Coord(e.clientX, e.clientY);
        switch (that.synthesis) {
          case 'none':
            that.showMenuEmit(screenCoords, contextSelector.canvasNoSyn);
            break;
          case 'three_pos':
            that.showMenuEmit(screenCoords, contextSelector.canvasThreePositionSyn);
            break;
          case 'path_point':
            that.showMenuEmit(screenCoords, contextSelector.canvasPathPointSyn);
            break;
          default:
            break;
        }
      }
    });
    this.SVGCanvas.addEventListener('wheel', function (e) {
      e.preventDefault();
      e.stopPropagation();
      that.hideMenu.emit(true); // Hide the context menu
      let wheelAmount = e.deltaY;
      if (wheelAmount > 0) {
        wheelAmount = 20 / 21;
      } else if (wheelAmount < 0) {
        wheelAmount = 21 / 20;
      } else {
        return;
      }
      const rawSVGCoords = StaticFuncs.getMousePosition(e);
      that.transformMatrix.zoomPoint(wheelAmount, rawSVGCoords.x, rawSVGCoords.y);
    });
  }

  // Handles zooming when the user presses one of the zooming buttons
  handleZoomEmit(zoomFactor: number) {
    console.log('zoom in out');
    switch (zoomFactor) {
      case 1:
        this.zoomInButton();
        break;
      case 0:
        this.zoomResetButton();
        break;
      case -1:
        this.zoomOutButton();
        break;
      default:
        break;
    }
  }

  handleshowIDEmit(showIDNum: number) {
    console.log('show Id tags');
  }

  // receiveJointLinkTagStatus($event) {
  //   this.jointLinkTagPressed = $event;
  // }
  //
  // receiveCoMTagStatus($event) {
  //   this.comTagPressed = $event;
  // }

  recordMousePosition(e: MouseEvent) {
    const rawCoords = StaticFuncs.getMousePosition(e);
    const trueCoords = this.transformMatrix.screenToGrid(rawCoords.x, rawCoords.y);
    this.contextMenuCoords = trueCoords;
  }

  getMouseCoordOnGrid(event: MouseEvent) {
    let mCoord = StaticFuncs.getMousePosition(event);
    mCoord = this.transformMatrix.screenToGrid(mCoord.x, mCoord.y);
    return mCoord;
  }

  zoomResetButton() {
    this.transformMatrix.reset();
  }

  zoomOutButton() {
    const halfWidth = this.SVGCanvas.clientWidth / 2;
    const halfHeight = this.SVGCanvas.clientHeight / 2;
    this.transformMatrix.zoomPoint(20 / 21, halfWidth, halfHeight);
  }

  zoomInButton() {
    const halfWidth = this.SVGCanvas.clientWidth / 2;
    const halfHeight = this.SVGCanvas.clientHeight / 2;
    const newCoords = this.transformMatrix.screenToGrid(halfWidth, halfHeight);
    this.transformMatrix.zoomPoint(21 / 20, halfWidth, halfHeight);
  }

  updateXYPos(x: number, y: number) {
    const xPos = document.getElementById('xPos');
    const yPos = document.getElementById('yPos');
    if (xPos && yPos) {
      xPos.innerText = IndiFuncs.roundNumber(x, 0).toString();
      yPos.innerText = IndiFuncs.roundNumber(y, 0).toString();
    }
  }

  addJointToLinkById(firstJointId: string) {
    const j1 = this.jointArray.find(joint => {
      return joint.id === firstJointId;
    });
    if (j1 !== undefined) {
      this.addJointToLink(j1, this.contextLink);
      // j1 && this.addJointToLink(j1, this.contextLink);
    }
  }

  secondJointOnCanvas(x: number, y: number) {
    const jids = this.setSecondJoint(x, y);
    // add tag from created joint

    //
    // if first joint is on a link, add it to link
    if (this.contextLink) {
      this.addJointToLinkById(jids.firstJointId);
      this.contextLink = undefined;
    }
    this.cancelCreation();
    this.state = states.waiting;
  }

  secondJointOnLink(link: Link, x: number, y: number) {
    if (link.uiShape === Shape.line) {
      // dont allow adding link on top of a bar
      IndiFuncs.showErrorNotification('Cannot link to a bar. Change it to a different shape first.');
      this.cancelCreation();
      this.state = states.waiting;
      return;
    } else if (this.contextLink === link || this.tempJoint.links.includes(link)) {
      // dont allow adding link to its link
      IndiFuncs.showErrorNotification('Don\'t link a joint to its link');
      this.cancelCreation();
      this.state = states.waiting;
      return;
    }


    const jids = this.setSecondJoint(x, y);

    // if first joint is on a link, add it to link
    if (this.contextLink) {
      this.addJointToLinkById(jids.firstJointId);
      this.contextLink = undefined;
    }

    // clear callback and change state to wait
    const resetLinkageChangeCallback = () => {
      this.linkageChangeCallback = undefined;
      this.state = states.waiting;
    };

    // add second joint to link after first joint is added
    this.linkageChangeCallback = () => {
      const nj = this.jointArray.find(joint => {
        return joint.id === jids.secondJointId;
      });
      if (nj !== undefined) {
        this.addJointToLink(nj, link);
        // nj && this.addJointToLink(nj, link);
      }
      resetLinkageChangeCallback();
    };
    this.cancelCreation();
    // hang everything before callbacks finish
    this.state = states.processing;
  }

  secondJointOnJoint(joint: Joint) {
    SVGFuncs.unhighlightCircle(joint.svg);
    const firstJoint = this.tempJoint;
    // Check to make sure not trying to link to the same joint
    if (firstJoint === joint) {
      IndiFuncs.showErrorNotification('Don\'t link a joint to itself');
      this.cancelCreation();
      this.state = states.waiting;
      return;
    } else if (this.sameIndexInTwoArrays(firstJoint.links, joint.links) > -1) {
      IndiFuncs.showErrorNotification('Don\'t link to a joint on the same link');
      this.cancelCreation();
      this.state = states.waiting;
      return;
    }

    const indexOfFirstJoint = this.jointArray.indexOf(firstJoint);
    if (indexOfFirstJoint >= 0) {
      // connect an existing joint to an existing joint
      this.createLink([joint, firstJoint]);
    } else {
      // connect a new joint to an existing joint

      const idArray = this.jointArray.map(j => j.id);
      const next1JointID = StaticFuncs.getNewJointID(idArray);

      const firstJointId = next1JointID;
      const firstJointParams = this.getTempJointData(firstJoint, firstJointId);
      // Link the first joint and the second joint
      this.createJointAndLinkToExistingJoint(firstJointParams.x, firstJointParams.y, firstJointParams.id, joint);

      // if first joint is on a link, add it to link
      if (this.contextLink) {
        this.addJointToLinkById(firstJointId);
        this.contextLink = undefined;
      }
    }
    this.cancelCreation();
    this.state = states.waiting;
  }


  sameIndexInTwoArrays(a1: any[], a2: any[]): number {
    for (let i = 0; i < a1.length; i++) {
      const element = a1[i];
      const index = a2.indexOf(element);
      if (index > -1) {
        return i;
      }
    }
    return -1;
  }

  panCanvas(x: number, y: number) {
    const offsetX = this.panOffset.x - x;
    const offsetY = this.panOffset.y - y;
    this.panOffset.x = x;
    this.panOffset.y = y;
    const box = this.SVGCanvas.getBoundingClientRect();
    const width = box.right - box.left;
    const height = box.bottom - box.top;
    let correctedPan = false;
    // Cause panning outside the defined area to pan the user back in.
    if (this.transformMatrix.screenToGrid(offsetX, 0).x < -100) {
      this.transformMatrix.panSVG(Math.abs(offsetX), 0);
      correctedPan = true;
    }
    if (this.transformMatrix.screenToGrid(width + offsetX, 0).x > 100) {
      this.transformMatrix.panSVG(-Math.abs(offsetX), 0);
      correctedPan = true;
    }
    if (this.transformMatrix.screenToGrid(0, offsetY).y < -100) {
      this.transformMatrix.panSVG(0, Math.abs(offsetY));
      correctedPan = true;
    }
    if (this.transformMatrix.screenToGrid(0, height + offsetY).y > 100) {
      this.transformMatrix.panSVG(0, -Math.abs(offsetY));
      correctedPan = true;
    }
    if (!correctedPan) {
      this.transformMatrix.panSVG(offsetX, offsetY);
    }
  }


  endMovePathPoint(pathPoint: PathPoint) {
    // pathPoint.relocate(this.initialPathPointCoord.x, this.initialPathPointCoord.y);

    this.endDragPathPoint(pathPoint);
    // this.endDragJoint(joint);

    // if (this.isEditing && this.editingLink.getJoints().includes(joint)) {
    //   SVGFuncs.highlightCircle(joint.getSVG());
    // } else {
    //   SVGFuncs.unhighlightCircle(joint.getSVG());
    // }
  }

  endMoveThreePosition(threePosition: ThreePosition) {
    this.endDragThreePosition(threePosition);
  }

  endMoveJoint(joint: Joint) {
    // check if joint is in its links
    let inLink = true;
    for (let i = 0; i < joint.links.length; i++) {
      const link = joint.links[i];
      if (link.uiShape !== Shape.line && !link.checkJointInLink(joint)) {
        inLink = false;
        break;
      }
      if (!link.checkForceEndpointInLink()) {
        inLink = false;
        break;
      }
    }
    // if not, reset it to its original position
    if (!inLink) {
      joint.relocate(this.initialJointCoord.x, this.initialJointCoord.y);
    }

    this.endDragJoint(joint);

    if (this.isEditing && this.editingLink.joints.includes(joint)) {
      SVGFuncs.highlightCircle(joint.svg);
    } else {
      SVGFuncs.unhighlightCircle(joint.svg);
    }
  }


  // Sets the joint as input and
  setJointAsInput(newInput: Joint) {
    // Insure the joint exists
    if (newInput === undefined) {
      return;
    }
    // If there was an old input joint, remove it's input status
    // if (this.inputJoint !== undefined) {
    //   if (this.inputJoint.type === 'R') {
    //     this.inputJoint.grounded ?
    //       this.inputJoint.setGrounded(true, 'R') :
    //       this.inputJoint.setGrounded(false);
    //   } else if (this.inputJoint.type === 'P') {
    //     this.inputJoint.setGrounded(true, 'P');
    //   }
    //   this.inputJoint = undefined;
    // }
    let flag = true;
    this.jointArray.forEach(j => {
      // if (j.type === 'R') {
      //   j.grounded === true ? j.setGrounded(true, 'R') : j.setGrounded(false);
      // } else if (j.type === 'P') {
      //   j.grounded === true ? j.setGrounded(true, 'P') : j.setGrounded(false);
      // }
      if (j.input && j !== newInput) {
        j.setInput(false, j);
      } else if (j.input && j === newInput) {
        j.setInput(false, j);
        flag = false;
      }
    });
    if (flag) {
      this.inputJoint = newInput;
      this.inputJoint.setInput(true, newInput);
      this.newInputBooleanEmit.emit(true);
      this.updateSimulatorEmit.emit();
    } else {
      this.inputJoint.setInput(false, newInput);
      // this.inputJoint = undefined;
      this.newInputBooleanEmit.emit(false);
      this.updateSimulatorEmit.emit();
      this.inputJoint = undefined;
    }
    // this.inputJoint = undefined;
    // this.inputJoint = newInput;
    // this.inputJoint.setInput(true, newInput);
    // this.newInputBooleanEmit.emit(true);
    // this.updateSimulatorEmit.emit();
  }

  setJointAsInputLinkageTable() {
    // Insure the joint exists
    if (this.inputJoint === undefined) {
      return;
    }
    // If there was an old input joint, remove it's input status
    this.jointArray.forEach(j => {
      // if (j.input === true) {
      //     if (j.type === 'R') {
      //       this.inputJoint.grounded ?
      //         this.inputJoint.setGrounded(true, 'R') :
      //         this.inputJoint.setGrounded(false);
      //     } else if (this.inputJoint.type === 'P') {
      //       this.inputJoint.setGrounded(true, 'P');
      //     }
      //     j.input = false;
      //   j.setInput(false, j);
      // }
      if (j.input && j !== this.inputJoint) {
        j.setInput(false, j);
      }
    });
    this.inputJoint.setInput(true, this.inputJoint);
    this.newInputBooleanEmit.emit(true);
    this.updateSimulatorEmit.emit();
  }

  // First function called when creating a link from an existing joint
  setFirstJointFromExistingJoint(joint: Joint, mouseX: number, mouseY: number) {
    // Create the temp line
    if (this.tempLink === undefined) {
      const newLine = SVGFuncs.createSVGTempLineOpaque(joint.x, joint.y, mouseX, mouseY);
      // prevent the temp link from interferring with mouse events
      newLine.style.pointerEvents = 'none';
      this.tempLink = newLine;
      this.tempHolder.appendChild(this.tempLink);
    }

    // Create the first joint
    this.tempJoint = joint;

    // Set the state
    this.state = states.creating;
    this.createMode = createModes.link;
  }

  // setTracerJoint(firstX: number, firstY: number, mouseX: number, mouseY: number) {
  //   // Create the first joint
  //   this.tempJoint = this.createTempJoint(firstX, firstY);
  //
  //   this.initialJointCoord = undefined;
  //   this.state = states.waiting;
  //   this.simulationCallback = undefined;
  //   this.clearPathSVGs();
  //   this.refreshJointAndLinkArrayEmit.emit();
  // }

  // First function called for click-click joint creation
  setFirstJoint(firstX: number, firstY: number, mouseX: number, mouseY: number) {
    // Create the temp line
    firstX = IndiFuncs.roundNumber(firstX, 3);
    firstY = IndiFuncs.roundNumber(firstY, 3);
    if (this.tempLink === undefined) {
      const newLine = SVGFuncs.createSVGTempLineOpaque(firstX, firstY, mouseX, mouseY);
      // prevent the temp link from interfering with mouse events
      newLine.style.pointerEvents = 'none';
      this.tempLink = newLine;
      this.tempHolder.appendChild(this.tempLink);
    }

    // Create the first joint
    this.tempJoint = this.createTempJoint(firstX, firstY);

    // Set the state
    this.state = states.creating;
    this.createMode = createModes.link;
  }

  setThreePosition(firstX: number, firstY: number, mouseX: number, mouseY: number) {
    firstX = IndiFuncs.roundNumber(firstX, 3);
    firstY = IndiFuncs.roundNumber(firstY, 3);

    const idArray = this.threePositionArray.map(threeposition => threeposition.id);
    const id = StaticFuncs.getNewJointID(idArray);
    const three_position = new ThreePosition(id, firstX, firstY);
    // switch (this.threePositionArray.length) {
    //   case 0:
    //     three_position.neighbor_one = undefined;
    //     three_position.neighbor_two = undefined;
    //     break;
    //   case 1:
    //     three_position.neighbor_one = this.threePositionArray[0];
    //     three_position.neighbor_two = undefined;
    //     this.threePositionArray[0].neighbor_two = three_position;
    //     break;
    //   default:
    //     three_position.neighbor_one = this.threePositionArray[this.threePositionArray.length - 1];
    //     three_position.neighbor_two = this.threePositionArray[0];
    //     this.threePositionArray[this.threePositionArray.length - 1].neighbor_two = three_position;
    //     this.threePositionArray[0].neighbor_one = three_position;
    //     break;
    // }
    this.addThreePositionEventHandlers(three_position);
    this.newThreePositionEmit.emit(three_position);
  }

  setPathPoint(firstX: number, firstY: number, mouseX: number, mouseY: number) {
    firstX = IndiFuncs.roundNumber(firstX, 3);
    firstY = IndiFuncs.roundNumber(firstY, 3);
    const idArray = this.pathPointArray.map(pathpoint => pathpoint.id);
    const id = StaticFuncs.getNewJointID(idArray);
    const path_point = new PathPoint(id, firstX, firstY);
    switch (this.pathPointArray.length) {
      case 0:
        path_point.neighbor_one = undefined;
        path_point.neighbor_two = undefined;
        break;
      case 1:
        path_point.neighbor_one = this.pathPointArray[0];
        path_point.neighbor_two = undefined;
        this.pathPointArray[0].neighbor_one = path_point;
        break;
      case 2:
        path_point.neighbor_one = this.pathPointArray[1];
        path_point.neighbor_two = this.pathPointArray[0];
        this.pathPointArray[0].neighbor_one = path_point;
        this.pathPointArray[0].neighbor_two = this.pathPointArray[1];
        this.pathPointArray[1].neighbor_two = path_point;
        break;
      default:
        path_point.neighbor_one = this.pathPointArray[this.pathPointArray.length - 1];
        path_point.neighbor_two = this.pathPointArray[0];
        this.pathPointArray[this.pathPointArray.length - 1].neighbor_two = path_point;
        this.pathPointArray[0].neighbor_one = path_point;
        break;
    }
    // this.addPathPointEventHandlers(path_point);
    this.newPathPointEmit.emit(path_point);
    // if (this.idTagPressed()) {
    //   const pathPointTag = this.createTempJointTag(firstX, firstY, id);
    //   this.createJointLinkTag(pathPointTag);
    //   this.jointLinkTagHolder.appendChild(pathPointTag.svg);
    // }
  }

  // Second function called after click-click joint creation
  setSecondJoint(cursorX: number, cursorY: number): { firstJointId: string, secondJointId: string } {
    // Confirm the first joint
    const firstJoint = this.tempJoint;
    let firstJointId: string, secondJointId: string;

    cursorX = IndiFuncs.roundNumber(cursorX, 3);
    cursorY = IndiFuncs.roundNumber(cursorY, 3);

    const idArray = this.jointArray.map(j => j.id);
    const next1JointID = StaticFuncs.getNewJointID(idArray);
    idArray.push(next1JointID);
    const next2JointID = StaticFuncs.getNewJointID(idArray);

    if (this.jointArray.includes(firstJoint)) {
      // if first joint already exist
      firstJointId = firstJoint.id;
      secondJointId = next1JointID;
      const joint2Params = { x: cursorX, y: cursorY, id: secondJointId };
      this.createJointAndLinkToExistingJoint(joint2Params.x, joint2Params.y, joint2Params.id, firstJoint);
    } else {
      // if first joint needs to be created
      firstJointId = next1JointID;
      const joint1Params = this.getTempJointData(firstJoint, firstJointId);
      secondJointId = next2JointID;
      const joint2Params = { x: cursorX, y: cursorY, id: secondJointId };
      this.createTwoJointLinkRemote(joint1Params.x, joint1Params.y, joint1Params.id, joint2Params.x, joint2Params.y, joint2Params.id);
    }
    return { firstJointId, secondJointId };
  }

  // Deletes the temporary joints and links
  cancelCreation() {
    this.tempHolder.innerHTML = '';
    this.tempLink = undefined;
    this.tempJoint = undefined;
    this.tempForce = undefined;
    // this.tempJointTag = undefined;
    // this.tempLinkTag = undefined;
    this.tempForceEndpoint = undefined;
    this.contextJoint = undefined;
    this.contextLink = undefined;
    this.createMode = undefined;
  }

  getTempJointData(tempJoint: Joint, id: string) {
    return { x: tempJoint.x, y: tempJoint.y, id: id };
  }

  createTracerJoint(jointX: number, jointY: number) {
    const idArray = this.jointArray.map(j => j.id);
    const next1JointID = StaticFuncs.getNewJointID(idArray);
    const firstJointId = next1JointID;
    const newJoint = new Joint(firstJointId, jointX, jointY, 'R');
    this.addJointEventHandlers(newJoint);
    SVGFuncs.setCircleAsJoint(newJoint.svg);
    return newJoint;
  }

  // Creates a temporary joint
  createTempJoint(jointX: number, jointY: number) {
    const newJoint = new Joint('temp_joint', jointX, jointY, 'R');
    this.addJointEventHandlers(newJoint);
    SVGFuncs.setJointTempCreated(newJoint.svg);
    this.tempHolder.appendChild(newJoint.svg);
    return newJoint;
  }

  // createTempJointTag(joint: Joint) {
  createTempJointTag(x: number, y: number, id: string) {
    // const x_offset = 0.7;
    // const y_offset = 0.3;
    const x_offset = 0.1;
    const y_offset = 0.1;
    const joint_tag = new Tag(id, (x - x_offset), ( y + y_offset), 'jointLink');
    SVGFuncs.setTagTempCreated(joint_tag.svg, id);
    return joint_tag;
  }

  createTempCoMTag(link: Link) {
    const link_com_tag = new Tag(link.id, link.centerOfMassX, link.centerOfMassY, 'CoM');
    SVGFuncs.setTagTempCreated(link_com_tag.svg, link.id);
    return link_com_tag;
  }

  createTempLinkTag(link: Link) {
    const link_x = link.determineCenterOfMassX();
    const link_y = link.determineCenterOfMassY();
    // const link_x = StaticFuncs.determineCenterOfMassX(link.uiBounds, link.uiShape);
    // const link_y = StaticFuncs.determineCenterOfMassY(link.uiBounds, link.uiShape);
    const link_tag = new Tag(link.id, link_x, link_y, 'jointLink');
    // link_tag.svg.style.textAlign = 'left';
    link_tag.svg.style.textAnchor = 'middle';
    SVGFuncs.setTagTempCreated(link_tag.svg, link.id);
    return link_tag;
  }

  createJointAndLinkToExistingJoint(joint1X: number, joint1Y: number, joint1ID: string, joint2: Joint) {
    this.newJointAndLinkToExistingJointEmit.emit({ joint1X, joint1Y, joint1ID, joint2 });
  }

  createTwoJointLinkRemote(joint1X: number, joint1Y: number, joint1ID: string, joint2X: number, joint2Y: number, joint2ID: string) {
    this.newTwoJointLinkRemoteEmit.emit({ joint1X, joint1Y, joint1ID, joint2X, joint2Y, joint2ID });
  }

  createJointLinkTag(tag: Tag) {
    // this.newJointLinkTagEmit.emit(tag);
  }

  createCoMTag(tag: Tag) {
    this.newCoMTagEmit.emit(tag);
  }

  deleteJoint(joint: Joint) {
    // Tell the mainpage to delete the joint
    this.delJointEmit.emit(joint);
    // Check the DOF
    this.updateSimulatorEmit.emit();
  }

  deleteThreePosition(threePosition: ThreePosition) {
    this.delThreePositionEmit.emit(threePosition);
  }

  deletePathPoint(pathPoint: PathPoint) {
    this.delPathPointEmit.emit(pathPoint);
  }

  deleteForce(force: Force) {
    // Tell mainpage to delete force
    this.delForceEmit.emit(force);
  }

  // deleteTag(tag: Tag) {
  //   this.delJointLinkTagEmit.emit(tag);
  // }

  changeForceDirection(force: Force) {
    this.changeForceDirectionEmit.emit(force);
  }

  changeForceLocal(force: Force) {
    this.changeForceLocalEmit.emit(force);
  }

  // Calls all necessary functions to sync a joint created in another component with the GridComponent
  addJointSVG(joint: Joint) {
    this.linkageHolder.appendChild(joint.svg);
    this.addJointEventHandlers(joint);
    return joint;
  }

  // Adds the event handlers to move, select, open context menu, etc. on a joint
  addJointEventHandlers(joint: Joint) {
    const circle = joint.svg;
    const that = this;
    if (circle.getAttribute('eventHandlersAdded') !== 'true') {
      circle.setAttribute('eventHandlersAdded', 'true');
      circle.addEventListener('mousedown', function (e) {
        e.preventDefault();
        const currentState = that.state;
        if (currentState !== states.creating || that.createMode !== createModes.force) {
          e.stopPropagation();
        }
        if (that.animationMode()) { return; }
        that.hideMenu.emit(true); // Hide the context menu
        switch (e.button) {
          case 0: // Left mouse down
            switch (currentState) {
              case states.creating:
                if (that.createMode === createModes.link) {
                  that.secondJointOnJoint(joint);
                }
                break;
              case states.waiting:
                that.startDragJoint(joint); // Start dragging the selected joint
                break;
              default:
            }
            break;
          case 1: // Middle mouse down
            break;
          case 2: // Right mouse down
            break;
        }
      });
      circle.addEventListener('mouseup', function (e) {
        e.preventDefault();
        // e.stopPropagation();
        const currentState = that.state;
        switch (currentState) {
          case states.creating:
            break;
          case states.waiting:
            if (!that.isEditing) {
              SVGFuncs.unhighlightCircle(joint.svg);
            }
            break;
          case states.moving:
            break;
          case states.editing:
            that.state = states.waiting;
            break;
          default:
        }
      });
      circle.addEventListener('mouseover', function (e) {
        e.stopPropagation();
        e.preventDefault();
        if (that.state === states.creating) {
          if (that.createMode === createModes.link) {
            SVGFuncs.highlightCircle(joint.svg);
          }
        }
      });
      circle.addEventListener('mouseout', function (e) {
        e.stopPropagation();
        e.preventDefault();
        const currentState = that.state;
        switch (currentState) {
          case states.creating:
            if (that.createMode === createModes.link) {
              SVGFuncs.unhighlightCircle(joint.svg);
            }
            break;
          default:
        }
      });
      circle.addEventListener('contextmenu', function (e) {
        e.stopPropagation();
        e.preventDefault();

        if (that.animationMode()) {
          IndiFuncs.showErrorNotification('stop the animation before making changes');
          return;
        }
        if (that.isEditing) { return; }

        if (that.state === states.creating) {
          that.cancelCreation();
          that.state = states.waiting;
        } else {
          that.recordMousePosition(e);
          const screenCoords = new Coord(e.clientX, e.clientY);
          that.showMenuEmit(screenCoords, contextSelector.joint, joint);
          that.contextJoint = joint;
        }
      });
    }
  }

  addThreePositionEventHandlers(threePosition: ThreePosition) {
    const circle = threePosition.svg;
    const that = this;
    if (circle.getAttribute('eventHandlersAdded') !== 'true') {
      circle.setAttribute('eventHandlersAdded', 'true');
      circle.addEventListener('mousedown', function (e) {
        e.preventDefault();
        const currentState = that.state;
        if (currentState !== states.creating || that.createMode !== createModes.force) {
          e.stopPropagation();
        }
        if (that.animationMode()) { return; }
        that.hideMenu.emit(true); // Hide the context menu
        switch (e.button) {
          case 0: // Left mouse down
            switch (currentState) {
              case states.creating:
                const hello = 'hi';
                // add option to delete three-point
                // if (that.createMode === createModes.link) {
                //   that.secondJointOnJoint(joint);
                // }
                break;
              case states.waiting:
                const hey = 'hi';
                that.startDragThreePosition(threePosition); // Start dragging the selected joint
                break;
              default:
            }
            break;
          case 1: // Middle mouse down
            break;
          case 2: // Right mouse down
            break;
        }
      });
      circle.addEventListener('mouseup', function (e) {
        e.preventDefault();
        // e.stopPropagation();
        const currentState = that.state;
        switch (currentState) {
          case states.creating:
            break;
          case states.waiting:
            if (!that.isEditing) {
              // SVGFuncs.unhighlightCircle(threePosition.svg);
            }
            break;
          case states.moving:
            break;
          case states.editing:
            that.state = states.waiting;
            break;
          default:
        }
      });
      circle.addEventListener('mouseover', function (e) {
        e.stopPropagation();
        e.preventDefault();
        // if (that.state === states.creating) {
        //   const hi = 'hi';
        // if (that.createMode === createModes.link) {
        //   SVGFuncs.highlightCircle(threePosition.getSVG());
        // }
        // }
      });
      circle.addEventListener('mouseout', function (e) {
        e.stopPropagation();
        e.preventDefault();
        const currentState = that.state;
        switch (currentState) {
          case states.creating:
            // if (that.createMode === createModes.link) {
            // SVGFuncs.unhighlightCircle(joint.getSVG());
            // }
            break;
          default:
        }
      });
      circle.addEventListener('contextmenu', function (e) {
        e.preventDefault();
        e.stopPropagation();

        if (that.animationMode()) {
          IndiFuncs.showErrorNotification('stop the animation before making changes');
          return;
        }
        if (that.isEditing) { return; }

        if (that.state === states.creating) {
          that.cancelCreation();
          that.state = states.waiting;
        } else {
          that.recordMousePosition(e);
          const screenCoords = new Coord(e.clientX, e.clientY);
          that.showMenuEmit(screenCoords, contextSelector.threePosition, threePosition);
          that.contextThreePosition = threePosition;
          // that.contextJoint = joint;
        }
      });
    }
  }

  showMenuEmit(coords: Coord, section: contextSelector, object?: any) {
    // showMenuEmit(coords: Coord, section: contextSelector, showOptional?: boolean) {
    //   const showOptionalMenuItems = showOptional === undefined ? false : showOptional;
    //   const curr_object = object === undefined ? false : object;
    this.contextMenuFocus.emit({
      newCoords: coords, section: section, object: object
      // showSelectionOptions: showOptionalMenuItems
    });
  }

  toggleJointGrounded(joint: Joint) {
    if (joint.grounded) {
      if (joint.type === 'P') {
        joint.setGrounded(true, 'R');
      } else {
        joint.setGrounded(false);
      }
    } else {
      joint.setGrounded(true, 'R');
    }
    this.newGroundEmit.emit();
    this.updateSimulatorEmit.emit();
  }

  toggleJointSlider(joint: Joint) {
    if (joint.grounded) {
      if (joint.type === 'R') {
        joint.setGrounded(true, 'P');
      } else {
        joint.setGrounded(false);
      }
    } else {
      joint.setGrounded(true, 'P');
    }
    this.newGroundEmit.emit();
    this.updateSimulatorEmit.emit();
  }

  // First function called when mouse dragging a joint
  // Gets the current location of the joint to calculate offset from the new location when finished
  startDragJoint(joint: Joint) {
    this.state = states.moving;
    this.moveMode = moveModes.joint;
    this.draggingJoint = joint;
    this.initialJointCoord = { x: joint.x, y: joint.y };
    SVGFuncs.setCircleMoveSelected(joint.svg);

    // every time a new tsl is received, update paths
    this.simulationCallback = () => {
      this.clearPathSVGs();
      this.drawPaths(this.posTimeSortedList);
    };
  }

  startDragPathPoint(pathPoint: PathPoint) {
    this.state = states.moving;
    this.moveMode = moveModes.pathPoint;
    this.draggingPathPoint = pathPoint;
    this.initialPathPointCoord = {x: pathPoint.x, y: pathPoint.y };

    this.refreshPathPointArray();
    this.pathsPathPointHolder.innerHTML = '';
    switch (this.synthesisDislay) {
      case 'none':
        break;
      case 'path_point':
        this.drawPathPointPaths(this.connection);
        break;
      case 'three_pos':
        break;
      case 'gear_syn':
        break;
    }
    // this.simulationCallback = () => {
    //   this.clearPathPointSVGs();
    //   this.drawPathPointPaths();
    // };
  }

  startDragThreePosition(threePosition: ThreePosition) {
    this.state = states.moving;
    this.moveMode = moveModes.threePosition;
    this.draggingThreePosition = threePosition;
    this.initialThreePositionCoord = {x: threePosition.x, y: threePosition.y };

    this.simulationCallback = () => {};
  }

  // Second and continuous function called when mouse dragging a joint
  // Relocates the joint object and it's corresponding SVG to the mouse cursor's location
  dragJoint(e: MouseEvent, joint: Joint) {
    let coord = StaticFuncs.getMousePosition(e);
    coord = this.transformMatrix.screenToGrid(coord.x, coord.y);
    coord.x = IndiFuncs.roundNumber(coord.x, 3);
    coord.y = IndiFuncs.roundNumber(coord.y, 3);

    joint.relocate(coord.x, coord.y);
    joint.links.forEach(l => {
      l.centerOfMassX = l.determineCenterOfMassX();
      l.centerOfMassY = l.determineCenterOfMassY();
      // l.centerOfMassX = StaticFuncs.determineCenterOfMassX(l.uiBounds, l.uiShape);
      // l.centerOfMassY = StaticFuncs.determineCenterOfMassY(l.uiBounds, l.uiShape);
    });
    this.refreshJointAndLinkArrayEmit.emit();
  }

  dragPathPoint(e: MouseEvent, pathPoint: PathPoint) {
    let coord = StaticFuncs.getMousePosition(e);
    coord = this.transformMatrix.screenToGrid(coord.x, coord.y);
    coord.x = IndiFuncs.roundNumber(coord.x, 3);
    coord.y = IndiFuncs.roundNumber(coord.y, 3);

    pathPoint.relocate(coord.x, coord.y);

    this.refreshPathPointArrayEmit.emit();
    // if (this.idTagPressed()) {
    //   this.refreshJointLinkTags();
    // }
  }

  dragThreePosition(e: MouseEvent, threePosition: ThreePosition) {
    let coord = StaticFuncs.getMousePosition(e);
    coord = this.transformMatrix.screenToGrid(coord.x, coord.y);
    coord.x = IndiFuncs.roundNumber(coord.x, 3);
    coord.y = IndiFuncs.roundNumber(coord.y, 3);

    threePosition.relocate(coord.x, coord.y);

    this.refreshThreePositionArrayEmit.emit();
  }

  // Final function called when mouse dragging a joint
  // Sets the currently selected elements back to undefined
  endDragJoint(joint: Joint) {
    // save relative coords for all its links and forces
    joint.links.forEach(link => {
      link.saveBounds();
      link.forces.forEach(force => force.saveRelativeCoords());
    });

    this.draggingJoint = undefined;
    this.initialJointCoord = undefined;
    this.state = states.waiting;
    this.simulationCallback = undefined;
    this.clearPathSVGs();
    this.refreshJointAndLinkArrayEmit.emit();
  }

  endDragThreePosition(threePosition: ThreePosition) {
    this.draggingThreePosition = undefined;
    this.initialThreePositionCoord = undefined;
    this.state = states.waiting;
    this.simulationCallback = undefined;
    this.refreshThreePositionArrayEmit.emit();
  }

  endDragPathPoint(pathPoint: PathPoint) {
    this.draggingPathPoint = undefined;
    this.initialPathPointCoord = undefined;
    this.state = states.waiting;
    this.simulationCallback = undefined;
    this.refreshPathPointArrayEmit.emit();
  }

  // Calls all necessary functions to create a link
  createLink(jointArray: Joint[], linkProps?: { shape?: Shape, bounds?: Bounds, color?: string, forces?: Force[] }) {
    this.newLinkEmit.emit({ jointArray, linkProps });
  }

  addLinkEventHandlers(link: Link) {
    const that = this;
    const shape = link.linkSVG;

    if (shape.getAttribute('eventHandlersAdded') !== 'true') {
      shape.setAttribute('eventHandlersAdded', 'true');
      shape.addEventListener('mousedown', e => {
        e.preventDefault();
        if (that.state !== states.creating || that.createMode !== createModes.force) {
          e.stopPropagation();
        }

        if (e.button === 0) {

          const rawCoords = StaticFuncs.getMousePosition(e);
          const trueCoords = that.transformMatrix.screenToGrid(rawCoords.x, rawCoords.y);

          // move only when shape is not a bar
          if (that.isEditing
            && link.editing
            && link.uiShape !== Shape.line) {
            that.state = states.editing;
            that.editingMode = shapeEditModes.move;
            that.initialMouseCoord = trueCoords;
          } else if (that.state === states.creating && that.createMode === createModes.link) {
            that.secondJointOnLink(link, trueCoords.x, trueCoords.y);
          }
        }
      });
      shape.addEventListener('contextmenu', e => {
        e.preventDefault();
        e.stopPropagation();

        if (that.animationMode()) {
          IndiFuncs.showErrorNotification('stop the animation before making changes');
          return;
        }
        if (that.isEditing) { return; }
        if (that.state === states.creating) {
          that.cancelCreation();
          that.state = states.waiting;
        } else {
          that.recordMousePosition(e);
          const screenCoords = new Coord(e.clientX, e.clientY);
          that.showMenuEmit(screenCoords, contextSelector.link, link);
          // that.showMenuEmit(screenCoords, contextSelector.link, link.getUiShape() === Shape.line ? false : true);
          that.contextLink = link;
        }
      });
    }
  }

  addPathsPathPointEventHandlers(pathsPathPointSVG: SVGElement) {
    const that = this;
    const umm = 'umm';

    if (pathsPathPointSVG.getAttribute('eventHandlersAdded') !== 'true') {
      pathsPathPointSVG.setAttribute('eventHandlersAdded', 'true');
      pathsPathPointSVG.addEventListener('mousedown', e => {
        e.preventDefault();
        // e.stopPropagation();
        const hello = ';p';
        const h = 'd';
      });
    }
  }

  addPathPointEventHandlers(pathPoint: PathPoint) {
    const that = this;
    const shape = pathPoint.svg;

    if (shape.getAttribute('eventHandlersAdded') !== 'true') {
      shape.setAttribute('eventHandlersAdded', 'true');
      shape.addEventListener('mousedown', e => {
        e.preventDefault();
        if (that.state !== states.creating || that.createMode !== createModes.force) {
          e.stopPropagation();
        }

        if (e.button === 0) {

          const rawCoords = StaticFuncs.getMousePosition(e);
          const trueCoords = that.transformMatrix.screenToGrid(rawCoords.x, rawCoords.y);

          // move only when shape is not a bar
          // if (that.isEditing
          //   && link.editing
          //   && link.uiShape !== Shape.line) {
          //   that.state = states.editing;
          //   that.editingMode = shapeEditModes.move;
          //   that.initialMouseCoord = trueCoords;
          // } else if (that.state === states.creating && that.createMode === createModes.link) {
          //   that.secondJointOnLink(link, trueCoords.x, trueCoords.y);
          // }
        }
      });
      // shape.addEventListener('contextmenu', e => {
      //   e.preventDefault();
      //   e.stopPropagation();
      //
      //   if (that.animationMode()) {
      //     IndiFuncs.showErrorNotification('stop the animation before making changes');
      //     return;
      //   }
      //   if (that.isEditing) { return; }
      //   if (that.state === states.creating) {
      //     that.cancelCreation();
      //     that.state = states.waiting;
      //   } else {
      //     that.recordMousePosition(e);
      //     const screenCoords = new Coord(e.clientX, e.clientY);
      //     that.showMenuEmit(screenCoords, contextSelector.link, link);
      //     // that.showMenuEmit(screenCoords, contextSelector.link, link.getUiShape() === Shape.line ? false : true);
      //     that.contextLink = link;
      //   }
      // });
    }
  }

  addEditorEventHandlers(link: Link) {
    const editors = link.editorSVGs;
    const that = this;

    const handleEditor = (editor: SVGElement, eid: editorID) => {
      if (editor.getAttribute('eventHandlersAdded') !== 'true') {
        editor.setAttribute('eventHandlersAdded', 'true');
        editor.addEventListener('mousedown', e => {
          const mCoord = that.getMouseCoordOnGrid(e);
          that.state = states.editing;
          that.editingMode = shapeEditModes.resize;
          that.editingDot = eid;
          that.editingLink = link;
          that.initialMouseCoord = mCoord;
        });
      }
    };

    handleEditor(editors.b1, editorID.b1);
    handleEditor(editors.b2, editorID.b2);
    handleEditor(editors.b3, editorID.b3);
    handleEditor(editors.b4, editorID.b4);
    handleEditor(editors.arrow, editorID.arrow);
  }

  selectShape(shape: Shape) {
    if (!this.isEditing) { return; }
    this.removeLinkSVG(this.editingLink);
    this.editingLink.changeShape(shape);
    let link_x = this.editingLink.determineCenterOfMassX();
    let link_y = this.editingLink.determineCenterOfMassY();
    // let link_x = StaticFuncs.determineCenterOfMassX(this.editingLink.uiBounds, this.editingLink.uiShape);
    // let link_y = StaticFuncs.determineCenterOfMassY(this.editingLink.uiBounds, this.editingLink.uiShape);
    if (Math.abs(link_x) < 0.01) {
      link_x = 0;
    }
    if (Math.abs(link_y) < 0.01) {
      link_y = 0;
    }
    this.editingLink.centerOfMassX = link_x;
    this.editingLink.centerOfMassY = link_y;

    this.refreshLinkage();
    this.updateSimulatorEmit.emit();
    // bet this is needed at the end
    this.refreshJointAndLinkArrayEmit.emit();
  }

  selectPath(shape: Shape) {
    // if (!this.isEditing) { return; }
    this.pathsPathPointHolder.innerHTML = '';
    let refCoord1, refCoord2: Coord;
    switch (shape) {
      case Shape.verticalLine:
        refCoord1 = new Coord (0, 2);
        refCoord2 = new Coord(0, -2);
        break;
      case Shape.horizontalLine:
        refCoord1 = new Coord (-2, 0);
        refCoord2 = new Coord(2, 0);
        break;
      case Shape.beanShape:
        refCoord1 = new Coord (-2, 2);
        refCoord2 = new Coord(2, 2);
        break;
      case Shape.slantedLineBackward:
        refCoord1 = new Coord (-1.709, 1.709);
        refCoord2 = new Coord(1.709, -1.709);
        break;
      case Shape.slantedLineForward:
        refCoord1 = new Coord (-1.709, -1.709);
        refCoord2 = new Coord(1.709, 1.709);
        break;
      case Shape.eightShape:
        refCoord1 = new Coord (-2, 2);
        refCoord2 = new Coord(2, 2);
        break;
      case Shape.infinityShape:
        refCoord1 = new Coord (-2, 2);
        refCoord2 = new Coord(2, 2);
        break;
      case Shape.customShape:
        refCoord1 = new Coord (-2, 2);
        refCoord2 = new Coord(2, 2);
        break;
    }
    // const refCoord1 = new Coord(-2, 2);
    // const refCoord2 = new Coord(2, 2);
    // create the shape of the path_point
    const shapeContainer = document.createElement('tr');
    const shapeSelection = document.createElement('td');
    shapeSelection.setAttribute('style', 'height: 120px; border-bottom: 1px solid black; text-align: center;');
    shapeSelection.className = 'shape-icon-wrapper';

    shapeSelection.innerText = shape;
    // shapeSelection.innerText = shape;
    // shapeSelection.addEventListener('mouseup', e => {
    //   if (e.button === 0) {
    //     switch (this.synthesisMode) {
    //       case 'none':
    //         that.selectShape.emit(shape);
    //         break;
    //       case 'path_point':
    //         that.selectPath.emit(shape);
    //         break;
    //     }
    //   }
    // });
    const icon = document.createElement('img');
    icon.setAttribute('src', `../../assets/shapes/${shape}.svg`);
    shapeSelection.appendChild(icon);
    shapeContainer.appendChild(shapeSelection);
    // this.pathsPathPointHolder.appendChild(shapeContainer);
    const bounds = SVGFuncs.getBounds(refCoord1, refCoord2, shape);
    const pathSVG = SVGFuncs.createShape('pathPoint', bounds, shape, 'black');
    this.pathsPathPointHolder.appendChild(pathSVG);
    // create the bounds of the path_point
    const pathPointBoundsSVG = SVGFuncs.createBounds('pathPoint' + '_bounds', bounds);
    pathPointBoundsSVG.style.display = 'block';
    this.pathsPathPointHolder.appendChild(pathPointBoundsSVG);
    // const pathPoint = new PathPoint('ummm', refCoord1.x, refCoord1.y);
    // this.pathsPathPointHolder.appendChild(shape);
    // create the editorSVGs of the path_point
    this.addPathsPathPointEventHandlers(pathPointBoundsSVG);
    // this.addPathPointEventHandlers(pathPoint);
    // this.addThreePositionEventHandlers(pathSVG);
    // this.addEditorEventHandlers(this.linkArray[0]);


    const editor1 = SVGFuncs.createEditor('pathPoint' + '_editor1', bounds.b1.x, bounds.b1.y);
    const editor2 = SVGFuncs.createEditor('pathPoint' + '_editor2', bounds.b2.x, bounds.b2.y);
    const editor3 = SVGFuncs.createEditor('pathPoint' + '_editor3', bounds.b3.x, bounds.b3.y);
    const editor4 = SVGFuncs.createEditor('pathPoint' + '_editor4', bounds.b4.x, bounds.b4.y);
    const mid_x = (bounds.b1.x + bounds.b2.x + bounds.b3.x + bounds.b4.x) / 4;
    const mid_y = (bounds.b1.y + bounds.b2.y + bounds.b3.y + bounds.b4.y) / 4;
    // const arrow = SVGFuncs.createEditorArrow(this.id + 'arrow')
    const arrow = SVGFuncs.createEditorArrow('pathPoint' + '_arrow', mid_x, mid_y);

    editor1.style.display = 'block';
    editor2.style.display = 'block';
    editor3.style.display = 'block';
    editor4.style.display = 'block';
    arrow.style.display = 'block';
    // append the editors of path_point
    const editorSVGs = { b1: editor1, b2: editor2, b3: editor3, b4: editor4, arrow: arrow };
    Object.keys(editorSVGs).forEach(eid => {
      if (eid === 'arrow') {
        return;
      }
      this.pathsPathPointHolder.appendChild(editorSVGs[eid]);
    });
    // this.addLinkEditorEventHandlers();
    // this.addEditorEventHandlers();
  }

  saveEditShape() {
    if (!this.isEditing) { return; }
    const result = this.editingLink.checkValidLink();
    if (result.valid) {
      this.editingLink.saveBounds();
      this.endEditState();
    } else {
      IndiFuncs.showErrorNotification(result.reason);
    }
  }

  endEditState() {
    this.editingLink.endEdit();
    this.editingLink = undefined;
    this.isEditing = false;
    this.showShapeSelector.emit([false, 'none']);
  }

  cancelEditShape() {
    if (!this.isEditing) { return; }
    this.revertEditShape();
    this.endEditState();
    this.refreshJointAndLinkArrayEmit.emit();
  }

  revertEditShape() {
    if (!this.isEditing) { return; }
    this.jointArray.forEach(j => {
      const ogJoint = this.initialState.jointArray.get(j.id);
      j.relocate(ogJoint.x, ogJoint.y);
    });
    this.forceArray.forEach(f => {
      const ogForce = this.initialState.forceArray.get(f.id);
      f.start.relocate(ogForce.start.x, ogForce.start.y);
      f.end.relocate(ogForce.end.x, ogForce.end.y);
    });
    const ogLink = this.initialState.linkArray.get(this.editingLink.id);
    this.selectShape(ogLink.shape);
    this.editingLink.tryNewBounds(ogLink.bounds);
    this.editingLink.saveBounds();
    // bet this is needed at bottom
    this.refreshJointAndLinkArrayEmit.emit();
  }

  saveCurrentState() {
    const ogJoints = new Map();
    const ogLinks = new Map();
    const ogForces = new Map();
    this.jointArray.forEach(j => {
      ogJoints.set(j.id, {id: j.id, x: j.x, y: j.y});
    });
    this.linkArray.forEach(l => {
      ogLinks.set(l.id, {id: l.id, shape: l.uiShape, bounds: l.uiBounds});
    });
    this.forceArray.forEach(f => {
      ogForces.set(f.id, {id: f.id, start: f.startCoord, end: f.endCoord});
    });
    this.initialState = {
      jointArray: ogJoints,
      linkArray: ogLinks,
      forceArray: ogForces
    };
    // bet this is needed at the bottom
    this.refreshJointAndLinkArrayEmit.emit();
  }

  // Calls all necessary functions to synchronize a link with the gridComponent.
  addLinkSVG(link: Link) {
    this.linkageHolder.appendChild(link.linkSVG);
    this.addLinkEventHandlers(link);

    const boundSVG = link.boundsSVG;
    if (boundSVG !== undefined) {
      this.linkageHolder.appendChild(boundSVG);
      // boundSVG && this.linkageHolder.appendChild(boundSVG);
    }

    const editorSVGs = link.editorSVGs;
    if (editorSVGs !== undefined) {
      Object.keys(editorSVGs).forEach(eid => {
        // editorSVGs && Object.keys(editorSVGs).forEach(eid => {
        this.linkageHolder.appendChild(editorSVGs[eid]);
      });
      this.addEditorEventHandlers(link);
      // editorSVGs && this.addEditorEventHandlers(link);
    }

    return link;
  }

  removeLinkSVG(link: Link) {
    this.linkageHolder.removeChild(link.linkSVG);

    const boundSVG = link.boundsSVG;
    if (boundSVG !== undefined) {
      this.linkageHolder.removeChild(boundSVG);
      // boundSVG && this.linkageHolder.removeChild(boundSVG);
    }

    const editorSVGs = link.editorSVGs;
    if (editorSVGs !== undefined) {
      Object.keys(editorSVGs).forEach(eid => {
        this.linkageHolder.removeChild(editorSVGs[eid]);
      });
      // editorSVGs && Object.keys(editorSVGs).forEach(eid => {
      //   this.linkageHolder.removeChild(editorSVGs[eid]);
      // });
    }


    return link;
  }

  // First function called for click-click joint creation
  setForceStartEndpoint(sx: number, sy: number, mouseX: number, mouseY: number) {
    // Create the temp line
    const newLine = SVGFuncs.createArrow('force_temp', sx, sy, mouseX, mouseY);
    newLine.setAttribute('opacity', '0.5');
    // prevent the temp line from interferring with mouse events
    newLine.style.pointerEvents = 'none';
    this.tempForce = newLine;
    this.tempHolder.appendChild(this.tempForce);

    this.tempForceEndpoint = SVGFuncs.createForceEndpoint('temp_start', sx, sy);

    // Set the state
    this.state = states.creating;
    this.createMode = createModes.force;
  }

  setForceEndEndpoint(mouseX: number, mouseY: number) {
    let startX = parseFloat(this.tempForceEndpoint.getAttribute('x'));
    let startY = parseFloat(this.tempForceEndpoint.getAttribute('y'));

    startX = IndiFuncs.roundNumber(startX, 3);
    startY = IndiFuncs.roundNumber(startY, 3);

    mouseX = IndiFuncs.roundNumber(mouseX, 3);
    mouseY = IndiFuncs.roundNumber(mouseY, 3);

    const startCoord = new Coord(startX, startY);
    const endCoord = new Coord(mouseX, mouseY);
    const nextForceID = StaticFuncs.getNewForceID(this.forceArray.map(f => f.id));
    const newForce = new Force(nextForceID, this.contextLink, startCoord, endCoord);

    this.newForceEmit.emit(newForce);
  }

  addForceEndpointEventHandlers(forceEndpoint: ForceEndpoint) {
    const that = this;
    const endpoint = forceEndpoint.svg;

    if (endpoint.getAttribute('eventHandlersAdded') !== 'true') {
      endpoint.setAttribute('eventHandlersAdded', 'true');
      endpoint.addEventListener('mousedown', e => {
        e.preventDefault();
        if (that.state === states.waiting) {
          e.stopPropagation();
        } else {
          return;
        }
        switch (e.button) {
          case 0: {
            if (that.state === states.waiting) {
              that.startDragForceEndpoint(forceEndpoint);
            }
            break;
          }
        }
      });
      endpoint.addEventListener('contextmenu', e => {
        e.preventDefault();
        if (that.state === states.waiting) {
          e.stopPropagation();
        } else {
          return;
        }
      });
    }
  }

  addForceEventHandlers(force: Force) {
    const that = this;
    const forceSVG = force.svg;

    if (forceSVG.getAttribute('eventHandlersAdded') !== 'true') {
      forceSVG.setAttribute('eventHandlersAdded', 'true');
      forceSVG.addEventListener('contextmenu', e => {
        e.preventDefault();
        e.stopPropagation();

        if (that.animationMode()) {
          IndiFuncs.showErrorNotification('stop the animation before making changes');
          return;
        }
        if (that.isEditing) { return; }
        if (that.state === states.creating) {
          that.cancelCreation();
          that.state = states.waiting;
        } else {
          that.recordMousePosition(e);
          const screenCoords = new Coord(e.clientX, e.clientY);
          that.showMenuEmit(screenCoords, contextSelector.force, force);
          that.contextForce = force;
        }
      });
    }
  }

  startDragForceEndpoint(forceEndpoint: ForceEndpoint) {
    this.state = states.moving;
    this.moveMode = moveModes.forceEndpoint;
    this.draggingEndpoint = forceEndpoint;
    this.initialEndpointCoord = { x: forceEndpoint.x, y: forceEndpoint.y };
  }

  // Second and continuous function called when mouse dragging a joint
  // Relocates the joint object and it's corresponding SVG to the mouse cursor's location
  dragForceEndpoint(e: MouseEvent, forceEndpoint: ForceEndpoint) {
    let forceCoord = StaticFuncs.getMousePosition(e);
    forceCoord = this.transformMatrix.screenToGrid(forceCoord.x, forceCoord.y);
    const isEnd = forceEndpoint.id.includes('end');
    // if (isEnd === true || (forceEndpoint.force.link.uiShape !== 'line' && forceEndpoint.force.link.uiShape !== 'bar')) {
    if (isEnd === true || forceEndpoint.force.link.uiShape !== 'line') {
      forceCoord.x = IndiFuncs.roundNumber(forceCoord.x, 3);
      forceCoord.y = IndiFuncs.roundNumber(forceCoord.y, 3);
    } else {
      const link = forceEndpoint.force.link;
      const jointOne = link.joints[0];
      const jointTwo = link.joints[1];
      const smallestX = jointOne.x < jointTwo.x ? jointOne.x : jointTwo.x;
      const biggestX = jointOne.x > jointTwo.x ? jointOne.x : jointTwo.x;
      if (smallestX > forceCoord.x) {
        forceCoord.x = IndiFuncs.roundNumber(smallestX, 3);
      } else if (biggestX < forceCoord.x) {
        forceCoord.x = IndiFuncs.roundNumber(biggestX, 3);
      } else {
        forceCoord.x = IndiFuncs.roundNumber(forceCoord.x, 3);
      }
      const slope = (jointTwo.y - jointOne.y ) / (jointTwo.x - jointOne.x);
      const b = jointOne.y;
      forceCoord.y = IndiFuncs.roundNumber(jointOne.y + (forceCoord.x - jointOne.x) * slope, 3);
    }
    forceEndpoint.relocate(forceCoord.x, forceCoord.y);
    const desiredForce = forceEndpoint.force;
    desiredForce.xMag = desiredForce.end.x > desiredForce.start.x ?
      Math.abs(desiredForce.xMag) : -1 * Math.abs(desiredForce.xMag);
    desiredForce.yMag = desiredForce.end.y > desiredForce.start.y ?
      Math.abs(desiredForce.yMag) : -1 * Math.abs(desiredForce.yMag);
    this.refreshForceArrayEmit.emit();
  }

  // Final function called when mouse dragging a joint
  // Sets the currently selected elements back to undefined
  endDragForceEndpoint(fep: ForceEndpoint) {
    fep.force.saveRelativeCoords();
    this.draggingEndpoint = undefined;
    this.initialEndpointCoord = undefined;
    this.state = states.waiting;
    this.refreshForceArrayEmit.emit();
  }

  // Search the joints array for the provided ID, Returns -1 if not found
  jointIndexByID(inputID: string) {
    return this.jointArray.findIndex((joint) => {
      return joint.id === inputID;
    });
  }

  // Clears all joint, link, and force SVG's from the canvas.
  clearLinkageSVGs() {
    // if (this.tempHolder !== undefined) {
    this.tempHolder.innerHTML = '';
    this.linkageHolder.innerHTML = '';
    this.jointLinkTagHolder.innerHTML = '';
    // }
  }

  clearForceSVGs() {
    this.forcesHolder.innerHTML = '';
  }

  clearJointLinkTagSVGs() {
    this.jointLinkTagHolder.innerHTML = '';
  }

  clearCoMTagSVGs() {
    this.comTagHolder.innerHTML = '';
  }

  clearPathPointSVGs() {
    this.pathPointHolder.innerHTML = '';
  }

  clearThreePositionSVGs() {
    this.threePositionHolder.innerHTML = '';
  }

  addJointLinkTag(tag: SVGElement) {
    this.jointLinkTagHolder.appendChild(tag);
  }

  addJointLinkTagSVG(tag: Tag) {
    this.jointLinkTagHolder.appendChild(tag.svg);
  }

  addCoMTagSVG(tag: Tag) {
    this.comTagHolder.appendChild(tag.svg);
  }

  addPathPointSVG(pathPoint: PathPoint) {
    this.pathPointHolder.appendChild(pathPoint.svg);
  }

  addThreePositionSVG(threePosition: ThreePosition) {
    this.threePositionHolder.appendChild(threePosition.svg);
  }

  addForceSVG(force: Force) {
    this.addForceEventHandlers(force);
    this.addForceEndpointEventHandlers(force.start);
    this.addForceEndpointEventHandlers(force.end);
    this.forcesHolder.appendChild(force.svg);
    this.forcesHolder.appendChild(force.start.svg);
    this.forcesHolder.appendChild(force.end.svg);
  }

  idTagPressed() {
    const dd = <HTMLButtonElement> document.getElementById('showIDOn');
    return dd !== null;
  }

  comTagPressed() {
    const ss = <HTMLButtonElement> document.getElementById('showCoMOn');
    return ss !== null;
  }
  // delete and re-add all linkage svgs and ask for simulation
  refreshLinkage() {
    this.clearLinkageSVGs();
    // resync all links
    this.linkArray.forEach(link => {
      this.addLinkSVG(link);
    });
    // resync all joints
    this.jointArray.forEach(joint => {
      this.addJointSVG(joint);
    });
    this.pathPointArray.forEach(pathPoint => {
      this.addPathPointSVG(pathPoint);
      this.addPathPointEventHandlers(pathPoint);
    });
    this.pathPointArray.forEach(pathPoint => {
      pathPoint.svg.setAttribute('visibility', 'hidden');
    });
    this.threePositionArray.forEach(threePosition => {
      this.addThreePositionSVG(threePosition);
      this.addThreePositionEventHandlers(threePosition);
    });
    this.threePositionArray.forEach(threePosition => {
      threePosition.svg.setAttribute('visibility', 'hidden');
    });
    if (this.idTagPressed()) {
      this.refreshJointLinkTags();
    }
    // Get a new DOF
    // this.state == states.moving;
    // this.newDOFEmit.emit();
  }

  refreshPathsPathPoint() {

  }

  // delete and re-add all force svgs
  refreshForces() {
    this.clearForceSVGs();
    // resync all forces
    this.forceArray.forEach(force => {
      this.addForceSVG(force);
    });
    // this.state = states.moving;
    this.updateSimulatorEmit.emit();
  }

  refreshJointLinkTags() {
    this.clearJointLinkTagSVGs();
    // resync all tags
    switch (this.synthesis) {
      case 'none':
        // this.jointLinkTagArray.forEach(tag => {
        //   if (tag.id.length > 1) { // link
        //     const link = this.linkArray.find(l => l.id === tag.id);
        //     const link_x = link.determineCenterOfMassX();
        //     const link_y = link.determineCenterOfMassY();
        //     // const link_x = StaticFuncs.determineCenterOfMassX(link.uiBounds, link.uiShape);
        //     // const link_y = StaticFuncs.determineCenterOfMassY(link.uiBounds, link.uiShape);
        //     tag = new Tag(tag.id, link_x, link_y, 'jointLink');
        //   } else { // joint
        //     // should replace this with a map
        //     const joint = this.jointArray.find(jt => jt.id === tag.id);
        //     // const x_offset = 0.7;
        //     // const y_offset = 0.3;
        //     const x_offset = 0.1;
        //     const y_offset = 0.1;
        //     tag = new Tag(tag.id, joint.x - x_offset, joint.y + y_offset, 'jointLink');
        //   }
        //   this.addJointLinkTagSVG(tag);
        // });
        this.jointArray.forEach(j => {
          this.addJointLinkTag(j.idTag);
        });
        this.linkArray.forEach(l => {
          this.addJointLinkTag(l.idTag);
        });
        break;
      case 'path_point':
        this.pathPointArray.forEach(pp => {
          // should replace this with a map
          // const pathPoint = this.pathPointArray.find(pp => pp.id === tag.id);
          // const x_offset = 0.7;
          // const y_offset = 0.3;
          const x_offset = 0.1;
          const y_offset = 0.1;
          // tag = new Tag(tag.id, pathPoint.x - x_offset, pathPoint.y + y_offset, 'jointLink');
          const tag = new Tag(pp.id, pp.x - x_offset, pp.y + y_offset, 'jointLink');
          this.addJointLinkTagSVG(tag);
        });
        break;
      case 'three_pos':
        // this.jointLinkTagArray.forEach(tag => {
        //   // should replace this with a map
        //   const threePos = this.threePositionArray.find(tp => tp.id === tag.id);
        //   // const x_offset = 0.7;
        //   // const y_offset = 0.3;
        //   const x_offset = 0.1;
        //   const y_offset = 0.1;
        //   tag = new Tag(tag.id, threePos.x - x_offset, threePos.y + y_offset, 'jointLink');
        //   this.addJointLinkTagSVG(tag);
        // });
        break;
      case 'gear_syn':
        break;
    }
  }

  refreshCoMTags() {
    this.clearCoMTagSVGs();
    // resync all tags
    this.comTagArray.forEach(tag => {
      const link = this.linkArray.find(l => l.id === tag.id);
      let link_x = 0;
      let link_y = 0;
      link_x += link.centerOfMassX;
      link_y += link.centerOfMassY;
      tag = new Tag(tag.id, link_x, link_y, 'CoM');
      this.addCoMTagSVG(tag);
    });
  }

  refreshPathPointArray() {
    this.clearPathPointSVGs();
    this.pathPointArray.forEach(pathPoint => {
      this.addPathPointSVG(pathPoint);
    });
    // i believe...?
    this.jointLinkTagHolder.innerHTML = '';
  }

  refreshThreePositionArray() {
    this.clearThreePositionSVGs();
    this.threePositionArray.forEach(threePosition => {
      this.addThreePositionSVG(threePosition);
    });
  }

  renameJointLinkLabels($event) {
    // check to see if labelID is pressed. If so, change name of joint and link
    const oldJointID = $event.oldJointID;
    const newJointID = $event.newJointID;
    if (document.getElementById('showIDOn') !== null) {
      this.jointArray.forEach(j => {
        if (j.idTag.textContent.indexOf(oldJointID) > -1) {
          const indexChange = j.idTag.textContent.indexOf(oldJointID);
          j.idTag.textContent = j.idTag.textContent.substring(0, indexChange) +
            newJointID + j.idTag.textContent.substring(indexChange + newJointID.length);
        }
      });
      this.linkArray.forEach(l => {
        if (l.idTag.textContent.indexOf(oldJointID) > -1) {
          const indexChange = l.idTag.textContent.indexOf(oldJointID);
          l.idTag.textContent = l.idTag.textContent.substring(0, indexChange) +
            newJointID + l.idTag.textContent.substring(indexChange + newJointID.length);
        }
      });
      // this.jointLinkTagArray.forEach(tag => {
      //   if (tag.id.indexOf(oldJointID) > -1) {
      //     const indexChange = tag.id.indexOf(oldJointID);
      //     tag.id = tag.id.substring(0, indexChange) + newJointID + tag.id.substring(indexChange + newJointID.length);
      //   }
      // });
      this.refreshJointLinkTags();
      // }
      // if (this.comTagPressed()) {
      //   this.refreshCoMTags();
      // }
    }
  }

  relocateAllLinkJoints() {
    this.jointArray.forEach(j => {
      j.relocate(j.x, j.y);
      j.setGrounded(j.grounded, j.type);
      console.log(j.id + ' ground is ' + j.grounded + ' and type is ' + j.type);
      if (j.input) {
        this.inputJoint = j;
      }
    });
    this.linkArray.forEach(l => {
      l.centerOfMassX = l.determineCenterOfMassX();
      l.centerOfMassY = l.determineCenterOfMassY();
      // l.centerOfMassX = StaticFuncs.determineCenterOfMassX(l.uiBounds, l.uiShape);
      // l.centerOfMassY = StaticFuncs.determineCenterOfMassY(l.uiBounds, l.uiShape);
    });
    this.setJointAsInputLinkageTable();
  }

  relocateAllPathPoints() {
    this.pathPointArray.forEach(pathPoint => {
      pathPoint.relocate(pathPoint.x, pathPoint.y);
    });
    this.refreshPathPoints(this.connection);
    if (this.idTagPressed()) {
      this.refreshJointLinkTags();
    }
  }

  relocateAllThreePositions() {
    this.threePositionArray.forEach(threePosition => {
      threePosition.relocate(threePosition.x, threePosition.y);
    });
  }

  relocateAllForceJoints() {
    this.forceArray.forEach(force => {
      force.relocate(force.start.x, force.start.y, force.end.x, force.end.y);
    });
  }

  // Called when an @Input variable changes
  ngOnChanges(changes: SimpleChanges) {
    if (changes.jointArray || changes.linkArray) {
      if (this.SVGCanvasTM) { // Wait until svgCanvas is ready
        this.refreshLinkage();
        this.updateSimulatorEmit.emit();
        const nextCallback = this.linkageChangeCallback;
        // nextCallback && nextCallback();
        if (nextCallback !== undefined) {
          nextCallback();
        }
        if (this.idTagPressed()) {
          this.refreshJointLinkTags();
        }
        if (this.comTagPressed()) {
          this.refreshCoMTags();
        }
      }
    }
    if (changes.forceArray) {
      // this.refreshForces();
      if (this.SVGCanvasTM) { // Wait until svgCanvas is ready
        this.refreshForces();
      }
    }
    // if (changes.jointLinkTagArray) {
    //   if (this.SVGCanvasTM) {
    //     if (this.idTagPressed()) {
    //       this.refreshJointLinkTags();
    //     }
    //   }
    // }
    if (changes.comTagArray) {
      if (this.SVGCanvasTM) {
        this.refreshCoMTags();
      }
    }
    if (changes.pathPointArray) {
      if (this.SVGCanvasTM) {
        this.refreshPathPointArray();
        this.pathsPathPointHolder.innerHTML = '';
        if (this.synthesisDislay === 'path_point') {
          this.drawPathPointPaths(this.connection);
        }
        if (this.idTagPressed()) {
          this.refreshJointLinkTags();
        }
      }
    }
    if (changes.threePositionArray) {
      if (this.SVGCanvasTM) {
        this.refreshThreePositionArray();
        if (this.idTagPressed()) {
          this.refreshJointLinkTags();
        }
      }
    }
    // if (changes.showIdFromButton) {
    //   this.handleshowIDEmit(changes.showIdFromButton.currentValue);
    // }
    if (changes.zoomFromButton) {
      this.handleZoomEmit(changes.zoomFromButton.currentValue);
    }
    if (changes.menuClicked) {
      this.handleContextMenuClick(changes.menuClicked.currentValue);
    }
    if (changes.newContextElement) {
      this.contextJoint = changes.newContextElement.currentValue;
    }
    if (changes.menuCoords) {
      this.contextMenuCoords = changes.menuCoords.currentValue;
    }
    if (changes.posTimeSortedList && !changes.posTimeSortedList.isFirstChange()) {
      this.directionSortedList = this.posTimeSortedList;
      this.speedChange(this.currentSpeed);
      this.animationMode() &&  this.stopAnimation();
      this.simulationCallback && this.simulationCallback();
    }
    // if (changes.inputJoint) {
    //   // possibly edit this since now joint refreshes correctly?
    //   if (this.jointArray.length !== 0) {
    //     this.refreshLinkage();
    //     // console.log('refreshLinkage is called because of ngOnChanges from inputJoint');
    //     // console.log('if corrected, this can be deleted');
    //     this.setJointAsInputLinkageTable();
    //   }
    // }
  }

  refreshPathPoints(connection: string) {
    this.refreshPathPointArray();
    this.pathsPathPointHolder.innerHTML = '';
    if (this.synthesisDislay === 'path_point') {
      this.drawPathPointPaths(connection);
    }
  }

  idTagStatus(status: string) {
    switch (status) {
      case 'show':
        this.showJointLinkTags(1);
        break;
      case 'hide':
        this.clearJointLinkTagSVGs();
        break;
    }
  }

  comTagStatus(status: string) {
    switch (status) {
      case 'show':
        this.showCoMTags(1);
        break;
      case 'hide':
        this.clearCoMTagSVGs();
        break;
    }
  }
  pathsPathStatus(status: string) {
    switch (status) {
      case 'show':
        // create a new from scratch
        this.pathsPathPointHolder.innerHTML = '';
        // this.pathsPathPointHolder.setAttribute('visibility', 'visible');
        // this.pathsPathPointHolder.style.display = 'hidden';
        break;
      case 'hide':
        this.pathsPathPointHolder.innerHTML = '';
        // this.pathsPathPointHolder.style.setAttribute('visibility', 'hidden');
        // this.pathsPathPointHolder.style.display = 'block';
        break;
    }
  }

  // Set the animation to a given timestamp based on slider input
  setToTimestamp(percentage: number) {
    if (this.speedChangedList.TSL.length <= 0) { return; }
    let progress = Math.round(this.speedChangedList.TSL.length * (percentage / 100));
    if (progress === this.speedChangedList.TSL.length) {
      progress--;
    }
    const timeRow = this.speedChangedList.TSL[progress];
    for (let i = 0; i < timeRow.joints.length; i++) {
      const tj = timeRow.joints[i];
      const aj = this.jointArray.find(j => j.id === tj.id);
      this.updateJoint(aj, tj.x, tj.y);
    }
    this.forceArray.forEach((force, i) => {
      const tslForce = timeRow.forces[i];
      this.updateForce(force, tslForce.startx, tslForce.starty, tslForce.endx, tslForce.endy);
    });
  }

  // start the animation with a pre-set tsl from the simulator if the animation is not already running
  startAnimation() {
    // if (this.inputJoint === undefined || Simulator[0]._dof !== 1) {
    // if (this.inputJoint === undefined) {
    //   IndiFuncs.showErrorNotification('DOF not = 1 or no input joint');
    //   return;
    // }
    // not playing animation
    if (!this.animationRunning && !this.isEditing) {
      this.directionSortedList = this.posTimeSortedList;
      this.speedChange(this.currentSpeed);
      // but in animation mode, it means it is paused, so resume playing
      if (this.animationMode()) {
        console.log('resume');
        this.animate(this.speedChangedList, this.animationProgress);
      } else {
        console.log('play');
        this.clearJointLinkTagSVGs();
        this.clearCoMTagSVGs();
        this.animate(this.speedChangedList);
      }
    }
  }

  animate(tsl: TimeSortedList, startPercentage?: number) {
    startPercentage = startPercentage || 0;

    // if (tsl.TSL[0] !== undefined) {
    if (tsl.TSL.length !== 0) {
      this.animationRunning = true;
      this.isAnimating = true;

      this.drawPaths(tsl);
      this.hideForceEndpoints();

      let progress = Math.round(tsl.TSL.length * startPercentage / 100);
      if (progress === tsl.TSL.length) {
        progress--;
      }

      this.animateJoints(tsl, progress);
    } else {
      // IndiFuncs.showErrorNotification('DOF not = 1 or no input joint');
      IndiFuncs.showErrorNotification('Ummmmmmm, Fix this bug ');
    }
  }

  animateJoints(tsl: TimeSortedList, progress: number) {
    if (!this.animationRunning) { return; }
    // for (let i = 0; i < this.jointArray.length; i++) {
    //   const tj = tsl.TSL[progress].joints[i];
    //   const aj = this.jointArray.find(j => j.getID() === tj.id);
    //   this.updateJoint(aj, tj.x, tj.y);
    // }
    this.jointArray.forEach(j => {
      // const thing = tsl.TSL[progress];
      // const tj = tsl.TSL[progress].joints.find(jt => jt.id === j.getID());
      // const thing = tsl.TSL[1];
      const tj = tsl.TSL[progress].joints.find(jt => jt.id === j.id);
      this.updateJoint(j, tj.x, tj.y);
    });

    this.forceArray.forEach((force, i) => {
      const tslForce = tsl.TSL[progress].forces[i];
      this.updateForce(force, tslForce.startx, tslForce.starty, tslForce.endx, tslForce.endy);
    });

    progress++;
    if (progress === tsl.TSL.length) {
      progress = 0;
    }
    this.animationProgress = progress / tsl.TSL.length * 100;
    const that = this;
    setTimeout(function () {
      that.animateJoints(tsl, progress);
    }, 10 / 3); // 60fps = 16.67ms/frame
  }

  animationMode(): boolean {
    return this.isAnimating;
  }

  hideForceEndpoints() {
    this.forceArray.forEach(force => {
      force.start.svg.setAttribute('visibility', 'hidden');
      force.end.svg.setAttribute('visibility', 'hidden');
    });
  }

  showForceEndpoints() {
    this.forceArray.forEach(force => {
      force.start.svg.setAttribute('visibility', 'visible');
      force.end.svg.setAttribute('visibility', 'visible');
    });
  }

  // Draws the curve that a joint will animate along
  drawPath(xVals: any[], yVals: any[]): SVGPathElement {
    const newpath = document.createElementNS('http://www.w3.org/2000/svg', 'path');

    let dVal = 'M';

    for (let i = 0; i < xVals.length; i++) {
      if (i === 0) {
        dVal = dVal + xVals[i].toString() + ',' + yVals[i].toString();
      } else {
        dVal = dVal + 'L' + xVals[i].toString() + ',' + yVals[i].toString();
      }
    }
    // for (let i = 0; i < xVals.length; i++) {
    //     dVal = dVal + 'L' + xVals[i].toString() + ',' + yVals[i].toString();
    // }

    newpath.setAttributeNS(undefined, 'd', dVal);
    newpath.setAttributeNS(undefined, 'stroke', 'green');
    newpath.setAttributeNS(undefined, 'fill', 'none');
    newpath.setAttributeNS(undefined, 'stroke-width', (2 * AppConstants.scaleFactor).toString());
    newpath.setAttributeNS(undefined, 'id', 'wire');

    return newpath;
  }

  drawPaths(tsl: TimeSortedList) {
    if (tsl.TSL.length <= 0) {
      return;
    }
    const xVals = [];
    const yVals = [];
    // for (let i = 0; i < tsl.TSL[0].joints.length; i++) {
    //   xVals.push([]);
    //   yVals.push([]);
    //   tsl.TSL.forEach(timestamp => {
    //     xVals[i].push(timestamp.joints[i].x);
    //     yVals[i].push(timestamp.joints[i].y);
    //   });
    // }
    for (let i = 0; i < tsl.TSL[0].joints.length; i++) {
      // if (tsl.TSL[0].joints[i] instanceof ImagJoint || tsl.TSL[0].joints[i]) {
      // if (this.jointArray.find(j => j.getID() === tsl.TSL[0].joints[i].id).grounded) {
      //   continue;
      // }
      const curr_xVal = [];
      const curr_yVal = [];
      tsl.TSL.forEach(curr_tsl => {
        curr_xVal.push(curr_tsl.joints[i].x);
        curr_yVal.push(curr_tsl.joints[i].y);
      });
      xVals.push(curr_xVal);
      yVals.push(curr_yVal);
    }
    // tsl.TSL.forEach(curr_tsl => {
    //   curr_tsl.joints.forEach(jt => {
    //     // xVals.push(jt.x);
    //     // yVals.push(jt.y);
    //   });
    //   // tslxVals.push()
    // });
    // Draw curves for each joint
    // for (let i = 0; i < this.jointArray.length; i++) {
    // if there is a place to save memory, it would be right here...
    for (let i = 0; i < xVals.length; i++) {
      this.pathsHolder.appendChild(this.drawPath(xVals[i], yVals[i]));
    }
  }

  // source
  // https://advancedweb.hu/plotting-charts-with-svg/
  drawPathPointPaths(connectionType: string) {
    this.pathsPathPointHolder.innerHTML = '';
    const points = [];
    if (this.pathPointArray.length < 2) {
      return;
    }
    // this.pathPointArray.forEach(pathPoint => {
    //   points.push([pathPoint.x, pathPoint.y]);
    // });
    points.push([this.pathPointArray[0].x, this.pathPointArray[0].y]);
    let prevPathPoint = this.pathPointArray[0];
    let currPathPoint = prevPathPoint.neighbor_one;
    let tempPathPoint: PathPoint;
    if (currPathPoint.neighbor_one === undefined) {
    } else if (currPathPoint.neighbor_two === undefined) {
      points.push([currPathPoint.neighbor_one.x, currPathPoint.neighbor_one.y]);
      points.push([currPathPoint.x, currPathPoint.y]);
    } else {
      while (currPathPoint.id !== this.pathPointArray[0].id) {
        points.push([currPathPoint.x, currPathPoint.y]);
        if (currPathPoint.neighbor_one.id === prevPathPoint.id) {
          tempPathPoint = currPathPoint;
          currPathPoint = currPathPoint.neighbor_two;
          prevPathPoint = tempPathPoint;
        } else {
          tempPathPoint = currPathPoint;
          currPathPoint = currPathPoint.neighbor_one;
          prevPathPoint = tempPathPoint;
        }
      }
      points.push([this.pathPointArray[0].x, this.pathPointArray[0].y]);
    }

    let desired_points: Array<Array<number>>;
    let result = '';

    switch (connectionType) {
      case 'none':
        return;
      case 'line':
        result = 'M' + points[0][0] + ',' + points[0][1] + ' ';
        for (let i = 1; i < points.length; i++) {
          result += 'L' + points[i][0] + ',' + points[i][1] + ' ';
        }
        // desired_points = this.catmullRom2bezier_points(points);
        // result = 'M' + points[0][0] + ',' + points[0][1] + ' ';
        // // let catmull = catmullRom2bezier(points);
        // for (let i = 0; i < desired_points.length; i++) {
        //   result += 'C' + desired_points[i][0][0] + ',' + desired_points[i][0][1] +
        //     ' ' + desired_points[i][1][0] + ',' + desired_points[i][1][1] + ' ' +
        //     desired_points[i][2][0] + ',' + desired_points[i][2][1] + ' ';
        // }
        break;
      case 'curve':
        desired_points = this.catmullRom2bezier_points(points);
        result = 'M' + points[0][0] + ',' + points[0][1] + ' ';
        // let catmull = catmullRom2bezier(points);
        for (let i = 0; i < desired_points.length; i++) {
          result += 'C' + desired_points[i][0][0] + ',' + desired_points[i][0][1] +
            ' ' + desired_points[i][1][0] + ',' + desired_points[i][1][1] + ' ' +
            desired_points[i][2][0] + ',' + desired_points[i][2][1] + ' ';
        }
        break;
    }

    const newpath = document.createElementNS('http://www.w3.org/2000/svg', 'path');

    newpath.setAttributeNS(undefined, 'd', result);
    newpath.setAttributeNS(undefined, 'stroke', 'royalblue');
    newpath.setAttributeNS(undefined, 'fill', 'none');
    newpath.setAttributeNS(undefined, 'stroke-width', '0.1');
    // newpath.setAttributeNS(undefined, 'stroke-width', (2 * AppConstants.scaleFactor).toString());
    newpath.setAttributeNS(undefined, 'id', 'wire');
    this.pathsPathPointHolder.appendChild(newpath);
    // return result;
  }

  catmullRom2bezier_points(points: Array<Array<number>>) {
    const result = [];
    for (let i = 0; i < points.length - 1; i++) {
      const p = [];

      p.push([points[Math.max(i - 1, 0)][0], points[Math.max(i - 1, 0)][1]]);
      p.push([points[i][0], points[i][1]]);
      p.push([points[i + 1][0], points[i + 1][1]]);
      p.push([points[Math.min(i + 2, points.length - 1)][0], points[Math.min(i + 2, points.length - 1)][1]]);

      // Catmull-Rom to Cubic Bezier conversion matrix
      //    0       1       0       0
      //  -1/6      1      1/6      0
      //    0      1/6      1     -1/6
      //    0       0       1       0

      const bp = [];
      bp.push([((-p[0][0] + 6 * p[1][0] + p[2][0]) / 6), ((-p[0][1] + 6 * p[1][1] + p[2][1]) / 6)]);
      bp.push([((p[1][0] + 6 * p[2][0] - p[3][0]) / 6), ((p[1][1] + 6 * p[2][1] - p[3][1]) / 6)]);
      bp.push([p[2][0], p[2][1]]);
      result.push(bp);
    }

    return result;
  }


  // Repositions joints at a set interval
  updateJoint(joint: Joint, x: number, y: number) {
    this.setJoint(joint, x, y);
    this.updateLinkSVG(joint);
  }

  updateForce(force: Force, sx: number, sy: number, ex: number, ey: number) {
    force.start.relocate(sx, sy);
    force.end.relocate(ex, ey);
  }

  // sets the position of a joint SVG element
  // will be implemented if we want to move joints too
  setJoint(joint: Joint, xVal: number, yVal: number) {
    joint.relocate(xVal, yVal);
  }

  updateLinkSVG(joint: Joint) {
    joint.links.forEach(link => {
      if (link.uiShape !== Shape.line && link.uiShape !== undefined) {
        link.updateBounds();
        link.updateBoundsSVG();
        link.updateShapeSVG();
      }
    });
  }

  // Set stopAnimation variable to true
  stopAnimation() {
    if (this.isEditing) { return; }
    console.log('stop');
    this.clearPathSVGs();
    this.showForceEndpoints();
    this.animationRunning = false;
    this.isAnimating = false;
    this.state = states.waiting;
    this.animationProgress = 0;
    this.setToTimestamp(this.animationProgress);
    if (this.idTagPressed()) {
      this.refreshJointLinkTags();
    }
    if (this.comTagPressed()) {
      this.refreshCoMTags();
    }
  }


  setAnimation($event: number) {
    if (this.isEditing) { return; }
    this.animationProgress = $event;
    // force into animation mode when animation slider is dragged
    // this is to prevent linkage edits to happen when slider is dragged
    if (!this.animationMode()) {
      this.pathsHolder.innerHTML = '';
      // this.pathsHolder.innerHTML === '';
      this.drawPaths(this.posTimeSortedList);
      this.isAnimating = true;
    }
    this.setToTimestamp(this.animationProgress);
  }

  pauseAnimation() {
    console.log('pause animation');
    if (this.animationRunning) {
      this.animationRunning = false;
    }
  }

  // removes animation curves
  clearPathSVGs() {
    this.pathsHolder.innerHTML = '';
  }

  // handle event value extraction and call speedChange
  speedChangeHandler(e) {
    const newSpeed = e;
    this.speedChange(newSpeed);
  }

  // change speed and/or direction of animation
  speedChange(speed: string) {
    // select desired speed setting
    switch (speed) {
      case '1':
        this.slowSpeed();
        break;
      case '2':
        this.normalSpeed();
        break;
      case '3':
        this.fastSpeed();
        break;
      default:
        break;
    }
    this.currentSpeed = speed;
    this.animationProgress = 0;
  }


  slowSpeed() {
    const newTSL = new TimeSortedList;

    for (let i = 0; i < this.directionSortedList.TSL.length; i++) {
      const tempJoints = this.directionSortedList.TSL[i].joints;
      const tempLinks = this.directionSortedList.TSL[i].links;
      const temp_ang_vel = this.directionSortedList.TSL[i].angular_velocity;
      const tempForces = this.directionSortedList.TSL[i].forces;
      const tempICs = this.directionSortedList.TSL[i].instant_centers;
      newTSL.addRowFromTSLElement(tempJoints, tempLinks, temp_ang_vel, tempForces, tempICs);
      newTSL.addRowTSLSameTime(tempJoints, tempLinks, temp_ang_vel, tempForces, tempICs);
    }

    this.speedChangedList = newTSL;
  }

  normalSpeed() {
    const newTSL = new TimeSortedList;
    this.speedChangedList = this.directionSortedList;
  }


  fastSpeed() {
    const newTSL = new TimeSortedList;
    for (let i = 0; i < this.directionSortedList.TSL.length; i += 2) {
      const tempJoints = this.directionSortedList.TSL[i].joints;
      const tempLinks = this.directionSortedList.TSL[i].links;
      const ang_vel = this.directionSortedList.TSL[i].angular_velocity;
      const tempForces = this.directionSortedList.TSL[i].forces;
      const tempICs = this.directionSortedList.TSL[i].instant_centers;
      newTSL.addRowFromTSLElement(tempJoints, tempLinks, ang_vel, tempForces, tempICs);
    }
    this.speedChangedList = newTSL;
  }

  showJointLinkTags($event) {
    // this.deleteJointLinkTagsEmit.emit();
    if ($event === 1) {
      switch (this.synthesis) {
        case 'none':
          this.jointArray.forEach(j => {
            // j.createIDTag();
            // const jointTag = this.createTempJointTag(j.x, j.y, j.id);
            // this.createJointLinkTag(jointTag);
            // this.jointLinkTagHolder.appendChild(jointTag.svg);
            this.jointLinkTagHolder.appendChild(j.idTag);
          });
          this.linkArray.forEach(l => {
            // l.createIDTag();
            // const linkTag = this.createTempLinkTag(l);
            // this.createJointLinkTag(linkTag);
            // this.jointLinkTagHolder.appendChild(linkTag.svg);
            this.jointLinkTagHolder.appendChild(l.idTag);
          });
          break;
        case 'path_point':
          this.pathPointArray.forEach(pathPoint => {
            const pathPointTag = this.createTempJointTag(pathPoint.x, pathPoint.y, pathPoint.id);
            // this.createJointLinkTag(pathPointTag);
            this.jointLinkTagHolder.appendChild(pathPointTag.svg);
          });
          break;
        case 'three_pos':
          this.threePositionArray.forEach(threePos => {
            const threePosTag = this.createTempJointTag(threePos.x, threePos.y, threePos.id);
            // this.createJointLinkTag(threePosTag);
            this.jointLinkTagHolder.appendChild(threePosTag.svg);
          });
          break;
        case 'gear_syn':
          break;
      }
    } else {
      this.clearJointLinkTagSVGs();
      // this.delJointLinkTagEmit.emit();
    }
  }

  showCoMTags($event) {
    if ($event === 1) {
      this.linkArray.forEach(l => {
        const comTag = this.createTempCoMTag(l);
        this.createCoMTag(comTag);
        this.comTagHolder.appendChild(comTag.svg);
      });
    } else {
      this.clearCoMTagSVGs();
      this.delCoMTagEmit.emit();
    }
  }

  // receiveInputJoint($event: any) {
  //
  // }
}

interface SimpleJoint {
  id: string;
  x: number;
  y: number;
}

interface SimpleLink {
  id: string;
  shape: Shape;
  bounds: Bounds;
}

interface SimpleForce {
  id: string;
  start: Coord;
  end: Coord;
}
