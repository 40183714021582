import {Joint, RealJoint} from './Joints_Links_Forces/Joint';
import {InstantCenter} from './Joints_Links_Forces/InstantCenter';
import {Link, RealLink} from './Joints_Links_Forces/Link';
import { Force } from './Joints_Links_Forces/Force';
import {Bounds} from '../functions/SVGFuncs';

// Represents a single row in the time sorted list
// A row is a timestamp and an array of elements (joints)
export class TSLRow {
  time: number;
  angular_velocity: number;
  joints: TSLJoint[];
  links: TSLLink[];
  forces: TSLForce[];
  instant_centers: TSLInsantCenter[];

  // constructor(time: number = 0, angular_velocity: number = 0, joints: TSLJoint[] = []) {
  constructor(time: number, angular_velocity: number, joints: TSLJoint[], links: TSLLink[], forces: TSLForce[], ics: TSLInsantCenter[]) {
    this.time = time;
    this.angular_velocity = angular_velocity;
    this.joints = joints;
    this.links = links;
    this.forces = forces;
    this.instant_centers = ics;
  }
    // this.time = 0;
    // this.angular_velocity = 0;
    // this.joints = joints;
  // }
}

// Represents an instance of a single joint at the timestamp
export class TSLJoint {
  id: string; // ID of the joint.
  x: number; // x coord
  y: number; // y coord

  constructor(id: string, x: number, y: number) {
    this.id = id;
    this.x = x;
    this.y = y;
  }
}

export class TSLLink {
  // bounds: Bounds;
  CoM_x: number;
  CoM_y: number;

  constructor(CoM_x: number, CoM_y: number) {
    this.CoM_x = CoM_x;
    this.CoM_y = CoM_y;
  }
  // constructor(bounds: Bounds) {
    // this.bounds = bounds;
  // }
}

export class TSLInsantCenter {
  id: string; // ID of IC
  x: number; // x coord
  y: number; // y coord

  constructor(id: string, x: number, y: number) {
    this.id = id;
    this.x = x;
    this.y = y;
  }
}

export class TSLForce {
  id: string;
  startx: number;
  starty: number;
  endx: number;
  endy: number;
  xForce: number;
  yForce: number;

  constructor(id: string, startx: number, starty: number, endx: number, endy: number, xForce: number, yForce: number) {
    this.id = id;
    this.startx = startx;
    this.starty = starty;
    this.endx = endx;
    this.endy = endy;
    this.xForce = xForce;
    this.yForce = yForce;
  }
}

// A time sorted list stores the positions of joints at every time interval of their movement.
// Standard time interval is 60 frames a second.
export class TimeSortedList {
  TSL: TSLRow[];
  currentTime: number;
  readonly time_per_frame = 1 / 60;

  constructor(TSL: TSLRow[] = []) {
    this.TSL = TSL;
    this.currentTime = 0; // TSL.length * this.time_per_frame;
  }

  // Add an array of joints to the TSL.
  addRow(joints: Joint[], links: Link[], angular_velocity: number, forces: Force[],
         currentTime: number, rowID: number = this.TSL.length, ics: InstantCenter[]) {
    const tempJointElements = [];
    const tempLinkElements = [];
    joints.forEach(j => {
      if (j instanceof RealJoint) { // have to also include ground nodes
      // if (!j.ground) {
        const element = new TSLJoint(j.id, j.x, j.y);
        tempJointElements.push(element);
      }
    });
    links.forEach(l => {
      if (l instanceof RealLink) {
       const link = l as RealLink;
      // const element = new TSLLink(l.bounds);
      const element = new TSLLink(link.CoM_x, link.CoM_y);
      tempLinkElements.push(element);
      }
    });
    // for (let i = 0; i < joints.length; i++) {
    //   const joint = joints[i];
    //   const element = new TSLJoint(joint.id, joint.x, joint.y);
    //   tempJointElements.push(element);
    // }

    const tslForces = forces.map(force => new TSLForce(force.id, force.sx, force.sy, force.ex, force.ey, force.xForce, force.yForce));
    const tslICs = ics.map(ic => new TSLInsantCenter(ic.id, ic.x, ic.y));
    this.TSL.push(new TSLRow(currentTime, angular_velocity, tempJointElements, tempLinkElements, tslForces, tslICs));
    // this.TSL[rowID] = { time: currentTime, joints: tempJointElements, angular_velocity: angular_velocity, forces: tslForces };
    this.currentTime = this.currentTime + currentTime;
  }

  // Add an array of joints to the TSL.
  addRowFromTSLElement(tslJoints: TSLJoint[], tslLinks: TSLLink[], angular_velocity: number, tslForces: TSLForce[],
                      tslICs: TSLInsantCenter[],  rowID: number = this.TSL.length) {
    this.TSL.push(new TSLRow( this.currentTime, angular_velocity, tslJoints, tslLinks, tslForces, tslICs));
    // this.TSL[rowID] = { time: this.currentTime, joints: tslJoints, angular_velocity: angular_velocity, forces: tslForces };
    this.currentTime = this.currentTime + this.time_per_frame;
  }

  addRowTSLSameTime(tslJoints: TSLJoint[], tslLinks: TSLLink[], angular_velocity: number, tslForces: TSLForce[],
                    tslICs: TSLInsantCenter[], rowID: number = this.TSL.length) {
    this.TSL.push(new TSLRow(this.currentTime, angular_velocity, tslJoints, tslLinks, tslForces, tslICs));
    // this.TSL[rowID] = { time: this.currentTime, joints: tslJoints, angular_velocity: angular_velocity, forces: tslForces };
  }
}
