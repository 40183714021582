import { Joint } from '../classes/Joint';
import { Link } from '../classes/Link';
// import { Joint as SimJoint } from '../simulator/Joints_Links_Forces/Joint';
import {RealJoint, RealJoint as SimRealJoint} from '../simulator/Joints_Links_Forces/Joint';
import { ImagJoint as SimImagJoint } from '../simulator/Joints_Links_Forces/Joint';
import { Joint as SimJoint } from '../simulator/Joints_Links_Forces/Joint';
import {RealLink, RealLink as SimRealLink} from '../simulator/Joints_Links_Forces/Link';
import {ImagLink as SimImagLink} from '../simulator/Joints_Links_Forces/Link';
import { Link as SimLink } from '../simulator/Joints_Links_Forces/Link';
import { Force as SimForce } from '../simulator/Joints_Links_Forces/Force';
import { Coord } from 'src/classes/Coord';
import { Force } from 'src/classes/Force';
import { parse as parseCSV } from 'papaparse';
import {Bounds, editorID, Shape} from './SVGFuncs';
import { IndependentFuncs as IndiFuncs } from './IndependentFuncs';
import { LinkMassAndMassMoment } from 'src/simulator/Joints_Links_Forces/LinkMassAndMassMoment';
import {timer} from 'rxjs';
import {ToolbarComponent} from '../app/toolbar/toolbar.component';
import {settings} from 'cluster';
import {ThreePosition} from '../classes/ThreePosition';
import {PathPoint} from '../classes/PathPoint';
import * as Path from 'path';
import * as path from 'path';

// Standalone functions
class StaticFuncColle {

  shapeNameMap = {
    line: 'l',
    eTriangle: 'et',
    rTriangle: 'rt',
    rectangle: 'r',
    square: 's',
    circle: 'cr',
    bar: 'b',
    cShape: 'cs',
    tShape: 'ts',
    lShape: 'ls'
  };

  // Check to see if a link exists between two joints.
  // If a link exists, returns the link
  // If a linke does not exist. returns nothing
  findAndGetLink(joint1: Joint, joint2: Joint): Link | undefined {
    for (let i = 0; i < joint1.links.length; i++) {
      if (this.jointInArray(joint2, joint1.links[i].joints)) {
        return joint1.links[i];
      }
    }
  }

  // Get mouse position event handler
  getMousePosition(e): Coord {
    const canvasGET = document.getElementById('SVGCanvas') as unknown;
    const svg = canvasGET as SVGGraphicsElement;
    const CTM = svg.getScreenCTM();
    // if (e.touches) { e = e.touches[0]; }
    const box = svg.getBoundingClientRect();
    const width = box.right - box.left;
    const height = box.bottom - box.top;
    const newX = IndiFuncs.roundNumber((e.clientX - CTM.e) / CTM.a, 0);
    let newY = 0;
    // NOTE: CTM.f is the svg.ClientHeight + height of rest of elements. In Firefox, clientHeight does not work (returns 0) so we need to
    // manually detect and add it.
    if (svg.clientHeight === 0) {
      newY = IndiFuncs.roundNumber((e.clientY - (CTM.f + height)) / -Math.abs(CTM.d), 0);
    } else {
      newY = IndiFuncs.roundNumber((e.clientY - CTM.f) / -Math.abs(CTM.d), 0);
    }
    // NOTE: The CTM returns different values per browser. In Firefox & Safari it is 1 and in Chrome/Edge it is -1.
    // By putting a -Math.Abs() to it we are standardizing it at -1
    return new Coord(newX, newY);
  }

  // Looks for a joint in the given array. If found, returns the index to the joint, otherwise, returns -1.
  findInJointArray(joint: Joint, jointArray: Joint[]): number {
    for (let i = 0; i < jointArray.length; i++) {
      if (joint === jointArray[i]) {
        return i;
      }
    }
    return -1;
  }

  // Convert the Joint array to the simulator class equivalent
  // convertJointArraytoSim(jointArray: Joint[], inputJoint: Joint): SimJoint[] {
  convertJointsToSimJoints(jointArray: Joint[]): SimJoint[] {
    const simJoints = [] as SimJoint[];
    jointArray.forEach(j => {
      simJoints.push(j.convertToSimulatorJoint());
    });
    return simJoints;
  }

  determineImagJointPos(sliderJoint: SimJoint, inputJoint: SimJoint) {
    const radToDeg = 180 / Math.PI;
    if (sliderJoint.angle % (180 * radToDeg) === 0) {
      return [inputJoint.x, sliderJoint.y];
    } else if (sliderJoint.angle % (90 * radToDeg) === 0) {
      return [sliderJoint.x, inputJoint.y];
    } else {
      const m1 = Math.cos(sliderJoint.angle);
      const m2 = Math.cos((90 - sliderJoint.angle));
      const b1 = sliderJoint.y;
      const b2 = inputJoint.y;
      const x = (b2 - b1) / (m1 - m2);
      const y = m1 * x + b1;
      return [x, y];
    }
  }

  // Convert the Link array to the simulator class equivalent
  convertLinkToSimLinks(linkArray: Link[], simJoints: SimJoint[]): SimLink[] {
    const simLinks = [];
    const simJointMap = new Map();
    simJoints.forEach(j => {
      simJointMap.set(j.id, j);
    });
    linkArray.forEach(link => {
      const copiedSimJoints = link.joints.map(j => simJointMap.get(j.id));
      const simLink = new SimRealLink(link.id, copiedSimJoints, link.mass, link.massMomentOfInertia, link.uiBounds,
        link.centerOfMassX, link.centerOfMassY);
      simLinks.push(simLink);
      const simJointIndexMap = new Map();
      simLink.joints.forEach((j, index) => {
        simJointIndexMap.set(j, index);
      });
      simLink.joints.forEach(j => {
        simLink.joints.forEach(jt => {
          if (j !== jt) {
            jt.connectedJoints.push(j);
          }
        });
        j.connectedLinks.push(simLink); });
    });
    return simLinks;
  }

  convertForceToSimForces(forceArray: Force[], simLinks: SimLink[]): SimForce[] {
    const res = [];
    forceArray.forEach(force => {
      const simForce = new SimForce(
        force.id,
        force.start.x, force.start.y,
        force.end.x, force.end.y,
        force.relativeCoords.start.x, force.relativeCoords.start.y,
        force.relativeCoords.end.x, force.relativeCoords.end.y,
        force.xMag, force.yMag,
        force.link.id, force.isGlobal);
      res.push(simForce);
      const desiredLink = simLinks.find(l => l.id === force.link.id) as RealLink;
      desiredLink.forces.push(simForce);
    });
    return res;
  }

  // Converts all joints and links into their respective simulator classes.
  convertToSimulatorData(jointArray: Joint[], linkArray: Link[], forceArray: Force[]):
    { simJoints: SimJoint[], simLinks: SimLink[], simForces: SimForce[] } {
    const simJoints = this.convertJointsToSimJoints(jointArray);
    const simLinks = this.convertLinkToSimLinks(linkArray, simJoints);
    const simForces = this.convertForceToSimForces(forceArray, simLinks);
    this.createImagJoints(simJoints, simLinks);
    return { simJoints: simJoints, simLinks: simLinks, simForces: simForces };
  }

  private createImagJoints(jointSimArray: SimJoint[], linkSimArray: SimLink[]) {
    jointSimArray.forEach(j => {
      if (j.jointType === 'P') {
        // create simImagJoint
        const idArray = jointSimArray.map(jt => jt.id);
        const imagJointId = StaticFuncs.getNewJointID(idArray);
        const inputJoint = jointSimArray.find(jt => jt.input);
        if (inputJoint === undefined) {
          return;
        }
        const point = this.determineImagJointPos(j, inputJoint);
        const simImagJoint = new SimImagJoint(imagJointId, j.input, true, 'P', point, j as RealJoint);
        jointSimArray.push(simImagJoint);
        j.input = false;
        j.connectedJoints.push(simImagJoint);
        // create simImagLink
        const linkJoints = [];
        linkJoints.push(j);
        linkJoints.push(simImagJoint);
        const linkID = j.id + simImagJoint.id;
        const simImagLink = new SimImagLink(linkID, linkJoints);
        // linkSimArray.push(new SimImagLink(linkID, linkJoints));
        linkSimArray.push(simImagLink);
        j.connectedLinks.push(simImagLink);
        simImagJoint.connectedLinks.push(simImagLink);
      }
    });
  }

  // Return true if the given joint is in the given joint array
  jointInArray(joint: Joint, jointArray: Joint[]): boolean {
    return (this.getJointIndexInArray(joint, jointArray) >= 0);
  }

  // Return the index of the joint in the joint array. If the joint isn't found, return -1
  getJointIndexInArray(joint: Joint, jointArray: Joint[]): number {
    for (let i = 0; i < jointArray.length; i++) {
      if (jointArray[i].id === joint.id) {
        return i;
      }
    }
    return -1;
  }


  parseImportFile(file: string): { jointArray: Joint[], inputJoint: Joint, linkArray: Link[], forceArray: Force[],
    pathPointArray: PathPoint[], threePositionArray: ThreePosition[],  settings_: {
      input_speed_mag: number;
      clockwise: boolean;
      gravity: boolean;
      unit: string; } } {
    const csv = parseCSV(file);

    if (csv.errors.length > 0) {
      console.error(csv.errors);
      throw new Error('parse csv failed');
    }

    const lines = csv.data;
    let currentParseMode = 'none';
    let parsing = false;
    let propsLine = false;
    const jointArray = [];
    const linkArray = [];
    const forceArray = [];
    const pathPointArray = [];
    const threePositionArray = [];
    const gearSynthesisArray = [];
    let settings_: {
      input_speed_mag: number;
      clockwise: boolean;
      gravity: boolean;
      unit: string};
    let inputJoint: Joint;

    for (let i = 0; i < lines.length; i++) {
      const line = lines[i] as string[];

      if (line.length === 1) {
        switch (line[0]) {
          case 'joints': {
            currentParseMode = 'joint';
            propsLine = true;
            break;
          }
          case 'links': {
            currentParseMode = 'link';
            propsLine = true;
            break;
          }
          case 'forces': {
            currentParseMode = 'force';
            propsLine = true;
            break;
          }
          case 'pathPoints': {
            currentParseMode = 'pathPoint';
            propsLine = true;
            break;
          }
          case 'threePositions': {
            currentParseMode = 'threePosition';
            propsLine = true;
            break;
          }
          case 'settings': {
            currentParseMode = 'setting';
            propsLine = true;
            break;
          }
          default: {
            currentParseMode = 'none';
            break;
          }
        }
        parsing = false;
        continue;
      }

      // ignore props line
      if (propsLine) {
        propsLine = false;
        parsing = true;
        continue;
      }

      if (!parsing) { continue; }
      switch (currentParseMode) {
        case 'joint':
          try {
            const id = line[0];
            const x = this.stringToFloat(line[1]);
            const y = this.stringToFloat(line[2]);
            const links = this.getLinksByIds(line[3].split(','), linkArray);
            const type = line[4];
            const ground = this.stringToBoolean(line[5]);
            const angle = this.stringToFloat(line[6]);
            const coeff_of_friction = this.stringToFloat(line[7]);
            const input = this.stringToBoolean(line[8]);

            const newJoint = new Joint(id, x, y, type);
            newJoint.grounded = ground;
            if (input) {
              newJoint.setInput(true, newJoint);
              inputJoint = newJoint;
            } else if (newJoint.getSlider()) {
              newJoint.setSlider(true);
            } else if (ground) {
              newJoint.setGrounded(true, 'R');
            }
            newJoint.angle = angle;
            newJoint.coeffFriction = coeff_of_friction;
            newJoint.links = links;
            jointArray.push(newJoint);
          } catch (e) {
            console.error(line);
            console.error(e);
            throw new Error('parse csv failed');
          }
          break;
        case 'link':
          try {
            const id = line[0];
            const mass = line[1];
            const mass_moi = line[2];
            const CoM_x = line[3];
            const CoM_y = line[4];
            const joints = this.getJointsByIds(line[5].split(','), jointArray);
            const forces = this.getForcesByIds(line[6].split(','), forceArray);
            const shape = this.stringToShape(line[7]);
            const b1 = new Coord(this.stringToFloat(line[8]), this.stringToFloat(line[9]));
            const b2 = new Coord(this.stringToFloat(line[10]), this.stringToFloat(line[11]));
            const b3 = new Coord(this.stringToFloat(line[12]), this.stringToFloat(line[13]));
            const b4 = new Coord(this.stringToFloat(line[14]), this.stringToFloat(line[15]));
            const arrow_x = (b1.x + b2.x + b3.x + b4.x) / 4;
            const arrow_y = (b1.x + b2.x + b3.x + b4.x) / 4;
            const arrow = new Coord(arrow_x, arrow_y);
            const newLink = new Link(id, joints, shape);
            newLink.mass = this.stringToFloat(mass);
            newLink.massMomentOfInertia = this.stringToFloat(mass_moi);
            newLink.centerOfMassX = this.stringToFloat(CoM_x);
            newLink.centerOfMassY = this.stringToFloat(CoM_y);
            newLink.tryNewBounds({ b1: b1, b2: b2, b3: b3, b4: b4, arrow: arrow });
            newLink.saveBounds();
            linkArray.push(newLink);
          } catch (e) {
            console.error(line);
            console.error(e);
            throw new Error('parse csv failed');
          }
          break;
        case 'force':
          try {
            const id = line[0];
            const linkId = line[1];
            const link = linkArray.find(l => {
              return l.getID() === linkId;
            });
            if (!link) { throw new Error('link referenced in force does not exist'); }
            const start = new Coord(this.stringToFloat(line[2]), this.stringToFloat(line[3]));
            const end = new Coord(this.stringToFloat(line[4]), this.stringToFloat(line[5]));
            const global = this.stringToBoolean(line[6]);
            const newForce = new Force(id, link, start, end, global);
            newForce.directionOutward = this.stringToBoolean(line[7]);
            newForce.xMag = this.stringToFloat(line[8]);
            newForce.yMag = this.stringToFloat(line[9]);
            forceArray.push(newForce);
          } catch (e) {
            console.error(line);
            console.error(e);
            throw new Error('parse csv failed');
          }
          break;
        case 'pathPoint':
          try {
            // const id = line[0];
            // const x = this.stringToFloat(line[1]);
            // const y = this.stringToFloat(line[2]);
            // const newPathPoint = new PathPoint(id, x, y);
            // const neighborOne = this.getPathPointById(line[3], pathPointArray);
            // const neighborTwo = this.getPathPointById(line[4], pathPointArray);
            // if (neighborOne !== undefined) {
            //   newPathPoint.neighbor_one = neighborOne;
            //   newPathPoint.neighbor_one.neighbor_two = newPathPoint;
            // }
            // if (neighborTwo !== undefined) {
            //   newPathPoint.neighbor_two = neighborTwo;
            //   newPathPoint.neighbor_two.neighbor_one = newPathPoint;
            // }
            // pathPointArray.push(newPathPoint);
          } catch (e) {
            console.error(line);
            console.error(e);
            throw new Error('parse csv failed');
          }
          break;
        case 'threePosition':
          try {

          } catch (e) {
            console.error(line);
            console.error(e);
            throw new Error('parse csv failed');
          }
          break;
        case 'gearSynthesis':
          try {

          } catch (e) {
            console.error(line);
            console.error(e);
            throw new Error('parse csv failed');
          }
          break;
        case 'setting':
          try {
            const input_speed_mag = this.stringToFloat(line[0]);
            const clockwise = this.stringToBoolean(line[1]);
            const gravity = this.stringToBoolean(line[2]);
            const unit = line[3];
            settings_ = {input_speed_mag, clockwise, gravity, unit};
          } catch (e) {
            console.error(line);
            console.error(e);
            throw new Error('parse csv failed');
          }
          break;
      }
    }
    return { jointArray, inputJoint, linkArray, forceArray, pathPointArray, threePositionArray, settings_ };
  }


  generateExportFile(jointArray: Joint[], linkArray: Link[], forceArray: Force[], pathPointArray: PathPoint[],
                     threePositionArray: ThreePosition[],  input_speed_mag: number, clockwise: boolean,
                     gravity: boolean, unit: string): string {
    let result = '';
    result += 'joints\n';
    result += 'id,x,y,links,type,ground,angle,coeffOfFriction,input\n';
    jointArray.forEach(joint => {
      result += `${joint.id},`;
      result += `${joint.x},`;
      result += `${joint.y},`;
      const relatedLinkIDs = joint.links.map(link => {
        return link.id;
      });
      result += `${relatedLinkIDs.join('|')},`;
      result += `${joint.type},`;
      result += `${joint.grounded},`;
      result += `${joint.angle},`;
      result += `${joint.coeffFriction},`;
      result += `${joint.input},`;
      // result += `0`;
      result += '\n';
    });

    result += 'links\n';
    result += 'id,mass,mass_moi,center_of_mass_x,center_of_mass_y,joints,forces,shape,b1x,b1y,b2x,b2y,b3x,b3y,b4x,b4y\n';

    linkArray.forEach(link => {
      result += `${link.id},`;
      result += `${link.mass},`;
      result += `${link.massMomentOfInertia},`;
      result += `${link.centerOfMassX},`;
      result += `${link.centerOfMassY},`;
      const relatedJointIDs = link.joints.map(joint => {
        return joint.id;
      });
      const relatedForceIDs = link.forces.map(force => {
        return force.id;
      });
      result += `"${relatedJointIDs.join(',')}",`;
      result += `"${relatedForceIDs.join(',')}",`;
      result += `${link.uiShape}`;
      const bounds = link.uiBounds;
      const keyArray = [editorID.b1, editorID.b2, editorID.b3, editorID.b4];
      keyArray.forEach(eid => {
        result += `,${bounds[eid].x}`;
        result += `,${bounds[eid].y}`;
      });
      result += '\n';
    });

    result += 'forces\n';
    result += 'id,link,startx,starty,endx,endy,fixed,direction,xMag,yMag\n';
    forceArray.forEach(force => {

      result += `${force.id},`;
      result += `${force.link.id},`;
      result += `${force.start.x},`;
      result += `${force.start.y},`;
      result += `${force.end.x},`;
      result += `${force.end.y},`;
      result += `${force.isGlobal},`;
      result += `${force.directionOutward},`;
      result += `${force.xMag},`;
      result += `${force.yMag}`;
      result += '\n';
    });
    result += 'pathPoints\n';
    result += 'id,x,y,neighbor1,neighbor2\n';
    pathPointArray.forEach(pathPoint => {
      result += `${pathPoint.id},`;
      result += `${pathPoint.x},`;
      result += `${pathPoint.y},`;
      result += `${pathPoint.neighbor_one.id},`;
      result += `${pathPoint.neighbor_two.id},`;
      result += '\n';
    });
    // result += 'threePosition\n';
    // result += 'id,x,y\n';
    // threePositionArray.forEach(force => {
    //
    //   result += '\n';
    // });
    result += 'settings\n';
    result += 'input_speed_mag,clockwise,gravity,unit\n';
    result += `${input_speed_mag},`;
    result += `${clockwise},`;
    result += `${gravity},`;
    result += `${unit}`;
    result += '\n';

    return result;
  }

  generateExportURL(jointArray: Joint[], linkArray: Link[], forceArray: Force[], pathPointArray: PathPoint[],
                    threePositionArray: ThreePosition[],  angularVelocity: number, clockwise: boolean,
                    gravityBool: boolean, unit: string): string {
    let result = '';
    result += `j=`;
    jointArray.forEach(joint => {
      result += `${joint.id},`;
      result += `${IndiFuncs.roundNumber(joint.x, 3)},`;
      result += `${IndiFuncs.roundNumber(joint.y, 3)},`;
      const relatedLinkIDs = joint.links.map(link => {
        return link.id;
      });
      result += `${relatedLinkIDs.join('|')},`;
      result += `${joint.type},`;
      result += `${joint.grounded ? 't' : 'f'},`;
      result += `${joint.angle},`;
      result += `${joint.coeffFriction},`;
      result += `${joint.input ? 't' : 'f'},`;

      result += '\n';
    });
    result += `&l=`;
    linkArray.forEach(link => {
      result += `${link.id},`;
      result += `${link.mass},`;
      result += `${link.massMomentOfInertia},`;
      result += `${link.centerOfMassX},`;
      result += `${link.centerOfMassY},`;
      const relatedJointIDs = link.joints.map(joint => {
        return joint.id;
      });
      const relatedForceIDs = link.forces.map(force => {
        return force.id;
      });

      result += `${relatedJointIDs.join('|')},`;
      result += `${relatedForceIDs.join('|')},`;
      result += `${this.shapeFullnameToNickname(link.uiShape)}`;
      const bounds = link.uiBounds;
      const keyArray = [editorID.b1, editorID.b2, editorID.b3, editorID.b4];
      keyArray.forEach(eid => {
        result += `,${IndiFuncs.roundNumber(bounds[eid].x, 3)}`;
        result += `,${IndiFuncs.roundNumber(bounds[eid].y, 3)}`;
      });
      result += '\n';
    });

    result += `&f=`;
    forceArray.forEach(force => {
      result += `${force.id},`;
      result += `${force.link.id},`;
      result += `${IndiFuncs.roundNumber(force.start.x, 3)},`;
      result += `${IndiFuncs.roundNumber(force.start.y, 3)},`;
      result += `${IndiFuncs.roundNumber(force.end.x, 3)},`;
      result += `${IndiFuncs.roundNumber(force.end.y, 3)},`;
      result += `${force.isGlobal ? 't' : 'f'},`;
      result += `${force.directionOutward},`;
      result += `${force.xMag},`;
      result += `${force.yMag}`;
      result += '\n';
    });
    result += `&pp=`;
    pathPointArray.forEach(pp => {
      result += `${pp.id},`;
      result += `${IndiFuncs.roundNumber(pp.x, 3)},`;
      result += `${IndiFuncs.roundNumber(pp.y, 3)},`;
      result += `${pp.neighbor_one.id},`;
      result += `${pp.neighbor_two.id},`;
      result += '\n';
    });
    result += `&tp=`;
    threePositionArray.forEach(tp => {});
    result += `&s=`;
    result += `${angularVelocity},`; // input speed
    result += `${clockwise},`; // cw (true) or ccw (false)
    result += `${gravityBool},`; // gravity on or off
    result += `${unit}`;
    return result;
  }

  parseURL(url: string): { jointArray: Joint[], linkArray: Link[], forceArray: Force[], pathPointArray: PathPoint[],
    threePositionArray: ThreePosition[], settings_: {
      input_speed_mag: number, clockwise: boolean, gravity: boolean, unit: string}} {

    const jointStart = url.indexOf('?j=');
    const linkStart = url.indexOf('&l=');
    const forceStart = url.indexOf('&f=');
    const pathPointStart = url.indexOf('&pp=');
    const threePositionStart = url.indexOf('&tp=');
    const settingsStart = url.indexOf('&s=');

    const jointArray = [] as Joint[];
    const linkArray = [] as Link[];
    const forceArray = [] as Force[];
    const pathPointArray = [] as PathPoint[];
    const threePositionArray = [] as ThreePosition[];

    let settings_: {
      input_speed_mag: number;
      clockwise: boolean;
      gravity: boolean;
      unit: string;
    };
    // let input_speed: number;
    // let gravity: boolean;

    if (jointStart >= 0) {
      const jointArrayString = url.substring(jointStart + 3, linkStart < 0 ? url.length : linkStart);
      const jointStringArray = jointArrayString.split('\n');
      jointStringArray.forEach(jointString => {
        const propsArray = jointString.split(',');
        if (propsArray.length !== 10) { return; }
        // todo: needs input error checking
        const id = propsArray[0];
        const x = propsArray[1];
        const y = propsArray[2];
        const linkIDArray = propsArray[3].split('|');
        const links = this.getLinksByIds(linkIDArray, linkArray);
        // const links = propsArray[3];
        const type = propsArray[4];
        const ground_joint = this.stringToBoolean(propsArray[5]);
        const angle = propsArray[6];
        const coefficient_of_friction = propsArray[7];
        const input_joint = this.stringToBoolean(propsArray[8]);

        // const joint = new Joint(propsArray[0], parseFloat(propsArray[1]), parseFloat(propsArray[2]), propsArray[3]);
        // const ground = this.stringToBoolean(propsArray[4]);
        // const input = this.stringToBoolean(propsArray[5]);
        const joint = new Joint(id, parseFloat(x), parseFloat(y), type, parseFloat(angle), parseFloat(coefficient_of_friction));
        joint.input = input_joint;
        joint.grounded = ground_joint;
        if (input_joint) {
          joint.setInput(true, joint);
        } else if (joint.getSlider()) {
          joint.setSlider(true);
        } else if (ground_joint) {
          joint.setGrounded(true, type);
        }
        joint.links = links;
        // joint.setLinks(links);
        jointArray.push(joint);
      });
    }

    if (linkStart >= 0) {
      const linkArrayString = url.substring(linkStart + 3, forceStart < 0 ? url.length : forceStart);
      const linkStringArray = linkArrayString.split('\n');
      linkStringArray.forEach(linkString => {
        const propsArray = linkString.split(',');
        if (propsArray.length !== 16) { return; }

        const jointIDArray = propsArray[5].split('|');
        const forceIDArray = propsArray[6].split('|');
        // todo: needs input error checking
        const id = propsArray[0];
        const mass = this.stringToFloat(propsArray[1]);
        const mass_moi = this.stringToFloat(propsArray[2]);
        const CoM_X = this.stringToFloat(propsArray[3]);
        const CoM_Y = this.stringToFloat(propsArray[4]);
        const joints = this.getJointsByIds(jointIDArray, jointArray);
        const forces = this.getForcesByIds(forceIDArray, forceArray);
        const shapeFullname = this.shapeNicknameToFullname(propsArray[7]);
        const shape = this.stringToShape(shapeFullname);

        const b1 = new Coord(this.stringToFloat(propsArray[8]), this.stringToFloat(propsArray[9]));
        const b2 = new Coord(this.stringToFloat(propsArray[10]), this.stringToFloat(propsArray[11]));
        const b3 = new Coord(this.stringToFloat(propsArray[12]), this.stringToFloat(propsArray[13]));
        const b4 = new Coord(this.stringToFloat(propsArray[14]), this.stringToFloat(propsArray[15]));
        const arrow_x = (b1.x + b2.x + b3.x + b4.x) / 4;
        const arrow_y = (b1.x + b2.x + b3.x + b4.x) / 4;
        const arrow = new Coord(arrow_x, arrow_y);

        const newLink = new Link(id, joints, shape, { b1: b1, b2: b2, b3: b3, b4: b4, arrow: arrow });
        newLink.mass = mass;
        newLink.massMomentOfInertia = mass_moi;
        newLink.centerOfMassX = CoM_X;
        newLink.centerOfMassY = CoM_Y;
        newLink.forces = forces;
        linkArray.push(newLink);
      });
    }


    if (forceStart >= 0) {
      // const forceArrayString = url.substring(forceStart + 4, url.length);
      const forceArrayString = url.substring(forceStart + 3, pathPointStart < 0 ? url.length : pathPointStart);
      const forceStringArray = forceArrayString.split('\n');
      forceStringArray.forEach(forceString => {
        const propsArray = forceString.split(',');
        if (propsArray.length !== 10) { return; }

        const id = propsArray[0];
        const linkId = propsArray[1];
        const link = linkArray.find(l => {
          return l.id === linkId;
        });
        if (!link) { throw new Error('link referenced in force does not exist'); }
        const start = new Coord(this.stringToFloat(propsArray[2]), this.stringToFloat(propsArray[3]));
        const end = new Coord(this.stringToFloat(propsArray[4]), this.stringToFloat(propsArray[5]));
        const global = this.stringToBoolean(propsArray[6]);
        const direction = this.stringToBoolean(propsArray[7]);
        const xMag = this.stringToFloat(propsArray[8]);
        const yMag = this.stringToFloat(propsArray[9]);
        const newForce = new Force(id, link, start, end, global);
        newForce.directionOutward = direction;
        newForce.xMag = xMag;
        newForce.yMag = yMag;
        forceArray.push(newForce);
      });
    }

    if (pathPointStart >= 0) {
      const pathPointArrayString = url.substring(pathPointStart + 4, threePositionStart < 0 ? url.length : threePositionStart);
      const pathPointStringArray = pathPointArrayString.split('\n');
      pathPointStringArray.forEach(ppString => {
        const propsArray = ppString.split(',');
        if (propsArray.length !== 6) { return; }
        const id = propsArray[0];
        const x = this.stringToFloat(propsArray[1]);
        const y = this.stringToFloat(propsArray[2]);
        const newPathPoint = new PathPoint(id, x, y);
        pathPointArray.push(newPathPoint);
      });
      pathPointStringArray.forEach((ppString, index) => {
        const propsArray = ppString.split(',');
        if (propsArray.length !== 6) { return; }
        pathPointArray[index].neighbor_one = this.getPathPointById(propsArray[3], pathPointArray);
        pathPointArray[index].neighbor_two = this.getPathPointById(propsArray[4], pathPointArray);
      });
    }

    if (threePositionStart >= 0) {
      const threePositionArrayString = url.substring(threePositionStart + 4, settingsStart < 0 ? url.length : settingsStart);
      const threePositionStringArray = threePositionArrayString.split('\n');
      threePositionStringArray.forEach(tpString => {
        const propsArray = tpString.split(',');
        if (propsArray.length !== 10) { return; }
      });
    }

    if (settingsStart >= 0) {
      const settingArrayString = url.substring(settingsStart + 3, url.length);
      const propsArray = settingArrayString.split(',');
      if (propsArray.length !== 4) {return; }
      const input_speed_mag = this.stringToFloat(propsArray[0]);
      const clockwise = this.stringToBoolean(propsArray[1]);
      const gravity = this.stringToBoolean(propsArray[2]);
      const unit = propsArray[3];
      settings_ = {input_speed_mag, clockwise, gravity, unit};
    }
    return { jointArray, linkArray, forceArray, pathPointArray, threePositionArray, settings_};
  }

  stringToFloat(string: string): number {
    const res = parseFloat(string);
    if (Number.isNaN(res)) {
      throw new Error('should be a number, file corrupted');
    }
    return res;
  }


  stringToBoolean(string: string): boolean {
    let groundBool: boolean;
    const lowerCaseString = string.toLowerCase();
    if (lowerCaseString === 'true' || lowerCaseString === 't') {
      groundBool = true;
    } else if (lowerCaseString === 'false' || lowerCaseString === 'f') {
      groundBool = false;
    } else {
      throw new Error('should be a boolean, file corrupted');
    }
    return groundBool;
  }

  stringToShape(string: string): Shape {
    const shape = Object.entries(Shape).find(entry => {
      return entry[0] === string;
    });
    if (shape) {
      return shape[1];
    } else {
      throw new Error('incorrect shape, file corrupted');
    }
  }

  getJointsByIds(idArray: string[], jointArray: Joint[]): Joint[] {
    const result = [];
    jointArray.forEach(joint => {
      if (idArray.includes(joint.id)) {
        result.push(joint);
      }
    });
    return result;
  }

  getForcesByIds(idArray: string[], forceArray: Force[]): Force[] {
    const result = [];
    forceArray.forEach(force => {
      if (idArray.includes(force.id)) {
        result.push(force);
      }
    });
    return result;
  }

  getLinksByIds(idArray: string[], linkArray: Link[]): Link[] {
    const result = [];
    linkArray.forEach(link => {
      if (idArray.includes(link.id)) {
        result.push(link);
      }
    });
    return result;
  }

  getPathPointById(id: string, pathPointArray: PathPoint[]): PathPoint {
    let desired_path_point: PathPoint;
    pathPointArray.forEach(pp => {
      if (id.includes(pp.id)) {
        desired_path_point = pp;
      }
    });
    return desired_path_point;
  }

  getNewJointID(IDArray: string[]): string {
    let increment = 0;
    let currentID = '';
    do {
      increment++;
      currentID = IndiFuncs.jointIncrementToId(increment);
    } while (IDArray.includes(currentID));
    return currentID;
  }

  getNewLinkID(joints: Joint[]): string {
    let currentID = '';
    for (let i = 0; i < joints.length; i++) {
      const addOn = joints[i].id;
      currentID = currentID + addOn;
    }
    return currentID;
  }

  getNewForceID(IDArray: string[]): string {
    let increment = 0;
    let currentID = '';
    do {
      increment++;
      currentID = increment.toString();
    } while (IDArray.includes(`F${currentID}`));
    return `F${currentID}`;
  }

  shapeFullnameToNickname(fullname: string): string {
    return this.shapeNameMap[fullname];
  }

  shapeNicknameToFullname(nickname: string): string {
    const nameEntry = Object.entries(this.shapeNameMap).find(entry => entry[1] === nickname);
    return nameEntry !== undefined ? nameEntry[0] : undefined;
  }

  // determineCenterOfMassX(uiBounds: Bounds, shape: Shape) {
  //   const uiBoundsArray = [uiBounds.b1, uiBounds.b2, uiBounds.b3, uiBounds.b4];
  //   // determineCenterOfMassX(uiBounds: Bounds, shape: Shape.line | Shape.bar | Shape.eTriangle | Shape.rTriangle | Shape.rectangle |
  //   //   Shape.square | Shape.circle | Shape.cShape | Shape.tShape | Shape.lShape) {
  //   let link_x = 0;
  //   switch (shape) {
  //     case Shape.bar:
  //       uiBoundsArray.forEach(ui => {
  //         link_x += ui.x / 4;
  //       });
  //       break;
  //     case Shape.circle:
  //       uiBoundsArray.forEach(ui => {
  //         link_x += ui.x / 4;
  //       });
  //       break;
  //     case Shape.cShape:
  //       uiBoundsArray.forEach(ui => {
  //         link_x += ui.x / 4;
  //       });
  //       break;
  //     case Shape.eTriangle:
  //       uiBoundsArray.forEach(ui => {
  //         link_x += ui.x / 4;
  //       });
  //       break;
  //     case Shape.line:
  //       uiBoundsArray.forEach(ui => {
  //         link_x += ui.x / 4;
  //       });
  //       break;
  //     case Shape.lShape:
  //       uiBoundsArray.forEach(ui => {
  //         link_x += ui.x / 4;
  //       });
  //       break;
  //     case Shape.rectangle:
  //       uiBoundsArray.forEach(ui => {
  //         link_x += ui.x / 4;
  //       });
  //       break;
  //     case Shape.rTriangle:
  //       uiBoundsArray.forEach(ui => {
  //         link_x += ui.x / 4;
  //       });
  //       break;
  //     case Shape.square:
  //       uiBoundsArray.forEach(ui => {
  //         link_x += ui.x / 4;
  //       });
  //       break;
  //     case Shape.tShape:
  //       uiBoundsArray.forEach(ui => {
  //         link_x += ui.x / 4;
  //       });
  //       break;
  //   }
  //   return link_x;
  // }
  //
  // determineCenterOfMassY(uiBounds: Bounds, shape: Shape) {
  //   const uiBoundsArray = [uiBounds.b1, uiBounds.b2, uiBounds.b3, uiBounds.b4];
  //   let link_y = 0;
  //   switch (shape) {
  //     case Shape.bar:
  //       uiBoundsArray.forEach(ui => {
  //         link_y += ui.x / 4;
  //       });
  //       break;
  //     case Shape.circle:
  //       uiBoundsArray.forEach(ui => {
  //         link_y += ui.x / 4;
  //       });
  //       break;
  //     case Shape.cShape:
  //       uiBoundsArray.forEach(ui => {
  //         link_y += ui.x / 4;
  //       });
  //       break;
  //     case Shape.eTriangle:
  //       uiBoundsArray.forEach(ui => {
  //         link_y += ui.x / 4;
  //       });
  //       break;
  //     case Shape.line:
  //       uiBoundsArray.forEach(ui => {
  //         link_y += ui.x / 4;
  //       });
  //       break;
  //     case Shape.lShape:
  //       uiBoundsArray.forEach(ui => {
  //         link_y += ui.x / 4;
  //       });
  //       break;
  //     case Shape.rectangle:
  //       uiBoundsArray.forEach(ui => {
  //         link_y += ui.x / 4;
  //       });
  //       break;
  //     case Shape.rTriangle:
  //       uiBoundsArray.forEach(ui => {
  //         link_y += ui.x / 4;
  //       });
  //       break;
  //     case Shape.square:
  //       uiBoundsArray.forEach(ui => {
  //         link_y += ui.x / 4;
  //       });
  //       break;
  //     case Shape.tShape:
  //       uiBoundsArray.forEach(ui => {
  //         link_y += ui.x / 4;
  //       });
  //       break;
  //   }
  //   return link_y;
  // }
}

export const StaticFuncs = new StaticFuncColle();
