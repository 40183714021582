import {Vertex} from './Vertex';
import {SVGFuncs} from '../functions/SVGFuncs';

// export class PathPoint extends Vertex {
export class PathPoint extends Vertex {
  // x: number;
  // y: number;
  // ID: string;
  private _neighbor_one: PathPoint;
  private _neighbor_two: PathPoint;
  private _svg: SVGElement;

  constructor(id: string, x: number, y: number) {
    super(id, x, y);
    this._svg = SVGFuncs.createPathPoint(id, x, y);
  }

  get svg() {
    return this._svg;
  }

  get neighbor_one(): PathPoint {
    return this._neighbor_one;
  }

  set neighbor_one(value: PathPoint) {
    this._neighbor_one = value;
  }

  get neighbor_two(): PathPoint {
    return this._neighbor_two;
  }

  set neighbor_two(value: PathPoint) {
    this._neighbor_two = value;
  }

  relocate(x: number, y: number) {
    this.x = x;
    this.y = y;
    this._svg.setAttributeNS(undefined, 'x', x.toString());
    this._svg.setAttributeNS(undefined, 'y', y.toString());
  }
}
