// force on a link.
import {Link} from './Link';

export class Force {
  id: string;
  sx: number; // start
  sy: number; //
  ex: number; // end
  ey: number;
  srx: number; // start relative X
  sry: number; // start relative Y
  erx: number; // start relative X
  ery: number; // start relative Y
  onlink: string;
  isGlobal: boolean;

  // todo: convert length of the force arrow to some x and y component value of the overall force with direction and add to force fields x/y
  // todo: used and needed for static's Solver. Initialize them properly on creation or update them on modification -------------
  link: Link;
  // x location of the force on the link (location where they force connects with the link) --- currently uses sx assuming it is on link
  // private readonly _xLocOfForceOnLink: number;
  // y location of the force on the link (location where they force connects with the link) --- currently uses sy assuming it is on link
  // private readonly _yLocOfForceOnLink: number;
  // xLocOfForceOnLink: number;
  // yLocOfForceOnLink: number;
  xForce: number; // x component of the force in newtons (direction matters. AKA: +, -)
  yForce: number; // y component of the force in newtons (direction matters. AKA: +, -)

  constructor(id: string, sx: number, sy: number, ex: number, ey: number, srx: number, sry: number, erx: number, ery: number,
              xMag: number, yMag: number, link: string, isGlobal?: boolean) {
    this.id = id;
    this.sx = sx;
    this.sy = sy;
    this.ex = ex;
    this.ey = ey;
    this.srx = srx;
    this.sry = sry;
    this.erx = erx;
    this.ery = ery;
    this.onlink = link;
    this.isGlobal = isGlobal === undefined ? true : isGlobal;
    this.xForce = xMag;
    this.yForce = yMag;

    // update this if needed later --- needed for static forces calculations
    // this.xLocOfForceOnLink = sx;
    // this.yLocOfForceOnLink = sy;
  }

  // getters -------------------------------------------------------------------------------------------------------------
  // get xLocOfForceOnLink(): number {
  //   return this._xLocOfForceOnLink;
  // }
  //
  // get yLocOfForceOnLink(): number {
  //   return this._yLocOfForceOnLink;
  // }
}
