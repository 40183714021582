import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {TimeSortedList} from '../../simulator/TimeSortedList';
import {Joint} from '../../classes/Joint';
import {Link} from '../../classes/Link';

@Component({
  selector: 'app-animationbar',
  templateUrl: './animationbar.component.html',
  styleUrls: ['./animationbar.component.css']
})
export class AnimationbarComponent implements OnInit, OnChanges {
  @Input() sliderPos: number;
  @Input() animationRunning: boolean;
  @Input() animationMode: boolean;
  @Input() synthesisDisplay: string;
  @Input() posTimeSortedList: TimeSortedList;

  @Output() newStopEmit = new EventEmitter<undefined>();
  @Output() newPauseEmit = new EventEmitter<undefined>();
  @Output() newResetLinkageEmit = new EventEmitter<undefined>();
  @Output() newStartEmit = new EventEmitter<number>();
  @Output() newSetEmit = new EventEmitter<number>();
  @Output() newZoomEmit = new EventEmitter<number>();
  @Output() newSpeedChangeEmit = new EventEmitter<string>();
  @Output() newDirectionChangeEmit = new EventEmitter<string>();
  @Output() toolbarDirectionChangeEmit = new EventEmitter<string>();
  @Output() newShowIdEmit = new EventEmitter<undefined>();
  @Output() newShowCoMEmit = new EventEmitter<undefined>();
  @Output() sliderMousedownEmit = new EventEmitter<undefined>();
  @Output() sliderMouseupEmit = new EventEmitter<undefined>();

  slider: HTMLInputElement;
  constructor() { }

  ngOnInit() {
    this.slider = <HTMLInputElement> document.getElementById('slider');
    this.slider.disabled = !this.animatable();
  }

  animatable() {
    return this.posTimeSortedList.TSL.length > 0;
  }


  ngOnChanges(changes: SimpleChanges) {
    if (changes.sliderPos !== undefined) {
      this.sliderPos !== undefined && this.setSlider(this.sliderPos);
    }
    if (changes.animationRunning !== undefined) {
      if (this.animationRunning) {
        // when animation is running, change play button to pause and disable the slider
        this.playButtonToPause();
        if (this.slider) { this.slider.disabled = true; }
      } else {
        // when animation is not running, change pause button to play and enable the slider
        this.pauseButtonToPlay();
        if (this.slider) { this.slider.disabled = false; }
      }
    }
    if (changes.animationMode !== undefined) {
      if (!this.animationMode) {
        this.pauseButtonToPlay();
      }
    }
    if (changes.timeSortedList !== undefined && this.slider) {
      this.slider.disabled = !this.animatable();
    }
  }

  stopAnimation() {
    this.newStopEmit.emit();
  }

  startAnimation() {
    this.animationRunning ? this.newPauseEmit.emit() : this.newStartEmit.emit();
  }

  playButtonToPause() {
    const playPause = <HTMLButtonElement> document.getElementById('playBtn');
    if (playPause) { playPause.id = 'pauseBtn'; }
  }

  pauseButtonToPlay() {
    const playPause = <HTMLButtonElement> document.getElementById('pauseBtn');
    if (playPause) { playPause.id = 'playBtn'; }
  }

  setAnim() {
    if (!this.animatable()) { return; }
    const progress = parseInt(this.slider.value, 10);
    this.newSetEmit.emit(progress);
  }

  setSlider(pos: number) {
    this.slider.value = pos.toString();
  }

  onResetLinkagePressed() {
    this.newResetLinkageEmit.emit();
  }

  onZoomResetPressed() {
    this.newZoomEmit.emit(0);
  }

  onZoomInPressed() {
    this.newZoomEmit.emit(1);
  }

  onZoomOutPressed() {
    this.newZoomEmit.emit(-1);
  }
  // event emitting function for the speed change.
  onSpeedChange() {
    let speed = '';
    if (<HTMLButtonElement> document.getElementById('mediumspeed')) {
      const speedButton = <HTMLButtonElement> document.getElementById('mediumspeed');
      speedButton.id = 'fastspeed';
      speed = '3';
    } else if (<HTMLButtonElement> document.getElementById('fastspeed')) {
      const speedButton = <HTMLButtonElement> document.getElementById('fastspeed');
      speedButton.id = 'slowspeed';
      speed = '1';
    } else if (<HTMLButtonElement> document.getElementById('slowspeed')) {
      const speedButton = <HTMLButtonElement> document.getElementById('slowspeed');
      speedButton.id = 'mediumspeed';
      speed = '2';
    }
    this.newSpeedChangeEmit.emit(speed);
  }

  onDirectionChange() {
    let dir;
    if (<HTMLButtonElement> document.getElementById('counterclockwise')) {
      const direction = <HTMLButtonElement> document.getElementById('counterclockwise');
      direction.id = 'clockwise';
      dir = 'clockwise';
    } else {
      const direction = <HTMLButtonElement> document.getElementById('clockwise');
      direction.id = 'counterclockwise';
      dir = 'counterclockwise';
    }
    this.newDirectionChangeEmit.emit(dir);
    this.toolbarDirectionChangeEmit.emit(dir);
  }

  showCenterOfMass() {
    let showCoMLabel;
    if (<HTMLButtonElement> document.getElementById('showCoM')) {
      const direction = <HTMLButtonElement> document.getElementById('showCoM');
      direction.id = 'showCoMOn';
      showCoMLabel = 1;
    } else {
      const direction = <HTMLButtonElement> document.getElementById('showCoMOn');
      direction.id = 'showCoM';
      showCoMLabel = 0;
    }
    this.newShowCoMEmit.emit(showCoMLabel);
  }

  onShowIDPressed() {
    let showIDNum;
    if (<HTMLButtonElement> document.getElementById('showID')) {
      const direction = <HTMLButtonElement> document.getElementById('showID');
      direction.id = 'showIDOn';
      showIDNum = 1;
    } else {
      const direction = <HTMLButtonElement> document.getElementById('showIDOn');
      direction.id = 'showID';
      showIDNum = 0;
    }
    this.newShowIdEmit.emit(showIDNum);
  }
}
